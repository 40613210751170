import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import React, { CSSProperties, LegacyRef, useEffect, useState } from "react";
require("../components/comum_css/rawCss.css");

type AppProps = {
  displayDialog: boolean;
  onHide: () => void;
  children?: React.ReactNode;
  contentStyle?: object;
  headerStyle?: CSSProperties | undefined;
  ["string"]?: string;
};

// type MessageSeverityType = "success" | "info" | "warn" | "error";

// type messageType = {
//   type?: MessageSeverityType;
//   title: string;
// };
// const defaultMessage = {
//   // type: '',
//   title: ""
// };

const addWorkspace = React.forwardRef(
  (
    {
      displayDialog,
      onHide,
      children,
      contentStyle,
      headerStyle,
      ...rest
    }: AppProps,
    ref: LegacyRef<Dialog> | undefined
  ) => {
    const [showMessage, setShowMessage] = useState(false);
    // const [messageData, setMessageData] = useState<messageType>(defaultMessage);

    // hide message after 5 secunds
    useEffect(() => {
      setTimeout(() => {
        setShowMessage(false);
      }, 8000);
    }, [showMessage]);

    const hideDialog = () => {
      onHide();
    };

    // function apresentaMensagem(messageObj: messageType): void {
    //   setMessageData(messageObj);
    //   setShowMessage(true);
    // }

    // const defDialogCss = { width: '50vw', height: '29vw' }
    // const dialogPreference = { width: '50vw',  height: '30vw' }

    const modalStyle = css`
      width: 65vw;

      @media (max-width: 870px) {
        width: 85vw;
      }
    `;

    return (
      <div>
        <Dialog
          ref={ref}
          className="addWspaceDialog relative"
          css={modalStyle}
          visible={displayDialog}
          closeOnEscape={true}
          resizable={false}
          closable={true}
          keepInViewport={false}
          draggable={false}
          onHide={() => hideDialog()}
          contentStyle={contentStyle}
          headerStyle={headerStyle}
          {...rest}
        >
          <div className="rigtContent"></div>
          {children}
        </Dialog>
      </div>
    );
  }
);

export default addWorkspace;
