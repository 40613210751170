import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Back, BluePencil, Export } from "../../../../assets";
import {
  ButtonBack,
  ButtonBackground,
  ButtonSalvar,
  DivIcon,
  DivRight,
  DivTextButton,
  H1,
  HeaderDiv,
  IconButton,
  IconButtonBackground
} from "./styles";
import { setBotIdActive } from "../../../../redux/slices/flowInteractions";
import BotsServices from "../../../../services/bots";
import {
  setActive,
  setCompanyId,
  setDefaultFallBackMessage,
  setFirstBotInteractions,
  setName,
  setTarget,
  setWorkspaceId
} from "../../../../redux/slices/bot";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { useEffect, useState } from "react";
import { useToast } from "../../../../components/toast-provider";

export const HeaderGestaoBot: React.FC = () => {
  const redirect = useNavigate();
  const dispatch = useAppDispatch();
  const BotActiveId = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const valueTarget = useAppSelector((state) => state.bot.target);
  const botObject = useAppSelector((state) => state.bot);
  const [loading, setloading] = useState(false);
  const { GetBotResume, UpdateBot } = BotsServices();
  const { company } = useAuth() as AuthContextType;
  const { botId } = useParams();
  const toast = useToast();
  const [showButtonEditScript, setShowButtonEditScript] = useState(true);

  useEffect(() => {
    const handleShowEditScript = async (BotId: number) => {
      const activeFlowId = await GetBotResume(BotId);
      if (activeFlowId.target === 4) {
        setShowButtonEditScript(false);
      } else if (activeFlowId.target !== 4) {
        setShowButtonEditScript(true);
      }
    };
    if (botId) {
      handleShowEditScript(parseInt(botId));
    }
  }, [loading]);

  useEffect(() => {
    const BotId = botId ? parseInt(botId) : 0;
    const getResumeBot = async () => {
      dispatch(setBotIdActive(BotId));
    };
    if (!isNaN(BotId)) {
      getResumeBot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  const handleEditarScript = async (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    const activeFlowId = await GetBotResume(BotId);
    dispatch(setName(activeFlowId?.name));
    dispatch(setActive(activeFlowId?.active));
    dispatch(setTarget(activeFlowId?.target));
    dispatch(setDefaultFallBackMessage(activeFlowId?.defaulFallbackMessages));
    dispatch(setCompanyId(company?.id));
    dispatch(setWorkspaceId(activeFlowId?.workspaceId));
    dispatch(setFirstBotInteractions(activeFlowId?.liveChatInteractions));

    redirect(`/drawflow/${BotId}/${activeFlowId?.activeBotFlowId}`);
  };

  const handleSave = async () => {
    try {
      setloading(true);
      const newbotObject = botObject;
      const res = await UpdateBot(BotActiveId, newbotObject);
      if (res.id) {
        toast?.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Dados atualizados com sucesso!"
        });
      }
    } catch (error: any) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: error.message
      });
    } finally {
      setloading(false);
    }

    setloading(false);
  };

  return (
    <div css={HeaderDiv}>
      <button
        type="button"
        css={ButtonBack}
        onClick={() => redirect("/chatbots")}
      >
        <img src={Back} alt="Button Back" />
      </button>
      <h1 className="ml-2" css={H1}>
        Gestão do Bot
      </h1>
      <div css={DivRight}>
        {showButtonEditScript && (
          <button
            type="button"
            onClick={() => handleEditarScript(BotActiveId)}
            className="ml-4"
            css={ButtonBackground}
          >
            <div css={DivIcon}>
              <img
                css={IconButtonBackground}
                src={BluePencil}
                alt="IconButton Blue Pencil"
              />
            </div>
            <div css={DivTextButton}>Editar Script</div>
          </button>
        )}
        <button
          type="button"
          disabled={loading}
          onClick={handleSave}
          css={ButtonSalvar}
        >
          Salvar
        </button>
      </div>
    </div>
  );
};
