/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";

interface iDropzone {
  isHover: boolean;
  isAllAccepted: {
    accepted: "all" | "some" | "none"
  };
  expanded: string;
}
export const DropZone = styled.div<iDropzone>`
position: absolute;
background-color: #FFF;
z-index: 10;
width: 88%;
min-height: 10.5rem;
height: ${props => props.expanded};
top: 2.7rem;
left: 1rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
border:  ${   props => props.isAllAccepted.accepted === "some" ? "2px solid #FBBC04" : 
              props.isAllAccepted.accepted === "none" ? "2px solid #FF0025" : 
              "2px solid #02CDAD"
          };
border-radius: 1.25rem 1.25rem 0 1.25rem;
box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
`;
export const FileUploaderContainer = styled.div`
position: relative;
.file-list {
  margin-top: 20px;
}

`;
interface iFileUploader {
  open: boolean;
}
export const FileUploader = styled.div<iFileUploader>`
  position: relative;
  
  display: ${props => props.open ? "block" : "none"};
`;
// export const ButtonClose = styled.div`
// position: absolute;
// z-index: 11;
// right: 0rem;
// bottom: 0;
// width: 1rem;
// height: 1rem;
// `;
export const HiddenFileInput = styled.input`
  display: none;
`;


interface iImgRotate {
  rotate: number;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}
export const ImgRotate = styled.img<iImgRotate>`
transform: ${props => props.rotate ? `rotate(${props.rotate.toString()}deg)` : `rotate(45deg)`};
position: absolute;
left: ${props => props.left ? props.left : "unset"};
right: ${props => props.right ? props.right : "unset"};
top: ${props => props.top ? props.top : "unset"};
bottom: ${props => props.bottom ? props.bottom : "unset"};
`;

export const PhotoVideoContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 2.6875rem;
height: 2.6875rem;
border-radius: 0.75rem;
background-color: #E5FAF7;
`;

export const MyIconButton = styled.button`
position: absolute;
z-index: 11;
right: calc(12% - 1.6rem);
bottom: -10.2rem;
width: 1.5rem;
height: 1.5rem;
display: flex;
align-items: center;
justify-content: center;
background: #FFFFFF;
border: 1px solid #D2D2D2;
border-radius: 50%;
transition: all 0.2s;
:hover {
background-color: #024AAE;
cursor: pointer;
}
`;