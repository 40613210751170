import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = css`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  display: block;
  margin-bottom: 2rem;
`;

export const Title = css`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  margin: 1.5rem 0 1rem 1.5rem;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0a043c;
  font-family: "Poppins";
`;

export const Icon = css`
  background: #d4e2f3;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface IActive {
  active: boolean;
}
export const Active = styled.div<IActive>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin: 0 auto;
  background-color: ${(props) => (props.active ? "#6FCF97" : "#801515")};
`;

export const Button = css`
  height: 28px;
  background: #d4e2f3;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #046af3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
