import { css } from "@emotion/react";
import { format, parseISO } from "date-fns";
import { Message as IMessage, iDialogMessagesFile } from "../use-omnichannel";
import MessageButton, { Message, StatusMessage } from "./message";

export interface MessagesProps {
  dialog: IMessage[];
  dialogInfo: iDialogMessagesFile;
  shouldScroll: boolean;
  onShouldScroll: () => void;
  messageRef: React.RefObject<HTMLDivElement>;
}

const Messages = ({
  dialog,
  dialogInfo,
  // shouldScroll,
  // onShouldScroll,
  messageRef
}: MessagesProps) => {
  // const messageContainerRef = useRef<HTMLDivElement>(null);
  // const orderedMessages = dialog?.messages?.sort((a, b) => a.id - b.id);

  return (
    <div
      css={css`
        padding: 20px;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;

        &&::-webkit-scrollbar {
          width: 5px;
          background-color: transparent;
          border-radius: 4px;
        }

        &&::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
          border-radius: 4px;
        }

        @media screen and (max-width: 599px) {
          padding: 0;
        }
      `}
      // ref={messageContainerRef}
    >
      {dialogInfo?.dateTime ? (
        <StatusMessage
          message={`Conversa nº ${dialogInfo?.id} - ${format(
            parseISO(dialogInfo?.dateTime),
            "dd/MM/yyyy HH:mm"
          )}`}
        />
      ) : null}

      {dialog?.map((m) => {
        const dateTime = parseISO(m?.dateTime);
        return (
          <div ref={messageRef} key={m.id}>
            <Message
              date={dateTime}
              reply={m.sender !== "CLIENT"}
              isHumanResponder={m.sender === "HUMAN"}
              robotName={dialogInfo.botName}
              chatName={dialogInfo.name}
              message={m.message}
              files={m.files}
            />

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 0px 20px 10px 20px;
              `}
            >
              {m.buttons
                ? m.buttons.map((b) => (
                    <MessageButton key={b.id} label={b.label} />
                  ))
                : null}
            </div>
          </div>
        );
      })}

      {/* {messageHistory.map((m) => {
        const dateTime = parseISO(m.dateTime);

        return (
          <div
            key={m.id}
            css={css`
              width: 100%;
            `}
          >
            <Message
              date={dateTime}
              reply={m.sender !== "CLIENT"}
              isHumanResponder={m.sender === "HUMAN"}
              robotName={dialog.botName}
              chatName={dialog.name}
              message={m.message}
              file={m.file}
            />

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 10px;
              `}
            >
              {m.buttons
                ? m.buttons.map((b) => (
                    <MessageButton key={b.id} label={b.label} />
                  ))
                : null}
            </div>
          </div>
        );
      })} */}
    </div>
  );
};

export default Messages;
