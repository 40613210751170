import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = css`
  position: relative;
  background: #ffffff;
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  padding: 1.3rem 1.6rem;
  height: 100%;
  max-height: 17.2375rem;
`;
export const CardHover = css`
  position: absolute;
  top: -16px;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  padding: 1.3rem 1.6rem;
`;
export const DivIcon = css`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: #d4e2f3;
`;

export const Title = css`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0a043c;
`;

export const Channels = css`
  display: block;
  margin-top: 1.5rem;
  gap: 2.2rem;
  width: 100%;
`;

export const Subtitle = css`
  font-size: 12px;
  line-height: 16px;
  color: #6e6b7b;
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  @media (min-width: 1440px) {
    gap: 1rem;
  }
`;

export const MyLink = styled.a`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px 10px 10px;
  text-decoration: none;
  background: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 0.3rem;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 800px;
  :hover {
    cursor: pointer;
    color: #fff;
    background-color: #7887ab;
    transition: 0.2s ease-in-out;
  }
  padding: 0.7rem;
  border: 2px solid #acd0f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  color: #999999;
  margin-top: 1.1rem;
  height: 50px;
  width: 116px;
`;
export const Inactive = styled.div`
  display: flex;
  background-color: #fff;
  opacity: 0.5;
`;
