import {
  IRobotInteraction,
  NodeData
} from "./../components/react-flow-renderer/NodeData.d";
import { iNodesSave } from "../components/react-flow-renderer/NodeData";
import { Edge, EdgeProps, getIncomers, getOutgoers, Node } from "react-flow-renderer";
import { deserializeBotInteractions } from "./deserializeOnLoad";

interface iRetorno {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeProps>[];
}
interface ILevelsCount {
  nodeId: string;
  incomers: Node<NodeData>[];
  incomersLength: number;
  outgoers: Node<NodeData>[];
  outgoersLength: number;
  outgoersIds: string[];
  visited: boolean;
  incomersIds: string[];
}
export const getIdByLabel = (nodeLabel = '', nodes: iNodesSave[]): string => {
  if (nodeLabel) {
    const nodeId = nodes.find(node => node.name === nodeLabel.replaceAll("_", " "))?.id
    if (nodeId) { return nodeId.toString(); }
  }
  return ""
}
export const onLoad = (nodesFromDB: iNodesSave[]): iRetorno => {
  const nodes = nodesFromDB.map((node) => {
    return {
      id: node.id.toString(),
      dragging: true,
      position: {
        x: node.x || 0,
        y: node.y || 0
      },
      type: node.end ? "endNode" : node.first ? "startNode" : "defaultNode",
      data: {
        label: node.name,
        userInteractions: node.userInteractios!.map((ui, idx) => {
          return {
            id: (ui.id).toString(),
            source: getIdByLabel(ui.source, nodesFromDB),
            target: getIdByLabel(ui.target, nodesFromDB),
            comparison: "Igual a",
            phrases: ui.phrases,
            entityTypeName: ui.entityTypeName ? ui.entityTypeName : "",
            entityTypeId: ui.entityTypeId ? ui.entityTypeId : -1,
          };
        }),
        botInteractions: node.botInteractions!.map((bi, idx) => {
          return {
            id: (bi.id).toString(),
            text: deserializeBotInteractions(bi.interaction, nodesFromDB),
            entityName: bi?.entityName,
            entityTypeId: bi?.entityTypeId,
            buttons: bi?.buttons || [],
            files: bi?.files?.map(file => {
              return {
                ...file,
                id: file?.identifier
              }
            })
          };
        }),
        fallbackMessages: node.fallbackMessages,
        entityTypeId: node?.userInteractios?.length === 1 ? node?.userInteractios[0]?.entityTypeId : -1,
        entityTypeName: node?.userInteractios?.length === 1 ? node?.userInteractios[0]?.entityTypeName : "",
        sourceEntityNodeId: "",
        transfer: node?.transfer || undefined,
        transferData: node?.transferDto || undefined,
        webhookCall: node.callWebhook || false,
      },
      width: 196,
      height: 41,
      selected: false
    };
  });
  const edgesMount = nodesFromDB.map((edges) => {
    return edges?.userInteractios?.map((ui) => {
      return {
        id: `reactflow__edge-${getIdByLabel(ui.source, nodesFromDB)}a-${getIdByLabel(ui.target, nodesFromDB)}`,
        source: getIdByLabel(ui.source, nodesFromDB),
        sourceHandle: "a",
        target: getIdByLabel(ui.target, nodesFromDB),
        targetHandle: null,
        animated: false,
        style: {
          stroke: "rgba(217, 217, 217, 1)",
          strokeWidth: "2px"
        },
        markerEnd: { type: "arrowclosed" }
      };
    });
  });
  const edges: any[] = [];
  edgesMount?.map((edge) => {
    if (Array.isArray(edge)) {
      if (edge.length > 1) {
        return edge.map((subedges) => {
          edges.push({ ...subedges });
        });
      }
      if (edge.length === 1) {
        return edges.push(edge[0]);
      }



    }
  });
  return { nodes, edges };
};

export const setPositions = (
  nodes: Node<NodeData>[],
  edges: Edge<EdgeProps>[]
): iRetorno => {
  const NodesChildren = nodes
    .map((_node) => {
      return {
        nodeId: _node.id,
        incomers: getIncomers(_node, nodes, edges),
        incomersIds: getIncomers(_node, nodes, edges)
          .map((__node) => __node.id)
          .filter((notnulls) => notnulls !== null),
        outgoersIds: getOutgoers(_node, nodes, edges)
          .map((__node) => __node.id)
          .filter((notnulls) => notnulls !== null),
        incomersLength: getIncomers(_node, nodes, edges).length || 0,
        outgoers: getOutgoers(_node, nodes, edges),
        outgoersLength: getOutgoers(_node, nodes, edges).length || 0,
        visited: false,
        level: -1,
        hlevel: -1
      };
    })
    .sort((a, b) => a.incomersLength - b.incomersLength);
  const updateLevel = (node: ILevelsCount): number => {
    let number = -1;
    NodesChildren.find((_node) => {
      if (node.incomersIds.includes(_node.nodeId) && _node.level !== -1) {
        number = _node.level + 1;
      }
    });
    return number;
  };
  NodesChildren.find((_node) => {
    if (_node.incomersLength) {
      _node.level = updateLevel(_node);
    }
    else {
      _node.level = 0;
    }
  });
  NodesChildren.find((_node) => {
    if (_node.level === -1) { _node.level = updateLevel(_node); }
  });
  const length = Math.max(...NodesChildren.map((_node) => _node.level));
  const ArrayCount = new Array(length + 1).fill(0);
  NodesChildren.map((node) => {
    ArrayCount[node.level] += 1;
  });
  const x = new Array(length + 1).fill(0);
  nodes.find((node) => {
    NodesChildren.find((_node) => {
      if (_node.nodeId === node.id) {
        node.position = {
          x: -(ArrayCount[_node.level] * 240) / 2 + 240 * x[_node.level],
          y: _node.level * 130
        };
        x[_node.level] += 1;
      }
    });
  });
  return { nodes, edges };
};
