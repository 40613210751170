import styled from "@emotion/styled";

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
width: 100%;
height: 15rem;
margin-top: -6rem;
`;

export const ImgCenter = styled.img`
display: flex;
align-items: center;
justify-content: center;
width: 4.125rem;
height: 4.125rem;
`;

export const ImgPontas = styled.img`
display: flex;
align-items: center;
justify-content: center;
width: 4.125rem;
height: 4.125rem;
margin-top: 4rem;
`;