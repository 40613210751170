import { BotPreview } from "../BotPreview";
import { Text } from "../Text";
import { Container } from "./styles"
interface IChatPreview {
    chatColor: string;
    botColor: string;
}
export const ChatPreview: React.FC<IChatPreview> = ({chatColor, botColor}) => {
    return (
        <Container color={chatColor}>
            <BotPreview color={botColor} />
            <Text color="#FFF">Olá, tudo bem?</Text>
        </Container>
    )
}