/* eslint-disable no-negated-condition */
/* eslint-disable react/button-has-type */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-warning-comments */
import React, { useRef, useState } from "react";
import {
  Button,
  ButtonStyleFull,
  ContainerRelative,
  DeleteButton,
  DivFlex,
  Divisor,
  DivRoundedBigBlue,
  DivRoundedBigRed,
  DivTags,
  IconCss,
  InputRounded,
  Message,
  QuestionStyle,
  RoundedButton,
  Strong,
  TextUserInteractionHeader,
  UserInteractionCSS,
  UserInteractionHeader,
  Hide
} from "./styles";
import { Button as ButtonPrime } from "primereact/button";
import { BluePencil, Boy, TrashRed } from "../../../../assets";
import MyTag from "./MyTag";
import { useAppSelector } from "../../../../redux/hooks";
import {
  addTag,
  deleteEntity,
  deleteTag,
  deleteUserInteraction,
  updateRobotInteraction,
  updateUserInteraction,
  updateUserInteractions
} from "../../../../redux/slices/nodes";
import { useDispatch } from "react-redux";
import { IUserInteraction } from "../../../react-flow-renderer/NodeData";
import {
  initEdges,
  onEdgesChange,
  setEdges,
  zerarEdges
} from "../../../../redux/slices/edges";
import { EdgeChange } from "react-flow-renderer";
import { Toast } from "primereact/toast";
import { changedPhoneComponent } from "../../../../redux/slices/flowupdates";
import {
  setCustomEntitiesModalOpen,
  setCustomEntityUpdating,
  setEntitiesModalOpen,
  setEntityIdUpdate,
  setTransferModal,
  setUpdatedEntities,
  setUserInteractionId
} from "../../../../redux/slices/flowInteractions";
import Dropdown from "../../../../components/dropdown";
import { DropdownChangeParams } from "primereact/dropdown";
import { Text } from "../../../../components/Text";
import { ISlateText } from "@App/util/deserializeOnLoad";
import { entityIsSetAsVariable } from "../../../../util/entityIsUsedAsVariable";
import { Dialog } from "primereact/dialog";
import { useLocation, useParams } from "react-router-dom";
interface IInteracaoUsuario {
  userInteraction: IUserInteraction;
  index: number;
  total?: number;
}
export const InteracaoUsuario: React.FC<IInteracaoUsuario> = ({
  userInteraction,
  index,
  total = 1
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { templateId } = useParams();

  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const modalOpen = useAppSelector(
    (state) => state.flowInteractions.entityModalOpen
  );
  const customModalOpen = useAppSelector(
    (state) => state.flowInteractions.customEntityModalOpen
  );
  const [nodeIsUsedAsVariableModal, setNodeIsUsedAsVariableModal] =
    useState(false);
  const toast = useRef<Toast>(null);
  const [variableToDelete, setVariableToDelete] = useState<{
    nodeId: string;
    entityName: string;
  }>({} as { nodeId: string; userInteractionId: string });
  const [tagToAdd, setTagToAdd] = useState("");
  // const [displayDropdown, setDisplayDropdown] = useState(false);
  // const [displayDropdownNodes, setDisplayDropdownNodes] = useState(false);
  const [nodeDestination, setNodeDestination] = useState("");
  const [deletingEntity, setDeletingEntity] = useState(false);
  const [deletingUserInteraction, setDeletingUserInteraction] = useState(false);
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  const nodeSelected = nodes.filter((_node) => _node.selected === true)[0];
  const nodeSelectedUserInteractionsLength =
    nodeSelected &&
    nodeSelected?.data &&
    nodeSelected?.data.userInteractions &&
    nodeSelected?.data.userInteractions.length;
  //
  const nodesLabel = nodes
    .filter((_node) => _node.type !== "startNode")
    .filter(
      (_nd) =>
        Number(_nd.data.level) > Number(nodeSelected.data.level) ||
        _nd.data.level === -1
    )
    .map((node) => {
      return {
        label: node?.data?.label,
        id: node?.id
      };
    })
    .filter(
      (takeOffSelected: { id: string }) =>
        takeOffSelected.id !== nodeSelected?.id
    )
    .filter(
      (takeOffOthersFromThisNode) =>
        !nodeSelected?.data?.userInteractions
          ?.map((_userInteraction) => _userInteraction?.target)
          .filter((notUndefineds) => notUndefineds !== undefined)
          .includes(takeOffOthersFromThisNode?.id)
    )
    .concat({
      label: nodes?.filter((_node) => _node?.id === userInteraction?.target)[0]
        ?.data?.label,
      id: userInteraction?.target as string
    });
  const handleEditEntity = (entityId: number) => {
    dispatch(setCustomEntityUpdating(true));
    dispatch(setEntityIdUpdate(entityId));
    dispatch(setCustomEntitiesModalOpen(!customModalOpen));
    dispatch(setTransferModal(false));
  };
  const handleDeleteEntity = () => {
    setDeletingEntity(true);
    setVariableToDelete({
      nodeId: nodeSelected.id,
      entityName: userInteraction.entityTypeName
    });
    const entityIsSetAsVariableOnAnotherLocal = entityIsSetAsVariable(
      userInteraction.entityTypeName,
      nodeSelected.id,
      nodes
    );
    if (entityIsSetAsVariableOnAnotherLocal?.length) {
      setDeletingEntity(true);
      setNodeIsUsedAsVariableModal(true);
    } else {
      dispatch(changedPhoneComponent(true));
      dispatch(
        deleteEntity({
          nodeId: nodeSelected?.id,
          userInteractionId: userInteraction.id
        })
      );
      setVariableToDelete({
        nodeId: "",
        entityName: ""
      });
      setDeletingEntity(false);
    }
  };
  const AcceptDelete = () => {
    // if deleting node check if it has more than one variable set

    const entityIsSetAsVariableOnAnotherLocal = entityIsSetAsVariable(
      userInteraction.entityTypeName,
      nodeSelected.id,
      nodes
    );
    // eslint-disable-next-line no-use-before-define
    EraseEntityFromBotId(
      entityIsSetAsVariableOnAnotherLocal,
      variableToDelete.nodeId,
      variableToDelete.entityName
    );

    if (deletingUserInteraction) {
      const edgesToDelete = {
        id: `reactflow__edge-${nodeSelected?.id}a-${userInteraction?.target}`,
        type: "remove",
        source: nodeSelected?.id,
        target: userInteraction?.target
      };
      const filterUserInteractions = nodeSelected.data.userInteractions.filter(
        (el) => el.id !== userInteraction?.id
      );
      setDeletingEntity(false);
      setDeletingUserInteraction(false);
      dispatch(changedPhoneComponent(true));
      setNodeIsUsedAsVariableModal(false);
      dispatch(updateUserInteractions(filterUserInteractions));
      setNodeIsUsedAsVariableModal(false);
      dispatch(onEdgesChange([edgesToDelete] as EdgeChange[]));
    }
    if (deletingEntity) {
      dispatch(
        deleteEntity({
          nodeId: nodeSelected?.id,
          userInteractionId: userInteraction.id
        })
      );
      setDeletingEntity(false);
      setDeletingUserInteraction(false);
      dispatch(changedPhoneComponent(true));
      setNodeIsUsedAsVariableModal(false);
    }
    setVariableToDelete({
      nodeId: "",
      entityName: ""
    });
  };

  const EraseEntityFromBotId = (
    entityIsSetAsVariableOnAnotherLocal,
    nodeId,
    EntityName
  ) => {
    // eslint-disable-next-line array-callback-return
    nodes.map((node) => {
      if (
        entityIsSetAsVariableOnAnotherLocal
          ?.map((el) => el.nodeId)
          .includes(node.id)
      ) {
        const oldNode = node;
        const newBI = node?.data.botInteractions.map((bi) => {
          if (bi.text !== "") {
            const parseBi: ISlateText[] = JSON.parse(bi?.text);
            return {
              ...bi,
              text: JSON.stringify(
                parseBi?.map((biParsed) => {
                  return {
                    ...biParsed,
                    children: biParsed.children.map((biChildren) => {
                      if (
                        biChildren.type === "mention" &&
                        biChildren.children &&
                        biChildren?.children[0]?.text === nodeId &&
                        biChildren?.variable === EntityName
                      ) {
                        return { text: "" };
                      }
                      return biChildren;
                    })
                  };
                })
              )
            };
          }
          return bi;
        });
        dispatch(
          updateRobotInteraction({
            id: newBI[0]?.id,
            nodeId: oldNode?.id,
            text: newBI[0]?.text
          })
        );
      }
    });
  };
  // Nome é parametro que pode ser criado
  // Entidade é tipo de interação - boolean, lista de nomes
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagToAdd(e.target.value);
  };

  const deleteUserInteractionRedux = () => {
    setDeletingUserInteraction(true);
    setVariableToDelete({
      nodeId: nodeSelected.id,
      entityName: userInteraction.entityTypeName
    });
    const entityIsSetAsVariableOnAnotherLocal = entityIsSetAsVariable(
      userInteraction.entityTypeName,
      nodeSelected.id,
      nodes
    );
    if (entityIsSetAsVariableOnAnotherLocal?.length) {
      setDeletingUserInteraction(true);
      setNodeIsUsedAsVariableModal(true);
    } else {
      const edgesToDelete = {
        id: `reactflow__edge-${nodeSelected?.id}a-${userInteraction.target}`,
        type: "remove"
      };
      dispatch(onEdgesChange([edgesToDelete] as EdgeChange[]));

      dispatch(
        deleteUserInteraction({
          nodeId: nodeSelected?.id,
          userInteractionId: userInteraction.id
        })
      );
      dispatch(changedPhoneComponent(true));
      setVariableToDelete({
        nodeId: "",
        entityName: ""
      });
      setDeletingUserInteraction(false);
    }
  };

  const handleChangeSelectIrPara = (e: DropdownChangeParams) => {
    setNodeDestination(e.value);
    // 1. maximum number of edges will be number of nodesDefault - 1 (source node)

    /// First delete all edges that has this node in output
    /// Fix: This behavior shoul be excluded
    // const edgesToDelete = edges
    //   .map((edge) => {
    //     if (edge.source === nodeSelected?.id)
    //       return {
    //         id: edge.id,
    //         type: "remove"
    //       };
    //   })
    //   .filter((notUndefineds) => notUndefineds !== undefined);
    const edgesToDelete = {
      id: `reactflow__edge-${nodeSelected?.id}a-${userInteraction.target}`,
      type: "remove"
    };
    dispatch(onEdgesChange([edgesToDelete] as EdgeChange[]));
    /// After set new edge to that node
    const newEdge = {
      id: `reactflow__edge-${nodeSelected?.id}a-${e.target.value}`,
      source: nodeSelected?.id,
      target: e.target.value,
      animated: false,
      style: {
        stroke: "rgba(217, 217, 217, 1)",
        strokeWidth: "2px"
      },
      markerEnd: {
        type: "arrowclosed"
      }
    };
    const newConnection = {
      source: nodeSelected?.id,
      target: e.target.value,
      sourceHandle: "a",
      targetHandle: "a"
    };
    const ObjectUpdateUserInteractions = {
      nodeId: nodeSelected?.id,
      userInteractionId: userInteraction.id,
      id: userInteraction.id,
      source: nodeSelected?.id,
      target: e.target.value,
      comparison: userInteraction?.comparison,
      phrases: userInteraction?.phrases,
      entityTypeId: userInteraction?.entityTypeId,
      entityTypeName: userInteraction?.entityTypeName
    };
    dispatch(updateUserInteraction(ObjectUpdateUserInteractions));
    dispatch(onEdgesChange([{ item: newEdge, type: "add" }] as EdgeChange[]));
    // }
    // dispatch(onEdgesChange([{ item: newEdge, type: "add" }] as EdgeChange[]));
  };
  const addTagRedux = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      dispatch(
        addTag({
          tagToAdd,
          nodeId: nodeSelected?.id,
          userInteractionId: userInteraction.id
        })
      );
      setTagToAdd("");
      dispatch(changedPhoneComponent(true));
    }
  };
  const deleteTagRedux = (tagToDelete: string) => {
    dispatch(
      deleteTag({
        tagToDelete,
        nodeId: nodeSelected?.id,
        userInteractionId: userInteraction.id
      })
    );
    dispatch(changedPhoneComponent(true));
  };
  // eslint-disable-next-line consistent-return
  const addUserInteractionToRedux = () => {
    const nodesDefaultQuantity = nodes.reduce((count, element) => {
      if (element.type === "defaultNode") {
        // eslint-disable-next-line no-param-reassign
        return (count += 1);
      }
      return count;
    }, 0);

    if (
      nodeSelectedUserInteractionsLength &&
      nodeSelectedUserInteractionsLength > nodesDefaultQuantity
    ) {
      return toast?.current?.show({
        severity: "error",
        summary: "Erro ao adicionar interação de usuário.",
        detail:
          "Não é possível adicionar mais interações de usuário. Nodes de destino insuficientes."
      });
    }
    // 2. can't erase edges that already exists. For this happen userInteractions should be same number of edges

    const edgesOutput = edges.filter(
      (_edge) => _edge.source === nodeSelected?.id
    );
    // check if edgesQuantity are same quantity as userInteractions
    // Set Edges on each userInteraction, if adding a new one set on any node that is not target setted before on same node.
    // const addUserInteractions =
    if (
      nodeSelectedUserInteractionsLength &&
      edgesOutput.length < nodeSelectedUserInteractionsLength
    ) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro ao adicionar interação de usuário.",
        detail:
          "Não é possível adicionar mais interações de usuário. Nodes de destino insuficientes."
      });
    }

    // 3. if edges for that node already exists set each when add userInteraction.
    // 4. if userInteractions.length > number of edges as source add new Edge and set newEdge target different of any other that has already set with that node as source

    // linha abaixo veio do button add - ver necessidade de refatoramento
    // dispatch(addUserInteractions())
  };
  const handleEntitiesModalOpen = () => {
    dispatch(setUserInteractionId(userInteraction.id));
    dispatch(setEntitiesModalOpen(true));
  };
  const existsEntity = userInteraction?.entityTypeId !== -1;
  return (
    <div css={UserInteractionCSS}>
      <Dialog
        header="Atenção"
        visible={nodeIsUsedAsVariableModal}
        onHide={() => {
          setDeletingEntity(false);
          setDeletingUserInteraction(false);
          setNodeIsUsedAsVariableModal(false);
        }}
      >
        <div className="flex">
          <span className="pi pi-exclamation-triangle mr-2" />
          {deletingEntity ? (
            <p>
              Entidade a ser excluída do fluxo está sendo utilizada como
              variável em outro(s) node(s).
            </p>
          ) : null}
          {deletingUserInteraction ? (
            <p>
              Interação do usuário possui entidade que está sendo utilizada como
              variável em outro(s) node(s).
            </p>
          ) : null}
        </div>
        <p>Caso prossiga esta informação será perdida.</p>
        <p>Deseja prosseguir com a exclusão?</p>
        <div className="mt-4 mb-4">
          <ButtonPrime
            label="Sim"
            className="p-button-info"
            onClick={AcceptDelete}
          />
          <ButtonPrime
            label="Não"
            className="p-button-danger ml-2 mr-2"
            onClick={() => {
              setDeletingEntity(false);
              setDeletingUserInteraction(false);
              setNodeIsUsedAsVariableModal(false);
            }}
          />
        </div>
      </Dialog>
      {isTemplate === false ? (
        <>
          <ContainerRelative>
            <input
              css={InputRounded}
              value={tagToAdd}
              onChange={(e) => onChange(e)}
              onKeyUp={(e) => addTagRedux(e)}
              placeholder={
                existsEntity
                  ? "Complete com mais frases (opcional)"
                  : "Escreva algo.."
              }
            />
            <Button
              onClick={handleEntitiesModalOpen}
              css={existsEntity ? Hide : undefined}
            >
              Usar modelo
            </Button>
          </ContainerRelative>
          <Text color="#4C5862" s="0.75rem">
            Adicione uma interação e pressione ENTER
          </Text>
        </>
      ) : null}
      <div css={DivTags}>
        {userInteraction?.phrases?.map((tag, idx) => (
          <MyTag key={idx} deleteTag={deleteTagRedux} text={tag}></MyTag>
        ))}
      </div>
      <Divisor css={existsEntity ? undefined : Hide} />
      {total > 1 ? (
        <button onClick={deleteUserInteractionRedux} css={DeleteButton}>
          <img
            style={{ width: "0.65rem" }}
            src={TrashRed}
            alt="Button trash - exclude"
          />
        </button>
      ) : null}
      <Toast ref={toast} />
      <div css={UserInteractionHeader}>
        <div css={IconCss}>
          <img src={Boy} style={{ maxWidth: "100%" }} alt="icon-boy" />
        </div>
        <div css={TextUserInteractionHeader}>Se a interação do usuário for</div>
        <div css={QuestionStyle}>?</div>
      </div>
      {userInteraction?.entityTypeName ? (
        <div css={ButtonStyleFull}>
          <div css={DivFlex}>
            <div>{userInteraction?.entityTypeName}</div>
          </div>
          {isTemplate === false ? (
            <>
              <button
                onClick={() => handleEditEntity(userInteraction.entityTypeId)}
                css={DivRoundedBigBlue}
              >
                <img src={BluePencil} alt="Edit Button" />
              </button>
              <button onClick={handleDeleteEntity} css={DivRoundedBigRed}>
                <img src={TrashRed} alt="TrashRed" />
              </button>
            </>
          ) : null}
        </div>
      ) : null}
      {/* TODO: Colocar aqui uma maneira de identificar as variáveis do node.*/}
      <div css={existsEntity ? Message : Hide}>
        Nosso robô vai consultar a base de{" "}
        <strong css={Strong}>{` ${userInteraction?.entityTypeName} `}</strong>{" "}
        de forma automática.
      </div>
      <Divisor />
      <Dropdown
        disabled={isTemplate}
        placeholder="Ir para o card"
        value={userInteraction.target}
        onChange={(e) => handleChangeSelectIrPara(e)}
        options={nodesLabel}
        optionLabel="label"
        optionValue="id"
      ></Dropdown>
      {!userInteraction?.entityTypeId && isTemplate === false ? (
        <button onClick={addUserInteractionToRedux} css={RoundedButton}>
          +
        </button>
      ) : null}
    </div>
  );
};
