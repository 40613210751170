import { zodResolver } from "@hookform/resolvers/zod";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams
} from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import { v } from "../../../util";
import { WorkspaceContext } from "./workspace-list";
import functions from "../../../util/functions";
import UserServices from "../../../services/UserServices";
import { useToast } from "../../../components/toast-provider";
import { VirtualScrollerLazyParams } from "primereact/virtualscroller";

type inviteTeamType = {
  value: { id: number }[];
  onChange: (value: { id: number }[]) => void;
};
interface IUser {
  id: number;
  name: string;
  phone: string;
  email: string;
  profile: string;
  workspaces: number;
  active: boolean;
  companyId: number;
  thirdPartyId?: number;
}
interface ResponseObject {
  result: IUser[];
  total: number;
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

const WorkspaceInviteTeam: React.FC<inviteTeamType> = ({
  value,
  onChange
}: inviteTeamType) => {
  const schema = v.object({});
  const [loading, setLoading] = useState(false);
  // const [first, setFirst] = useState(0);
  // const [rows, setRows] = useState(10);

  const defaultValues = {
    defaultValues: { nome: "" },
    resolver: zodResolver(schema)
  };
  const { control, getValues } = useForm(defaultValues);
  const { isObjectEmpty } = functions();
  const toast = useToast();
  const [users, setUsers] = useState<IUser[]>([] as IUser[]);
  const [filterValue, setFilterValue] = useState("");
  const debouncedText = useDebounce(filterValue, 600);
  const [formMod, setFormMod] = useState<any>([]);
  const [inviteData, setInviteData] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [valueEdit, setValueEdit] = useState<any>([]);

  const { user, company } = useAuth() as AuthContextType;
  // const { findList } = useUsuario();
  const companyId = company?.id;
  const companyIdSelected = user.admin ? companyId : user.companyId;
  const { getUser } = UserServices();

  const editWKData = useContext(WorkspaceContext);
  const returnValueToggle = () => {
    // eslint-disable-next-line no-negated-condition
    if (!isObjectEmpty(editWKData)) {
      setFormMod(editWKData.users);
      setValueEdit(editWKData.users);
    } else {
      setFormMod(value);
      setValueEdit(value);
    }
  };

  const loadDataLazy = async (event: VirtualScrollerLazyParams) => {
    const { first, last } = event;
    // const virtualUsers = [...users];
    setLoading(true);
    try {
      if (users?.length < totalRecords || (first === 0 && last === 0)) {
        // const firstFetch = firstLocal+10;
        // setFirst(firstFetch + 10);
        const workspaceIdArray = editWKData.id
          ? [editWKData.id]
          : user.workspaces?.workspaces.map((el) => el.id) || [];
        const MoreUsers: ResponseObject = await getUser({
          companyId: companyIdSelected,
          limit: 10,
          offset: users.length,
          name: debouncedText || "",
          workspaceId: workspaceIdArray
        });
        // Array.prototype.splice.apply(virtualUsers, [
        //   ...[first, last - first],
        //   ...MoreUsers
        // ]);
        const newUsers = MoreUsers.result;
        const allUsers = [...users, ...newUsers];
        const unique = allUsers.reduce(
          (acc, cur) => [...acc.filter((obj) => obj.id !== cur.id), cur],
          [] as IUser[]
        );

        //  = [...oldUsers, [...newUsers]]
        setTotalRecords(MoreUsers.total);
        setUsers(unique);
        returnValueToggle();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast?.current?.show({
        severity: "error",
        detail: error.message
      });
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   const e = {first: 0, last: 10};
  //   loadDataLazy(e);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setInviteData(false);
    }, 6000);
  }, [inviteData]);

  const onSelectionChange = (e: DataTableSelectionChangeParams) => {
    // eslint-disable-next-line no-negated-condition
    if (!isObjectEmpty(editWKData)) {
      setValueEdit(e);
      onChange(e);
    } else {
      onChange(e);
    }
  };

  const change = () => {
    setInviteData(true);
  };
  const unselect = () => {
    setInviteData(false);
  };

  // this code never gonna be Triggered becouse, the data form is automaticaly trigged  by dealing props function
  // const executForm = handleSubmit(async (data) => {
  // });

  const loadDataOnFilterEmpty = async () => {
    const workspaceIdArray = editWKData.id
      ? [editWKData.id]
      : user.workspaces?.workspaces.map((el) => el.id) || [];
    const MoreUsers: ResponseObject = await getUser({
      companyId: companyIdSelected,
      limit: 10,
      offset: 0,
      name: debouncedText || "",
      workspaceId: workspaceIdArray
    });
    setUsers(MoreUsers.result);
    setTotalRecords(MoreUsers.total);
  };

  const setFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  useEffect(() => {
    loadDataOnFilterEmpty();
  }, [debouncedText]);

  return (
    <>
      <form
        className=""
        // onSubmit={executForm}
      >
        <div
          style={{
            borderRadius: "6px",
            border: "solid 1px #ced4da",
            borderTop: "0px",
            width: "100%"
          }}
        >
          <div>
            <div className="">
              <div className="layout-sidebar-filter-content p-input-icon-left p-fluid p-input-icon-right w-full">
                <i
                  className="pi pi-search"
                  style={{
                    marginTop: "-7px",
                    width: "min-content"
                  }}
                ></i>
                <InputText
                  style={{ width: "100%" }}
                  type="text"
                  id="nome"
                  value={filterValue}
                  className="p-inputtext p-component p-filled noBorderRL"
                  placeholder="Buscar"
                  onChange={setFilter}
                />
              </div>
            </div>
          </div>
          {/* <div onScroll={onScroll} style={{ maxHeight: '100px', overflowY: 'auto' }}> */}
          <DataTable
            value={isObjectEmpty(editWKData) ? users : editWKData.users}
            // value={users}
            selectionMode="checkbox"
            // eslint-disable-next-line no-negated-condition
            selection={!isObjectEmpty(editWKData) ? valueEdit : value}
            onSelectionChange={(e) => onSelectionChange(e.value)}
            // dataKey="id"
            totalRecords={totalRecords}
            // responsiveLayout="scroll"
            // style={{ height: "40%" }}
            scrollHeight="12rem"
            scrollable={true}
            virtualScrollerOptions={
              users?.length > 0 &&
              users.length === totalRecords &&
              !isObjectEmpty(editWKData)
                ? {}
                : {
                    lazy: true,
                    onLazyLoad: loadDataLazy,
                    itemSize: 32,
                    showLoader: true,
                    loading,
                    loadingTemplate: (
                      <div className="flex align-items-center justify-content-center">
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "2rem" }}
                        ></i>
                      </div>
                    )
                  }
            }
            // rows={rows}
            // loading={loading}
            onRowSelect={change}
            showGridlines={false}
            showSelectAll={true}
            onRowUnselect={unselect}
          >
            <Column
              style={{ maxWidth: "3rem" }}
              selectionMode="multiple"
              headerStyle={{ maxWidth: "3rem", height: "2rem" }}
              bodyStyle={{ height: "2rem" }}
            ></Column>
            <Column
              field="name"
              style={{ minWidth: "6rem" }}
              headerStyle={{ width: "6rem", height: "2rem" }}
              header="Nome"
              bodyStyle={{ height: "2rem" }}
            ></Column>
          </DataTable>
          {/* <DataTableVirtualScrollDemo /> */}
        </div>
        {/* </div> */}
      </form>
      {/* <pre>Show Users</pre>
      <pre>{JSON.stringify(users, null, 2)}</pre> */}

      <div className="flex col-4, align-items-center justify-content-end">
        {inviteData && (
          <Message
            severity="success"
            text="Convite adicionado!"
            style={{
              width: "100%",
              height: "13%"
            }}
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceInviteTeam;
