import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import * as comunCss from "../../components/comum_css";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../util";
import { LetterCircle } from "../../components/gestaoBot/components/LetterCircle";
import InputPhone from "../../components/input-fone";
import OmnichannelService from "../../services/omnichannelService";
import { useToast } from "../../components";
import { iDialogMessagesFile } from "../use-omnichannel";
import inputFone from "../../components/input-fone";

export interface ChatInfosBodyProps {
  dialog: iDialogMessagesFile;
}
interface IUserInfo {
  name: string;
  createdAt: string;
  phone: string;
  email: string;
}
const ChatInfosBody = (ChatInfosBodyProps: ChatInfosBodyProps) => {
  interface ChatInfoValidation {
    name: string;
    email: string;
    phone: string;
    description: string;
  }

  const [maskedPhone, setMaskedPhone] = useState(["", ""]);
  const [nameField, setNameField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [descriptionField, setDescriptionField] = useState("");

  const onChangeName = (name: string) => setNameField(name);
  const onChangeEmail = (email: string) => setEmailField(email);
  const onChangeDescription = (description: string) =>
    setDescriptionField(description);
  const { getContact } = OmnichannelService();
  const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);
  const toast = useToast();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<ChatInfoValidation>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      description: ""
    },
    values: {
      name: nameField,
      email: emailField,
      phone: phoneField,
      description: descriptionField
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        email: v.string().superRefine(v.nonempty()),
        phone: v.fone().superRefine(v.nonempty()),
        description: v.fone().superRefine(v.nonempty())
      })
    )
  });
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getContact(ChatInfosBodyProps.dialog.id);
        setUserInfo(data);
        setValue("name", data.name);
        setValue("email", data.email);
        setValue("phone", data.phone);
        setNameField(data.name);
        setEmailField(data.email);
        setPhoneField(data.phone);
      } catch (error) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro.",
          detail:
            "Erro ao buscar dados do usuário. Por favor tente mais tarde.",
          sticky: false
        });
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ChatInfosBodyProps.dialog.id]);
  const handleInputChange = (values: string[]) => {
    const completePhone = values[0] + values[1];

    setMaskedPhone(values);

    setPhoneField(completePhone);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 10px 1rem 60px 1rem;
          overflow: auto;
        `}
      >
        {/* <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #e5e5e5;
          `}
        >
          Etapa do Atendimento
        </div> */}

        <b
          css={css`
            font-size: 16px;
            margin: 20px 0 5px 10px;
          `}
        >
          Informações
        </b>

        <div
          css={css`
            display: flex;
            align-items: center;
            height: 48px;
            width: 100%;
            padding: 0.8rem;
            margin: 10px 0;
            border-radius: 2px;
            border: 1px solid #d2d2d2;
            background: #ffffff;
            color: #4c5862;

            @media screen and (max-width: 400px) {
              font-size: 12px;
            }
          `}
        >
          Criado por:
          <LetterCircle initial={nameField} />
          {nameField}
        </div>

        <span className="field mb-1 relative" style={{ top: "-10px" }}>
          <label
            css={comunCss.inputLabelDefault}
            htmlFor="userName"
            className="block"
          >
            Nome do usuário
          </label>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                className="w-full"
                css={comunCss.noBorder}
                id="userName"
                value={nameField}
                onChange={(e) => onChangeName(e.target.value)}
              />
            )}
          />

          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
          ) : null}
        </span>
        <span className="field mb-1 relative" style={{ top: "-10px" }}>
          <label
            css={comunCss.inputLabelDefault}
            htmlFor="phone"
            className="block"
          >
            Telefone
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                className="w-full"
                css={comunCss.noBorder}
                id="userPhone"
                value={phoneField}
                onChange={(e) => onChangeName(e.target.value)}
              />
            )}
          />

          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
          ) : null}
        </span>

        {/* <InputPhone
          id="phone-input"
          value={inputFone}
          onChange={handleInputChange}
        /> */}

        <span className="field mb-0 relative" style={{ top: "-10px" }}>
          <label css={comunCss.inputLabelDefault} htmlFor="userEmail">
            E-mail
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                className="w-full"
                css={comunCss.noBorder}
                id="userEmail"
                value={emailField}
                onChange={(e) => onChangeEmail(e.target.value)}
              />
            )}
          />
        </span>
        {errors.email?.message ? (
          <div style={{ color: "#AA3939" }}>{errors.email.message}</div>
        ) : null}

        {/* <span
          className="field mb-0 relative"
          style={{ top: "-10px", marginBottom: "60px" }}
        >
          <label
            css={comunCss.inputLabelDefault}
            htmlFor="description"
            className="block"
          >
            Descrição
          </label>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <span className="p-input-icon-left">
                <i className="pi pi-book" />

                <InputTextarea
                  {...field}
                  className="w-full"
                  css={comunCss.noBorder}
                  id="description"
                  value={descriptionField}
                  onChange={(e) => onChangeDescription(e.target.value)}
                />
              </span>
            )}
          />

          {errors.description?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.description.message}</div>
          ) : null}
        </span>*/}
      </div>

      {/* <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          height: 50px;
          width: 325px;
          z-index: 5;
          bottom: 0;
          border-top: 1px solid #e5e5e5;
          background: #ffffff;
          color: #046af3;
          cursor: pointer;
        `}
      >
        <i
          className="pi pi-check-circle"
          css={css`
            margin-right: 5px;
          `}
        />
        Salvar
      </div> */}
    </div>
  );
};

export default ChatInfosBody;
