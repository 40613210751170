import styled from "@emotion/styled";

export const Img = styled.img`
display: flex;
align-items: center;
justify-content: center;
width: 4.125rem;
height: 4.125rem;
margin-top: 4rem;
`;

export const MetaContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
width: 4.125rem;
height: 4.125rem;
background-color: #D4E2F3;
`;

export const IconsContainer = styled.div`
display: flex;
position: relative;
width: 100%;
justify-content: center;
align-items: center;
`;

export const Icon1 = styled.img`
display: flex;
position: absolute;
top: 0;
left: calc(50% - 3rem);
z-index: 11;
justify-content: center;
align-items: center;
width: 4.125rem;
height: 4.125rem;
`;
export const Icon2 = styled.div`
display: flex;
position: absolute;
width: 100%;
z-index: 10;
top:0;
left: calc(50% + 0.2rem);
justify-content: center;
align-items: center;
width: 4.125rem;
height: 4.125rem;
border-radius: 50%;
background-color: #D4E2F3;
`;