import { css } from "@emotion/react";
import { RadioButton, RadioButtonProps } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm, UseFormRegister } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, PoliticaPrivacidadeConfirmar } from "../components";
import { useFetch } from "../util";
import { botRed, background, dialogosInteligentes } from "./../assets";
import { useAuth } from "../auth/auth-provider";

const ImageUpload = ({
  register
}: {
  register: UseFormRegister<{ pic: string; color: string }>;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { ref, onChange, ...rest } = register("pic");

  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div
      css={css`
        align-items: center;
        background-color: white;
        border: 2px solid #d4e2f3;
        border-radius: 50%;
        display: flex;
        height: 101px;
        justify-content: center;
        width: 101px;
      `}
    >
      <div
        css={css`
          align-items: center;
          background-color: #d4e2f3;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          height: 90px;
          justify-content: center;
          width: 90px;
        `}
        onClick={() => inputFileRef.current?.click()}
      >
        {preview ? null : (
          <>
            <i
              className="pi pi-camera"
              css={css`
                color: #046af3;
              `}
            ></i>
            <div
              css={css`
                color: #046af3;
              `}
            >
              sua foto
            </div>
          </>
        )}

        {preview ? (
          <img
            src={preview}
            width={90}
            height={90}
            css={css`
              border-radius: 50%;
            `}
          />
        ) : null}

        <input
          {...rest}
          name="pic"
          type="file"
          css={css`
            display: none;
          `}
          ref={(e) => {
            ref(e);
            inputFileRef.current = e;
          }}
          onChange={(e) => {
            onChange(e);
            if (!e.target.files || e.target.files.length === 0) {
              setSelectedFile(undefined);
              return;
            }
            setSelectedFile(e.target?.files?.[0]);
          }}
        />
      </div>
    </div>
  );
};

const RadioButtonColor = (props: RadioButtonProps & { color: string }) => {
  return (
    <RadioButton
      css={css`
        .p-radiobutton-box {
          background: ${props.color};
          border: 2px solid ${props.color};
        }

        .p-radiobutton-box.p-highlight {
          background: ${props.color};
          border-color: ${props.color};
        }

        .p-radiobutton-box .p-radiobutton-icon {
          background-color: ${props.color};
        }

        .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
          border-color: ${props.color};
        }

        .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
          border-color: ${props.color};
          background: ${props.color};
          color: #ffffff;
        }

        .p-radiobutton-box:not(.p-disabled).p-focus {
          border-color: ${props.color};
        }
      `}
      {...props}
    />
  );
};

export default () => {
  const { idUser, nameUser } = useParams();
  const updateAvatar = useFetch(`/api/users/avatar/${idUser}`, {
    method: "PUT"
  });
  const navigate = useNavigate();

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      pic: "",
      color: "#7b68ee"
    }
  });

  const [avatarColor, setAvatarColor] = useState("#7b68ee");

  const { user } = useAuth();

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{user.plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={botRed} />
            <div>
              <span>Bem-vindo ao seu </span>
              <span
                css={css`
                  font-weight: bold;
                `}
              >
                {user.plataform.displayName}
              </span>
              👋
              <div
                css={css`
                  font-weight: bold;
                `}
              >
                Personalize o seu avatar.
              </div>
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async (data) => {
                const { id } = await updateAvatar(data);
                navigate("/chatbots", { replace: true });
              })}
            >
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  gap: 10px;
                `}
              >
                <ImageUpload register={register} />

                <span
                  css={css`
                    font-size: 12px;
                  `}
                >
                  ou
                </span>

                <div
                  css={css`
                    align-items: center;
                    background-color: white;
                    border: 2px solid ${avatarColor};
                    border-radius: 50%;
                    display: flex;
                    height: 101px;
                    justify-content: center;
                    width: 101px;
                  `}
                >
                  <div
                    css={css`
                      align-items: center;
                      background-color: ${avatarColor};
                      border-radius: 50%;
                      color: white;
                      display: flex;
                      flex-direction: column;
                      font-size: 50px;
                      height: 90px;
                      justify-content: center;
                      width: 90px;
                    `}
                  >
                    {nameUser?.substring(0, 1)}
                  </div>
                </div>
              </div>

              <div
                css={css`
                  align-items: center;
                  margin-top: 30px;
                  display: flex;
                  flex-direction: column;
                  gap: 30px;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    align-items: center;
                    display: flex;
                    gap: 50px;
                    justify-content: center;
                  `}
                >
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#7b68ee"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#7b68ee");
                        }}
                        checked={field.value === "#7b68ee"}
                        color="#7b68ee"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#f3a700"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#f3a700");
                        }}
                        checked={field.value === "#f3a700"}
                        color="#f3a700"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#ff5722"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#ff5722");
                        }}
                        checked={field.value === "#ff5722"}
                        color="#ff5722"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#f42c2c"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#f42c2c");
                        }}
                        checked={field.value === "#f42c2c"}
                        color="#f42c2c"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#f8306d"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#f8306d");
                        }}
                        checked={field.value === "#f8306d"}
                        color="#f8306d"
                      />
                    )}
                  />
                </div>

                <div
                  css={css`
                    align-items: center;
                    display: flex;
                    gap: 50px;
                    justify-content: center;
                  `}
                >
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#ff00fc"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#ff00fc");
                        }}
                        checked={field.value === "#ff00fc"}
                        color="#ff00fc"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#4169e1"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#4169e1");
                        }}
                        checked={field.value === "#4169e1"}
                        color="#4169e1"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#5f81ff"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#5f81ff");
                        }}
                        checked={field.value === "#5f81ff"}
                        color="#5f81ff"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#0ab4ff"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#0ab4ff");
                        }}
                        checked={field.value === "#0ab4ff"}
                        color="#0ab4ff"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#08c7e0"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#08c7e0");
                        }}
                        checked={field.value === "#08c7e0"}
                        color="#08c7e0"
                      />
                    )}
                  />
                </div>

                <div
                  css={css`
                    align-items: center;
                    display: flex;
                    gap: 50px;
                    justify-content: center;
                  `}
                >
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#07a092"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#07a092");
                        }}
                        checked={field.value === "#07a092"}
                        color="#07a092"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#1db954"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#1db954");
                        }}
                        checked={field.value === "#1db954"}
                        color="#1db954"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#2ea52c"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#2ea52c");
                        }}
                        checked={field.value === "#2ea52c"}
                        color="#2ea52c"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#757380"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#757380");
                        }}
                        checked={field.value === "#757380"}
                        color="#757380"
                      />
                    )}
                  />

                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <RadioButtonColor
                        name="color"
                        value="#202020"
                        onChange={(e) => {
                          field.onChange(e.value);
                          setAvatarColor("#202020");
                        }}
                        checked={field.value === "#202020"}
                        color="#202020"
                      />
                    )}
                  />
                </div>
              </div>

              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  gap: 25px;
                  margin-top: 30px;
                `}
              >
                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="Tudo pronto!"
                />

                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <Link to="/forgot">Voltar</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
