import { HeaderGestaoBot } from "../components/header/components/gestaoBot";
import { Sidebar } from "../components";
import { GestaoBot } from "../components/gestaoBot";

export const GestaoBotsPage: React.FC = () => {
  return (
    <>
      <HeaderGestaoBot />
      <div style={{ width: "100%", height: "100%", display: "flex" }}>
        <section className="fixed top-0 left-0 z-3" style={{ paddingLeft: "9rem"}}>
        </section>
        
          <section style={{ marginTop: "79px", padding: "1rem", backgroundColor: "#F5F5F5", width: "100%", height: "max-content" }}>
            <GestaoBot />
          </section>
      </div>
    </>
  );
};
