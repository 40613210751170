import styled from "@emotion/styled";

export const Container = styled.div`
padding: 1rem;
width: 100%;
min-width: 411px;
display: flex;
flex-direction: column;
background: #FFFFFF;
border: 1px solid #D2D2D2;
border-radius: 25px;
align-items: center;
height: 546px;
overflow-y: scroll;
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  border: none;
  :hover {
    cursor: pointer;
  background-color: #F5F5F5;
  }
`;
export const ContainerIcon = styled.div`
width: 25px;
height: 25px;
background: #D4E2F3;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
`;

export const Divisor = styled.div`
height: 1px;
width: 100%;
border: 1px solid #E5E5E5;
margin-bottom: 1rem;
margin-top: 1rem;
`;