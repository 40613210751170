import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import DialogPadrao from "../../../../DialogPadrao";
import {
  BackgrounIcon,
  ContainerLeftModal,
  ContainerLogged,
  ContainerRightModal,
  Content,
  Green,
  Green2,
  LoadingDiv,
  LoadingRotateIcon,
  MyLink,
  PhotoContainer,
  PlayButton,
  Tag
} from "./styles";
import { setModalFbLoginOpen } from "../../../../../redux/slices/flowInteractions";
import { useEffect, useState } from "react";
import { AuthContextType, useAuth } from "../../../../../auth/auth-provider";
import FacebookServices from "../../../../../services/facebook";
import { useToast } from "../../../../../components/toast-provider";
import { Text } from "../../../../../components/Text";
import { ComboChannels } from "./ComboChannels";
import { FbWhite, PlayRed, Reload, girl } from "../../../../../assets";
import { LoggedIcons } from "./LoggedIcons";
import { Button } from "primereact/button";
import { SocialMediaPage } from "./SocialMediaPage";
interface IModalFbLogin {
  type: "FACEBOOK" | "INSTAGRAM";
}
interface IPages {
  id: string;
  name: string;
  url: string;
  active: true;
  updatedAt: string;
}
interface retornoFbLogin {
  userName: string;
  userPictureUrl: string;
  id: string;
  pages: IPages[];
}
export const ModalFbLogin: React.FC<IModalFbLogin> = ({ type }) => {
  const ModalFbLoginOpen = useAppSelector(
    (state) => state.flowInteractions.modalFbLoginOpen
  );
  const dispatch = useAppDispatch();
  const botTokenActive = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const botIdActive = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const [baseURL, setBaseURL] = useState("");
  const toast = useToast();
  const { user } = useAuth() as AuthContextType;
  const [fbPagesLogin, setFbPagesLogin] = useState<retornoFbLogin>(
    {} as retornoFbLogin
  );
  //   const [fbPagesLoginTest, setFbPagesLoginTest] = useState<retornoFbLogin>(
  //     {
  //     userName: "Rogerio",
  //     userPictureUrl: "https://www.yttags.com/blog/wp-content/uploads/2023/02/image-urls-for-testing.webp",
  //     pages: [
  //       {
  //         id: "1",
  //         name: "page1",
  //         url: "PageURL1",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "2",
  //         name: "page2",
  //         url: "PageURL2",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "3",
  //         name: "page3",
  //         url: "PageURL3",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "4",
  //         name: "page4",
  //         url: "PageURL4",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "5",
  //         name: "page5",
  //         url: "PageURL5",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "6",
  //         name: "page6",
  //         url: "PageURL6",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       },
  //       {
  //         id: "7",
  //         name: "page7",
  //         url: "PageURL7",
  //         active: true,
  //         updatedAt: "2022-03-10T12:15:50"
  //       }
  //     ]
  // });
  const { getFbPages, connectFbPage, disconnectFbPage, disconnectMetaAccount } =
    FacebookServices();

  // const urlRedirect = process.env.NODE_ENV === "development" ? "http://localhost:8080/facebook/Oauth" : "https://imbotstaging.ibasi.com.br/facebook/Oauth";
  const [checkStorage, setCheckStorage] = useState(false);
  // dispatch(setModalFbLoginOpen(false));
  const [loading, setLoading] = useState(false);
  const checkFbAuthentication = async () => {
    try {
      if (botIdActive > 0) {
        const retorno = await getFbPages({
          botId: botIdActive,
          type
        });
        setFbPagesLogin(retorno);
        setLoading(false);
      }
    } catch (error) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro.",
        detail: `Conexão não Não foi possível buscar os dados neste momento! Por favor, tente mais tarde. ${JSON.stringify(
          error
        )}`,
        sticky: false
      });
      setLoading(false);
    }
  };

  const ConnectFbPage = async (pageId: string) => {
    setLoading(true);
    await connectFbPage({ botId: String(botIdActive), pageId })
      .then(() => {
        checkFbAuthentication();
      })
      .catch((e) => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro.",
          detail: `Não foi possível conectar sua página neste momento! Por favor, tente mais tarde. ${JSON.stringify(
            e
          )}`,
          sticky: false
        });
        setLoading(false);
      });
  };
  const DisconnectFbPage = async (pageId: string) => {
    setLoading(true);
    await disconnectFbPage({ botId: String(botIdActive), pageId })
      .then(() => {
        checkFbAuthentication();
        setLoading(false);
      })
      .catch((e) => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro.",
          detail: `Não foi possível desconectar sua página neste momento! Por favor, tente mais tarde. ${JSON.stringify(
            e
          )}`,
          sticky: false
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setBaseURL(`${window.location.origin.toString()}`);
    checkFbAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botIdActive, type]);
  useEffect(() => {
    let check;
    const intervalId = setInterval(() => {
      if (checkStorage) {
        check = localStorage.getItem("FbLogin");
        if (check === "true") {
          clearInterval(intervalId);
          setCheckStorage(false);
          localStorage.removeItem("FbLogin");
          checkFbAuthentication();
        }
        if (check === null) {
          return null;
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStorage]);
  const disconectUser = async () => {
    setLoading(true);
    await disconnectMetaAccount({
      botId: botIdActive,
      userId: fbPagesLogin?.id
    })
      .then(() => {
        setFbPagesLogin({} as retornoFbLogin);
        setLoading(false);
      })
      .catch((e) => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro.",
          detail: `Erro ao desconectar-se da conta neste momento! Por favor, tente mais tarde. ${JSON.stringify(
            e.description
          )}`,
          sticky: false
        });

        setLoading(false);
      });
  };

  return (
    <DialogPadrao
      displayDialog={ModalFbLoginOpen}
      contentStyle={{ height: "420px" }}
      onHide={() => dispatch(setModalFbLoginOpen(false))}
      headerStyle={{
        backgroundColor: "#ff0025"
      }}
    >
      <ContainerLeftModal>
        <LoadingDiv show={loading}>
          <BackgrounIcon>
            <LoadingRotateIcon
              rotateIcon={loading}
              src={Reload}
              alt="reload-button"
            />
          </BackgrounIcon>
        </LoadingDiv>
        {fbPagesLogin?.pages?.length > 0 ? (
          <div className="flex h-full flex-column align-items-center justify-content-start">
            <LoggedIcons type={type} />
            <Text
              className="mt-8"
              s="0.9375rem"
              weight={600}
              color="#0A043C"
              lh="1.25rem"
              style={{ maxWidth: "70%" }}
            >
              {type === "FACEBOOK"
                ? "Conta Empresarial Facebook Meta"
                : "Instagram"}
            </Text>
            <Text
              s="0.75rem"
              weight={500}
              color="#999999"
              lh="1.25rem"
              style={{ maxWidth: "70%" }}
            >
              Ative ou desative as páginas em que o bot vai interagir!
            </Text>
            <div className="gap-8 w-full flex-grow-1 overflow-y-scroll">
              {fbPagesLogin?.pages?.map((page) => (
                <SocialMediaPage
                  key={page.id}
                  img={page.url}
                  pageTitle={page.name}
                  pageId={page.id}
                  activateDate={page.updatedAt}
                  active={page.active}
                  connect={ConnectFbPage}
                  disconnect={DisconnectFbPage}
                />
              ))}
            </div>
            <div className="h-3rem flex align-items-center justify-content-center w-full">
              <Button
                onClick={disconectUser}
                label="Desconectar"
                className="p-button-outlined p-button-danger"
                style={{ width: "9rem" }}
              />
              {/* <Button
                label="Salvar"
                className="p-button-raised p-button-primary"
                style={{ width: "9rem", backgroundColor: "#046AF3" }}
              /> */}
            </div>
          </div>
        ) : (
          <>
            <ComboChannels />
            {type === "FACEBOOK" && (
              <>
                <Text s="0.875rem" weight={600} color="#0A043C" lh="20px">
                  Facebook Messenger
                </Text>
                <Text s="0.75rem" weight={400} color="#0A043C" lh="18px">
                  Conecte-se agora mesmo com a sua
                </Text>
                <Text s="0.75rem" weight={400} color="#0A043C" lh="18px">
                  conta do Facebook Messenger.
                </Text>
              </>
            )}
            {type === "INSTAGRAM" && (
              <>
                <Text s="0.875rem" weight={600} color="#0A043C" lh="20px">
                  Canal do Instagram
                </Text>
                <Text
                  s="0.75rem"
                  weight={400}
                  color="#0A043C"
                  lh="18px"
                  style={{ maxWidth: "70%" }}
                >
                  Ao clicar no botão abaixo, você irá para o fluxo de conexão do
                  Facebook. Não se preocupe, o Instagram faz parte do
                  ecossistema de automação do Facebook.
                </Text>
              </>
            )}
            <MyLink
              onClick={() => setCheckStorage(true)}
              target="_blank"
              href={`https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.FACEBOOK_APP_ID}&redirect_uri=${baseURL}/facebook/Oauth&state=${botTokenActive}&response_type=code&scope=public_profile,email,pages_manage_metadata,pages_messaging,pages_show_list,business_management,instagram_manage_messages,instagram_basic`}
              rel="noreferrer"
            >
              <img src={FbWhite} alt="facebook" />
              Ir para o Facebook
            </MyLink>
          </>
        )}
      </ContainerLeftModal>
      <ContainerRightModal>
        <div className="text-white ">
          {fbPagesLogin?.pages?.length > 0 ? (
            // {fbPagesLogin ? (
            <div className="flex gap-3 mb-2 flex-column align-items-center justify-content-center">
              <Text s="14px" weight={600} lh="20px" color="#FFF">
                Status da conexão{" "}
              </Text>
              <ContainerLogged>
                <Green>
                  <Green2>
                    <Content>
                      <PhotoContainer>
                        <img
                          src={fbPagesLogin?.userPictureUrl}
                          alt="user-image"
                        />
                      </PhotoContainer>
                      <Text
                        s="12px"
                        weight={500}
                        lh="1rem"
                        color="#0A043C"
                        style={{ maxWidth: "100%" }}
                      >
                        {fbPagesLogin?.userName}
                      </Text>
                      <Text
                        s="11px"
                        weight={400}
                        lh="1rem"
                        color="#999999"
                        style={{ maxWidth: "100%" }}
                      >
                        {fbPagesLogin?.id}
                      </Text>
                      <Tag>Ativo</Tag>
                    </Content>
                  </Green2>
                </Green>
              </ContainerLogged>
              <Text
                s="0.75rem"
                weight={400}
                lh="1.25rem"
                color="#FFF"
                style={{ maxWidth: "13.125rem" }}
              >
                Você já está conectado com o Facebook. Agora você já pode
                automatizar as suas mensagens no canal do {type.toLowerCase()}.
              </Text>
            </div>
          ) : (
            <>
              <div className="flex align-items-center justify-content-center">
                <img src={girl} alt="Bot Girl" />
              </div>
              <div className="my-4">
                <Text s="13px" weight={500} lh="16px" color="#FAFBFC">
                  {type === "FACEBOOK"
                    ? "Como realizar a integração via Facebook Messenger?"
                    : "Como realizar a integração via Canal do Instagram?"}
                </Text>
              </div>
              <Text s="11px" weight={400} lh="14px" color="#FAFBFC">
                {type === "FACEBOOK"
                  ? `Realizar automações, capturar informações de contato e criar relacionamentos, você pode fazer tudo isso no Messenger graças às ferramentas de chatbot do ${user.plataform.displayName}.`
                  : `Realizar automações, capturar informações de contato e criar relacionamentos, você pode fazer tudo isso no Instagram graças às ferramentas de chatbot do ${user.plataform.displayName}.`}
              </Text>
              <div className="flex gap-2 align-items-center justify-content-center mt-4">
                <PlayButton>
                  <img
                    style={{ marginLeft: "0.14rem" }}
                    src={PlayRed}
                    alt="Icon-Play-Video"
                  />
                </PlayButton>
                <Text s="12px" weight={500} lh="16px" color="#FAFBFC">
                  Confira o vídeo
                </Text>
              </div>
            </>
          )}
        </div>
      </ContainerRightModal>
    </DialogPadrao>
  );
};
