import { css } from "@emotion/react";

export const UserBallonPosition = css`
  display: flex;
  text-align: start;
  white-space: break-word;
`;
export const UserBallon = css`
  margin-right: 0.2rem;
  margin-left: auto;
  width: max-content;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 14px 10px 10px;
  gap: 4px;
  background: #dbeefd;
  border-radius: 16px 16px 0px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-bottom: 1rem;
`;
export const divWatch = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.02em;
  color: #adb5bd;
`;
