import { iNodesSave } from "../components/react-flow-renderer/NodeData";

export interface ISlateText {
    type: 'paragraph';
    children: Child[];
}

interface Child {
    text?: string;
    type?: 'mention';
    variable?: string;
    children?: Child2[];
}
interface Child2 {
    text: string
}

export const deserializeBotInteractions = (strFormatted: string, nodes?: iNodesSave[]): string => {
    const arrayOfParagraphs = strFormatted.split('\n').map(el => {
        return {
            type: 'paragraph',
            children: deserialize1(el, nodes)
        }
    })
    return JSON.stringify(arrayOfParagraphs)
}
const deserialize1 = (str: string, nodes?: iNodesSave[]) => {
    const string1 = str.split(/(\|#@\w+-[\w\d\.?-]+)/gim);
    const content: { type?: string; variable?: string | null; children?: { type?: string | undefined; variable?: string | null | undefined; children?: { text: string; }[] | undefined; text?: string | undefined; }[][] | { text: string; }[]; text?: string; }[] = [];
    string1.map(el1 => {
        if (el1.match(/(\|#@\w+-[\w\d\.?-]+)/gim)) {
            content.push({
                type: 'mention',
                variable: el1?.match(/(\|#@\w+-[\w\d\.?-]+)/gim)?.[0].substring(2, el1.indexOf("-")) as string,
                children: [{ text: `${el1?.match(/(\|#@\w+-[\w\d\.?-]+)/gim)?.[0].substring(el1.indexOf("-") + 1) as string}` }],
            })
        } else if (el1[0] === " ") {content.push({ text: el1?.substring(1) })}
            else {content.push({ text: el1 })}
    })
    return content;
}

export const getIdByLabel = (nodeLabel = '', nodes: iNodesSave[]): string => {
    const nodeId = nodes.find(node => node.name === nodeLabel)?.id
    if (nodeId) {return nodeId.toString();}
    return ""
}
// export const testes = (): string => {
//     const teste1 = '[{"type":"paragraph","children":[{"text":"Olá, bem-vindo ao seu primeiro desenvolvido pela imBot 🚀 "}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"A conversa do seu chatbot sempre inicia através da interação do usuário."}]}]';
//     const resultTeste1 = serializeBotInteractions(teste1);
//     const deserializeTest1 = deserializeBotInteractions(resultTeste1)
//   const teste2 =
//   '[{"type":"paragraph","children":[{"text":"Olá "},{"type":"mention","variable":"@Nomes","children":[{"text":""}]},{"text":". Com qual área deseja conversar?"}]},{"type":"paragraph","children":[{"text":"1 - Comercial"}]},{"type":"paragraph","children":[{"text":"2 - Finandeiro"}]}]';
//     const resultTeste2 = serializeBotInteractions(teste2)
//     const deserializeTest2 = deserializeBotInteractions(resultTeste2)
//   return resultTeste1
// }
// Example:
//[
//  {
//    id: 1,
//    name: "Início",
//    first: true,
//    end: false,
//    botInteractions: [
//      {
//        id: "5314.618320917219",
//        interaction:
//          '[{"type":"paragraph","children":[{"text":"Olá, bem-vindo ao seu primeiro desenvolvido pela imBot 🚀 "}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"A conversa do seu chatbot sempre inicia através da interação do usuário."}]}]',
//        entityName: "",
//        entityTypeId: -1,
//        position: 0
//      }
//    ],
//    userInteractions: [
//      {
//        id: "8947388.39506772",
//        source: "Início",
//        target: "Setor",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: [],
//        position: 0
//      },
//      {
//        id: "58.80897759909631",
//        source: "Início",
//        target: "Nome",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: [],
//        position: 1
//      }
//    ]
//  },
//  {
//    id: 2,
//    name: "Setor",
//    first: false,
//    end: false,
//    botInteractions: [
//      {
//        id: "6541837.663050502",
//        interaction:
//          '[{"type":"paragraph","children":[{"text":"Olá "},{"type":"mention","variable":"@Nomes","children":[{"text":""}]},{"text":". Com qual área deseja conversar?"}]},{"type":"paragraph","children":[{"text":"1 - Comercial"}]},{"type":"paragraph","children":[{"text":"2 - Finandeiro"}]}]',
//        entityName: "",
//        entityTypeId: -1,
//        position: 0
//      }
//    ],
//    userInteractions: [
//      {
//        id: "6812.830257535349",
//        source: "Setor",
//        target: "Comercial",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: ["1", "Comercial"],
//        position: 0
//      },
//      {
//        id: "2320.8519775180825",
//        source: "Setor",
//        target: "Financeiro",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: ["2", "Financeiro"],
//        position: 1
//      }
//    ]
//  },
//  {
//    id: 3,
//    name: "Comercial",
//    first: false,
//    end: false,
//    botInteractions: [
//      {
//        id: "4512337.391766547",
//        interaction: null,
//        entityName: "",
//        entityTypeId: -1,
//        position: 0
//      }
//    ],
//    userInteractions: [
//      {
//        id: "7701395.527169977",
//        source: "Comercial",
//        target: "Fim",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: [],
//        position: 0
//      }
//    ]
//  },
//  {
//    id: 4,
//    name: "Financeiro",
//    first: false,
//    end: false,
//    botInteractions: [
//      {
//        id: "6471971.361786572",
//        interaction: null,
//        entityName: "",
//        entityTypeId: -1,
//        position: 0
//      }
//    ],
//    userInteractions: [
//      {
//        id: "5844300.577434105",
//        source: "Financeiro",
//        target: "Fim",
//        entityTypeId: -1,
//        entityName: "",
//        phrases: [],
//        position: 0
//      }
//    ]
//  },
//  {
//    id: 5,
//    name: "Fim",
//    first: false,
//    end: true,
//    botInteractions: [],
//    userInteractions: []
//  },
//  {
//    id: 6,
//    name: "Nome",
//    first: false,
//    end: false,
//    botInteractions: [
//      {
//        id: "6848781.801564859",
//        interaction:
//          '[{"type":"paragraph","children":[{"text":"Qual o seu nome?"}]}]',
//        entityName: "",
//        entityTypeId: -1,
//        position: 0
//      }
//    ],
//    userInteractions: [
//      {
//        id: "2639885.8402606994",
//        source: "Nome",
//        target: null,
//        entityTypeId: 1,
//        entityName: "@Nomes",
//        phrases: [],
//        position: 0
//      }
//    ]
//  }
//];
//