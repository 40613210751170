import { Header2 } from "../components/header/components/Header2";
import { Chatbots } from "../components/chatbots";

export const ChatbotsPage: React.FC = () => {
  return (
    <>
      <Header2 />
      <Chatbots />
    </>
  );
};
