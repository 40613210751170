import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { Modal, NewWaveformContainer, Overlay } from "./styles";
import {
  addFilesIds,
  setAudioModalOpen
} from "../../../../../redux/slices/flowInteractions";
import { Button } from "primereact/button";

import { addFiles } from "../../../../../redux/slices/nodes";
import { AudioRecorder } from "../../../../../omnichannel/chat/AudioRecorder";
import publishFlowServices from "../../../../../services/onSave";

export const AudiosModal: React.FC = () => {
  const modalAudioOpen = useAppSelector(
    (state) => state.flowInteractions.audioModal
  );
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const [concatenatedAudioBlob, setConcatenatedAudioBlob] = useState<
    Blob | MediaSource | undefined
  >(undefined);
  const botInteractionIdActiveRedux = useAppSelector(
    (state) => state.flowInteractions.botInteractionIDActive
  );
  const { saveFile } = publishFlowServices();
  // const qtFiles = nodeSelected?.data.botInteractions.find(
  //   (bot) => bot.id === botInteractionIdActiveRedux
  // )?.files?.length;

  const dispatch = useAppDispatch();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const sendToRedux = () => {
    const myFile = [
      new File([concatenatedAudioBlob as Blob], `myAudio.ogg`, {
        type: "audio/ogg"
      })
    ] as unknown as FileList;
    // const base64File = btoa(myFile.toString());
    for (const file of myFile) {
      toBase64(file).then(async (res) => {
        const fileObject = new FormData();
        fileObject.append("file", file);
        fileObject.append("name", file.name);
        fileObject.append("mimeType", file.type);
        const save = await saveFile(fileObject);
        dispatch(addFilesIds([save.fileId]));
        dispatch(
          addFiles({
            nodeId: nodeSelected?.id as string,
            botId: botInteractionIdActiveRedux,
            files: {
              id: uuidv4(),
              fileId: save.fileId,
              name: `myAudio.ogg`
              // base64File: res as string
            }
          })
        );
        setConcatenatedAudioBlob(undefined);
        dispatch(setAudioModalOpen(false));
      });
    }
  };

  return (
    <Overlay style={{ display: modalAudioOpen ? "block" : "none" }}>
      <Modal
        style={{ display: modalAudioOpen ? "block" : "none" }}
        open={modalAudioOpen}
      >
        <Button
          onClick={() => dispatch(setAudioModalOpen(false))}
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
          aria-label="Cancel"
        />
        <NewWaveformContainer>
          <AudioRecorder
            concatenatedAudioBlob={concatenatedAudioBlob}
            setConcatenatedAudioBlob={setConcatenatedAudioBlob}
            onFilesAttached={sendToRedux}
          />
        </NewWaveformContainer>
      </Modal>
    </Overlay>
  );
};
