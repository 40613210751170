import { useNavigate } from "react-router-dom";
import { useToast } from "../components";
// import axios from "axios";


// const urlAxios = process.env.BACKEND_API_URL;
// export const tryAxios = axios.create({url: urlAxios, 
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
//   withCredentials: true,
// }) 
export default () => {
  const navigate = useNavigate();
  const toast = useToast();

  return async <T>(url: string, init?: RequestInit, input?: FormData) => {
      const response = await fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: input
      });
      if (response.status <= 399) {
          return response.json();
      } else if (response.status >= 400 && response.status <= 499) {
        if (response.status === 401) {
          navigate("/login");
        } else {
          const errors = await response.json();
          const message = errors?.message;
          toast.current?.show({
            severity: "error",
            content: "Ocorreu um erro.",
            detail: message,
          });
          throw errors;
        }
      } else {
        const errors = await response.json();
        toast.current?.show({
          severity: "error",
          content: "Ocorreu um erro.",
          detail: errors?.message,
        });
        throw errors;
      }
    }
};
