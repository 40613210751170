import { Text } from "../../components/Text/text";
import { PlayRed, Reload, WhatsappOutlineWhite } from "../../assets";
import { PlayButton } from "./styles";
import { css } from "@emotion/react";
import { ButtonReload, ButtonReloadDiv } from "./styles";
import { useEffect, useState } from "react";
import { useToast } from "../../components/toast-provider";
import QrCodeServices from "../../services/omnichannelQrcode";
import { AuthContextType, useAuth } from "../../auth/auth-provider";

export const RightModalBox: React.FC<{
  emitWhatsappInfo: (objeto: any) => void;
}> = ({ emitWhatsappInfo }) => {
  interface Qrcode {
    url: string;
  }

  interface Webhook {
    enabled: string;
    webhookUrl: string;
  }

  interface Browser {
    browser: string;
    platform: string;
    version: string;
  }

  interface IWhatsappInstance {
    browser: Browser;
    error: string;
    key: string;
    message: string;
    qrcode: Qrcode;
    webhook: Webhook;
  }

  interface IWhatsappInstanceInfo {
    error: string;
    message: string;
    instance_data: {
      instance_key: string;
      phone_connected: string;
      webhookUrl: string;
      user: {
        id: string;
        name: string;
        phone: string;
      };
    };
  }

  interface IWhatsappImage {
    error: string;
    message: string;
    qrcode: string;
  }

  const toast = useToast();

  const { GetWhatsappInstance, GetWhatsappImage, GetWhatsappInstanceInfo } =
    QrCodeServices();

  const { user } = useAuth() as AuthContextType;

  const [rotateIconComponent, setRotateIconComponent] = useState(false);

  const [whatsappInstance, setWhatsappInstance] = useState<IWhatsappInstance>(
    {} as IWhatsappInstance
  );

  const [whatsappInstanceInfo, setWhatsappInstanceInfo] =
    useState<IWhatsappInstanceInfo>({} as IWhatsappInstanceInfo);

  const [whatsappImage, setWhatsappImage] = useState<IWhatsappImage | null>({
    error: "",
    message: "",
    qrcode: ""
  });

  const sleep = async (ms: number) => {
    // eslint-disable-next-line no-new
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const getWhatsappImage = async () => {
    const imagem = await GetWhatsappImage(user.id);

    setWhatsappImage(imagem);

    setRotateIconComponent(false);
  };

  const getWhatsappInstance = async () => {
    await GetWhatsappInstance(user.id)
      .then(async (res) => {
        setWhatsappInstance(res);

        await sleep(2000);

        getWhatsappImage();
      })
      .catch(() => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao buscar dados. Por favor tente mais tarde!"
        });
      });
  };

  const getWhatsappInstanceInfo = async () => {
    await GetWhatsappInstanceInfo(user.id)
      .then((res) => {
        setWhatsappInstanceInfo(res);
        setWhatsappImage(null);

        emitWhatsappInfo(res);
      })
      .catch(() => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao buscar dados. Por favor tente mais tarde!"
        });
      });
  };

  const handleRotateIcon = async () => {
    setRotateIconComponent(true);
    getWhatsappInstance();
  };

  useEffect(() => {
    getWhatsappInstanceInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <div
      className="text-white"
      css={css`
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
      `}
    >
      {whatsappInstanceInfo?.instance_data?.phone_connected == "true" ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            `}
          >
            Status da conexão
          </div>

          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 250px;
                width: 250px;
                padding: 1rem;
                margin: 20px 0 15px 0;
                border-radius: 20px;
                background: #ffffff;
                color: #4c5862;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  height: 125px;
                  width: 125px;
                  border-radius: 100%;
                  background: rgba(0, 230, 118, 0.2);
                  box-shadow: 0 0 0 30px rgba(0, 230, 118, 0.1);
                `}
              >
                <img
                  src={WhatsappOutlineWhite}
                  alt="whatsIcon"
                  width={36}
                  height={36}
                />

                <div
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 10px;
                    color: #02b960;
                  `}
                >
                  WhatsApp <br />
                  Ativo
                </div>
              </div>
            </div>
          </div>

          <div
            css={css`
              text-align: center;
              font-size: 13px;
              font-weight: 400;
              margin-top: 25px;
            `}
          >
            Agora você já pode receber mensagens diretamente do seu celular.
          </div>
        </div>
      ) : (
        <div>
          <div
            css={css`
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            `}
          >
            <div>Leia o QRCode para atualizar o número do celular.</div>
          </div>

          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 250px;
                width: 250px;
                padding: 1rem;
                margin: 10px 0 15px 0;
                border-radius: 20px;
                background: #ffffff;
                color: #4c5862;
              `}
            >
              {whatsappImage?.qrcode ? (
                <div className="flex flex-column align-items-center justify-content-center">
                  <img
                    src={whatsappImage?.qrcode}
                    alt="Qr code example"
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  />
                </div>
              ) : (
                <div className="flex flex-column align-items-center justify-content-center">
                  <ButtonReload
                    rotateIcon={rotateIconComponent}
                    onClick={handleRotateIcon}
                    src={Reload}
                    alt="reload-button"
                  />

                  <div
                    css={ButtonReloadDiv}
                    onClick={handleRotateIcon}
                    className="mt-2"
                  >
                    {rotateIconComponent ? "Gerando QRCode" : "Gerar QRCode..."}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            css={css`
              text-align: center;
              font-size: 13px;
              font-weight: 400;
            `}
          >
            O scanner do WhastApp está num lugar incomum! Para achá-lo, clique
            em
            <br />
            <span
              css={css`
                font-weight: 600;
              `}
            >
              Menu {">"} Dispositivos conectados {">"} Conectar um aparelho.
            </span>
          </div>

          <div className="flex align-items-center justify-content-start  mt-4">
            <PlayButton>
              <img
                style={{ marginLeft: "0.14rem" }}
                src={PlayRed}
                alt="Icon-Play-Video"
              />
            </PlayButton>

            <div className="ml-2">
              <Text
                fontSize="13px"
                fontWeight={500}
                lineHeight="16px"
                color="#FAFBFC"
              >
                Confira o vídeo
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
