import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  InputText,
  Password,
  PoliticaPrivacidadeConfirmar
} from "../components";
import Plataform from "../services/Plataform";
import useCompany from "../use-company";
import { v } from "../util";
import { background, botRed, dialogosInteligentes } from "./../assets";

const schema = v.object({
  companyName: v.string().superRefine(v.nonempty()),
  userName: v.string().superRefine(v.nonempty()),
  userEmail: v.string().superRefine(v.nonempty()),
  userPhone: v.string().superRefine(v.nonempty()),
  password: v.string().superRefine(v.nonempty()),
  confirm: v.string().superRefine(v.nonempty())
});

export default () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      companyName: "",
      userName: "",
      userEmail: "",
      userPhone: "",
      password: "",
      confirm: ""
    },
    resolver: zodResolver(schema)
  });

  const { setup } = useCompany();
  const navigate = useNavigate();
  const location = useLocation();

  const from =
    (
      location.state as {
        from: {
          pathname: string;
        };
      }
    )?.from?.pathname || "/confirm/";

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            font-size: 12px;
            margin-top: 10px;
          `}
        >
          Já possui uma conta? &nbsp;
          <Link to="/login">Login</Link>
        </div>

        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={botRed} />
            <div>
              <span>Bem-vindo ao seu </span>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                {Plataform.displayName}
              </span>
              👋
              <div
                css={css`
                  font-weight: 600;
                `}
              >
                Faça o seu cadastro :)
              </div>
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async ({ confirm, ...data }) => {
                const { userEmail } = await setup(data);
                navigate(`/confirm-subscription/${userEmail}`, {
                  replace: true
                });
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div>
                  <div className="p-float-label">
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <InputText {...field} id="organization" />
                      )}
                    />
                    <label htmlFor="organization">Nome da empresa</label>
                  </div>
                  {errors.companyName?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.companyName?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="userName"
                      control={control}
                      render={({ field }) => <InputText {...field} id="name" />}
                    />
                    <label htmlFor="name">Nome completo</label>
                  </div>
                  {errors.userName?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.userName?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="userEmail"
                      control={control}
                      render={({ field }) => (
                        <InputText {...field} id="email" />
                      )}
                    />
                    <label htmlFor="email">E-mail</label>
                  </div>
                  {errors.userEmail?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.userEmail?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="userPhone"
                      control={control}
                      render={({ field }) => (
                        <InputText {...field} id="userPhone" />
                      )}
                    />
                    <label htmlFor="userPhone">Telefone</label>
                  </div>
                  {errors.userPhone?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.userPhone?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Password {...field} id="passwd" />
                      )}
                    />
                    <label htmlFor="passwd">Criar senha</label>
                  </div>
                  {errors.password?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.password?.message}
                    </div>
                  ) : null}
                </div>

                <div>
                  <div className="p-float-label">
                    <Controller
                      name="confirm"
                      control={control}
                      render={({ field }) => (
                        <Password {...field} id="confirm" />
                      )}
                    />
                    <label htmlFor="confirm">Confirmar senha</label>
                  </div>
                  {errors.confirm?.message ? (
                    <div
                      css={css`
                        margin-top: 5px;
                        font-size: 12px;
                        color: #ff4d4f;
                      `}
                    >
                      {errors.confirm?.message}
                    </div>
                  ) : null}
                </div>

                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="Cadastrar"
                />
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
