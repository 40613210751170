import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputText, PoliticaPrivacidadeConfirmar } from "../components";
import Plataform from "../services/Plataform";
import useUser from "../use-usuario";
import { v } from "../util";
import { background, botRed, dialogosInteligentes } from "./../assets";

const schema = v.object({
  email: v.string().superRefine(v.nonempty())
});

export default () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: ""
    },
    resolver: zodResolver(schema)
  });

  const { forgot } = useUser();
  const navigate = useNavigate();

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
          width: 400px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={botRed} />
            <div>
              <span>Esqueceu a sua </span>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                senha
              </span>
              😞
              <div
                css={css`
                  font-weight: 600;
                `}
              >
                Entre com seu e-mail para recuperar.
              </div>
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async (data) => {
                await forgot({
                  url: window.location.href,
                  email: data.email
                });

                navigate(`/confirm-reset-password/${data.email}`, {
                  replace: true
                });
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div className="p-float-label">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <InputText {...field} id="email" />}
                  />
                  <label htmlFor="email">E-mail</label>
                </div>

                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="RECUPERAR"
                />

                <div
                  css={css`
                    color: "#046AF3";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <i
                    css={css`
                      color: #046af3;
                    `}
                    className="pi pi-arrow-left"
                  ></i>
                  <Link to="/login">Voltar para o login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
