import styled from "@emotion/styled";
interface IText {
    fontStyle?: string
    fontWeight?: number;
    fontSize?: string;
    lineHeight?: string;
    color?: string;
}
export const Text = styled.div<IText>`
font-family: 'Poppins';
font-style: ${props => props.fontStyle ? props.fontStyle : "normal"};
font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
font-size: ${props => props.fontSize ? props.fontSize : "1rem"};
line-height: ${props => props.lineHeight ? props.lineHeight : "1rem"};
color: ${props => props.color ? props.color : "#333C3F"};
`;