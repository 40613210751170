import { css } from "@emotion/react";
import { Password, PasswordProps } from "primereact/password";
import { forwardRef } from "react";

export default forwardRef<HTMLInputElement, PasswordProps & { css?: any }>(
  ({ css: cssProp, ...props }, ref) => (
    <Password
      css={[
        css`
          width: 100%;

          .p-inputtext {
            width: 100%;
          }
        `,
        cssProp
      ]}
      {...props}
      ref={ref}
      toggleMask={true}
      feedback={false}
    />
  )
);
