import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addEdge,
  applyEdgeChanges,
  Connection,
  Edge,
  EdgeChange,
  EdgeMarker,
  EdgeMarkerType,
  EdgeProps,
  updateEdge,
  getConnectedEdges,
  getIncomers,
  getOutgoers
} from "react-flow-renderer";
import { initialEdgesDefault } from "../../components/react-flow-renderer/elements";

// const initialState: Edge<EdgeProps>[] = initialEdgesDefault;
const initialState: Edge<EdgeProps>[] = [];

const edgesSlice = createSlice({
  name: "edges",
  initialState,
  reducers: {
    onEdgesChange(state, action: PayloadAction<EdgeChange[]>) {
      return applyEdgeChanges(action.payload, state);
    },
    onConnect(state, action: PayloadAction<Connection>) {
      const newEdge = {
        animated: false,
        style: {
          stroke: "rgba(217, 217, 217, 1)",
          strokeWidth: "2px"
        },
        markerEnd: {
          type: "arrowclosed"
        },
        ...action.payload
      };
      return addEdge(newEdge, state);
    },
    onEdgeUpdate(
      state,
      action: PayloadAction<{ oldEdge: Edge<EdgeProps>; newConnection: Connection }>
    ) {
      return updateEdge(
        action.payload.oldEdge,
        action.payload.newConnection,
        state
      );
    },
    zerarEdges (state) {
      return state = [];
    },
    initEdges(state, action: PayloadAction<Edge<EdgeProps>[]>) {
      state.push(...action.payload);
    },
    setEdges(state, action: PayloadAction<Edge<EdgeProps>[]>) {
      return state = action.payload;
    },
    
  }
});

export const { onEdgesChange, onConnect, onEdgeUpdate, initEdges, setEdges, zerarEdges } = edgesSlice.actions;

export default edgesSlice.reducer;
