import BotsServices from "../../services/bots";
import { IChatbot } from "../../types/chatbots";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface IRChatbots {
  status: 'idle' | 'loading' | 'failed'
  chatbots: IChatbot[];
}
const initialState: IRChatbots = {
  status: 'idle',
    chatbots: [],
};

export const getChatBotsAsync = createAsyncThunk(
  'chatbots/botsAsync',
  async (companyId: number, thunkAPI) => {
    const { GetBots } = BotsServices();
    const response1 =  await GetBots({
      limit: 100,
      offset: 0,
      companyId
    }).then(async response => {
      return response.results.result
    })
})

const chatbotsSlice = createSlice({
  name: "chatbots",
  initialState,
  reducers: {
    setRChatBots(state, action: PayloadAction<IChatbot[]>) {
      state.chatbots = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(getChatBotsAsync.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(getChatBotsAsync.rejected, state => {
      state.status = 'failed'
      // toast?.current?.show({
      //   life: 3000,
      //   severity: "error",
      //   summary: "Erro.",
      //   detail:
      //     "Erro ao buscar chatbots. Por favor tente mais tarde.",
      //   sticky: true
      // });
    })
    .addCase(getChatBotsAsync.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.chatbots = payload as unknown as IChatbot[]
    })
  },
});

export const { setRChatBots } = chatbotsSlice.actions;

export default chatbotsSlice.reducer;
