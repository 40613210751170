import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import { IoMdPlayCircle } from "react-icons/io";
import { girl } from "../../../assets";
import ProfileForm from "./profile-form";
import "../../comum_css/rawCss.css";
import { RightContent } from "./styles";

type AddProfileProps = {
  visible: boolean;
  onSave: () => void;
  onHide: () => void;
};

export default ({ visible, onSave, onHide }: AddProfileProps) => {
  return (
    <div>
      <Dialog
        className="addWspaceDialog relative"
        visible={visible}
        closeOnEscape={true}
        resizable={false}
        closable={true}
        draggable={false}
        style={{ width: "60vw", height: "42vw" }}
        onHide={onHide}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
      >
        <div className="rigtContent"></div>

        <RightContent>
          <ProfileForm onSave={onSave} />
        </RightContent>

        <div className="leftContent">
          <div>
            <div
              css={css`
                color: white;
                padding: 0px 30px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <img
                  src={girl}
                  css={css`
                    margin-top: 20px;
                  `}
                />
              </div>

              <div>Dica para perfis</div>
              <br />
              <div
                css={css`
                  font-size: 12px;
                `}
              >
                Ao criar um perfil, escolha um nome descritivo, atribua apenas
                as permissões necessárias e revise regularmente para garantir
                alinhamento contínuo com as responsabilidades do usuário.
              </div>
              <div className="mt-2">
                <IoMdPlayCircle
                  css={css`
                    top: 8px;
                    margin-right: 0.3rem;
                  `}
                  size={25}
                  color="white"
                  className="relative"
                />
                <span>Vídeo - Perfis</span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
