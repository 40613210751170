/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { isBefore } from "date-fns";
import { z } from "zod";
import validarCpfCnpj from "./validar-cpf-cnpj";

const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/iu;

const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/iu;

export const { string } = z;

export const cpfCnpj = () =>
  z.string().superRefine((val, ctx) => {
    if (val && !validarCpfCnpj(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: { validation: "cpf_cnpj" }
      });
    }
  });

export const cep = () =>
  z.string().superRefine((val, ctx) => {
    if (val && !/\d{8}/u.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: { validation: "cep" }
      });
    }
  });
// ^(\+55)?\s?(\(?[1-9]{2}\)?)?\s?(9\d{4}|[2-9]\d{3})[-.\s]?\d{4}$ - regex for phone number in Brazil
export const fone = () =>
  z.string().superRefine((val, ctx) => {
    // const teste = val.replace(/[^\d]/g, "");
    if (val && !/^(\+55)?\s?(\(?[1-9]{2}\)?)?\s?(9\d{4}|[2-9]\d{3})[-.\s]?\d{4}$/u.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: { validation: "fone" }
      });
    }
  });

export const { number } = z;

export const { boolean } = z;

export const { object } = z;

export const { date } = z;

export const { array } = z;

export const { record } = z;

export const enumeration = z.enum;

export const email = () => (val: string, ctx: z.RefinementCtx) => {
  if (val && !emailRegex.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { validation: "email" }
    });
  }
};

export const url = () => (val: string, ctx: z.RefinementCtx) => {
  if (val && !urlRegex.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { validation: "url" }
    });
  }
};

export const unique =
  (mapFn = (e: any) => e) =>
  (val: any[], ctx: z.RefinementCtx) => {
    if (val && !(new Set(val.map(mapFn)).size === val.map(mapFn).length)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: { validation: "unique" }
      });
    }
  };

export const nonempty = () => (val: string, ctx: z.RefinementCtx) => {
  if (val !== undefined && val !== null && val.trim() === "") {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { validation: "string_vazia" }
    });
  }
};

export const relation = () =>
  z
    .string()
    .superRefine(nonempty())
    .or(
      z.object({
        id: z.string()
      })
    )
    .superRefine((val, ctx) => {
      if (val !== "" && typeof val !== "object") {
        ctx.addIssue({
          code: "custom",
          message: "Valor inválido"
        });
      }
    });

export const optionalRelation = () =>
  z
    .string()
    .or(
      z.object({
        id: z.string()
      })
    )
    .superRefine((val, ctx) => {
      if (val !== "" && typeof val !== "object") {
        ctx.addIssue({
          code: "custom",
          message: "Valor inválido"
        });
      }
    })
    .optional()
    .nullable();

export const periodo = () =>
  z
    .array(z.date())
    .superRefine((val, ctx) => {
      if (val && ((!val[0] && val[1]) || (val[0] && !val[1]))) {
        ctx.addIssue({
          code: "custom",
          message: "Os dois campos devem ser informados"
        });
      }
    })
    .superRefine((val, ctx) => {
      if (val && val[0] && val[1] && isBefore(val[1], val[0])) {
        ctx.addIssue({
          code: "custom",
          message: "Data inicial deve ser inferior a final"
        });
      }
    });

export const optionalPeriodo = () =>
  z
    .array(z.date().optional().nullable())
    .superRefine((val, ctx) => {
      if (val && ((!val[0] && val[1]) || (val[0] && !val[1]))) {
        ctx.addIssue({
          code: "custom",
          message: "Os dois campos devem ser informados"
        });
      }
    })
    .superRefine((val, ctx) => {
      if (val && val[0] && val[1] && isBefore(val[1], val[0])) {
        ctx.addIssue({
          code: "custom",
          message: "Data inicial deve ser inferior a final"
        });
      }
    })
    .optional()
    .nullable();

z.setErrorMap((error, ctx) => {
  if (error.message) {
    return { message: error.message };
  }

  switch (error.code) {
    case z.ZodIssueCode.invalid_type:
      if (error.received === "undefined" || error.received === "null") {
        return { message: "Campo obrigatório" };
      }

      if (error.expected === "date") {
        return { message: "Data inválida" };
      }

      break;

    case z.ZodIssueCode.too_small:
      if (error.type === "string" && error.minimum === 1) {
        return { message: "Campo obrigatório" };
      } else if (error.type === "array") {
        return { message: "Deve conter no mínimo um registro" };
      } else if (
        error.type === "number" &&
        !error.inclusive &&
        error.minimum === 0
      ) {
        return { message: "Deve ser maior que 0" };
      }

      break;

    case z.ZodIssueCode.too_big:
      return { message: `Máximo de ${error.maximum} caracteres` };

    case z.ZodIssueCode.custom:
      if (error.params) {
        switch (error.params.validation) {
          case "email":
            return { message: "E-mail inválido" };

          case "url":
            return { message: "URL inválida" };

          case "cpf_cnpj":
            return { message: "CPF/CNPJ inválido" };

          case "cep":
            return { message: "CEP inválido" };

          case "fone":
            return { message: "Fone inválido" };

          case "id":
            return { message: "Registro inexistente" };

          case "unique":
            return { message: "Não pode conter elementos duplicados" };

          case "string_vazia":
            return { message: "Campo obrigatório" };

          default:
            break;
        }
      }

      break;

    case z.ZodIssueCode.invalid_union:
      return { message: "Campo obrigatório" };

    default:
      break;
  }

  return { message: ctx.defaultError };
});

export const validateSchema = async (schema: z.ZodSchema, input: unknown) =>
  schema.safeParseAsync(input);
