import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Chat } from "./chat";
import NoDialog from "./chat/no-dialog";
import { Sidebar } from "./sidebar";
import { iDialogMessagesFile } from "./use-omnichannel";
import { Button } from "primereact/button";
import OmnichannelService from "../services/omnichannelService";
import "./omnichannel.css";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setLoadOmnichannel } from "../redux/slices/workspaces";

const Omnichannel = () => {
  const { findDialogFilesURL } = OmnichannelService();
  const location = useLocation();
  const [dialog, setDialog] = useState<iDialogMessagesFile>();
  const [shouldScroll, setShouldScroll] = useState(false);
  const [shouldUpdateSidebar, setShouldUpdateSidebar] = useState(false);
  const [dialogId, setDialogId] = useState<number>(0);
  const [chatsSideBarState, setExpandedChat] = useState(true);
  const [screenMobile, setScreenMobile] = useState(false);
  const sidebarChats = useAppSelector((state) => state.sidebar.sidebarChats);
  const [lastId, setLastId] = useState(0);
  const dispatch = useAppDispatch();

  const run = async () => {
    const dialogIdLocal = location?.state?.dialogId || dialogId;
    if (dialogIdLocal) {
      const dialog = await findDialogFilesURL(dialogIdLocal);
      setDialog(dialog);
    }
  };

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const expanded = location?.state?.showSidebar !== false;

    setExpandedChat(expanded);
  }, [location.state?.showSidebar]);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setExpandedChat(false);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.dialogId !== undefined) {
      setDialogId(location.state.dialogId);
    }
  }, [location.state?.dialogId]);

  useEffect(() => {}, [location.state?.showSidebar]);

  useEffect(() => {
    if (location?.state?.dialogId !== undefined) {
      setDialogId(location.state.dialogId);
    }
  }, [location.state?.dialogId]);

  useEffect(() => {
    const fetchDialog = async () => {
      if (dialogId) {
        const dialog = await findDialogFilesURL(dialogId);
        setDialog(dialog);
        if (dialogId) {
          const dialog = await findDialogFilesURL(dialogId);
          setDialog(dialog);
        }
      }
    };
    fetchDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogId]);

  useEffect(() => {
    setShouldScroll(false);
  }, [shouldScroll]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (sidebarChats) {
      setExpandedChat(true);
    }
  }, [sidebarChats]);

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        background: #ffffff;
        overflow: none;
      `}
    >
      <div className="chatBarHolder">
        {chatsSideBarState ? (
          <Sidebar
            shouldUpdate={shouldUpdateSidebar}
            onShouldUpdateChange={() => setShouldUpdateSidebar(false)}
            dialog={dialog}
            onDialogClick={async (id) => {
              if (id !== lastId) {
                const dialog = await findDialogFilesURL(id);
                setDialogId(id);
                setDialog(dialog);
                setShouldScroll(true);
              }
              setLastId(id);
            }}
          />
        ) : null}

        {!screenMobile && (
          <Button
            className="p-button-secondary openCloseButton"
            onClick={() => {
              setExpandedChat((previous) => !previous);
            }}
            label={chatsSideBarState ? "<" : ">"}
            css={
              chatsSideBarState
                ? css`
                    right: -19px;
                  `
                : css`
                    left: -2px;
                  `
            }
          />
        )}
      </div>

      {dialog ? (
        <Chat
          key={dialog.id}
          dialog={dialog}
          onDialogFinalize={() => setShouldUpdateSidebar(true)}
          shouldScroll={shouldScroll}
          onShouldScroll={setShouldScroll}
        />
      ) : (
        <NoDialog />
      )}
    </div>
  );
};

export default Omnichannel;
