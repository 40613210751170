// interface IOptionsRobotActions {

import React, { useState, useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import OpusMediaRecorder from "opus-media-recorder";
import EncoderWorker from "opus-media-recorder/encoderWorker.js";
import OggOpusWasm from "opus-media-recorder/OggOpusEncoder.wasm";
import WebMOpusWasm from "opus-media-recorder/WebMOpusEncoder.wasm";
import { useDispatch } from "react-redux";
import { setRecordedAudioBlob } from "../../../redux/slices/flowInteractions";

// Polyfill MediaRecorder
window.MediaRecorder = OpusMediaRecorder;

import {
  NewWaveformContainer,
  RoundedButton,
  RoundedButtonGreen,
  WaveContainer
} from "./styles";
import {
  MicBlue,
  Pause,
  PlayWhite,
  SendWhite,
  TrashBlack
} from "../../../assets";
import { ClockAudio } from "./ClockAudio";
import { useToast } from "../../../components/toast-provider";

interface iAudioRecorder {
  setConcatenatedAudioBlob: (
    media: Blob | MediaSource | undefined
  ) => Blob | MediaSource | undefined | void;
  concatenatedAudioBlob: Blob | MediaSource | undefined;
  onFilesAttached: (files: FileList) => void;
}
// audio/ogg
export const AudioRecorder: React.FC<iAudioRecorder> = ({
  setConcatenatedAudioBlob,
  concatenatedAudioBlob,
  onFilesAttached
}) => {
  const toast = useToast();

  const workerOptions = {
    encoderWorkerFactory: (_) => new EncoderWorker(),
    OggOpusEncoderWasmPath: OggOpusWasm,
    WebMOpusEncoderWasmPath: WebMOpusWasm,
    mimeType: "audio/ogg;codecs=opus"
  };

  // Polyfill MediaRecorder
  window.MediaRecorder = OpusMediaRecorder;

  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const waveSurferRef = useRef<WaveSurfer>(null);
  const mediaRecorderRef = useRef<Blob | MediaSource>(null);
  const recordedChunks = useRef<BlobPart[]>([]);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const dispatch = useDispatch();

  const BlobArrayToBlob = async (
    blobs: BlobPart[]
  ): Promise<Blob | BlobPart> => {
    return blobs[0];
  };

  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: "#waveRecorder",
      waveColor: "blue",
      progressColor: "green"
    });
    return () => waveSurferRef.current?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleRecord = async () => {
    let setIntervall: string | number | NodeJS.Timeout | undefined;
    clearInterval(setIntervall);
    // eslint-disable-next-line no-negated-condition
    if (!isRecording) {
      setCurrentTime(waveSurferRef?.current?.getDuration() as number);
      if (OpusMediaRecorder === undefined) {
        // console.error('No OpusMediaRecorder found');
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro ao iniciar gravação.",
          detail: "No OpusMediaRecorder found",
          sticky: false
        });
      }
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(
            stream,
            { mimeType: "audio/ogg;codecs=opus" },
            workerOptions
          );
          setIntervall = setInterval(() => {
            setCurrentTime((currentTime) => currentTime + 1);
          }, 1000);
          mediaRecorderRef.current.ondataavailable = (event: {
            data: Blob;
          }) => {
            clearInterval(setIntervall);
            recordedChunks?.current?.push(event.data);
          };
          mediaRecorderRef.current.onstop = async () => {
            clearInterval(setIntervall);
            const audioBlob = await BlobArrayToBlob(recordedChunks.current);
            setConcatenatedAudioBlob(audioBlob as Blob);
            waveSurferRef?.current?.loadBlob(audioBlob as Blob);
            const objectUrl = URL.createObjectURL(audioBlob as Blob);
            dispatch(setRecordedAudioBlob(objectUrl));
            stream?.getTracks()?.forEach((i) => i?.stop());
            stream.removeTrack(stream.getAudioTracks()[0]);
            // stream.getAudioTracks()?.forEach((i) => i?.stop());
          };
          mediaRecorderRef?.current?.start();
          setIsRecording(true);
        })
        .catch(() => {
          toast?.current?.show({
            life: 3000,
            severity: "error",
            summary: "Erro ao iniciar gravação.",
            detail: `Erro ao acessar microfone. Acesso não autorizado.`,
            sticky: false
          });
        });
    } else {
      mediaRecorderRef?.current?.stop() as MediaSource;
      setIsRecording(false);
      clearInterval(setIntervall);
    }
  };

  useEffect(() => {
    let setIntervall: string | number | NodeJS.Timeout | undefined;

    waveSurferRef?.current?.on("ready", () => {
      setDuration(waveSurferRef?.current?.getDuration() as number);
      setCurrentTime(0);
    });
    waveSurferRef?.current?.on("play", () => {
      setIsPlaying(true);
      setIntervall = setInterval(() => {
        setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
      }, 1000);
      setDuration(waveSurferRef?.current?.getDuration() as number);
    });
    waveSurferRef?.current?.on("finish", () => {
      setIsPlaying(false);
      waveSurferRef?.current?.setCurrentTime(0);
      setCurrentTime(0);
      setDuration(waveSurferRef?.current?.getDuration() as number);
      clearInterval(setIntervall);
    });
    waveSurferRef?.current?.on("pause", () => {
      setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
      setIsPlaying(false);
      clearInterval(setIntervall);
    });
    // }
    if (isPlaying === false) {
      clearInterval(setIntervall);
    }
    return clearInterval(setIntervall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentTime, duration]);

  const eraseAudios = () => {
    setConcatenatedAudioBlob(undefined);
    recordedChunks.current = [];
    waveSurferRef?.current?.empty();
    waveSurferRef?.current?.destroy();
    setCurrentTime(0);
    setDuration(0);
    setIsPlaying(false);
    setIsRecording(false);
    waveSurferRef.current = WaveSurfer.create({
      container: "#waveRecorder",
      waveColor: "blue",
      progressColor: "green"
    });
  };

  const send = async () => {
    const myFile = [
      new File([concatenatedAudioBlob as Blob], `myAudio.ogg`, {
        type: "audio/ogg"
      })
    ] as unknown as FileList;

    onFilesAttached(myFile);
    eraseAudios();
  };

  const togglePlay = () => {
    if (isPlaying) {
      waveSurferRef?.current?.pause();
      setIsPlaying(false);
    } else {
      waveSurferRef?.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <NewWaveformContainer>
      {concatenatedAudioBlob && (
        <RoundedButton onClick={eraseAudios}>
          <img src={TrashBlack} alt="trash erase audios" />
        </RoundedButton>
      )}
      {(concatenatedAudioBlob !== undefined || isRecording) && (
        <ClockAudio
          isPlaying={isPlaying}
          timeTotal={duration}
          timeCurrent={currentTime}
        />
      )}
      <WaveContainer>
        <div id="waveRecorder"></div>
      </WaveContainer>
      {!concatenatedAudioBlob && (
        <RoundedButton onClick={toggleRecord}>
          <img
            src={isRecording ? Pause : MicBlue}
            alt="Pause or Continue Record"
          />
        </RoundedButton>
      )}
      {concatenatedAudioBlob && (
        <>
          <RoundedButton onClick={togglePlay}>
            <img
              src={isPlaying ? Pause : PlayWhite}
              alt="Play or Pauseaudios"
            />
          </RoundedButton>

          <RoundedButtonGreen onClick={send}>
            <img
              style={{ marginLeft: "3px" }}
              src={SendWhite}
              alt="Play or Pauseaudios"
            />
          </RoundedButtonGreen>
        </>
      )}
    </NewWaveformContainer>
  );
};
