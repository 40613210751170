import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { WorkspaceList } from "../components/Pages/workspace";

export const Workspaces: React.FC = () => {
  return (
    <LayoutHeader2>
      <WorkspaceList />
    </LayoutHeader2>
  );
};
