import { useFetch, useFetch2 } from "./util";

export default () => {
  const auth = useFetch("/api/auth", { method: "POST" });
  const me = useFetch("/api/me", { method: "GET" });
  const forgot = useFetch("/api/users/forgot-password", { method: "POST" });
  const refreshToken = useFetch("/api/refresh-token", { method: "POST" });
  const findList = useFetch2();
  return {
    auth,
    me,
    confirm,
    forgot,
    refreshToken,
    findList: (companyId: number) =>
      findList(
        `/api/users/list?${ 
          new URLSearchParams({
            companyId: `${companyId}`
          })}`,
        { method: "GET" }
      )
  };
};
