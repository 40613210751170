import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import { BotListDropdown } from "./sidebar";

export interface FiltersBarProps {
  status: string;
  bot: number;
  botsList?: BotListDropdown[];
  onStatusChange: (status: string) => void;
  onBotsListChange: (botsList: number) => void;
}

const FiltersBar = ({
  status,
  bot,
  botsList,
  onStatusChange,
  onBotsListChange
}: FiltersBarProps) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      border-bottom: 1px solid #282d35;
    `}
  >
    <div
      css={css`
        width: 48%;
        padding: 12px 0;
      `}
    >
      <Dropdown
        css={css`
          width: 100%;
          padding: 0 3px;
          border: none;
          background-color: #2f343d;

          .p-dropdown-label {
            color: #ffffff;
            padding-left: 0;
            padding-bottom: 4px;
            padding-top: 4px;
          }

          .p-dropdown-label.p-placeholder {
            color: #ffffff;
          }

          &:not(.p-disabled):hover {
            border-color: #2f343d;
          }
        `}
        placeholder="Mensagens"
        options={[
          {
            label: "Todas as salas",
            value: ""
          },
          {
            label: "Abertas",
            value: "OPEN"
          },
          {
            label: "Fechadas",
            value: "CLOSED"
          }
        ]}
        optionLabel="label"
        optionValue="value"
        value={status}
        onChange={(e) => onStatusChange(e.value)}
      />
    </div>

    <div
      css={css`
        height: 100%;
        width: 1px;
        background: #282d35;
      `}
    />

    <div
      css={css`
        width: 48%;
        padding: 12px 0;
      `}
    >
      <Dropdown
        css={css`
          width: 100%;
          padding: 0 3px;
          border: none;
          background-color: #2f343d;

          .p-dropdown-label {
            color: #ffffff;
            padding-left: 0;
            padding-bottom: 4px;
            padding-top: 4px;
          }

          .p-dropdown-label.p-placeholder {
            color: #ffffff;
          }

          &:not(.p-disabled):hover {
            border-color: #2f343d;
          }
        `}
        placeholder="Mensagens"
        options={botsList}
        optionLabel="label"
        optionValue="value"
        value={bot}
        onChange={(e) => onBotsListChange(e.value)}
      />
    </div>
  </div>
);

export default FiltersBar;
