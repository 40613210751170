import { css } from "@emotion/react";

export const Container = css`
  display: flex;
  align-items: center;
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 2.8125rem;
  min-width: fit-content;
  padding: 1rem;
  border-radius: 3rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  color: #0a043c;

  .p-inputtext {
    border: none;
    font-size: 16px !important;
    color: #000000 !important;

    @media screen and (max-width: 820px) {
      max-width: 9rem;
    }
    @media screen and (max-width: 700px) {
      max-width: 7rem;
    }
    @media screen and (max-width: 600px) {
      max-width: 100%;
      padding-left: 0;
    }
  }

  .p-button {
    background: transparent;
    color: #495057;
    border: none;
    :hover {
      background: transparent;
      color: #495057;
      border: none;
    }
  }

  .autoComplete {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 9.5rem;
  }
`;

export const Flex = css`
  display: flex;
  align-items: center;
`;

export const Divisor = css`
  height: 33px;
  width: 1px;
  margin: 0 15px;
  opacity: 0.2;
  background: #07102f;

  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    height: 1px;
    margin: 5px 0;
  }
`;

export const Text = css`
  display: flex;
  position: relative;
  inline-size: max-content;
  smargin-left: 1rem;
  color: #0a043c;
  cursor: pointer;
`;

export const ArrowDownStyle = css`
  word-wrap: break-word;
  width: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
`;

export const treeSelect = css`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
