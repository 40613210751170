import { NodeData } from "./../components/react-flow-renderer/NodeData.d";
import { Node } from "react-flow-renderer";
import { getLabelBotInteraction } from "./onSave";
interface ISlateText {
  type: "paragraph";
  children: Child[];
}

interface Child {
  text?: string;
  type?: "mention";
  variable?: string;
  children?: ChildMention[];
}
interface ChildMention {
  text: "string";
}

export const serializeBotInteractions = (
  strUnformatted: string,
  nodes: Node<NodeData>[]
): string => {
  const jsonObject: ISlateText[] = JSON.parse(strUnformatted);
  return jsonObject
    .map((obj, i) => {
      if (obj.type === "paragraph" && i > 0) {
        return (
          "\n" +
          obj.children
            .map((child) => {
              if (child.text) return child.text;
              else if (child?.type === "mention") {
                return `|#${child.variable}-${
                  child.children ? child.children[0].text : ""
                } `;
              }
            })
            .join("")
        );
      } else {
        return obj.children
          .map((child) => {
            if (child.text) return child.text;
            else if (child?.type === "mention")
              return `|#${child.variable}-${
                child.children ? child.children[0].text : ""
              } `;
          })
          .join("");
      }
    })
    .join("");
};
