import styled from "@emotion/styled";

interface iCard {
    cursor?: boolean;
}
export const Card = styled.div<iCard>`
position: relative;
width: 18.75rem;
height: 20.6875rem;
background: #FFFFFF;
box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.1);
border-radius: 1.25rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 1rem;
gap: 1rem;
:hover {
    cursor: ${props => props.cursor ? "pointer" : "unset"}
}
@media (min-width: 1024px) {
    width: 24%;
    height: 20.6875rem;
}
`;

export const Img = styled.img`
position: absolute;
right: 0;
top: 0;
`;