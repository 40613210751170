import { Fb3d, Instagram3d, Messenger3d, Telegram3d } from "../../../../../../assets"
import { Container, ImgCenter, ImgPontas } from "./styles"

export const ComboChannels: React.FC = () => {
    return (
        <Container>
            <ImgPontas src={Fb3d} alt="Facebook-Icon"/>
            <ImgCenter src={Messenger3d} alt="Messenger-Icon"/>
            <ImgCenter src={Instagram3d} alt="Instagram-Icon"/>
            <ImgPontas src={Telegram3d} alt="Telegram-Icon"/>
        </Container>
    )
}