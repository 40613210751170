import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./auth/auth-provider";
import { Layout } from "./components/Layout";
import { LayoutDrawflow } from "./components/LayoutDrawflow";
import ChangeCompany from "./pages/change-company";
import { ChatbotsPage } from "./pages/chatbots";
import { PageDrawflowChart } from "./pages/Drawflow";
import { EntitiesPage } from "./pages/entidades";
import { GestaoBotsPage } from "./pages/gestaoBot";
import { AddTemplate } from "./components/template";
import { GCredentialsPage } from "./pages/gcredentials";
import { Rooms } from "./pages/rooms";
import { Users } from "./pages/users";
import { Workspaces } from "./pages/workspaces";
import { Omnichannel } from "./omnichannel";
import { Home } from "./pages/home";
import { Dashboard } from "./pages/dashboard";
import { ProfilesPage } from "./pages/profiles";
import { FlowTemplatesPage } from "./pages/TemplatesList";
import Company from "./pages/company";

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/users"
        element={
          <RequireAuth permission="user_view">
            <Layout>
              <Users />
            </Layout>
          </RequireAuth>
        }
      />

      <Route
        path="/entities"
        element={
          <RequireAuth permission="entity_view">
            <Layout>
              <EntitiesPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/workspaces"
        element={
          <RequireAuth permission="workspace_view">
            <Layout>
              <Workspaces />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/templates"
        element={
          <RequireAuth>
            <Layout>
              <AddTemplate />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/chatbots"
        element={
          <RequireAuth>
            <Layout>
              <ChatbotsPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/chatbots/:botId"
        element={
          <RequireAuth>
            <Layout>
              <GestaoBotsPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/change-company"
        element={
          <RequireAuth>
            <Layout>
              <ChangeCompany />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/drawflow/:botId/:flowId"
        element={
          <RequireAuth>
            <LayoutDrawflow>
              <PageDrawflowChart />
            </LayoutDrawflow>
          </RequireAuth>
        }
      />
      <Route
        path="/drawflow/templates-view/:templateId"
        element={
          <RequireAuth>
            <LayoutDrawflow>
              <PageDrawflowChart />
            </LayoutDrawflow>
          </RequireAuth>
        }
      />
      <Route
        path="/profiles"
        element={
          <RequireAuth permission="profile_view">
            <Layout>
              <ProfilesPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/gcredentials"
        element={
          <RequireAuth permission="credential_view">
            <Layout>
              <GCredentialsPage />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/rooms"
        element={
          <RequireAuth permission="dialog_view">
            <Layout>
              <Rooms />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/omnichannel"
        element={
          <RequireAuth permission={["dialog_view", "omnichannel_view"]}>
            <Layout>
              <Omnichannel />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequireAuth>
            <Layout>
              <Home />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/templates-list"
        element={
          // <RequireAuth permission="template_view">
          <RequireAuth>
            <Layout>
              <FlowTemplatesPage />
            </Layout>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Layout>
              <Dashboard />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        index={true}
        element={
          <RequireAuth>
            <Layout>
              <Home />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/company"
        element={
          <RequireAuth>
            <Layout>
              <Company />
            </Layout>
          </RequireAuth>
        }
      />
    </Routes>
  );
};
