/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-negated-condition */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { AttachmentBlack, Send } from "../../assets";
import { AudioRecorder } from "./AudioRecorder";
import { iDialogMessagesFile } from "../use-omnichannel";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import { Button } from "./styles";
import { Tooltip } from "primereact/tooltip";
import OmnichannelService from "../../services/omnichannelService";
import { useToast } from "../../components";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

export interface InputProps {
  onSendMessage: (message: string) => void;
  onFilesAttached: (files: FileList) => void;
  dialog: iDialogMessagesFile;
}

interface AttachFilesProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const AttachFiles = ({ onChange }: AttachFilesProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <img
        src={AttachmentBlack}
        css={css`
          padding: 10px;
          cursor: pointer;
        `}
        onClick={() => inputFileRef.current?.click()}
      />
      <input
        type="file"
        multiple={true}
        ref={inputFileRef}
        css={css`
          display: none;
        `}
        onChange={onChange}
      />
    </>
  );
};

const Input = ({ onSendMessage, onFilesAttached, dialog }: InputProps) => {
  const { findDialogFilesURL } = OmnichannelService();
  const [message, setMessage] = useState<string>("");
  const messageRef = useRef<HTMLInputElement>(null);
  const [concatenatedAudioBlob, setConcatenatedAudioBlob] = useState<
    Blob | MediaSource | undefined
  >(undefined);
  const { joinDialog, openDialog } = OmnichannelService();
  const toast = useToast();
  const sendMessage = () => {
    onSendMessage(message);
    setMessage("");
    messageRef.current?.focus();
  };
  const [showDisableButton, setShowDisableButton] = useState(
    dialog.transferred
  );
  const [showInput, setShowInput] = useState(false);
  const [valueUserId, setValueUserId] = useState(true);
  const [valueDialog, setValueDialog] = useState<iDialogMessagesFile>();
  const location = useLocation();
  const newValueDialogId = useAppSelector(
    (state) => state.flowInteractions.newValueDialogId
  );
  const redirect = useNavigate();

  const run = async () => {
    const dialogIdLocal = location?.state?.dialogId || valueDialog;
    if (dialogIdLocal) {
      const dialog = await findDialogFilesURL(dialogIdLocal);
      setValueDialog(dialog);
    }
  };

  interface iFiles {
    fileType: string;
    mimeTypes: string[];
    sizeLimit: number;
  }
  const allowedFileTypes = [
    {
      fileType: "audio",
      mimeTypes: [
        "audio/aac",
        "audio/mp4",
        "audio/mpeg",
        "audio/amr",
        "audio/ogg opus codecs",
        "audio/ogg"
      ],
      sizeLimit: 16 * 1024 * 1024
      // 16Mb
    },
    {
      fileType: "image",
      mimeTypes: ["image/jpeg", "image/png", "image/gif"],
      sizeLimit: 5 * 1024 * 1024
      // 5Mb
    },
    {
      fileType: "sticker",
      mimeTypes: ["image/webp"],
      sizeLimit: 0.5 * 1024 * 1024
      //500Kb
    },
    {
      fileType: "video",
      mimeTypes: ["video/mp4", "video/3gp"],
      sizeLimit: 16 * 1024 * 1024
      // 16Mb
    },
    {
      fileType: "documents",
      mimeTypes: [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ],
      sizeLimit: 100 * 1024 * 1024
      //100Mb
    }
  ];

  const formatsAccepted =
    ".jpeg, .jpg, .png, .gif, .mp4, .pdf, .txt, .doc, .docx, .xls, .xlsx, .ogg, .amr, .webp, .3gp, .ppt, .pptx, .ods, .opus";

  useEffect(() => {
    if (valueDialog?.userTransferredId === user?.user.id) {
      setValueUserId(true);
    } else if (
      valueDialog?.userTransferredId !== undefined &&
      valueDialog?.userTransferredId !== user?.user.id
    ) {
      setValueUserId(false);
    } else if (dialog.userTransferredId !== user?.user.id) {
      setValueUserId(false);
    }
    if (newValueDialogId === user?.user.id) {
      setValueUserId(true);
    } else if (newValueDialogId !== 0 && newValueDialogId !== user?.user.id) {
      setValueUserId(false);
    }
  }, [valueDialog, newValueDialogId]);

  const joinRoom = async () => {
    if (
      newValueDialogId !== undefined &&
      newValueDialogId !== 0 &&
      newValueDialogId !== user.user.id
    ) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail:
          "Não é permitido entrar em salas já transferidas, utilize a função de transferência."
      });
      return;
    }
    try {
      await joinDialog(dialog.id);
      redirect(
        { pathname: `/omnichannel/` },
        {
          state: {
            dialogId: dialog.id,
            showSidebar: false
          }
        }
      );
      await run();
      setShowInput(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any | { message: string }) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: error?.message
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        valueDialog?.userTransferredId !== undefined &&
        valueDialog?.userTransferredId === user.user.id &&
        dialog.userTransferredId === user.user.id
      ) {
        setShowInput(true);
        setShowDisableButton(true);
        redirect(`/omnichannel/`);
      }
    }, 100);
  });

  useEffect(() => {
    if (dialog.userTransferredId === user.user.id) {
      setShowInput(true);
    }
  });

  const openRoom = async () => {
    try {
      await openDialog(dialog.id);
      redirect(`/omnichannel/`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any | { message: string }) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: error?.message
      });
    }
  };
  const user = useAuth() as AuthContextType;

  return (
    <div
      css={css`
        display: flex;
        padding: 5px 10px 5px 20px;
        margin: 0 20px 20px 20px;
        border-radius: 800px;
        border: 2px solid #cbced1;
        background-color: #ffffff;
      `}
    >
      {dialog.closedAt === null ? (
        <>
          {showInput === false ? (
            <div className="flex w-full align-items-center justify-content-center">
              <div>
                <Button
                  onClick={joinRoom}
                  disabled={showDisableButton === true}
                >
                  Entrar na Conversa
                </Button>
              </div>
              {dialog.userTransferredId !== user?.user.id && (
                <Tooltip position="mouse" target=".disabled-Message">
                  Esta sala foi transferida para outro atendente. Para entrar na
                  sala novamente, por favor, utilize a opção de transferência de
                  sala.
                </Tooltip>
              )}
            </div>
          ) : (
            <>
              {valueUserId ? (
                <>
                  {concatenatedAudioBlob === undefined && (
                    <input
                      placeholder="Mensagem..."
                      css={css`
                        border: none;
                        flex: 1;
                        margin-right: 10px;
                        font-family: "Poppins";
                        font-size: 14px;

                        @media screen and (max-width: 450px) {
                          margin-right: 0;
                        }
                      `}
                      ref={messageRef}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && message.length > 0) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                    />
                  )}
                  <div
                    css={css`
                      display: flex;
                      margin-left: auto;
                      gap: 10px;
                      width: ${concatenatedAudioBlob === undefined
                        ? "unset"
                        : "100%"};
                    `}
                  >
                    <AttachFiles
                      onChange={(e) => {
                        const fileList = e.target.files as FileList;
                        let fileSize = 0;
                        for (let i = 0; i < fileList.length; i++) {
                          fileSize += fileList[i].size;
                        }

                        let validFiles = true;

                        Array.from(fileList).forEach((file) => {
                          const [fileType] = allowedFileTypes.filter(
                            (fileType) => fileType.mimeTypes.includes(file.type)
                          );

                          if (!fileType) {
                            toast.current?.show({
                              life: 5000,
                              severity: "error",
                              summary: "Erro.",
                              detail: `Formato de arquivo inválido. Formatos permitidos: ${formatsAccepted
                                .split(",")
                                .map((el) => el.trim().replace(".", ""))
                                .join("/")}.`
                            });

                            validFiles = false;

                            return;
                          }

                          const fullSize = fileType?.sizeLimit;
                          const sizeString = fullSize?.toString();
                          const sizeMB = parseInt(sizeString?.substring(0, 2));

                          if (fileSize > fileType.sizeLimit) {
                            if (fileType.fileType === "documents") {
                              toast.current?.show({
                                life: 3000,
                                severity: "error",
                                summary: "Erro.",
                                detail: `Tamanho máximo excedido. Máximo permitido: 100 MB`
                              });
                            } else if (fileType.fileType === "sticker") {
                              toast.current?.show({
                                life: 3000,
                                severity: "error",
                                summary: "Erro.",
                                detail: `Tamanho máximo excedido. Máximo permitido: 100 KB`
                              });
                            } else {
                              toast.current?.show({
                                life: 3000,
                                severity: "error",
                                summary: "Erro.",
                                detail: `Tamanho máximo excedido. Máximo permitido: ${sizeMB} MB`
                              });
                            }

                            validFiles = false;
                          }
                        });

                        if (validFiles) {
                          onFilesAttached(e.target.files as FileList);
                        }
                      }}
                    />
                    {message?.length ? (
                      <img
                        src={Send}
                        css={css`
                          background-color: #dddddf;
                          border-radius: 50%;
                          cursor: pointer;
                          padding: 10px;
                          height: 43px;
                          width: 43px;
                        `}
                        onClick={() => sendMessage()}
                      />
                    ) : (
                      <AudioRecorder
                        concatenatedAudioBlob={concatenatedAudioBlob}
                        setConcatenatedAudioBlob={setConcatenatedAudioBlob}
                        onFilesAttached={onFilesAttached}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="flex w-full align-items-center justify-content-center">
                  <div>
                    <Button onClick={joinRoom}>Entrar na Conversa</Button>
                  </div>
                  {dialog.userTransferredId !== user?.user.id && (
                    <Tooltip position="mouse" target=".disabled-Message">
                      Esta sala foi transferida para outro atendente. Para
                      entrar na sala novamente, por favor, utilize a opção de
                      transferência de sala.
                    </Tooltip>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex w-full align-items-center justify-content-center">
          <div>
            <Button onClick={openRoom}>Abrir Sala</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;
