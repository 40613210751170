import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Modal, Overlay } from "./styles";
import { Button } from "../../..";
import { Text } from "../../../Text";
import { setCloseRoomsModalOpen } from "../../../../redux/slices/flowInteractions";
interface iModalCloseRooms {
  confirmar: () => void;
}
export const ModalCloseRooms: React.FC<iModalCloseRooms> = ({ confirmar }) => {
  const closeRoomsModalOpen = useAppSelector(
    (state) => state.flowInteractions.closeRoomsModalOpen
  );
  const dispatch = useAppDispatch();
  return (
    <Overlay style={{ display: closeRoomsModalOpen ? "block" : "none" }}>
      <Modal
        style={{ display: closeRoomsModalOpen ? "block" : "none" }}
        open={closeRoomsModalOpen}
      >
        <Text style={{ textAlign: "left" }} s="1.5rem" weight={600} lh="2rem">
          Atenção
        </Text>
        <Text style={{ textAlign: "left" }} s="1rem" weight={400} lh="2rem">
          Deseja fechar as salas selecionadas?
        </Text>
        <div className="flex gap-2 align-items-center justify-content-center mt-4 w-full">
          <Button
            className="p-button-raised p-button-info"
            label="Cancelar"
            onClick={() => dispatch(setCloseRoomsModalOpen(false))}
          />
          <Button
            className="p-button-raised p-button-danger"
            label="Confirmar"
            onClick={() => confirmar()}
          />
        </div>
      </Modal>
    </Overlay>
  );
};
