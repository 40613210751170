import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { InputText } from "primereact/inputtext";
import {
  setAdvisor,
  setCustomButtonModalOpen,
  setOverlayInteractionsShow
} from "../../../../../redux/slices/flowInteractions";
import {
  Dica,
  Modal,
  Overlay,
  Span,
  Title,
  VDivisor,
  Button as ButtonStyle,
  VDivisorLeft,
  ButtonSave,
  ButtonRemove
} from "./styles";
import { Check } from "../../../../../assets";
import { Dropdown } from "../../../../../components";
import {
  deleteAllCustomButtons,
  // addCustomButton,
  // deleteCustomButton,
  // updateCustomButton,
  setCustomButtons as setCustomButtonsRedux
} from "../../../../../redux/slices/nodes";
import { Button } from "primereact/button";
import { SelectItemOptionsType } from "primereact/selectitem";
import { ICustomButtons } from "../../../../../components/react-flow-renderer/NodeData";
import { changedPhoneComponent } from "../../../../../redux/slices/flowupdates";

export const CustomButtonModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const customButtonModalOpenRedux = useAppSelector(
    (state) => state.flowInteractions.customButtonModalOpen
  );
  const toast = useRef<Toast>(null);
  const nodes = useAppSelector((state) => state.nodes);
  const [newButtonLabel, setNewButtonLabel] = useState("");
  const nodeSelected = nodes.find((node) => node.selected === true);
  const [optionSelected, setOptionSelected] = useState(1);
  const [buttonName, setButtonName] = useState("");
  const botInteractionActive = useAppSelector(
    (state) => state.flowInteractions.botInteractionIDActive
  );
  const CustomButtons = nodeSelected?.data.botInteractions.filter(
    (el) => el.id === botInteractionActive
  )[0]?.buttons;
  const [customButtons, setCustomButtons] = useState<
    ICustomButtons[] | undefined
  >([]);
  const [newlink, setNewlink] = useState("");
  const options: SelectItemOptionsType = [
    { label: "Lista", value: 1 },
    { label: "Link", value: 2 }
  ];

  useEffect(() => {
    if (CustomButtons?.length === 1 && CustomButtons[0].link) {
      setNewlink(CustomButtons[0].link);
      // setNewButtonLabel(CustomButtons[0].label);
      setButtonName(CustomButtons[0].label);
      setOptionSelected(2);
      setCustomButtons(CustomButtons);
    } else {
      setCustomButtons(CustomButtons);
      setOptionSelected(1);
    }
    return () => {
      setNewlink("");
      setNewButtonLabel("");
      setOptionSelected(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botInteractionActive, customButtonModalOpenRedux]);
  const handleSetNewButton = () => {
    if (customButtons?.length) {
      const maxNumber = customButtons.reduce((acc, el) => {
        if (el.position > acc) {
          return el.position;
        }
        return acc;
      }, 0);
      setCustomButtons([
        ...customButtons,
        {
          position: maxNumber + 1,
          label: newButtonLabel,
          type: "QUICK_REPLY"
        }
      ]);
    } else {
      setCustomButtons([
        {
          position: 1,
          label: newButtonLabel,
          type: "QUICK_REPLY"
        }
      ]);
    }
    // dispatch(
    //   addCustomButton({
    //     nodeId: nodeSelected?.id as string,
    //     botInteractionId: botInteractionActive,
    //     label: newButtonLabel
    //   })
    // );
    setNewButtonLabel("");
    dispatch(changedPhoneComponent(true));
  };
  const handleDeleteNewButton = (position: number) => {
    const oldCustomButtons = customButtons;
    const newCustomButtons = oldCustomButtons?.filter(
      (btn) => btn.position !== position
    );
    setCustomButtons(newCustomButtons);
    dispatch(changedPhoneComponent(true));

    // dispatch(
    //   deleteCustomButton({
    //     nodeId: nodeSelected?.id as string,
    //     botInteractionId: botInteractionActive,
    //     position
    //   })
    // );
  };
  const handleSetLink = () => {
    dispatch(
      setCustomButtonsRedux({
        nodeId: nodeSelected?.id as string,
        botInteractionId: botInteractionActive,
        customButtons: [
          {
            label: buttonName,
            position: 1,
            type: "LINK",
            link: newlink
          }
        ]
      })
    );
    setNewButtonLabel("");
    setNewlink("");
    dispatch(setCustomButtonModalOpen(false));
    dispatch(setOverlayInteractionsShow(false));
    dispatch(changedPhoneComponent(true));
  };
  const handleUpdateNewButton = (position: number, label: string) => {
    const buttonLength = label.slice(0, 50);
    const oldCustomButtons = customButtons;
    const newCustomButtons = oldCustomButtons?.map((btn) => {
      if (btn.position === position) {
        return {
          position: btn.position,
          label: buttonLength,
          type: "QUICK_REPLY" as const
        };
      }
      return btn;
    });
    setCustomButtons(newCustomButtons);
    // dispatch(
    //   updateCustomButton({
    //     nodeId: nodeSelected?.id as string,
    //     botInteractionId: botInteractionActive,
    //     position,
    //     label
    //   })
    // );
  };
  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSetNewButton();
      // dispatch(
      //   addCustomButton({
      //     nodeId: nodeSelected?.id as string,
      //     botInteractionId: botInteractionActive,
      //     label: newButtonLabel
      //   })
      // );
      // setNewButtonLabel("");
    }
  };
  const handleRemoveButton = () => {
    dispatch(
      deleteAllCustomButtons({
        nodeId: nodeSelected?.id as string,
        botInteractionId: botInteractionActive
      })
    );
    dispatch(setOverlayInteractionsShow(false));
    dispatch(setAdvisor(false));
    dispatch(setCustomButtonModalOpen(false));
  };

  return (
    <Overlay style={{ display: customButtonModalOpenRedux ? "block" : "none" }}>
      <Toast ref={toast} />
      <Modal
        style={{ display: customButtonModalOpenRedux ? "flex" : "none" }}
        open={customButtonModalOpenRedux}
      >
        <div className="flex-none w-full">
          <Button
            onClick={() => {
              dispatch(setOverlayInteractionsShow(false));
              dispatch(setAdvisor(false));
              dispatch(setCustomButtonModalOpen(false));
            }}
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
            aria-label="Cancel"
          />
          <div className="block mt-2 w-full justify-content-start align-items-start">
            <div className="flex ml-2 mt-2 justify-content-center align-items-center">
              <div className="flex">
                <p css={Title}>Inserir Botão</p>
                <Span>?</Span>
              </div>
            </div>
            <div className="mt-4">
              <Dropdown
                style={{
                  border: "1px solid #D2D2D2",
                  borderRadius: "0.5rem",
                  height: "2.8125rem",
                  paddingTop: "0.2rem"
                }}
                placeholder="Tipo de botão"
                options={options}
                value={optionSelected}
                onChange={(e) => setOptionSelected(e.value)}
              />
            </div>
          </div>
        </div>
        {optionSelected === 2 ? (
          <>
            <div className="flex mt-2 flex-grow-1 flex-column align-items-start justify-content-start h-full w-full">
              <InputText
                style={{ borderRadius: "0.5rem", height: "2.8125rem" }}
                className="w-full"
                value={buttonName}
                onChange={(e) => setButtonName(e.target.value)}
                placeholder="Nome do botão"
                maxLength={50}
              />
              <span className="p-input-icon-left mt-2 w-full">
                <i
                  className="pi pi-link text-blue-500"
                  onClick={handleSetNewButton}
                />
                <div css={VDivisorLeft} />
                <InputText
                  style={{
                    paddingLeft: "3rem",
                    borderRadius: "0.5rem",
                    height: "2.8125rem"
                  }}
                  className="w-full"
                  value={newlink}
                  onChange={(e) => setNewlink(e.target.value)}
                  placeholder="Adicionar link"
                />
              </span>
            </div>

            <div className="mt-2 w-full">
              {CustomButtons?.length ? (
                <div className="flex mt-4 w-full align-items-center justify-content-between gap-2">
                  <ButtonRemove onClick={handleRemoveButton}>
                    Remover
                  </ButtonRemove>
                  <ButtonSave onClick={() => handleSetLink()}>
                    Salvar
                  </ButtonSave>
                </div>
              ) : (
                <ButtonStyle onClick={() => handleSetLink()}>
                  <img src={Check} alt="Save Button" />
                  Adicionar
                </ButtonStyle>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex-grow-1 w-full">
              <span className="p-input-icon-right mt-2 w-full">
                <div css={VDivisor} />

                <i
                  className="pi pi-plus-circle text-blue-500"
                  onClick={handleSetNewButton}
                />
                <InputText
                  style={{
                    borderRadius: "0.5rem",
                    height: "2.8125rem",
                    width: "100%"
                  }}
                  className="w-full"
                  value={newButtonLabel}
                  onChange={(e) => setNewButtonLabel(e.target.value)}
                  onKeyUp={(e) => handleKeyUp(e)}
                  placeholder="Adicionar opções"
                  maxLength={50}
                />
              </span>
              <div className="mt-2">
                {customButtons?.map((el) => (
                  <span
                    key={el.position}
                    className="p-input-icon-right mt-2 w-full"
                  >
                    <div css={VDivisor} />
                    <i
                      className="pi pi-times-circle text-red-500"
                      onClick={() => handleDeleteNewButton(el.position)}
                    />
                    <InputText
                      style={{ borderRadius: "0.5rem", height: "2.8125rem" }}
                      className="w-full"
                      value={el.label}
                      onChange={(e) =>
                        handleUpdateNewButton(el.position, e.target.value)
                      }
                      placeholder="Adicionar opções"
                    />
                  </span>
                ))}
              </div>
              <div className="flex mt-3 w-full flex-grow flex-column align-items-start justify-content-evenly">
                <Dica>Dica:</Dica>
                <Dica>
                  Para a lista do WhatsApp sugerimos usar no máximo 10 opções
                </Dica>
                <Dica>Limite de caracteres por whatsapp</Dica>
              </div>
            </div>
            {CustomButtons?.length ? (
              <div className="flex mt-2 w-full align-items-center justify-content-between gap-2">
                <ButtonRemove onClick={handleRemoveButton}>
                  Remover
                </ButtonRemove>
                <ButtonSave
                  onClick={() => {
                    dispatch(
                      setCustomButtonsRedux({
                        nodeId: nodeSelected?.id as string,
                        botInteractionId: botInteractionActive,
                        customButtons: customButtons as ICustomButtons[]
                      })
                    );
                    dispatch(setCustomButtonModalOpen(false));
                    dispatch(setOverlayInteractionsShow(false));
                    dispatch(changedPhoneComponent(true));
                  }}
                >
                  Salvar
                </ButtonSave>
              </div>
            ) : (
              <div className="mt-2 w-full">
                <ButtonStyle
                  onClick={() => {
                    dispatch(
                      setCustomButtonsRedux({
                        nodeId: nodeSelected?.id as string,
                        botInteractionId: botInteractionActive,
                        customButtons: customButtons as ICustomButtons[]
                      })
                    );
                    dispatch(setCustomButtonModalOpen(false));
                    dispatch(setOverlayInteractionsShow(false));
                    dispatch(changedPhoneComponent(true));
                  }}
                >
                  <img src={Check} alt="Save Button" />
                  Adicionar
                </ButtonStyle>
              </div>
            )}
          </>
        )}
      </Modal>
    </Overlay>
  );
};
