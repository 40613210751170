import { useAppSelector } from "../../../../redux/hooks";
import { ReactNode } from "react"
import { OverlayInteractionsContainer } from "./styles";

interface IOverlayInteractions {
    children?: ReactNode
}
export const OverlayInteractions: React.FC<IOverlayInteractions> = ({}) => {
    const overlayInteractionsShow = useAppSelector(state => state.flowInteractions.overlayInteractionsShow);
    return (
    <OverlayInteractionsContainer show={overlayInteractionsShow}>
        <div className="relative"> 
            {/* {children} */}
        </div>
    </OverlayInteractionsContainer>
)}