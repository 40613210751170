import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 1rem;
  height: calc(100% - 79px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
`;
export const BgGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 24.5rem;
  top: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(154.77deg, #046af3 9.18%, #95c2ff 90.33%);

  @media screen and (max-width: 1024px) {
    height: 33rem;
  }
  @media screen and (max-width: 772px) {
    height: 50.5rem;
  }
`;
export const ContainerTransparent = styled.div`
  position: relative;
  width: auto;
  height: max-content;
  background-color: transparent;
`;
export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border: 2px solid #4290f9;
  padding: 2px;
  border-radius: 50%;
`;
export const DivImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Img = styled.img`
  width: 3.5rem;
  max-height: 100%;
  max-width: 100%;
`;
export const SelectMultiple = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 2.875rem;
  border: 1px solid #ffffff;
  border-radius: 0.5rem;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
`;
export const SelectIcon = styled.select`
  border: none;
  width: max-content;
  min-width: 100px;
  background-color: transparent;
  color: #fff;
`;
export const VDivisor = styled.div`
  width: 2.875rem;
  transform: rotate(90deg);
  border: 1px solid #ffffff;
  margin: 0;
`;
export const Card = styled.div`
  padding: 1rem;
  width: 18.1875rem;
  height: 7.6875rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  /* opacity: 0.3;
background: #085FD5; */
  background: rgba(8, 94, 213, 0.3);
  border: 1px solid #8abbfe;
  /* Dash */
  box-shadow: 0px -2px 4px rgba(51, 51, 51, 0.06),
    0px 4px 8px rgba(51, 51, 51, 0.06);
  border-radius: 10px;
  @media (min-width: 1024px) {
    width: 20%;
    max-width: 18.1875rem;
    height: 7.6875rem;
  }
`;
export const ContainerIcon = styled.div`
  mix-blend-mode: normal;
  border: 1px solid #3e8df8;
  color: #ffffff;
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(8, 94, 213, 0.3);
  @media (max-width: 1145px) {
    height: 2.1875rem;
    min-height: 2.1875rem;
    max-height: 2.1875rem;
    height: 7.6875rem;
  }
`;

export const MinutesCard = styled.div`
  width: 100%;
  height: 3.562rem;
  border-radius: 6px;
  margin-top: 1.937rem;
  margin-bottom: 1.31rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.62rem 0.875rem;
  gap: 1.5rem;

  img {
    height: 3.812rem;
    overflow: overlay;
    margin-bottom: 4px;
  }
`;

export const HalfChartContainer = styled.div`
  background-color: #ffffff;
  border-radius: 1.5rem;
  width: 50%;
  height: 100%;
  padding: 1.75rem 2.5rem;
  min-width: 500px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
`;

export const TitleLine = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;

  span {
    color: #046af3;
    background-color: #d4e2f3;
    width: 1.56rem;
    height: 1.56rem;
    border-radius: 10rem;
    display: flex;

    align-items: center;
    justify-content: center;
  }
`;
export const IconDiv = styled.div`
  border: 2px solid #046af3;
  border-radius: 10rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.2rem;
  padding-bottom: 0.15rem;
`;

export const TableText = styled.div`
  display: block;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  div {
    display: flex;
    justify-content: space-between;
  }
`;

interface ISpanCollor {
  color?: string;
}
export const SpanCollor = styled.span<ISpanCollor>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: ${(props) =>
    props.color ? `2px solid ${props.color}` : "2px solid #40EF90"};
`;

export const FullChartContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
  width: 100%;
  height: 34rem;
  padding: 1.75rem 2.5rem;
  min-width: 500px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
`;

interface IButtonReload {
  rotateIcon: boolean;
}
export const ButtonReload = styled.img<IButtonReload>`
  background-color: transparent;
  color: white;
  :hover {
    cursor: pointer;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  -webkit-animation: ${(props) =>
    props.rotateIcon ? "2s linear infinite" : ""};
  -moz-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -ms-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -o-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;
