/* eslint-disable func-style */
import { useFetch2 } from "../util";

export interface iCloseRooms {
  rooms: number[];
}
export interface iTransferDataRooms {
  workspaceId: number;
  typeTransfer?: number;
  // 1-Colaborador Específico 2-Sequencial
  userId?: number;
  sendToThirdpartySystem: boolean;
}
export interface iTransferRooms {
  rooms: number[];
  transferData: iTransferDataRooms;
}
function DialogResourceServices() {
  const useQuery = useFetch2();
  type getDialogType = {
    offset?: number;
    limit?: number;
    name?: string;
    companyId: number;
    workspaces: number[];
  };

  const getDialogs = async (data: getDialogType) => {
    const params = new URLSearchParams();
    params.append("limit", `${data.limit || 10}`);
    params.append("offset", `${data.offset || 0}`);
    if (data.name) {
      params.append("name", `${data.name}`);
    }
    if (data.companyId) {
      params.append("companyId", `${data.companyId}`);
    }
    if (data?.workspaces?.length) {
      data.workspaces.map((el) => params.append("workspaces", String(el)));
    } else {
      params.append("workspaces", "0");
    }
    try {
      const method = { method: "GET" };
      return await useQuery(`/api/dialogs?${params}`, method);
    } catch (error) {
      return error;
    }
  };
  const postGetDialogs = async (data: any) => {
    try {
      const method = { method: "POST" };
      return await useQuery(`/api/dialogs/list`, method, data);
    } catch (error) {
      return error;
    }
  };

  const closeDialogsRooms = async (data: iCloseRooms) => {
    try {
      const method = { method: "POST" };
      return await useQuery(`/api/dialogs/close-rooms`, method, data);
    } catch (error) {
      return error;
    }
  };
  const transferDialogsRooms = async (data: iTransferRooms) => {
    try {
      const method = { method: "POST" };
      return await useQuery(`/api/dialogs/transfer-rooms`, method, data);
    } catch (error) {
      return error;
    }
  };

  return {
    getDialogs,
    postGetDialogs,
    closeDialogsRooms,
    transferDialogsRooms
  };
}

export default DialogResourceServices;
