import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  addFilesIds,
  setSendFileModalOpen
} from "../../../../../redux/slices/flowInteractions";
import {
  DropZone,
  FileUploader,
  FileUploaderContainer,
  HiddenFileInput,
  ImgRotate,
  PhotoVideoContainer
} from "./styles";
import { addFiles, setRejectedFiles } from "../../../../../redux/slices/nodes";
import { setAttachedAudio } from "../../../../../redux/slices/flowInteractions";
import { PhotoVideo, Seta } from "../../../../../assets";
import { Text } from "../../../../Text";
import { v4 as uuidv4 } from "uuid";
import { iRejectedFiles } from "../../../../../components/react-flow-renderer/NodeData";
import publishFlowServices from "../../../../../services/onSave";
import { Toast } from "primereact/toast";
import { useToast } from "../../../../../components/toast-provider";
interface iFiles {
  fileType: string;
  mimeTypes: string[];
  sizeLimit: number;
}
const allowedFileTypes: iFiles[] = [
  {
    fileType: "audio",
    mimeTypes: [
      "audio/aac",
      "audio/mp4",
      "audio/mpeg",
      "audio/amr",
      "audio/ogg opus codecs",
      "audio/ogg"
    ],
    sizeLimit: 16 * 1024 * 1024
    // 16Mb
  },
  {
    fileType: "image",
    mimeTypes: ["image/jpeg", "image/png", "image/gif"],
    sizeLimit: 5 * 1024 * 1024
    // 5Mb
  },
  {
    fileType: "sticker",
    mimeTypes: ["image/webp"],
    sizeLimit: 0.5 * 1024 * 1024
    //500Kb
  },
  {
    fileType: "video",
    mimeTypes: ["video/mp4", "video/3gp"],
    sizeLimit: 16 * 1024 * 1024
    // 16Mb
  },
  {
    fileType: "documents",
    mimeTypes: [
      "text/plain",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ],
    sizeLimit: 100 * 1024 * 1024
    //100Mb
  }
];
const formatsAccepted =
  ".jpeg, .jpg, .png, .gif, .mp4, .pdf, .txt, .doc, .docx, .xls, .xlsx, .ogg, .amr, .webp, .3gp, .ppt, .pptx, .ods, .opus";

interface iIsAccepted {
  accepted: "all" | "some" | "none";
}
interface iSendFiles {
  fromClick: boolean;
}
export const SendFilesModal: React.FC<iSendFiles> = ({ fromClick }) => {
  const sendFilesModalOpen = useAppSelector(
    (state) => state.flowInteractions.sendFileModalOpen
  );
  const { saveFile } = publishFlowServices();
  const [isDragOver, setIsDragOver] = useState(false);
  const [loadding, setLoadding] = useState(false);
  const [isAccepted, setIsAccepted] = useState<iIsAccepted>({
    accepted: "all"
  });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const botInteractionIdActiveRedux = useAppSelector(
    (state) => state.flowInteractions.botInteractionIDActive
  );
  const newFilesIds = useAppSelector(
    (state) => state.flowInteractions.newFilesIds
  );

  const [myKey, setMyKey] = useState(uuidv4());
  const [fileNames, setFileNames] = useState<string[]>([]);
  const toast = useToast();
  // const toBase64 = (file: File) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = reject;
  //   });
  const onFilesAttached = async (files: File[]) => {
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      // const base64File = await toBase64(file);
    }
  };
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setLoadding(true);
    setIsDragOver(false);
    const fileList = event.dataTransfer.files;
    const excededFiles: iRejectedFiles[] = [] as iRejectedFiles[];
    Array.from(fileList).filter((file) => {
      const [filetype] = allowedFileTypes.filter((fileType) =>
        fileType.mimeTypes.includes(file.type)
      );
      if (filetype && filetype.sizeLimit < file.size) {
        excededFiles.push({
          id: uuidv4(),
          name: file.name,
          reason: `Tamanho máximo excedido. Máximo permitido: ${
            filetype.sizeLimit / 1024 / 1024
          }Mb.`
        });
      }
      if (!filetype) {
        excededFiles.push({
          id: uuidv4(),
          name: file.name,
          reason: `Formatos permitidos: ${formatsAccepted
            .split(",")
            .map((el) => el.trim().replace(".", ""))
            .join("/")}.`
        });
      }
      if (filetype && filetype.fileType === "audio") {
        const audioBlob = new Blob([file], { type: file.type });
        const urlAudio = URL.createObjectURL(audioBlob);
        dispatch(setAttachedAudio(urlAudio));
      }

      return null;
    });
    // const excededFilesNames = excededFiles.map((el) => el.name);
    if (excededFiles.length) {
      dispatch(
        setRejectedFiles({
          nodeId: nodeSelected?.id as string,
          botId: botInteractionIdActiveRedux,
          rejectedFiles: excededFiles
        })
      );
    }
    const filteredFiles = Array.from(fileList).filter((file) => {
      const [filetype] = allowedFileTypes.filter((fileType) =>
        fileType.mimeTypes.includes(file.type)
      );
      if (filetype && filetype.sizeLimit >= file.size) {
        return file;
      }
      return null;
    });
    if (filteredFiles.length === fileList.length) {
      setIsAccepted({ accepted: "all" });
      for (const file of filteredFiles) {
        const fileObject = new FormData();
        fileObject.append("file", file);
        fileObject.append("name", file.name);
        fileObject.append("mimeType", file.type);
        const save = await saveFile(fileObject);

        dispatch(addFilesIds([save.fileId]));

        dispatch(
          addFiles({
            nodeId: nodeSelected?.id as string,
            botId: botInteractionIdActiveRedux,
            files: {
              id: uuidv4(),
              name: file.name,
              fileId: save.fileId
              // base64File: base64File as string
            }
          })
        );
      }
      dispatch(setSendFileModalOpen(false));
      setLoadding(false);
    } else if (
      filteredFiles.length < fileList.length &&
      filteredFiles.length > 0
    ) {
      setIsAccepted({ accepted: "some" });
    } else if (
      filteredFiles.length < fileList.length &&
      filteredFiles.length === 0
    ) {
      setIsAccepted({ accepted: "none" });
    }
    await onFilesAttached(filteredFiles);
    setLoadding(false);
  };

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files as FileList;
    setLoadding(true);
    event.preventDefault();
    const excededFiles: iRejectedFiles[] = [] as iRejectedFiles[];

    Array.from(fileList).filter(async (file) => {
      const [filetype] = allowedFileTypes.filter((fileType) =>
        fileType.mimeTypes.includes(file.type)
      );

      if (!fileNames.includes(file.name)) {
        setFileNames([...fileNames, file.name]);
      } else {
        toast?.current?.show({
          life: 5000,
          severity: "warn",
          summary: "Arquivo duplicado",
          detail: `O arquivo ${file.name} foi adicionado mais de uma vez.`,
          sticky: false
        });
      }

      if (filetype && filetype.sizeLimit < file.size) {
        excededFiles.push({
          id: uuidv4(),
          name: file.name,
          reason: `Tamanho máximo excedido. Máximo permitido: ${
            filetype.sizeLimit / 1024 / 1024
          } MB.`
        });
      }
      if (!filetype) {
        excededFiles.push({
          id: uuidv4(),
          name: file.name,
          reason: `Formato de arquivo inválido. Formatos permitidos: ${formatsAccepted
            .split(",")
            .map((el) => el.trim().replace(".", ""))
            .join("/")}.`
        });
      }

      if (filetype && filetype.fileType === "audio") {
        const audioBlob = new Blob([file], { type: file.type });
        const urlAudio = URL.createObjectURL(audioBlob);
        dispatch(setAttachedAudio(urlAudio));
      }

      if (filetype && file.size <= filetype.sizeLimit) {
        const fileObject = new FormData();
        fileObject.append("file", file);
        fileObject.append("name", file.name);
        fileObject.append("mimeType", file.type);
        const save = await saveFile(fileObject);

        dispatch(addFilesIds([save.fileId]));

        dispatch(
          addFiles({
            nodeId: nodeSelected?.id as string,
            botId: botInteractionIdActiveRedux,
            files: {
              id: uuidv4(),
              name: file.name,
              fileId: save.fileId
              // base64File: base64File as string
            }
          })
        );
      }

      dispatch(setSendFileModalOpen(false));
      setMyKey(uuidv4());

      return null;
    });
    // const excededFilesNames = excededFiles.map((el) => el.name);
    if (excededFiles.length) {
      dispatch(
        setRejectedFiles({
          nodeId: nodeSelected?.id as string,
          botId: botInteractionIdActiveRedux,
          rejectedFiles: excededFiles
        })
      );
    }
    const filteredFiles = Array.from(fileList).filter((file) => {
      const [filetype] = allowedFileTypes.filter((fileType) =>
        fileType.mimeTypes.includes(file.type)
      );
      if (filetype && filetype.sizeLimit >= file.size) {
        return file;
      }
      return null;
    });
    if (filteredFiles.length === fileList.length) {
      setIsAccepted({ accepted: "all" });
    } else if (
      filteredFiles.length < fileList.length &&
      filteredFiles.length > 0
    ) {
      setIsAccepted({ accepted: "some" });
    } else if (
      filteredFiles.length < fileList.length &&
      filteredFiles.length === 0
    ) {
      setIsAccepted({ accepted: "none" });
    }
    await onFilesAttached(filteredFiles);
    setLoadding(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleOpenFileSelector = () => {
    inputFileRef.current?.click();
  };
  useEffect(() => {
    if (fromClick && !loadding && sendFilesModalOpen) {
      inputFileRef.current?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendFilesModalOpen]);
  const heightExpanded = (): string => {
    if (
      nodeSelected?.data.botInteractions[0].buttons?.length &&
      Boolean(nodeSelected?.data.botInteractions[0].files?.length)
    ) {
      const qtFiles = ((nodeSelected?.data.botInteractions[0].files
        ?.length as number) +
        (nodeSelected?.data?.botInteractions[0]?.rejectedFiles?.length ||
          0)) as number;
      const sizeGrow = qtFiles * 5 + 5;
      return `${sizeGrow}rem`;
    }
    if (
      !nodeSelected?.data.botInteractions[0].buttons?.length &&
      Boolean(nodeSelected?.data.botInteractions[0].files?.length)
    ) {
      const qtFiles = ((nodeSelected?.data.botInteractions[0].files
        ?.length as number) +
        (nodeSelected?.data?.botInteractions[0]?.rejectedFiles?.length ||
          0)) as number;
      const sizeGrow = qtFiles * 5 + 5;

      return `${sizeGrow}rem`;
    }
    if (nodeSelected?.data.botInteractions[0].buttons?.length) {
      return "6.5rem";
    }
    return "1rem";
  };

  return (
    <FileUploader open={sendFilesModalOpen}>
      <FileUploaderContainer>
        {/* <MyIconButton onClick={() => dispatch(setSendFileModalOpen(false))}>
          <img src={CloseXRed} alt="Close Button" />
        </MyIconButton> */}
        <DropZone
          expanded={heightExpanded()}
          isHover={isDragOver}
          isAllAccepted={isAccepted}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleOpenFileSelector}
        >
          {loadding ? (
            <div>loading...</div>
          ) : (
            <>
              <PhotoVideoContainer>
                <img src={PhotoVideo} alt="Img Photo Video" />
              </PhotoVideoContainer>
              <div
                className="flex align-items-center"
                style={{ maxWidth: "80%" }}
              >
                <Text s="1.125rem" weight={500} lh="1.375rem" color="#0A043C">
                  Arraste Imagem, vídeo, documento ou áudio aqui
                </Text>
              </div>
              <Text s="0.6875rem" weight={400} lh="1rem" color="#0A043C">
                O tamanho máximo para arquivos de áudio e vídeo é de 16 MB,
                imagem 5 MB e documentos 100 MB
              </Text>
              <ImgRotate
                right="1rem"
                top="1rem"
                rotate={90}
                src={Seta}
              ></ImgRotate>
              <ImgRotate
                right="1rem"
                bottom="1rem"
                rotate={-180}
                src={Seta}
              ></ImgRotate>
              <ImgRotate
                top="1rem"
                left="1rem"
                rotate={-10}
                src={Seta}
              ></ImgRotate>
              <ImgRotate
                bottom="1rem"
                left="1rem"
                rotate={-90}
                src={Seta}
              ></ImgRotate>
            </>
          )}
          <HiddenFileInput
            type="file"
            key={myKey}
            multiple={true}
            ref={inputFileRef}
            onChange={(e) => handleAddFile(e)}
            accept={formatsAccepted}
          />
        </DropZone>
      </FileUploaderContainer>
    </FileUploader>
  );
};
