import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { ProfileList } from "../components/Pages/profile/";

export const ProfilesPage: React.FC = () => {
  return (
    <LayoutHeader2>
      <ProfileList />
    </LayoutHeader2>
  );
};
