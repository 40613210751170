import styled from "@emotion/styled";
interface IText {
s?: string;
lh?: string;
color?: string;
weight?: number;
}
export const Text = styled.p<IText>`
font-family: 'Poppins';
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400};
font-size: ${props => props.s ? props.s : "12px"};
line-height: ${props => props.lh ? props.lh : "16px"};
text-align: center;
color: ${props => props.color ? props.color : "#6E6B7B"};
`
;

export const TextRejected = styled.p<IText>`
font-family: 'Poppins';
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400};
font-size: ${props => props.s ? props.s : "12px"};
line-height: ${props => props.lh ? props.lh : "16px"};
text-align: center;
color: ${props => props.color ? props.color : "#6E6B7B"};
width: 8rem;

@media (min-width: 1200px) {
    width: 11rem;
  }

@media (min-width: 1400px) {
    width: 14.5rem;
  }

@media (min-width: 1500px) {
    width: 16rem;
  }

@media (min-width: 1600px) {
    width: 17.5rem;
  }

@media (min-width: 1750px) {
    width: 20rem;
  }

@media (min-width: 1900px) {
    width: 22.5rem;
  }

@media (min-width: 2050px) {
    width: 25rem;
  }
`
;