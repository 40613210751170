import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { ButtonDelete, SixPoints } from "../../../../../../assets";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  Baloon,
  ButtonPlus,
  ButtonTrash,
  ImageSixPoints,
  Input
} from "./styles";
import { changedPhoneComponent } from "../../../../../../redux/slices/flowupdates";
import {
  addBotFallBack,
  deleteBotFallback,
  updateDefaultFallBackMessage
} from "../../../../../../redux/slices/bot";

interface ITextDefaultFallback {
  botId?: number;
}
export const TextDefaultFallback: React.FC<ITextDefaultFallback> = ({
  botId
}) => {
  const dispatch = useAppDispatch();
  const inputTexts = useAppSelector(
    (state) => state.bot.defaulFallbackMessages
  );

  const handleUpdate = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    dispatch(updateDefaultFallBackMessage({ newMessage: e.target.value, idx }));
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.ctrlKey) {
    } else {
      switch (e.key) {
        case "Enter": {
          e.preventDefault();
          handleAddFallbackMessage();
        }
      }
    }
  };
  const handleAddFallbackMessage = () => {
    dispatch(addBotFallBack());
    dispatch(changedPhoneComponent(true));
  };
  const handleDelete = (idx: number) => {
    dispatch(deleteBotFallback(idx));
    dispatch(changedPhoneComponent(true));
  };

  return (
    <>
      {inputTexts.map((el, index) => (
        <div key={index} css={Baloon}>
          <img css={ImageSixPoints} src={SixPoints} alt="icon-six-points" />
          <Input
            autoFocus={true}
            type="text"
            value={el}
            onChange={(e) => handleUpdate(e, index)}
            onKeyDown={handleKeyPress}
            placeholder="Insira sua mensagem de fallback aqui..."
          />
          <button onClick={handleAddFallbackMessage} css={ButtonPlus}>
            +
          </button>

          {inputTexts?.length > 1 ? (
            <button onClick={() => handleDelete(index)} css={ButtonTrash}>
              <img src={ButtonDelete} alt="icon-button-delete" />
            </button>
          ) : null}
        </div>
      ))}
    </>
  );
};
