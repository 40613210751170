import { NodeData } from "./../components/react-flow-renderer/NodeData.d";
import { Node } from "react-flow-renderer";


interface Child {
  text?: string;
  type?: 'mention';
  variable?: string;
  children?: Child[];
}
export interface ISlateText {
  type: 'paragraph';
  children: Child[];
}

export const entityIsSetAsVariable = (
  entityName: string,
  nodeId: string,
  nodes: Node<NodeData>[]
): { nodeId: string; botId: string, entityName: string }[] => {
  const entityIsSetAsVariableInThisBotsInteractionsIds: {
    nodeId: string;
    botId: string;
    entityName: string;
  }[] = [];
  nodes.map((_node) => {
    return _node?.data?.botInteractions.map((bi) => {
      if (bi.text !== "") {
        const biObject: Child[] = JSON?.parse(bi?.text);
        return biObject
          ?.map(biObj => {
            return biObj?.children
              ?.map(biChildren => {
                  if (biChildren?.type === "mention") {
                    if (
                      biChildren?.children[0]?.text === nodeId &&
                      biChildren?.variable === entityName
                    ) {
                      return entityIsSetAsVariableInThisBotsInteractionsIds.push(
                        {
                          nodeId: _node.id,
                          botId: bi?.id,
                          entityName
                        }
                      );
                    } return undefined;
                  } return undefined;
                }
              )
              .filter((notUndefined: undefined) => notUndefined !== undefined);
          })
          .filter((notUndefined: undefined) => notUndefined !== undefined);
      } return undefined;
    });
  });
  return entityIsSetAsVariableInThisBotsInteractionsIds;
};
