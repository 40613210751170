import React from 'react'
import { Button, Dropdown, InputText } from "../..";

type paramType = {
  labelName: string;
  clickToggle: () => void;
}

function NextBtn({labelName, clickToggle}: paramType) {
  return (
    <div className="flex justify-content-center">
      <Button label={labelName} icon="pi pi-arrow-right" onClick={clickToggle} iconPos="right" className="border-round-lg w-4 mt-3" />
    </div>
  )
}

export default NextBtn
