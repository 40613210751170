import { css } from "@emotion/react";

export const EntitiesStyle = css`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  border-radius: 6px;
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  z-index: 7;
  overflow: hidden;
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const EntityBlueBar = css`
  width: 0.75rem;
  height: 100%;
  background: #046af3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;
