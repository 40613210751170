import {
  NodeData
} from "./../components/react-flow-renderer/NodeData.d";
import { Node, XYPosition } from "react-flow-renderer";
import { Edge, EdgeProps, getIncomers, getOutgoers } from "react-flow-renderer";


interface IGetBestPosition {
    nodes: Node<NodeData>[];
    edges: Edge<EdgeProps>[];
    node: Node<NodeData>;
  }

  export const getBestPosition = ({nodes, edges, node}: IGetBestPosition): XYPosition => {
    const outgoers = getOutgoers(node, nodes, edges);
    let newPosition = {
      x: 0,
      y: 0
    }
    if (outgoers.length === 0) {
       newPosition =  {
        x: node.position.x,
        y: node.position.y + 130
      }
    }
    else {
      const minPositionYChildren = outgoers.reduce((acc, item, index) => {
        if (index === 0) return item.position.y;
        else {
          if (item.position.y < acc) return item.position.y;
          else return acc;
        }
      }, 0)
      const maxPositionXChildren = outgoers.reduce((acc, item, index) => {
        if (index === 0) return item.position.x;
        else {
          if (item.position.x > acc) return item.position.x;
          else return acc;
        }
      }, 0)
      newPosition.x = maxPositionXChildren+196+20;
      newPosition.y = minPositionYChildren;

    }
    const centerX = newPosition.x + 196/2;
    const centerY = newPosition.y + 41/2;
    if(checkColision(nodes, centerX, centerY) !== false) {
      const NewNewPositions = recursive(nodes, centerX, centerY);
      newPosition.x = NewNewPositions.x + 20

    }

    // newPosition.y = NewNewPositions.y
    return newPosition
  }

  const checkColision = (nodes: Node<NodeData>[], centerX: number, centerY: number) => {
  return nodes.find(n => {
    if(centerX > n.position.x - 196/2 &&
      centerX < n.position.x + 196 &&
      centerY > n.position.y - 41/2 &&
      centerY < n.position.y + 41
      ) return true
  }) || false
}

const recursive = (nodes: Node<NodeData>[], centerX: number, centerY: number): XYPosition => {
  if (checkColision(nodes, centerX, centerY) !== false ) {
    return recursive(nodes, centerX+1, centerY)
  }
  else return {x: centerX , y: centerY}
}