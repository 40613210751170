import React from "react";
import { CloseButton } from "../../../../../assets";
import { RoundedButton, RoundedDiv, Spacing } from "./styles";
import { useLocation, useParams } from "react-router-dom";
interface IMyTag {
  text: string;
  deleteTag: (tag: string) => void;
}
const MyTag: React.FC<IMyTag> = ({ text, deleteTag }) => {
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  return (
    <div css={RoundedDiv}>
      {text}
      {isTemplate ? (
        <Spacing />
      ) : (
        <div onClick={() => deleteTag(text)} css={RoundedButton}>
          <img src={CloseButton} alt="Exclude-button" />
        </div>
      )}
    </div>
  );
};

export default MyTag;
