/* eslint-disable no-negated-condition */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import WorkspaceService from "../../../services/WorkspaceService";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import { Button, Dropdown, InputText } from "../..";
require("../../comum_css/rawCss.css");
import WorkspaceInviteTab from "./WorkspaceInviteTab";
import { v } from "../../../util";
import { botRed, BtnImgPersons } from "../../../assets";
import workspaceList, { WorkspaceContext } from "./workspace-list";
import functions from "../../../util/functions";
import { ProgressSpinner } from "primereact/progressspinner";
import { useToast } from "../../toast-provider";
import { setUpdateWorkspaces } from "../../../redux/slices/workspaces";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

type MessageSeverityType = "success" | "info" | "warn" | "error";

type messageType = {
  type?: MessageSeverityType;
  title: string;
};
type workspaceFormType = {
  showMessageBox: (value: messageType) => void;
  onHideDialog: (status: string | null) => void;
};

//
const WorkspaceForm: React.FC<workspaceFormType> = ({
  showMessageBox,
  onHideDialog
}: workspaceFormType) => {
  const { company, user } = useAuth() as AuthContextType;
  const companyId = company?.id;
  const dispatch = useAppDispatch();
  const companyIdSelected = user.admin ? companyId : user.companyId;
  const [stylesButton, setStylesButton] = useState(true);
  const toast = useToast();
  const updateWorkspaces = useAppSelector(
    (state) => state.workspaces.updateWorkspaces
  );
  const { getWorkspaceByCompanyId, createWorkspace, updateWorkspace } =
    WorkspaceService();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: "",
      nivel: null
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        nivel: v.number().optional().nullable()
      })
    )
  });

  const { isObjectEmpty } = functions();

  const workSP = useContext(WorkspaceContext);

  const [conviteValue, setConviteValue] = useState<
    { email: string; cargo: number }[]
  >([]);
  const [equipeValue, setEquipeValue] = useState<{ id: number }[]>([]);
  const [workspaceLevel, setWorkspaceLevel] = useState([]);
  const [mode, setMode] = useState("workspace");
  const [loading, setLoading] = useState(false);

  const countUserInvited = () => {
    if (!isObjectEmpty(workSP)) {
      const sufix = workSP.usersCount > 1 ? "Colaboradores" : "Colaborador";
      return `${workSP?.usersCount} ${sufix}`;
    }
    return "Convidar";
  };

  useEffect(() => {
    (async () => {
      const wokspaceList = await getWorkspaceByCompanyId(companyIdSelected);
      setWorkspaceLevel(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        wokspaceList?.filter((workspace: any) => workspace.name !== workSP.name)
      );
    })();

    if (!isObjectEmpty(workSP)) {
      setValue("name", workSP?.name);
      setValue("nivel", workSP?.workspaceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cssP = {
    backgroundColor: "white",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderTopRightRadius: "50px",
    width: "100%"
  };

  const showSuccess = (message: string) => {
    if (!isObjectEmpty(workSP)) {
      workSP.toast?.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: message,
        life: 3000
      });
    }
    onHideDialog("success");
  };

  useEffect(() => {
    if (mode === "workspace") {
      setStylesButton(true);
    } else {
      setStylesButton(false);
    }
  }, [mode]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendForm = handleSubmit(async (data, _e) => {
    setLoading(true);

    const newUsersSelected = equipeValue.length > 0;

    if (!isObjectEmpty(workSP)) {
      const dataReturn = await updateWorkspace(workSP.id, {
        active: true,
        companyId: companyIdSelected,
        invitesForNewUsers: conviteValue.map((c) => ({
          email: c.email,
          profileId: c.cargo
        })),
        name: data.name,
        parentId: data.nivel,
        users: newUsersSelected
          ? equipeValue.map((e) => e.id)
          : workSP.users.filter((s) => s.active).map((e) => e.id)
      });

      if (dataReturn.id) {
        showSuccess("Workspace Atualizado com sucesso!!");
        reset();
      }
    } else {
      const dataResp = await createWorkspace({
        active: true,
        companyId: companyIdSelected,
        invitesForNewUsers: conviteValue.map((c) => ({
          email: c.email,
          profileId: c.cargo
        })),
        name: data.name,
        parentId: data.nivel as unknown as number,
        users: newUsersSelected ? equipeValue.map((e) => e.id) : []
      });
      if (dataResp.description !== undefined) {
        showMessageBox({ title: dataResp.description, type: "error" });
      }
      if (dataResp.id) {
        showSuccess("Workspace adicionado com sucesso!!");
        reset();
      }
    }
    dispatch(setUpdateWorkspaces(!updateWorkspaces));
    setLoading(false);
  });

  const handleChangeMode = () => {
    setMode("invite");
  };

  useEffect(() => {
    if (errors.name?.message !== undefined && mode === "invite") {
      toast?.current?.show({
        severity: "warn",
        summary: "Campo obrigatório vazio",
        detail: "Preencha o campo referente ao nome da Workspace",
        life: 3000
      });
    }
  }, [mode, sendForm]);

  const errorNameMessage = () => errors.name?.message;
  const errorNivelMessage = () => errors.nivel?.message;

  return (
    <div className="col-8" style={cssP}>
      <div>
        {mode === "invite" ? (
          <i
            className="pi pi-arrow-left"
            title="Voltar"
            onClick={() => setMode("workspace")}
            style={{ position: "absolute", top: "0rem", cursor: "pointer" }}
          ></i>
        ) : null}
        <div style={{ alignItems: "center", display: "flex", gap: "10px" }}>
          <img src={botRed} className="mb-2" />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {mode === "workspace" && (
              <>
                <div
                  className="font-bold"
                  style={{ color: "#0A043C", fontSize: "1.02rem !important" }}
                >
                  Workspace
                </div>
                <div>
                  Organize a sua empresa criando áreas de trabalho para seus
                  objetivos.
                </div>
              </>
            )}
            {mode === "invite" && (
              <div
                className="font-bold"
                style={{ color: "#0A043C", fontSize: "1.02rem !important" }}
              >
                Convide a sua equipe para fazer parte de tudo isso!
              </div>
            )}
          </div>
        </div>

        {mode === "workspace" ? (
          <form onSubmit={sendForm} style={{ marginTop: "25px" }}>
            <div
              className="flex, flex-column"
              style={{ flex: "1", gap: "25px" }}
            >
              <div>
                <div className="">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        {...field}
                        id="name"
                        placeholder="Nome da workspace"
                      />
                    )}
                  />
                </div>
                <div className="mt-1">
                  {" "}
                  {errors.name?.message !== undefined ? (
                    <> {errorNameMessage()} </>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>

              <div className="mt-2">
                <div className="">
                  <Controller
                    name="nivel"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        id="nivel"
                        options={workspaceLevel}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Nível"
                        onChange={(e) => field.onChange(e.value)}
                        filter={true}
                      />
                    )}
                  />
                </div>
                <div className="mt-1">
                  {" "}
                  {errors.nivel?.message !== undefined ? (
                    <> {errorNivelMessage()} </>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>

              <div
                className="mt-2 mb-2 invitBtnDiv relative"
                onClick={handleChangeMode}
                style={{
                  cursor: "pointer"
                }}
              >
                <Button
                  label={countUserInvited()}
                  className="w-full"
                  title="Convidar para Seu Workspace"
                  style={{
                    border: "solid 1px #ced4da",
                    backgroundColor: "transparent",
                    color: "#544F77"
                  }}
                />
                <img src={BtnImgPersons} className="btnPersonInvite" />
              </div>

              {!isObjectEmpty(workSP) ? (
                <Button
                  className="creatWorkspaceBtnform mt-3"
                  permission="workspace_edit"
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px"
                      }}
                    >
                      <ProgressSpinner
                        style={{
                          width: "16px",
                          height: "16px"
                        }}
                        animationDuration=".5s"
                      />
                      Atualizar
                    </div>
                  ) : (
                    "Atualizar"
                  )}
                </Button>
              ) : (
                <Button
                  label="Criar Workspace"
                  className="creatWorkspaceBtnform mt-3"
                  permission="workspace_create"
                />
              )}
            </div>
          </form>
        ) : (
          <>
            <WorkspaceInviteTab
              conviteValue={conviteValue}
              onConviteChange={(value) => setConviteValue(value)}
              equipeValue={equipeValue}
              onEquipeChange={(value) => setEquipeValue(value)}
              sendForm={sendForm}
            />
            <form onSubmit={sendForm}>
              <Button
                className="creatWorkspaceBtnform mt-3"
                permission="workspace_create"
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {loading ? (
                  <>
                    <ProgressSpinner
                      style={{
                        width: "16px",
                        height: "16px"
                      }}
                      animationDuration=".5s"
                    />
                    {stylesButton === true ? "Criar Workspace" : "Atualizar"}
                  </>
                ) : (
                  "Continuar"
                )}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkspaceForm;
