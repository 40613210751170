import React, { useState } from "react";
import { createRoboImge, useOurTemplate, humanAttendent } from "../../assets";
import { AdicionarBar } from "../adicionar-bar";
import { Button, DataTable, InputText, useList } from "..";
import CreateCards from "./addBlocks/CreateCards";
import CreateForm from "./addBlocks/CreateForm";
import SideTextDialog from "./addBlocks/SideTextDialog";
import DialogPadrao from "../DialogPadrao";
import DialogCreateFromZero from "./addBlocks/DialogCreateFromZero";
import { useAuth } from "../../auth/auth-provider";

const AddTemplate = () => {
  const { user } = useAuth();

  const dataCreateCards = [
    {
      title: "Criar do zero",
      description: `Utilize a área do ${user.plataform.displayName} para criar um chatbot de forma prática e personalizada.`,
      img: createRoboImge
    },
    {
      title: "Usar nossos templates",
      description:
        "Escolha um template pronto e facilite toda implementação do seu robô.",
      img: useOurTemplate
    },
    {
      title: "Atendimento Humano",
      description: "Responda seus usuários através de um atendente humano.",
      img: humanAttendent
    }
  ];
  const [onpenSidebar, setOpenSidebar] = useState(false);
  const [openDialogCreateFromZero, setOpenDialogCreateFromZero] =
    useState<boolean>(false);
  const [openDialogUseTemplates, setOpenDialogUseTemplates] =
    useState<boolean>(false);
  const [openDialogHumanAttendant, setOpenDialogUseHumanAttendant] =
    useState<boolean>(false);

  return (
    <div>
      <AdicionarBar visible={onpenSidebar} hide={() => setOpenSidebar(false)} />
      <div className="p-5 mt-2">
        <div className="">
          Escolha uma das opções para começar a criar o script do seu robô.
        </div>
        <div className="card mt-5">
          <div className="flex card-container indigo-container">
            <CreateCards
              title={dataCreateCards[0].title}
              description={dataCreateCards[0].description}
              img={dataCreateCards[0].img}
              onclick={setOpenDialogCreateFromZero}
            />
            <CreateCards
              title={dataCreateCards[1].title}
              description={dataCreateCards[1].description}
              img={dataCreateCards[1].img}
              onclick={setOpenDialogUseTemplates}
            />
            <CreateCards
              title={dataCreateCards[2].title}
              description={dataCreateCards[2].description}
              img={dataCreateCards[2].img}
              onclick={setOpenDialogUseHumanAttendant}
            />
          </div>
        </div>
      </div>

      <DialogCreateFromZero
        displayDialog={openDialogCreateFromZero}
        onHide={() => setOpenDialogCreateFromZero(false)}
      />
    </div>
  );
};

export default AddTemplate;
