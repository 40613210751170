const map = {
  "app.imbot.com.br": { displayName: "imbot" },
  "stg.app.imbot.com.br": { displayName: "imbot" },
  "bot.ibasi.com.br": { displayName: "ibasibot" },
  "botstaging.ibasi.com.br": { displayName: "ibasibot" }
} as Record<string, { displayName: string }>;

const plataform = window.location.href
  .replaceAll(/https?:\/\//gu, "")
  .replaceAll(/\/.*/gu, "");

export default map[plataform] ?? { displayName: "imbot" };
