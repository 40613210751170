import { css } from "@emotion/react";
import { useEffect, useState, ReactNode, useRef, RefObject } from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export interface InfiniteScrollProps {
  hasRequest: boolean;
  children: ReactNode;
  emitFetch: () => void;
}

const InfiniteScroll = ({
  hasRequest,
  children,
  emitFetch
}: InfiniteScrollProps) => {
  const scrollContainerRef: RefObject<HTMLDivElement> = useRef(null);

  const handleScroll = () => {
    if (scrollContainerRef.current && !hasRequest) {
      const { clientHeight, scrollTop, scrollHeight } =
        scrollContainerRef.current;

      if (clientHeight + scrollTop === scrollHeight) {
        emitFetch();
      }
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasRequest]);

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        overflow-y: auto;
      `}
      ref={scrollContainerRef}
    >
      {children}
      {hasRequest && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 15px;
          `}
        >
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;
