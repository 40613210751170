import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544f77;
  text-align: left;
`;

export const WaveformContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background: transparent;
  /* gap: 2rem; */
`;
export const NewWaveformContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  justify-content: space-between;
  height: 2.5rem;
  width: 100%;
  background: transparent;
  /* border: 1px solid #DADADA; */
  border-radius: 1.25rem;
  flex-grow: 1;
  /* gap: 2rem; */
`;
export const Wave = styled.div`
  /* position: absolute; */
  /* z-index: 12; */
  margin-top: -9rem;
  width: 100%;
  /* height: "-moz-ava/ilable"; */
  height: 90px;
`;
export const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
  &:hover {
    background: #ddd;
  }
`;

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
  border-radius: 50%;
  border: 1px solid rgba(33, 150, 243, 1);
  min-height: 2.6875rem;
  min-width: 2.6875rem;
  height: 2.6875rem;
  width: 2.6875rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
    background-color: #fbfaf6;
    transition: 0.2s ease-in-out;
  }
`;
export const RoundedButtonGreen = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00cdac;
  border-radius: 50%;
  border: none;
  min-height: 2.6875rem;
  min-width: 2.6875rem;
  height: 2.6875rem;
  width: 2.6875rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
    background-color: #53d9c3;
    transition: 0.2s ease-in-out;
  }
`;

export const WaveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  position: relative;

  #waveRecorder {
    width: 100%;
    height: 100%;
    max-height: 128px;
    margin-top: 0.6rem;
    position: relative;
    wave {
      width: 100%;
      display: unset !important;
      height: 100%;
      max-height: 33px;
      position: absolute !important;
      bottom: 0 !important;
      left: 0;
      right: 0;
    }
  }
`;
