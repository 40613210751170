import React from "react";
import { AttributesStyle, AttributeBlueBar } from "./styles";

interface IAttribute {
  name: string;
  onClick: (entityTypeId: number, entityTypeName: string, nodeSourceID: string) => void;
  entityTypeId: number;
  entityTypeName: string;
  nodeSourceID: string;
}
export const Attribute: React.FC<IAttribute> = ({ name, onClick, entityTypeId, entityTypeName, nodeSourceID }) => {
  return (
      <div 
      onClick={() => onClick(entityTypeId, entityTypeName, nodeSourceID)}
      css={AttributesStyle}>
        <div css={AttributeBlueBar} />
        {name}
      </div>
  );
};
