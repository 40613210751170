import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { DashboardPage } from "../components/Pages/DashboardPage";

export const Dashboard: React.FC = () => {
  return (
      <LayoutHeader2>
        <DashboardPage />
      </LayoutHeader2>
  );
};
