import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  BoxAgentNotice,
  CancelButton,
  ContainerButtonEnd,
  LabelBlue,
  Modal,
  MyImage,
  Overlay,
  OverlayRelative,
  SaveButton,
  Span
} from "./styles";
import { Button, Dropdown } from "../../..";
import { Text } from "../../../Text";
import {
  setCloseRoomsModalOpen,
  setTransferRoomsModalOpen
} from "../../../../redux/slices/flowInteractions";
import { useState, useRef, useEffect } from "react";
import { DropdownChangeParams } from "primereact/dropdown";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { Check } from "../../../../assets";
import { iTransferDataRooms } from "../../../../services/dialogResource";
import { Checkbox } from "primereact/checkbox";
import OmnichannelService from "../../../../services/omnichannelService";
interface iModalCloseRooms {
  confirmar: (data: iTransferDataRooms) => void;
}
export const ModalTransferRooms: React.FC<iModalCloseRooms> = ({
  confirmar
}) => {
  const transferRoomsModalOpen = useAppSelector(
    (state) => state.flowInteractions.transferRoomsModalOpen
  );
  const dispatch = useAppDispatch();
  const { getUsersByWorkspace } = WorkspaceUserService();
  const { user } = useAuth() as AuthContextType;
  const toast = useRef<Toast>(null);

  const workspaces = user?.workspaces?.workspaces;

  const { transferOminichannelDialog } = OmnichannelService();
  const dialogIdActive = useAppSelector(
    (state) => state.flowInteractions.dialogActive
  );

  const [selectedWorkspace, setSelectedWorkspace] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  const [selectedTypeTransfer, setSelectedTypeTransfer] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  const { companyId } = user;

  const typeTransfer = [
    { id: 1, name: "Colaborador Específico" },
    { id: 2, name: "Sequencial" }
  ];
  const [sendToThirdpartySystem, setSendToThirdpartySystem] = useState(false);
  const [usersFromWorksPaceSelected, setUsersFromWorksPaceSelected] = useState(
    []
  );
  const handleSelectedWorkspace = async (e: DropdownChangeParams) => {
    setSelectedWorkspace(e.value);
    const retorno = await getUsersByWorkspace(companyId, e.value.id);
    setUsersFromWorksPaceSelected(retorno);
  };
  const handleSelectedTypeTransfer = (e: DropdownChangeParams) => {
    if (e.value.id === 2) {
      setSelectedUser({ id: -1, name: "" });
    }
    setSelectedTypeTransfer(e.value);
  };

  const handleSave = () => {
    const data: iTransferDataRooms = {
      typeTransfer: selectedTypeTransfer.id,
      workspaceId: selectedWorkspace.id,
      userId: selectedUser.id,
      sendToThirdpartySystem
    };
    confirmar(data);
    dispatch(setTransferRoomsModalOpen(false));
  };

  return (
    <Overlay style={{ display: transferRoomsModalOpen ? "block" : "none" }}>
      <OverlayRelative>
        <Modal
          style={{ display: transferRoomsModalOpen ? "block" : "none" }}
          open={transferRoomsModalOpen}
        >
          <div className="flex w-full align-items-center justify-content-end">
            <Button
              type="button"
              onClick={() => dispatch(setTransferRoomsModalOpen(false))}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
              aria-label="Cancel"
            />
          </div>
          <div className="flex align-items-center w-full justify-content-center">
            <Text
              style={{ textAlign: "left" }}
              s="0.9375rem"
              weight={600}
              lh="1.3125rem"
              color="#0A043C"
            >
              Transferir atendimento?
            </Text>
            <Span>?</Span>
          </div>
          <div className="flex flex-column overflow-y-scroll flex-grow-1">
            <div className="flex mt-2 w-full flex-column justify-content-center align-items-center">
              <Text s="0.875rem" weight={400} lh="1.3125rem" color="#4C5862">
                Informe como deseja distribuir as{" "}
              </Text>
              <Text s="0.875rem" weight={400} lh="1.3125rem" color="#4C5862">
                oportunidades desse atendimento.
              </Text>
            </div>
            <div className="mt-4" />

            <Dropdown
              style={{ width: "100%", marginTop: "1rem" }}
              value={selectedWorkspace}
              options={workspaces}
              onChange={(e) => handleSelectedWorkspace(e)}
              optionLabel="name"
              placeholder="Selecione um workspace"
              filter={true}
            />

            {
              // eslint-disable-next-line no-negated-condition
              selectedWorkspace.id > 0 ? (
                <Dropdown
                  style={{
                    width: "100%",
                    marginTop: "1rem",
                    lineHeight: "1.3125rem"
                  }}
                  value={selectedTypeTransfer}
                  options={typeTransfer}
                  onChange={(e) => handleSelectedTypeTransfer(e)}
                  optionLabel="name"
                  placeholder="Selecione o tipo de transferência"
                />
              ) : null
            }

            {selectedWorkspace.id > 0 && selectedTypeTransfer.id === 1 ? (
              <Dropdown
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  lineHeight: "1.3125rem"
                }}
                value={selectedUser}
                options={usersFromWorksPaceSelected}
                onChange={(e) => setSelectedUser(e.value)}
                optionLabel="name"
                placeholder="Selecione um colaborador"
                filter={true}
              />
            ) : null}
            <div className="flex gap-3 mt-3">
              <Checkbox
                id="id_atendente"
                checked={sendToThirdpartySystem}
                onChange={(e) => setSendToThirdpartySystem(e.checked)}
              />
              <LabelBlue htmlFor="id_atendente" className="p-checkbox-label">
                Transferir para plataforma Externa
              </LabelBlue>
            </div>
            {selectedWorkspace.id === -1 ||
            (selectedWorkspace.id !== -1 && selectedTypeTransfer.id === -1) ||
            (selectedWorkspace.id === 1 &&
              selectedWorkspace.id > 0 &&
              selectedTypeTransfer.id === 1 &&
              selectedUser.id === -1) ? (
              <BoxAgentNotice>
                <i className="pi pi-info-circle" />
                <Text
                  style={{ textAlign: "left" }}
                  s="0.875rem"
                  lh="1.125rem"
                  color="#000000"
                >
                  Cada agente vai receber a oportunidade de forma sequencial de
                  1 para 1.
                </Text>
              </BoxAgentNotice>
            ) : null}
          </div>

          <ContainerButtonEnd>
            <CancelButton
              onClick={() => dispatch(setTransferRoomsModalOpen(false))}
            >
              CANCELAR
            </CancelButton>
            <SaveButton
              disabled={
                selectedWorkspace.id === -1 ||
                (selectedWorkspace.id !== -1 &&
                  selectedTypeTransfer.id === -1) ||
                (selectedWorkspace.id === 1 &&
                  selectedWorkspace.id > 0 &&
                  selectedTypeTransfer.id === 1 &&
                  selectedUser.id === -1)
              }
              onClick={handleSave}
            >
              <MyImage
                disabled={
                  selectedWorkspace.id === -1 ||
                  (selectedWorkspace.id !== -1 &&
                    selectedTypeTransfer.id === -1) ||
                  (selectedWorkspace.id > 0 &&
                    selectedTypeTransfer.id === 1 &&
                    selectedUser.id === -1)
                }
                src={Check}
                alt="Check-Button"
              />
              Transferir
            </SaveButton>
          </ContainerButtonEnd>
        </Modal>
      </OverlayRelative>
    </Overlay>
  );
};
