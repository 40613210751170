import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FlowChanges {
  updatePhoneComponent: boolean;
  flowName: string;
  onSetShouldUpdate: string;
  shouldUpdate: boolean;
  updateProfiles: boolean;
}
const initialState: FlowChanges = {
  updatePhoneComponent: true,
  flowName: "Teste",
  onSetShouldUpdate: "",
  shouldUpdate: false,
  updateProfiles: false
};

const flowAttributesSlice = createSlice({
  name: "flowAttributes",
  initialState,
  reducers: {
    changedPhoneComponent(state, action: PayloadAction<boolean>) {
      state.updatePhoneComponent = action.payload;
    },
    setFlowName(state, action: PayloadAction<string>) {
      state.flowName = action.payload;
    },
    setOnSetShouldUpdate(state, action: PayloadAction<string>) {
      state.onSetShouldUpdate = action.payload;
      state.shouldUpdate = !state.shouldUpdate;
    },
    setUpdateProfiles(state, action: PayloadAction<boolean>) {
      state.updateProfiles = action.payload;
    }
  }
});

export const {
  changedPhoneComponent,
  setFlowName,
  setOnSetShouldUpdate,
  setUpdateProfiles
} = flowAttributesSlice.actions;

export default flowAttributesSlice.reducer;
