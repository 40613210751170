import { css } from "@emotion/react";

export const DivDialogExceptions = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  `;
export const DialogException = css`
  
  display: flex;
  height: 3.5625rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
    background-color: #2196f3;
  }
  
`;
export const Divisor = css`
  border: 1px solid #d2d2d2;
`;
export const VDivisor = css`
  border: 1px solid #d2d2d2;
  width: 3.75rem;
  transform: rotate(90deg);
  position: absolute;
  top: 50%;
  left: calc(50% -3.75rem);
  /* transform-origin: left top; */
`;
export const Text = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #4c5862;
`;
