import { css } from "@emotion/react";
import { Avatar } from "primereact/avatar";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { Sidebar } from "primereact/sidebar";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import { Button } from "../..";
import { v } from "../../../util";
import * as comunCss from "../../comum_css";
import ProfileService from "../../../services/ProfileService";
import UserServices from "../../../services/UserServices";
import WorkspaceService from "../../../services/WorkspaceService";
import { base64ToBlob } from "../../../util/base64ToBlob";
import { blobToBase64 } from "../../../util/blobToBase64";
import functions from "../../../util/functions";
import { EditUserContext } from "./user-list";
import { Checkbox } from "primereact/checkbox";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneMask from "../../phone-mask";
require("../../comum_css/rawCss.css");

interface userData {
  id: number | null;
  name: string;
  status: boolean | null;
  profile: any;
  companyId: number | null;
  workspaces: number | any;
  statusNew?: boolean | null;
  workSpaceNew?: boolean | null;
  profileData?: string | null;
  isAttendant?: boolean;
}
type typeId = {
  id: string;
  name: string;
};
type paramComp = {
  onShowError: (message: string) => void;
  showSuccess: (message: string) => void;
  toggleUserData: object;
  setToggleUserData: any;
};

const UserAvatar = ({
  label,
  avatarFile,
  onChange
}: {
  label: string;
  avatarFile?: File;
  onChange: (value?: File) => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!avatarFile) {
      setPreview(undefined);
      return;
    }

    let objectUrl = URL.createObjectURL(avatarFile);

    objectUrl = URL.createObjectURL(avatarFile);

    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [avatarFile]);

  return (
    <>
      <Avatar
        css={comunCss.avatarCss}
        label={label}
        className="mr-2"
        size="xlarge"
        shape="circle"
        onClick={() => inputFileRef.current?.click()}
        image={preview}
      />
      <input
        type="file"
        css={css`
          display: none;
        `}
        ref={inputFileRef}
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            onChange(undefined);
          } else {
            onChange(e.target?.files?.[0]);
          }
        }}
      />
    </>
  );
};

interface IUserDataValidation {
  name: string;
  email: string;
  phone: string;
  active: boolean;
  workspaces: number[];
  profiles: number[];
}
const UserEdit = ({
  showSuccess,
  onShowError,
  toggleUserData,
  setToggleUserData
}: paramComp) => {
  const { user } = useAuth() as AuthContextType;

  const userData: userData = {
    id: null,
    name: "",
    status: null,
    companyId: null,
    profile: "",
    workspaces: null,
    isAttendant: false
  };

  const statusUser = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false }
  ];

  const responsable = [{ label: `Responsável: ${user.name}`, value: 1 }];

  const [nameField, setNameField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [atendente, setAtendente] = useState(false);
  const [userResponsable, setUserResponsable] = useState(1);
  const [avatarFile, setAvatarFile] = useState<File>();

  const onChangeName = (name: string) => setNameField(name);
  const onChangeEmail = (email: string) => setEmailField(email);
  const onChangePhone = (phone: string) => {
    setPhoneField(phone);
    setPhoneValue(phone);
  };

  const [visible, setVisible] = useState(false);
  const [characterAvatar, setCharacterAvatar] = useState("");
  const [userDados, setuserDados] = useState<userData>(userData);

  const [workspaceProfile, setWorkspaceProfile] = useState<any>([]);
  const [multiSelectedProfiles, setMultiSelectedProfiles] = useState<any>([]);

  const [workspaceLevel, setWorkspaceLevel] = useState<any>([]);
  const [multSelectedLevel, setMultSelectedLevel] = useState<any>([]);

  const { isObjectEmpty } = functions();

  const deleteColaborator = () => {
    confirmDialog({
      message: "Tem certeza que deseja excluir esse Colaborador?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => accept(),
      reject: () => reject()
    });
  };
  const accept = () => {
    // toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'Usuario será deletado.', life: 3000 });
  };
  const reject = () => {
    // console.log("Colaborador nao será deletado...")
  };
  const userOptionStatus = (option: any) => {
    return (
      <div className="country-item flex">
        <i
          className="pi pi-circle-fill mr-2"
          style={
            option?.label === "Ativo" ? { color: "#6FCF97" } : { color: "#000" }
          }
        ></i>
        <div>{option.label}</div>
      </div>
    );
  };

  const { getUserById, updateUser } = UserServices();

  // receve id from Context and query user by id and bring
  // workspace andl all profile tipe and show then in multiselect input
  const refineUserData = (userDataRet: object | any) => {
    return userDataRet
      ?.filter((ud: any) => (ud?.active === true ? ud?.id : false))
      .map((ud: any) => ud?.id);
  };

  const getUser = async (id: number) => {
    const userDataRet = await getUserById(id);
    const getProfileIds = refineUserData(userDataRet?.profiles);

    const activeWorkspaces = userDataRet?.workspaces.filter(
      (workspace: { active: boolean }) => workspace.active
    );
    const activeWorkspacesId = activeWorkspaces.map(
      (workspace: { id: number }) => workspace.id
    );

    setMultSelectedLevel(activeWorkspacesId);
    setWorkspaceLevel(userDataRet.workspaces);

    setMultiSelectedProfiles(getProfileIds);
    setWorkspaceProfile(userDataRet.profiles);

    setAtendente(userDataRet.isAttendant);

    if (userDataRet.image) {
      const type = userDataRet.image.substring(
        4,
        userDataRet.image.indexOf("base64")
      );

      const blob = base64ToBlob(
        userDataRet.image.replace(/data.+?base64/u, ""),
        type
      );

      const file = new File([blob], type.replace("/", "."), {
        lastModified: new Date().getTime(),
        type
      });

      setAvatarFile(file);
    }
  };

  const formatObjectLabelValue = (value: []) => {
    const keysMap: typeId = { id: "value", name: "label" };
    return value.map((val, i) => {
      return renameKeys(keysMap, val);
    });
  };

  const renameKeys = (keysMap: any, obj: any) =>
    Object.keys(obj).reduce(
      (acc, key) => ({ ...acc, ...{ [keysMap[key] || key]: obj[key] } }),
      {}
    );

  const EditUser = useContext(EditUserContext);

  useEffect(() => {
    if (!isObjectEmpty(EditUser)) {
      userData.id = EditUser.id;
      userData.name = EditUser.name;
      userData.companyId = EditUser.companyId;
      userData.status = EditUser.active;
      userData.workspaces = EditUser.workspaces;
      getUser(EditUser.id);
      setNameField(EditUser.name);
      setEmailField(EditUser.email);
      setPhoneField(EditUser.phone);
      setuserDados(EditUser);
      getFirstNameLeter(EditUser.name);
      setuserDados(userData);
      setAtendente(EditUser.isAttendant);
      setVisible(true);
    }
  }, [toggleUserData]);

  const onStatusChange = (status: any) => {
    const statusNew: boolean = (userDados.status = status.value);
    setuserDados({ ...userDados, statusNew });
  };
  const onUserWorkspaceChange = (wokspc: any) => {
    setMultSelectedLevel(wokspc);
  };
  const onUserTypeChange = (profile: any) => {
    setMultiSelectedProfiles(profile);
  };
  const onUserResponsableChange = (e: any) => {
    setUserResponsable(e.value);
  };
  const onChangeRePassword = (rePassword: any) => {
    setRePassword(rePassword);
  };
  const onChangePassword = (pasword: any) => {
    setNewPassword(pasword);
  };
  const getFirstNameLeter = (name: string) => {
    setCharacterAvatar(name[0].toUpperCase());
  };

  const validatePassword = () => {
    if (newPassword || rePassword) {
      if (!newPassword || !rePassword) {
        return false;
      }
      return true;
    }
    return true;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IUserDataValidation>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      active: true,
      workspaces: [],
      profiles: []
    },
    values: {
      name: nameField,
      email: emailField,
      phone: phoneField,
      active: userDados.status as boolean,
      profiles: multiSelectedProfiles,
      workspaces: multSelectedLevel
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        email: v.string().superRefine(v.nonempty()),
        phone: v.string().refine((value) => {
          if (value.startsWith("55")) {
            return /^\d{13}$/.test(value);
          }
          return /^\d{9,}$/.test(value);
        }),
        active: v.boolean(),
        profiles: v.array(v.number()).min(1),
        workspaces: v.array(v.number()).min(1)
      })
    )
  });

  const sendUpdate = handleSubmit(async () => {
    const valid = validatePassword();

    if (valid) {
      const postData = {
        avatar: avatarFile ? await blobToBase64(avatarFile) : undefined,
        name: nameField,
        email: emailField,
        phone: phoneField,
        active: userDados.status,
        profiles: multiSelectedProfiles,
        password: rePassword,
        companyId: userDados.companyId,
        workspaces: multSelectedLevel,
        lastPassword: newPassword,
        attendant: atendente
      };

      try {
        const ret = await updateUser(userDados?.id, postData);
        showSuccess("Colaborador Atualizado com sucesso");

        setVisible(false);
      } catch (e) {}
    }
  });

  const onCloseSidebar = () => {
    setVisible(false);
    setToggleUserData({});
  };

  return (
    <Sidebar
      className="p-sidebar-sm"
      visible={visible}
      position="right"
      dismissable={true}
      onHide={onCloseSidebar}
    >
      <form onSubmit={sendUpdate}>
        <div>
          <UserAvatar
            label={characterAvatar}
            avatarFile={avatarFile}
            onChange={(file) => setAvatarFile(file)}
          />
          <div className="-mt-5">
            <div css={comunCss.boxProfileHead}>
              <div
                css={comunCss.TitleContent}
                className="flex align-items-center justify-content-center"
              >
                {userDados.name}
              </div>
              <span
                css={comunCss.idColor}
                className="flex align-items-center justify-content-center text-base"
              >
                ID {userDados.id}
              </span>
            </div>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <Dropdown
                  // {...field}
                  value={userDados.status}
                  options={statusUser}
                  onChange={onStatusChange}
                  optionLabel="label"
                  css={comunCss.noBorder}
                  className="flex"
                  itemTemplate={userOptionStatus}
                />
              )}
            />
            {errors.active?.message ? (
              <div style={{ color: "#AA3939" }}>{errors.active.message}</div>
            ) : null}
          </div>
          <hr className="hrCustomSideBarModal" css={comunCss.separatorBar} />
          <Controller
            name="workspaces"
            control={control}
            render={({ field }) => (
              <MultiSelect
                // {...field}
                optionLabel="workspace"
                optionValue="id"
                value={multSelectedLevel}
                options={workspaceLevel}
                onChange={(e) => onUserWorkspaceChange(e.value)}
                css={comunCss.noBorder}
                className="flex mt-2"
                filter={true}
              />
            )}
          />
          {errors.workspaces?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.workspaces.message}</div>
          ) : null}
          <Controller
            name="profiles"
            control={control}
            render={({ field }) => (
              <MultiSelect
                // {...field}
                optionLabel="profile"
                optionValue="id"
                value={multiSelectedProfiles}
                options={workspaceProfile}
                onChange={(e) => onUserTypeChange(e.value)}
                css={comunCss.noBorder}
                className="flex mt-2"
                filter={true}
              />
            )}
          />
          {errors.profiles?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.profiles.message}</div>
          ) : null}
          <Dropdown
            value={userResponsable}
            options={responsable}
            onChange={onUserResponsableChange}
            optionLabel="label"
            css={comunCss.noBorder}
            className="flex mt-2"
            disabled={true}
          />
          <div className="flex gap-3 mt-3">
            <Checkbox
              id="id_atendente"
              checked={atendente}
              onChange={(e) => setAtendente(e.checked)}
            />
            <label htmlFor="id_atendente" className="p-checkbox-label">
              Atendente
            </label>
          </div>
          <pre>{atendente}</pre>
          <hr className="hrCustomSideBarModal" css={comunCss.separatorBar} />
          <div css={comunCss.subTitleContent}>Informações</div>

          <span className="field mt-2 relative" style={{ top: "-10px" }}>
            <label
              css={comunCss.inputLabelDefault}
              htmlFor="userName"
              className="block"
            >
              Nome Completo
            </label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className="w-full"
                  css={comunCss.noBorder}
                  id="userName"
                  value={nameField}
                  onChange={(e) => onChangeName(e.target.value)}
                />
              )}
            />
            {errors.name?.message ? (
              <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
            ) : null}
          </span>
          <span className="field mt-2 relative" style={{ top: "-10px" }}>
            <label css={comunCss.inputLabelDefault} htmlFor="userEmail">
              E-mail
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className="w-full"
                  css={comunCss.noBorder}
                  id="userEmail"
                  value={emailField}
                  onChange={(e) => onChangeEmail(e.target.value)}
                />
              )}
            />
          </span>
          {errors.email?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.email.message}</div>
          ) : null}
          <span className="field mt-2 relative" style={{ top: "-10px" }}>
            <label css={comunCss.inputLabelDefault} htmlFor="userPhone">
              Telefone
            </label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneMask
                  {...field}
                  value={phoneField}
                  onChange={(e) => onChangePhone(e.target.value)}
                  style={{
                    width: "100%",
                    borderRadius: "0px",
                    border: "1px solid #ced4da",
                    padding: "8px 12px",
                    color: "#6e6b7b",
                    fontSize: "12px"
                  }}
                />
              )}
            />
          </span>
          {errors.phone?.message ? (
            <div style={{ color: "#AA3939" }}>Campo Obrigatório</div>
          ) : null}

          <div className="flex justify-content-between mt-2">
            {/* <div css={comunCss.subTitleContent}> Atualizar Senha </div> */}
            <Link to="/forgot">Esqueci minha senha</Link>
          </div>
          {/* <span className="field mt-2">
            <label css={comunCss.inputLabelDefault} htmlFor="SenhaAnterior">
              Senha Anterior
            </label>
            <Password
              inputClassName="w-full border-noround"
              className="flex"
              toggleMask
              id="SenhaAnterior"
              value={newPassword}
              onChange={(e) => onChangePassword(e.target.value)}
            />
          </span>
          <span className="field mt-2">
            <label css={comunCss.inputLabelDefault} htmlFor="NovaSenha">
              Nova Senha
            </label>
            <Password
              inputClassName="w-full border-noround"
              className="flex"
              toggleMask
              css={comunCss.noBorder}
              id="NovaSenha"
              value={rePassword}
              onChange={(e) => onChangeRePassword(e.target.value)}
            />
          </span> */}
          <Button
            title="Remover Colaborador"
            label="REMOVER COLABORADOR"
            onClick={deleteColaborator}
            className="p-button-outlined p-button-danger w-full mt-3 text-xs"
            disabled={true}
          />
          <Button
            title="Atualizar Colaborador"
            label="SALVAR"
            onClick={sendUpdate}
            icon="pi pi-check-circle"
            css={comunCss.saveBtn}
            className="saveBTN p-button-raised p-button-lg mt-3 p-button-text w-full text-blue-800"
            permission={["user_create", "user_edit"]}
          />
        </div>
      </form>
    </Sidebar>
  );
};

export default UserEdit;
