import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setInsertEntity,
  toggleGCredentialsDialog,
  toggleOpenEntityDialog,
  clearEntities
} from "../../../../redux/slices/flowInteractions";
import { useLocation } from "react-router-dom";
import { ButtonAdd } from "../../../../assets";
import { Bell } from "../Bell";
import { Identification } from "../Identification";
import { WorkspaceSelector } from "../workspaceSelector";
import { Button, Container, ContainerLeft } from "./styles";
import { useEffect, useState } from "react";
import { AdicionarBar } from "../../../../components/adicionar-bar";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { css } from "@emotion/react";

interface IHeader2 {
  extends?: number;
}
export const Header2: React.FC<IHeader2> = ({ extends: Ex }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const openGCredentialsDialog = useAppSelector(
    (state) => state.flowInteractions.dialogInsertGCredentials
  );
  const user = useAuth();
  const authContext = useAuth() as AuthContextType;
  const handleOnClick = () => {
    if (location.pathname === "/entities/") {
      dispatch(toggleOpenEntityDialog());
      dispatch(setInsertEntity(true));
      dispatch(setEntityIdUpdate(-1));
      dispatch(setCustomEntityUpdating(false));
    } else {
      setVisibleSidebar(true);
    }
  };
  const handleOnClickGerarChave = () => {
    dispatch(toggleGCredentialsDialog());
  };

  const [visibleSidebar, setVisibleSidebar] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearEntities());
    };
  }, []);

  return (
    <>
      <Container>
        <ContainerLeft extends={Ex}>
          <Identification />

          {/* <Bell news={true} /> */}

          <WorkspaceSelector />
        </ContainerLeft>

        <div
          css={css`
            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          {location.pathname === "/gcredentials" ? (
            <Button onClick={handleOnClickGerarChave}>
              <i className="pi pi-plus-circle" />
              Gerar Chave
            </Button>
          ) : (
            <Button onClick={handleOnClick}>
              <i className="pi pi-plus-circle" />
              Adicionar
            </Button>
          )}
        </div>
      </Container>

      <AdicionarBar
        visible={visibleSidebar}
        hide={() => setVisibleSidebar(false)}
      />
    </>
  );
};
