import { Payload } from "./../../services/botDialogTest";
import { iWorkspaces } from "../../types/iWorkspaces";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IUserWorkspaces {
  id: number;
  name: string;
}
interface Filter {
  value: any;
  matchMode: string;
}

interface Filters {
  botName?: Filter;
  firstMessage?: Filter;
  id?: Filter;
  lastMessage?: Filter;
  leadName?: Filter;
  objective?: Filter;
  phone?: Filter;
  plataform?: Filter;
  repetitions?: Filter;
  status?: Filter;
  statusTime?: Filter;
  tag?: Filter;
  transferredTo?: Filter;
  workspace?: Filter;
}

interface FlowChanges {
  userWorkspaces: iWorkspaces[];
  selectedWorkspaces: number[];
  usersFromWorkspaces: IUserWorkspaces[];
  mode: "edit" | "add";
  updateWorkspaces: boolean;
  filterValue: number[];
  loadOmnichannel: boolean;
  filters: Filters;
  valueLoad: string;
  updateKeys: boolean;
}

const initialState: FlowChanges = {
  userWorkspaces: [],
  selectedWorkspaces: [],
  usersFromWorkspaces: [],
  mode: "add",
  updateWorkspaces: false,
  filterValue: [],
  loadOmnichannel: false,
  filters: {},
  valueLoad: "firstLoad",
  updateKeys: false
};

const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setModeWorkspaces(state, action: PayloadAction<"edit" | "add">) {
      state.mode = action.payload;
    },
    setRWorkspaces(state, action: PayloadAction<iWorkspaces[]>) {
      state.userWorkspaces = action.payload;
    },
    setSelectedWorkspaces(state, action: PayloadAction<number[]>) {
      state.selectedWorkspaces = action.payload;
    },
    setUsersFromWorkspaces(state, action: PayloadAction<IUserWorkspaces[]>) {
      state.usersFromWorkspaces = action.payload;
    },
    setUpdateWorkspaces(state, action: PayloadAction<boolean>) {
      state.updateWorkspaces = action.payload;
    },
    setFilterValue(state, action: PayloadAction<number[]>) {
      state.filterValue = action.payload;
    },
    setLoadOmnichannel(state, action: PayloadAction<boolean>) {
      state.loadOmnichannel = action.payload;
    },
    setFilters(state, action: PayloadAction<Filters>) {
      state.filters = action.payload;
    },
    setValueLoad(state, action: PayloadAction<string>) {
      state.valueLoad = action.payload;
    },
    setUpdateKeys(state, action: PayloadAction<boolean>) {
      state.updateKeys = action.payload;
    }
  }
});

export const {
  setRWorkspaces,
  setSelectedWorkspaces,
  setUsersFromWorkspaces,
  setModeWorkspaces,
  setUpdateWorkspaces,
  setFilterValue,
  setLoadOmnichannel,
  setFilters,
  setValueLoad,
  setUpdateKeys
} = workspacesSlice.actions;

export default workspacesSlice.reducer;
