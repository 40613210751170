/* eslint-disable func-style */
import { useFetch2 } from "../util";

function WorkspaceUserService() {
  const useQuery = useFetch2();

  // this code just return a promise function to who is calling a function.

  const getUserWorkspaces = async () => {
    try {
      return await useQuery(`/api/workspaces/list-user-workspace`, {
        method: "GET"
      });
    } catch (error) {
      return error;
    }
  };
  const getUserWorkspaces2 = async (companyId?: number) => {
    try {
      const param = new URLSearchParams();

      if (companyId) {
        param.append("companyId", String(companyId));
      }

      return await useQuery(`/api/workspaces/list-user-workspace2?${param}`, {
        method: "GET"
      });
    } catch (error) {
      return error;
    }
  };
  const getUsersByWorkspace = async (
    companyId: number,
    workspaceId: number
  ) => {
    const param = new URLSearchParams();

    if (companyId) {
      param.append("companyId", String(companyId));
    }
    if (workspaceId) {
      param.append("workspaceId", String(workspaceId));
    }
    try {
      return await useQuery(`/api/users/list-by-workspace?${param}`, {
        method: "GET"
      });
    } catch (error) {
      return error;
    }
  };

  return {
    getUserWorkspaces,
    getUserWorkspaces2,
    getUsersByWorkspace
  };
}

export default WorkspaceUserService;
