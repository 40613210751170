import { css } from "@emotion/react";
import { iDialogMessagesFile } from "../use-omnichannel";
import TopBarChatInfos from "./top-bar-chat-infos";
import ChatInfosBody from "./chat-infos-body";

export interface ChatContactInfosProps {
  dialog: iDialogMessagesFile;
}

const ChatContactInfos = ({ dialog }: ChatContactInfosProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 20rem;
        background: #ffffff;
        border-left: 1px solid #e5e5e5;

        @media screen and (max-width: 599px) {
          width: 100%;
        }
        @media screen and (max-width: 400px) {
          width: 15rem;
        }
      `}
    >
      <TopBarChatInfos dialog={dialog} />

      {/* <ChatDates createdAt="" updatedAt="" /> */}

      <ChatInfosBody dialog={dialog} />
    </div>
  );
};

export default ChatContactInfos;
