// @refresh reset
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ButtonDelete, SixPoints } from "../../../../../assets";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setCustomButtonModalOpen,
  setModalOpenRobotActions,
  setOverlayInteractionsShow,
  setSendFileModalOpen,
  setSendsendFileSelectorSOOpenOpen,
  setbotInteractionIDActive,
  toggleModalVariablesBotInteractions,
  textEmoji,
  setEmojiModalOpen
} from "../../../../../redux/slices/flowInteractions";
import {
  addRobotInteraction,
  deleteRobotInteraction,
  selectNode,
  updateRobotInteraction
} from "../../../../../redux/slices/nodes";

import { IRobotInteraction } from "../../../../react-flow-renderer/NodeData";
import {
  createEditor,
  Editor,
  Transforms,
  Text as TextSlate,
  BaseEditor,
  Descendant
} from "slate";
import {
  Slate,
  Editable,
  withReact,
  useSelected,
  useFocused,
  ReactEditor
} from "slate-react";
import {
  Baloon,
  ButtonPlus,
  ButtonTrash,
  ImageSixPoints,
  MyCustomButton
} from "./styles";
import { HistoryEditor, withHistory } from "slate-history";
import {
  CustomElement,
  CustomText,
  EmptyText,
  MentionElement,
  EmojiElement
} from "../custom-types";
import { VariablesModal } from "./Variables";
import { changedPhoneComponent } from "../../../../../redux/slices/flowupdates";
import { FilesAdded } from "../senfFiles/Files";
import { AttributesContainer } from "../senfFiles/Files/styles";
import { FilesRejected } from "../senfFiles/RejectedFiles";
import { useLocation, useParams } from "react-router-dom";
import { EmojiModal } from "../EmojiModal";
declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText | EmptyText;
  }
}
interface IRobotTexts {
  nodeId: string;
  robotInteraction: IRobotInteraction;
}
export const RobotTexts: React.FC<IRobotTexts> = ({
  nodeId,
  robotInteraction
}) => {
  const modalOpenRobotActions = useAppSelector(
    (state) => state.flowInteractions.modalOpenRobotActions
  );
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { templateId } = useParams();

  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const [modalOpen, setModalOpen] = useState(false);
  const nodes = useAppSelector((state) => state.nodes);
  const advisor = useAppSelector((state) => state.flowInteractions.advisor);
  const emoji = useAppSelector(
    (state) => state.flowInteractions.valueTextEmoji
  );
  const [textValue, setTextValue] = useState(robotInteraction.text);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const toggleModal = useAppSelector(
    (state) => state.flowInteractions.modalVariablesBotInteractions
  );
  const qtdeBotInteractions = nodeSelected?.data?.botInteractions?.length;
  const botInteractionIdActiveRedux = useAppSelector(
    (state) => state.flowInteractions.botInteractionIDActive
  );
  const initialValue: Descendant[] = useMemo(() => {
    try {
      return JSON.parse(robotInteraction.text);
    } catch (error) {
      return [
        {
          type: "paragraph",
          children: [{ text: robotInteraction?.text }]
        }
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);
  const withMentions = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
    const { isInline, isVoid, markableVoid } = editor;

    editor.isInline = (element: CustomElement) => {
      return element.type === "mention" ? true : isInline(element);
    };

    editor.isVoid = (element: CustomElement) => {
      return element.type === "mention" ? true : isVoid(element);
    };

    editor.markableVoid = (element: CustomElement) => {
      return element.type === "mention" || markableVoid(element);
    };

    return editor;
  };
  const editorRef = useRef<BaseEditor & ReactEditor & HistoryEditor>();
  // eslint-disable-next-line max-statements-per-line
  if (!editorRef.current) {
    editorRef.current = withMentions(withReact(withHistory(createEditor())));
  }
  const editor = editorRef.current;

  useEffect(() => {
    Transforms.deselect(editor);
    editor.children = initialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    handleInsertEmojis(emoji, editor);
    dispatch(textEmoji(""));
  }, [emoji]);

  const handleInput = (e: string) => {
    const objectUpdate = {
      ...robotInteraction,
      nodeId: nodeSelected?.id as string,
      id: robotInteraction.id,
      text: e
    };
    dispatch(updateRobotInteraction(objectUpdate));
    dispatch(changedPhoneComponent(true));
  };

  const renderElement = useCallback(
    (props: { element: { type: any } }, nodes?: any) => {
      switch (props.element.type) {
        case "code":
          return <CodeElement {...props} />;
        case "mention":
          return <Mention {...props} />;
        default:
          return <DefaultElement {...props} />;
      }
    },
    []
  );
  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "&") {
      e.preventDefault();
      editor.insertText("and");
    }
    if (!e.ctrlKey) {
      /* empty */
    } else {
      switch (e.key) {
        // When "`" is pressed, keep our existing code block logic.
        case "`": {
          e.preventDefault();
          CustomEditor.toggleCodeBlock(editor);
          break;
        }

        // When "B" is pressed, bold the text in the selection.
        case "n": {
          e.preventDefault();
          CustomEditor.toggleBoldMark(editor);
          break;
        }
        case "Enter": {
          e.preventDefault();
          dispatch(addRobotInteraction({ nodeId }));
          dispatch(changedPhoneComponent(true));
        }
      }
    }
  };
  const handleInsertMentions = (
    variable: string,
    _editor: BaseEditor & ReactEditor & HistoryEditor,
    nodeSourceEntityId: string
  ) => {
    insertMention(_editor, variable, nodeSourceEntityId);
    setModalOpen(false);
  };
  const handleInsertEmojis = (
    emoji: string,
    _editor: BaseEditor & ReactEditor & HistoryEditor
  ) => {
    insertEmoji(_editor, emoji);
    dispatch(setEmojiModalOpen(false));
  };
  const handleAdd = () => {
    dispatch(addRobotInteraction({ nodeId }));
    dispatch(changedPhoneComponent(true));
    // dispatch(setVariablesModalOpen(true))
  };
  const update = () => {
    dispatch(selectNode(nodeId));
  };
  const handleDelete = () => {
    const objectAdd = {
      nodeId,
      id: robotInteraction.id,
      text: robotInteraction.text
    };
    dispatch(deleteRobotInteraction(objectAdd));
    dispatch(changedPhoneComponent(true));
    setTimeout(() => update(), 100);
  };
  const handleSetVariablesModalOpen = () => {
    if (botInteractionIdActiveRedux === robotInteraction.id) {
      setModalOpen(true);
      dispatch(setModalOpenRobotActions(false));
    }
  };

  const heightAttributesContainer = (): string => {
    if (
      robotInteraction.buttons?.length &&
      Boolean(robotInteraction.files?.length)
    ) {
      const qtFiles = robotInteraction.files?.length as number;
      const sizeGrow = qtFiles * 5;

      return `${sizeGrow}rem`;
    }
    if (
      !robotInteraction.buttons?.length &&
      Boolean(robotInteraction.files?.length)
    ) {
      const qtFiles = robotInteraction.files?.length as number;
      const sizeGrow = qtFiles * 5;

      return `${sizeGrow}rem`;
    }
    if (robotInteraction.buttons?.length) {
      return "6.5rem";
    }
    return "1rem";
  };
  const heightExpanded = (): string => {
    if (
      Boolean(robotInteraction.files?.length) ||
      Boolean(robotInteraction.rejectedFiles?.length)
    ) {
      const qtFilesAccepted = robotInteraction.files?.length || (0 as number);
      const qtFilesRejected =
        robotInteraction?.rejectedFiles?.length || (0 as number);
      const sizeGrowAccepted = qtFilesAccepted * 5;
      const sizeGrowRejected = qtFilesRejected * 6;
      const sizeGrowButtons = robotInteraction.buttons?.length ? 5 : 2;
      const sizeGrow = sizeGrowAccepted + sizeGrowRejected + sizeGrowButtons;
      return `${sizeGrow}rem`;
    }
    if (robotInteraction.buttons?.length) {
      return "6.5rem";
    }
    return "1rem";
  };
  // const checkPositionButtonsButton = (): string => {
  //   if (robotInteraction.buttons?.length && Boolean(nodeSelected?.data.files?.length)) {
  //     return "12rem"
  //   }
  //   if(robotInteraction.buttons?.length) {
  //     return "6.5rem"
  //   }
  //   return "1rem"
  // }
  const checkInitPositionButtonsFiles = (): string => {
    if (
      robotInteraction.buttons?.length &&
      Boolean(robotInteraction.files?.length) &&
      Boolean(robotInteraction.rejectedFiles?.length)
    ) {
      const sizeGrow = 11;

      return `${sizeGrow}rem`;
    }
    if (
      robotInteraction.buttons?.length &&
      Boolean(robotInteraction.files?.length)
    ) {
      const sizeGrow = 5;

      return `${sizeGrow}rem`;
    }
    if (
      robotInteraction.buttons?.length &&
      Boolean(robotInteraction.rejectedFiles?.length)
    ) {
      const sizeGrow = 5;

      return `${sizeGrow}rem`;
    }

    if (robotInteraction.buttons?.length) {
      return "6.5rem";
    }
    return "1rem";
  };

  const displayAtributesContainer = (): boolean => {
    return (
      Boolean(robotInteraction?.files?.length) ||
      Boolean(robotInteraction.rejectedFiles?.length)
    );
  };
  useEffect(() => {
    if (advisor) {
      handleSetVariablesModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisor]);
  const handleOpenModalButtons = () => {
    dispatch(setbotInteractionIDActive(robotInteraction.id));
    dispatch(setModalOpenRobotActions(false));
    dispatch(setOverlayInteractionsShow(true));
    dispatch(setCustomButtonModalOpen(true));
  };
  const handleOnDragOver = () => {
    dispatch(setbotInteractionIDActive(robotInteraction.id));
    dispatch(setSendsendFileSelectorSOOpenOpen(false));
    dispatch(setSendFileModalOpen(true));
  };
  const handleOnDragClose = () => {
    dispatch(setbotInteractionIDActive(robotInteraction.id));
    dispatch(setSendsendFileSelectorSOOpenOpen(false));
    dispatch(setSendFileModalOpen(false));
  };
  // const handleOnDragLeave = () => {
  //   dispatch(setbotInteractionIDActive(robotInteraction.id));
  //   dispatch(setSendsendFileSelectorSOOpenOpen(false));
  //   dispatch(setSendFileModalOpen(false));
  // };

  return (
    <>
      <Baloon
        expanded={heightExpanded()}
        // onDragOver={handleOnDragOver}
        // onDragLeave={handleOnDragLeave}
        onMouseLeave={handleOnDragClose}
      >
        <img
          css={ImageSixPoints}
          src={SixPoints}
          alt="icon-six-points"
          style={{ cursor: "pointer" }}
        />
        <Slate
          editor={editor}
          value={initialValue}
          onChange={(value) => {
            const isAstChange = editor.operations.some(
              (op: { type: string }) => op.type !== "set_selection"
            );
            if (isAstChange) {
              const content = JSON.stringify(value);
              handleInput(content);
            }
          }}
        >
          <Editable
            readOnly={
              nodeSelected
                ? nodeSelected?.type === "startNode" || isTemplate
                : false
            }
            style={{ width: "100%" }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(e) => handleKeyPress(e)}
            placeholder="Insira o texto de interação do robô aqui..."
          />
          {/* <button onClick={buttonTeste}>teste</button> */}
        </Slate>
        {nodeSelected &&
        (nodeSelected?.type === "startNode" || isTemplate) ? null : (
          <>
            <button
              type="button"
              onClick={() => {
                dispatch(setbotInteractionIDActive(robotInteraction.id));
                dispatch(setModalOpenRobotActions(true));
              }}
              css={ButtonPlus}
            >
              +
            </button>

            {qtdeBotInteractions &&
            qtdeBotInteractions > 1 &&
            isTemplate === false ? (
              <button type="button" onClick={handleDelete} css={ButtonTrash}>
                <img src={ButtonDelete} alt="icon-button-delete" />
              </button>
            ) : null}
          </>
        )}
        {robotInteraction.buttons?.map((button) =>
          button.type === "LINK" ? (
            <MyCustomButton
              bottom="1rem"
              onClick={handleOpenModalButtons}
              key={button.position}
            >
              {button.label}
            </MyCustomButton>
          ) : null
        )}
        {Boolean(robotInteraction.buttons?.length) &&
        Boolean(robotInteraction?.buttons[0]?.type === "QUICK_REPLY") ? (
          <MyCustomButton bottom="1rem" onClick={handleOpenModalButtons}>
            Botões
          </MyCustomButton>
        ) : null}
        {/* {robotInteraction?.files?.length ?  (
            <MyCustomButton bottom={checkPositionButtonsFiles()} onClick={() => dispatch(setSendFileModalOpen(true))}>Arquivos</MyCustomButton> 
            
        ) : null} */}
        <AttributesContainer
          style={{ display: displayAtributesContainer() ? "block" : "none" }}
          bottom={checkInitPositionButtonsFiles()}
          height={heightAttributesContainer()}
        >
          {robotInteraction?.files?.length
            ? // <MyCustomButton bottom={checkPositionButtonsFiles()} onClick={() => dispatch(setSendFileModalOpen(true))}>Arquivos</MyCustomButton>
              robotInteraction?.files.map((el, idx) => (
                <FilesAdded
                  key={idx}
                  name={el.name}
                  id={robotInteraction.id}
                  fileId={el.fileId}
                  url={el.url}
                  mime={el.mime}
                />
              ))
            : null}
          {robotInteraction?.rejectedFiles?.length
            ? // <MyCustomButton bottom={checkPositionButtonsFiles()} onClick={() => dispatch(setSendFileModalOpen(true))}>Arquivos</MyCustomButton>
              robotInteraction.rejectedFiles.map((el, idx) => (
                <FilesRejected
                  key={idx}
                  name={el.name}
                  reason={el.reason}
                  id={el.id}
                  botId={robotInteraction.id}
                />
              ))
            : null}
        </AttributesContainer>
      </Baloon>
      <VariablesModal
        key={robotInteraction.id}
        editor={editor}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        handleInsertMentions={handleInsertMentions}
      />
    </>
  );
};
const Mention = ({ attributes, children, element }: any) => {
  const selected = useSelected();
  const focused = useFocused();
  const nodes = useAppSelector((state) => state.nodes);
  const style: React.CSSProperties = {
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "#eee",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none"
  };
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = "bold";
  }
  if (element.children[0].italic) {
    style.fontStyle = "italic";
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.variable.replace(" ", "-")}`}
      style={style}
    >
      {children}
      {`${element.variable}-${
        nodes.find(
          (_node: { id: string }) => _node.id === element.children[0].text
        )?.data.label
      }`}
    </span>
  );
};
const CodeElement = (props: any) => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  );
};
const DefaultElement = (props: any) => {
  return <p {...props.attributes}>{props.children}</p>;
};
const Leaf = (props: any) => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf?.bold ? "bold" : "normal" }}
    >
      {props.children}
    </span>
  );
};
const CustomEditor = {
  isBoldMarkActive(editor: BaseEditor & ReactEditor & HistoryEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n?.bold === true,
      universal: true
    });
    return Boolean(match);
  },

  isCodeBlockActive(editor: BaseEditor & ReactEditor & HistoryEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n?.type === "code"
    });

    return Boolean(match);
  },

  toggleBoldMark(editor: BaseEditor & ReactEditor & HistoryEditor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => TextSlate.isText(n), split: true }
    );
  },

  toggleCodeBlock(editor: BaseEditor & ReactEditor & HistoryEditor) {
    const isActive = CustomEditor.isCodeBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : "code" },
      { match: (n) => Editor.isBlock(editor, n) }
    );
  }
};

const insertMention = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  variable: string,
  nodeSourceEntityId: string
) => {
  const mention: MentionElement = {
    type: "mention",
    variable,
    children: [{ text: nodeSourceEntityId }]
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const insertEmoji = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  emoji: string
) => {
  Transforms.insertText(editor, emoji);
  Transforms.move(editor);
};
