import { useAuth } from "../auth/auth-provider";
import { Button, ButtonProps } from "primereact/button";

type ExtendedButtonProps = ButtonProps & { permission?: string | string[] };

export default (props: ExtendedButtonProps) => {
  const { user, hasAnyPermission } = useAuth();

  if (!user?.admin && props.permission && !hasAnyPermission(props.permission)) {
    return null;
  }

  return <Button {...props} />;
};
