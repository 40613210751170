import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { v } from "../../../util";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import ProfileService from "../../../services/ProfileService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

type valueTbNewInvite = {
  email: string;
  cargo: number;
};

type thisPropsComponent = {
  value: valueTbNewInvite[];
  onChange: () => void;
  // (value: valueTbNewInvite[]) => void
};

const WorkspaceInviteTabNew = ({ value, onChange }: thisPropsComponent) => {
  const schema = v.object({
    email: v.string().superRefine(v.nonempty()),
    cargo: v.number()
  });
  const defaultValues = {
    email: "",
    cargo: undefined
  };
  const initConfigUseform = {
    defaultValues,
    resolver: zodResolver(schema)
  };
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<valueTbNewInvite>(initConfigUseform);

  const [profiles, setProfiles] = useState([]);
  const [inviteData, setInviteData] = useState(false);
  const { company, user } = useAuth() as AuthContextType;
  const { getProfile } = ProfileService();

  useEffect(() => {
    (async () => {
      const profiles = await getProfile(company?.id ?? user.companyId);
      setProfiles(profiles);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setInviteData(false);
    }, 6000);
  }, [inviteData]);

  const executForm = handleSubmit(async (data) => {
    onChange([...value, data]);
    setInviteData(true);
  });

  return (
    <>
      <div>
        <div
          className="flex align-items-center justify-content-center"
          style={{ color: "#544F77" }}
        >
          Convide quantas pessoas desejar
        </div>

        <form className="mt-4" onSubmit={executForm}>
          <div className="flex flex-column gap-4">
            <div>
              <div className="">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      className="w-full"
                      {...field}
                      id="email"
                      placeholder="Adicione o e-mail"
                    />
                  )}
                />
              </div>
              {errors.email?.message ? (
                <div className="mt-1">{errors.email?.message}</div>
              ) : null}
            </div>

            <div className="grid">
              <div className="col-8 w-full">
                <div
                  className="inviteContainer flex justify-content-between align-items-center"
                  style={{
                    border: "solid 1px #ced4da",
                    borderRadius: "8px"
                  }}
                >
                  <Controller
                    name="cargo"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="cargo"
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={profiles}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Cargo"
                        className="selectPlusButton"
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    label="Convidar"
                    style={{
                      color: "#046af3",
                      borderColor: "#ffffff",
                      backgroundColor: "#d4e2f3",
                      height: "30px",
                      marginRight: "10px"
                    }}
                  />
                </div>

                {errors.cargo?.message ? (
                  <div className="mt-1">{errors.cargo?.message}</div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="flex col-4, align-items-center justify-content-end">
        {inviteData && (
          <Message
            severity="success"
            text="convite adicionado com sucesso"
            style={{ width: "100%", height: "13%" }}
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceInviteTabNew;
