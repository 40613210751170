import { css } from "@emotion/react";
import { confirmDialog } from "primereact/confirmdialog";
import {
  IconChannelBot,
  whatsapp,
  facebookDataTable,
  InstagramIcon
} from "../../assets";
import { Button, useToast } from "../../components";
import useOmnichannel, { iDialogMessagesFile } from "../use-omnichannel";
import Avatar from "../avatar";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/hooks";
import {
  setDialogActive,
  toggleTransferModal
} from "../../redux/slices/flowInteractions";
import { setSidebarChats } from "../../redux/slices/sidebar";
import { useEffect, useState } from "react";

export interface TopbarProps {
  dialog: iDialogMessagesFile;
  onFinalize: () => void;
}

const Topbar = ({ dialog, onFinalize }: TopbarProps) => {
  const { finalize } = useOmnichannel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toastRef = useToast();
  const [screenMobile, setScreenMobile] = useState(false);

  const options: { [key: string]: string } = {
    LIVECHAT: IconChannelBot,
    WHATSAPP: whatsapp,
    FACEBOOK: facebookDataTable,
    INSTAGRAM: InstagramIcon
  };
  const handleOpenWhatsapp = () => {
    window.open(`https://wa.me/${dialog.phone}`);
  };
  useEffect(() => {
    dispatch(setDialogActive(dialog.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog.id]);
  const platformImage: string | undefined = options[dialog.platform];
  const handleOpennModal = () => {
    if (dialog.transferredToThirdpartySystem) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Este chamado já foi transferido para um sistema de terceiros"
      });
    } else {
      dispatch(toggleTransferModal());
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          ${screenMobile && `padding: 0.5rem`}
        `}
      >
        {screenMobile && (
          <Button
            label="<"
            className="p-button-info"
            onClick={() => dispatch(setSidebarChats(true))}
          />
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid #e5e5e5;
          width: 100%;

          @media screen and (max-width: 599px) {
            gap: 1rem;
            flex-direction: column;
            padding: 12px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <Avatar name={dialog.name} />

            <div
              className="bot-name-tooltip"
              data-pr-position="bottom"
              css={css`
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 0;
                right: 5px;
                gap: 10px;
              `}
            >
              <img src={platformImage} height={15} width={15} />

              <Tooltip target=".bot-name-tooltip"> {dialog.platform} </Tooltip>
            </div>
          </div>

          <div>
            <div>{dialog.name}</div>

            <div
              css={css`
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #90a0b7;
              `}
            >
              {dialog.botName} | Status: {dialog.status} | Sala: #{dialog.id}
            </div>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;

            @media screen and (max-width: 599px) {
              gap: 1rem;
            }
          `}
        >
          <Button
            className="p-button-success"
            icon="pi pi-whatsapp"
            tooltip="Falar via Whatsapp"
            tooltipOptions={{ position: "bottom" }}
            onClick={handleOpenWhatsapp}
            css={css`
              margin-right: 7px;
              border: none !important;
              height: 30px !important;
              width: 30px !important;
              padding: 0 !important;
              border-radius: 8px !important;
              background: #2ed47a !important;
            `}
          />

          <Button
            className="p-button-secondary"
            icon="pi pi-arrows-h"
            tooltip="Transferir"
            tooltipOptions={{ position: "bottom" }}
            onClick={handleOpennModal}
            css={css`
              margin-right: 7px;
              border: none !important;
              height: 30px !important;
              width: 30px !important;
              padding: 0 !important;
              border-radius: 8px !important;
              background: #d4e2f3 !important;
              color: #046af3 !important;
            `}
          />

          <Button
            icon="pi pi-check"
            onClick={async () => {
              confirmDialog({
                message: "Você deseja finalizar a conversa?",
                header: "Atenção",
                icon: "pi pi-exclamation-triangle",
                accept: async () => {
                  try {
                    await finalize(dialog.id);

                    toastRef.current?.show({
                      severity: "success",
                      detail: "Conversa finalizada com sucesso."
                    });

                    navigate({ pathname: "/rooms/" });

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } catch (e: any) {
                    toastRef.current?.show({
                      severity: "error",
                      detail: e.description
                    });
                  }

                  onFinalize();
                },
                acceptLabel: "Sim",
                rejectLabel: "Não"
              });
            }}
            tooltip="Finalizar"
            tooltipOptions={{ position: "left" }}
            css={css`
              border: none !important;
              height: 30px !important;
              width: 30px !important;
              padding: 0 !important;
              border-radius: 8px !important;
            `}
          />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
