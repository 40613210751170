// interface IOptionsRobotActions {

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  AttributeBar,
  AttributesStyle,
  DeleteButtonIcon,
  IconImage,
  FileName,
  AudioContainer,
  RoundedButton,
  WaveContainer,
  DeleteButtonIconAudio
} from "./styles";
import {
  ButtonDelete,
  PhotoVideo,
  Pause,
  PlayBlue
} from "../../../../../../assets";
import { excludeFiles } from "../../../../../../redux/slices/nodes";
import publishFlowServices from "../../../../../../services/onSave";
import {
  deleteFileIds,
  clearRecordedAudio
} from "../../../../../../redux/slices/flowInteractions";
import { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { ClockAudio } from "../../AudiosModal/ClockAudio";

interface iFilesAdded {
  name: string;
  id: string;
  fileId: number;
  url: string;
  mime: string;
}
export const FilesAdded: React.FC<iFilesAdded> = ({
  name,
  id,
  fileId,
  url,
  mime
}) => {
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const nodeId = nodeSelected?.id as string;
  const dispatch = useAppDispatch();
  const { deleteFile } = publishFlowServices();
  const newFilesIds = useAppSelector(
    (state) => state.flowInteractions.newFilesIds
  );
  const [isAudio, setIsAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const waveSurferRef = useRef<WaveSurfer>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [audio] = useState(new Audio(url));
  const recentAudio = useAppSelector(
    (state) => state.flowInteractions.recordedAudio
  );
  const attachedAudio = useAppSelector(
    (state) => state.flowInteractions.attachedAudio
  );

  const handleDelete = async () => {
    if (newFilesIds.includes(fileId)) {
      try {
        dispatch(excludeFiles({ nodeId, id, fileId }));
        await deleteFile(fileId);
        dispatch(deleteFileIds(fileId));
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        dispatch(excludeFiles({ nodeId, id, fileId }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (waveformRef.current) {
        clearInterval(interval);
        waveSurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          barHeight: 5,
          waveColor: "gray",
          progressColor: "green"
        });

        if (!url && !recentAudio && !attachedAudio) {
          return;
        }

        if (url) {
          waveSurferRef.current.load(url);
        } else if (recentAudio) {
          waveSurferRef.current.load(recentAudio);
        } else if (attachedAudio) {
          waveSurferRef.current.load(attachedAudio);
        }

        let setIntervall: string | number | NodeJS.Timeout | undefined;

        waveSurferRef?.current?.on("ready", () => {
          setDuration(waveSurferRef?.current?.getDuration() as number);
          setCurrentTime(0);
        });

        waveSurferRef.current.on("pause", () => {
          setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
          setIsPlaying(false);
          clearInterval(setIntervall);
        });

        waveSurferRef?.current?.on("play", () => {
          setIsPlaying(true);
          setIntervall = setInterval(() => {
            setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
          }, 1000);
          setDuration(waveSurferRef?.current?.getDuration() as number);
        });

        waveSurferRef?.current?.on("finish", () => {
          setIsPlaying(false);
          waveSurferRef?.current?.setCurrentTime(0);
          setCurrentTime(0);
          setDuration(waveSurferRef?.current?.getDuration() as number);
          clearInterval(setIntervall);
        });
      }
      return () => waveSurferRef.current?.destroy();
    }, 100);

    dispatch(clearRecordedAudio());
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const valueMime = mime?.startsWith("audio");
    const recentAudio = name.split(".").pop()?.toLowerCase();
    if (valueMime) {
      setIsAudio(true);
    } else if (
      recentAudio === "mp3" ||
      recentAudio === "opus" ||
      recentAudio === "ogg" ||
      recentAudio === "amr"
    ) {
      setIsAudio(true);
    }
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      waveSurferRef?.current?.pause();
      setIsPlaying(false);
    } else {
      waveSurferRef?.current?.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };
    audio.addEventListener("ended", handleAudioEnd);

    return () => {
      audio.removeEventListener("ended", handleAudioEnd);
    };
  }, [audio]);

  return (
    <div>
      {isAudio ? (
        <AudioContainer key={fileId} id={`audioContainer-${fileId}`}>
          <RoundedButton onClick={togglePlay}>
            <img src={isPlaying ? Pause : PlayBlue} alt="Play or Pauseaudios" />
          </RoundedButton>
          <ClockAudio
            isPlaying={isPlaying}
            timeTotal={duration}
            timeCurrent={currentTime}
          />
          <WaveContainer>
            <div id="waveRecorder" ref={waveformRef}></div>
          </WaveContainer>
          <DeleteButtonIconAudio onClick={handleDelete}>
            <img src={ButtonDelete} width="10px" alt="Delete-Node" />
          </DeleteButtonIconAudio>
        </AudioContainer>
      ) : (
        <AttributesStyle key={fileId} id={`attributesStyle-${fileId}`}>
          <AttributeBar />
          <IconImage>
            <img src={PhotoVideo} alt="Img Photo Video" />
          </IconImage>
          <div className="flex w-full align-items-center ml-2 justify-content-between">
            <div className="flex align-items-center justify-content-start">
              <FileName>{name}</FileName>
            </div>
            <DeleteButtonIcon onClick={handleDelete}>
              <img src={ButtonDelete} width="10px" alt="Delete-Node" />
            </DeleteButtonIcon>
          </div>
        </AttributesStyle>
      )}
    </div>
  );
};
