import { css } from "@emotion/react";

export interface SearchBarProps {
  search: string;
  onSearch: (search: string) => void;
  unreadRooms: number;
}

const SearchBar = ({ search, unreadRooms, onSearch }: SearchBarProps) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #282d35;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
      `}
    >
      <i className="pi pi-search" />

      <input
        css={css`
          appearance: none;
          font-family: "Poppins";
          font-size: 12px;
          width: 100%;
          padding: 4px;
          margin-left: 10px;
          border: none;
          background-color: #2f343d;
          color: #90a0b7;
        `}
        value={search}
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Buscar..."
      />
    </div>

    <div
      css={css`
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 12px;
        min-width: 20px;
        max-width: 50px;
        padding: 4px;
        margin-left: 10px;
        border-radius: 4px;
        background-color: #109cf1;
        color: #ffffff;
        overflow: hidden;
      `}
    >
      {unreadRooms}
    </div>
  </div>
);

export default SearchBar;
