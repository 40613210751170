import {
  iNodesSave,
  iNodesSaveObject
} from "../components/react-flow-renderer/NodeData";
import { useFetch2 } from "../util";

// export interface botPublish {
//   id: number;
//   name: string;
//   first: boolean;
//   end: boolean;
//   botInteractions: BotInteraction[];
//   userInteractions: UserInteraction[];
// }

// interface UserInteraction {
//   id: string;
//   source: string;
//   target: string;
//   entityTypeId: number;
//   entityTypeName: string;
//   phrases: string[];
//   position: number;
// }

// interface BotInteraction {
//   id: string;
//   interaction: string;
//   entityName: string;
//   entityTypeId: number;
//   position: number;
// }
interface IBotSave {
  botId: number;
  flowId: number;
  data: iNodesSaveObject;
}
interface IBotGet {
  botId: number;
  flowId: number;
}
function BotPublishDialogServices() {
  const useQuery = useFetch2();

  // this code just return a promise function to who is calling a function.

  const botPublishDialog = async (data: IBotSave) => {
    try {
      const method = { method: "POST" };
      return await useQuery(
        `/api/bots/${data.botId}/publish/${data.flowId}`,
        method,
        data.data
      );
    } catch (error) {
      return error;
    }
  };
  const GetBotPublishDialog = async (
    data: IBotGet
  ): Promise<iNodesSaveObject | unknown> => {
    try {
      const method = { method: "GET" };
      return await useQuery(
        `/api/bots/${data.botId}/flow/${data.flowId}`,
        method
      );
    } catch (error) {
      return error;
    }
  };

  const botUpdateDialog = async (data: IBotSave) => {
    try {
      const method = { method: "PUT" };
      return await useQuery(
        `/api/bots/${data.botId}/flow/${data.flowId}`,
        method,
        data.data
      );
    } catch (error) {
      return error;
    }
  };

  return {
    botPublishDialog,
    GetBotPublishDialog,
    botUpdateDialog
  };
}

export default BotPublishDialogServices;
