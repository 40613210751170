import styled from "@emotion/styled";

export const HeaderDiv = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 79px;
`;
export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
`;

interface IContent {
  fullScreen: boolean;
}
export const Content = styled.div<IContent>`
  width: 100vw;
  overflow: hidden;
  margin-top: ${(props) => (props.fullScreen ? "0px" : "79px")};
  background-color: #f5f5f5;
  flex: 1 1 auto;
`;

// Componentes abaixo estão na Pagina drawflow
interface IContainerDrawflowPage {
  fullScreen: boolean;
}
export const ContainerDrawflowPage = styled.div<IContainerDrawflowPage>`
  width: ${(props) => (props.fullScreen ? "100vw" : "33.3333%")};
  @keyframes onFullScreen {
    0% {
      width: 33.3333%;
      position: absolute;
      top: 10%;
      left: 1rem;
      height: 80%;
      z-index: 1000;
    }
    99% {
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      position: absolute;
      z-index: 4;
    }
    100% {
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      position: unset;
      z-index: 4;
    }
  }
  @keyframes onExitFullScreen {
    0% {
      width: 100%;
      bottom: 0;
      left: 0;
      height: 100%;
      position: absolute;
      z-index: 1000;
    }
    99% {
      bottom: 0.5rem;
      z-index: 1000;
      flex-grow: 2;
      height: 85%;
      position: absolute;
      width: 33.3333%;
    }
    100% {
      flex-grow: 2;
      width: 33.3333%;
      position: unset;
      bottom: 0.5rem;
      left: 0.5rem;
      height: 85%;
      z-index: 4;
    }
  }
  animation: ${(props) =>
    props.fullScreen
      ? "500ms ease-out 0s onFullScreen"
      : "500ms ease-out 0s  onExitFullScreen"};
  margin-right: ${(props) => (props.fullScreen ? "0rem" : "0.5rem")};
  flex-grow: ${(props) => (props.fullScreen ? "unset" : 2)};
  overflow: "hidden";
  /* z-index: 12; */
  background: #fff;
  border-radius: ${(props) => (props.fullScreen ? "0rem" : "0.5rem")};
  margin-top: ${(props) => (props.fullScreen ? "0rem" : "0.5rem")};
  margin-bottom: ${(props) => (props.fullScreen ? "0rem" : "0.5rem")};
`;

interface IInteractionArea {
  fullScreen: boolean;
  nodeSelected: boolean;
}
export const InteractionArea = styled.div<IInteractionArea>`
  position: ${(props) => (props.fullScreen ? "absolute" : "unset")};
  right: ${(props) =>
    props.fullScreen ? (props.nodeSelected ? 0 : "-32%") : 0};
  border-radius: 0.5rem;
  overflow: hidden;
  top: ${(props) => (props.fullScreen ? "2rem" : 0)};
  z-index: 11;
  /* flex: 0 0 auto; */
  /* padding: $gutter; */
  width: 33.3333%;
  padding: 0;
  background-color: #fff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: ${(props) => (props.fullScreen ? "90vh" : "unset")};
  /* transition: right linear 1s; */
  border-top-right-radius: ${(props) => (props.fullScreen ? 0 : "0.5rem")};
  border-bottom-right-radius: ${(props) => (props.fullScreen ? 0 : "0.5rem")};
  @keyframes onFullScreen1 {
    0% {
      position: absolute;
      right: -33.3333%;
      z-index: 5;
    }
    99% {
      position: absolute;
      right: -10px;
      z-index: 5;
    }
    100% {
      position: absolute;
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 11;
    }
  }
  @keyframes onExitFullScreen {
    0% {
      width: 100%;
    }
    100% {
      width: 33.3333%;
    }
  }
  @keyframes onNodeSelected {
    0% {
      position: absolute;
      right: -33.3333%;
      z-index: 5;
    }
    99% {
      position: absolute;
      right: -10px;
      z-index: 5;
    }
    100% {
      position: absolute;
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 11;
    }
  }
  @keyframes onNodeDeselected {
    0% {
      right: 0;
    }
    100% {
      right: -400px;
    }
  }
  animation: ${(props) =>
    props.fullScreen
      ? props.nodeSelected
        ? "400ms ease-out 0s onNodeSelected"
        : "400ms ease-out 0s onNodeDeselected"
      : ""};
`;

export const MyIconButton = styled.button`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  margin-left: 1rem;
  transition: all 0.2s;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;
interface IText {
  fullScreen: boolean;
}
export const Text = styled.p<IText>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.fullScreen ? "#FFF" : "#0A043C")};
`;
