import React from 'react'

type paramType = {
  setOption: () => void
}

function BackArrowBtn({setOption}:paramType) {
  return (
    <i className="pi pi-arrow-left" 
      title="Voltar" 
      onClick={setOption}
      style={{ position: 'absolute', top: '0rem', cursor: 'pointer' }}>
        
    </i>
          
  )
}

export default BackArrowBtn
