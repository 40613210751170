import styled from "@emotion/styled";

interface IContainer{
    fullScreen: boolean
}
export const Container = styled.div<IContainer>`
    width: 100%;
    height: ${props => props.fullScreen ? "91.9%" : "90%"};
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    border-top: 1px solid #EEE;
    border-bottom-left-radius: ${props => props.fullScreen ? "0rem" : "1rem"};
    border-bottom-right-radius: ${props => props.fullScreen ? "0rem" : "1rem"};
`;