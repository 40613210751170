import { GCredentials } from "../components/Credentials";
import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";

export const GCredentialsPage: React.FC = () => {
  return (
    <LayoutHeader2>
      <GCredentials />
    </LayoutHeader2>
  );
};
