import CreateForm from "./CreateForm";
import SideTextDialog from "./SideTextDialog";
import DialogPadrao from "../../DialogPadrao";

export default ({
  displayDialog,
  onHide
}: {
  displayDialog: boolean;
  onHide: () => void;
}) => (
  <DialogPadrao
    displayDialog={displayDialog}
    onHide={onHide}
    contentStyle={{ height: "420px" }}
    headerStyle={{
      backgroundColor: "#ff0025"
    }}
  >
    <div className="rigtContentFake">
      <CreateForm hideDialog={onHide} />
    </div>

    <div className="leftContent">
      <SideTextDialog />
    </div>
  </DialogPadrao>
);
