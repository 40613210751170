import { iNodesSaveObject } from "./../components/react-flow-renderer/NodeData.d";
import { iNodesSave } from "../components/react-flow-renderer/NodeData";
import { useFetch2, useFetchMultipart } from "../util";

interface ISaveFile {
  file: string;
  name: string;
  mimeType: string;
}

function publishFlowServices() {
  const useQuery = useFetch2();
  const useQueryMultiPart = useFetchMultipart();

  const saveFile = async (input: FormData) => {
    try {
      const method = { method: "POST" };
      return await useQueryMultiPart(`/api/files/upload`, method, input);
    } catch (error) {
      return error;
    }
  };

  const deleteFile = async (id: number) => {
    try {
      const method = { method: "DELETE" };
      return await useQuery(`/api/files/${id}`, method);
    } catch (error) {
      return error;
    }
  };

  // this code just return a promise function to who is calling a function.

  const publishFlow = async (
    idRobo: number,
    flowId: number,
    data: iNodesSaveObject
  ): Promise<any> => {
    try {
      const method = { method: "POST" };
      return await useQuery(
        `/api/bots/${idRobo}/publish/${flowId}`,
        method,
        data
      );
    } catch (error) {
      return error;
    }
  };

  return {
    publishFlow,
    saveFile,
    deleteFile
  };
}

export default publishFlowServices;
