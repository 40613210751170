import {
  A,
  Attachment,
  BotBlue,
  Link,
  MicBlue,
  Smile,
  ThreePointsBlue
} from "../../../../assets";
import { Container, DivIcon, Flex, Span } from "./styles";
import { Text } from "../../../Text";
import { TextDefaultFallback } from "./Components/TextFallback";

export const UnderstoodMessages: React.FC = () => {
  return (
    <Container>
      <div className="flex align-items-center justify-content-between w-full">
        <div className="flex align-items-center">
          <DivIcon>
            <img width="18px" src={BotBlue} alt="Img-bot" />
          </DivIcon>
          <Text className="ml-2" s="14px">
            Mensagem do bot para interações não compreendidas
          </Text>
        </div>
      </div>
      <Text className="mt-4" s="14px">
        <strong style={{ fontWeight: 700 }}>Dica:</strong> Selecione uma caixa
        de diálogo e pressione Ctrl+Enter para criar respostas aleatórias.
      </Text>
      <TextDefaultFallback />
      <div className="flex justify-content-between w-full">
        <div />
        <div>
          <Flex>
            <img src={A} alt="Icon-text-edit" />
            <img src={Smile} alt="Icon-Smiles" />
            <img src={Attachment} alt="Icon-Attachment" />
            <img src={Link} alt="Icon-Link" />
            <img src={MicBlue} alt="Icon-MicBlue" />
            <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" />
          </Flex>
        </div>
      </div>
    </Container>
  );
};
