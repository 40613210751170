import { CircleLetter, InsideCircleLetter } from "./styles";
interface ILetterCircle {
  initial?: string;
}
export const LetterCircle: React.FC<ILetterCircle> = ({ initial = "" }) => {
  return (
    <div css={CircleLetter}>
      <div css={InsideCircleLetter}>{initial[0]?.toUpperCase()}</div>
    </div>
  );
};
