import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";

interface phoneData {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const onlyNumber = (str: string) => str.replace(/[^0-9]/gu, "");

const PhoneMask: React.FC<phoneData> = ({ value, onChange, style }) => {
  const [autoPrefix, setAutoPrefix] = useState(true);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (value && !value.startsWith("55")) {
      setAutoPrefix(false);
    } else if (!value && initialRender) {
      setAutoPrefix(true);
      onChange({
        target: { value: "55" }
      } as React.ChangeEvent<HTMLInputElement>);
      setInitialRender(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = onlyNumber(e.target.value);
    const startsWith55 = inputValue.startsWith("55");

    setAutoPrefix(startsWith55);

    onChange({
      ...e,
      target: { ...e.target, value: onlyNumber(e.target.value) }
    });
  };

  return (
    <InputMask
      mask={autoPrefix ? "+99 (99) 9 9999-9999" : ""}
      value={value}
      onChange={handleChange}
      style={style}
    />
  );
};

export default PhoneMask;
