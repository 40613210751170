import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { HomePage } from "../components/Pages/HomePage";

export const Home: React.FC = () => {
  return (
    <LayoutHeader2>
      <HomePage />
    </LayoutHeader2>
  );
};
