import styled from "@emotion/styled";

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  border-radius: 1rem;
  border: none;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 11;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.5rem;
`;
