import { InputText } from "primereact/inputtext";
import { Border, Container, Label } from "./styles";
import { Text } from "../../components/Text";

interface IInputColor {
  color: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export const InputColor: React.FC<IInputColor> = ({
  color,
  onChange,
  label
}) => {
  return (
    <Container>
      <Border color={color}>
        <Label htmlFor={`InputTextContent${label}`} color={color}></Label>
        <InputText
          id={`InputTextContent${label}`}
          type="color"
          style={{ display: "none", outline: "none" }}
          onChange={(e) => onChange(e)}
        />
      </Border>
      <Text s="12px" lh="14px" weight={400} color="#544F77">
        {label}
      </Text>
    </Container>
  );
};
