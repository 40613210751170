import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import {
  Back,
  bot,
  CloseX,
  girl,
  PencilGray,
  RArrowWhite,
  flowChartLine,
  botRed,
  BtnImgPersons,
  filecsvExample
} from "../../assets";
import {
  StyledButton,
  ButtonBack,
  Container,
  ContainerBegin,
  ContainerEnd,
  ContainerLogo,
  ImageArrowRight,
  Img,
  ContainerDropdown,
  IconButton,
  StyledDropdown,
  LeftHeaderWhiter,
  ContainerLeftModal,
  ContainerRightModal
} from "./styles";
import { Button } from "primereact/button";
import { onSave } from "../../util/onSave";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setLevels } from "../../redux/slices/nodes";
import { setShowModalCSV } from "../../redux/slices/flowInteractions";
import publishFlowServices from "../../services/onSave";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  setFlowIdActive,
  setModalConfirmImportTemplate,
  setModalCreateTemplate,
  erasiFilesIds
} from "../../redux/slices/flowInteractions";
import { toggleShowSidebar } from "../../redux/slices/sidebar";
import { setFlowName } from "../../redux/slices/flowupdates";
import TemplatesService from "../../services/TemplatesService";
import { ModalCreateTemplate } from "../Pages/Templates/ModalCreateTemplate";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import { useToast } from "../toast-provider";
import { ModalConfirmImportTemplate } from "../Pages/Templates/ModalConfirmImportTemplate";
import ImportCSV from "../../components/Interactions/components/ImportCSV";
import ExportCSV from "../../components/Interactions/components/ExportCSV";
import { IoMdPlayCircle } from "react-icons/io";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

const Header: React.FC = () => {
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  const flowNameRedux = useAppSelector(
    (state) => state.flowAttributes.flowName
  );
  const toast = useToast();
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplatePage =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const dispatch = useAppDispatch();
  const [templateSelected, setTemplateSelected] = useState<number | null>(null);
  const redirect = useNavigate();
  const { publishFlow } = publishFlowServices();
  const BotIdActive = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const [searchTemplateName] = useState("");
  const [first] = useState(0);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const debouncedText = useDebounce(searchTemplateName, 600);
  const dropdownRef = useRef(null);
  const [selectedImport, setSelectedImport] = useState(null);

  // const [showModalCSV, setShowModalCSV] = useState(false);
  const showModalCSV = useAppSelector(
    (state) => state.flowInteractions.showModalCSV
  );
  // const fileUrl = imbot-frontend\src\assets\filecsvExample.csv

  const { templatesList } = TemplatesService();
  const { user, company, hasAnyPermission } = useAuth() as AuthContextType;
  const { botId, flowId } = useParams();
  const [displayModal, setDisplayModal] = useState(false);
  const location = useNavigate();
  const onHide = () => {
    setDisplayModal(false);
  };
  const fetchTemplatesList = async () => {
    try {
      const selected = user?.workspaces?.workspaces.map(
        (_ws: { id: number }) => {
          // setSelectedNodeKeys(_ws.id)
          return _ws.id;
        }
      );
      if (user?.workspaces?.workspaces?.length) {
        const data = await templatesList({
          offset: first,
          limit: 100,
          companyId: user.admin ? company.id : user.companyId,
          name: debouncedText || "",
          workspaces: selected as number[]
        });
        setTemplatesOptions(data.result);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: e.message
      });
    }
  };
  const handleUpdateTemplateList = async () => {
    fetchTemplatesList();
    setTemplateSelected(null);
  };
  useEffect(() => {
    fetchTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.workspaces?.workspaces, first]);
  // const handleImportTemplate = () => {
  //   if (Number(templateSelected) > 0) {
  //     dispatch(setModalConfirmImportTemplate(true));
  //   }
  // };
  // useEffect(() => {
  //   handleImportTemplate();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [templateSelected]);
  const handleCriarTemplate = () => {
    dispatch(setModalCreateTemplate(true));
  };
  const handlePublicar = async () => {
    setDisplayModal(true);
    dispatch(setLevels(edges));
    dispatch(erasiFilesIds());
    const ObjectSAve = onSave(flowNameRedux, nodes);
    await publishFlow(BotIdActive, parseInt(flowId as string), ObjectSAve)
      .then((res) => {
        // await sleep(5000);
        // const newReturn = onLoad(ObjectSAve)
        if (res.id) {
          dispatch(setFlowIdActive(res.id));

          location(`/drawflow/${botId}/${res.id}`, { replace: false });
        }
        onHide();
      })
      .catch(() => {
        onHide();
      });
  };
  // const sleep = (ms: number) => new Promise(
  //   resolve => setTimeout(resolve, ms));

  const inputRef = useRef<HTMLInputElement>(null);

  const sidebarHiddenRedux = useAppSelector(
    (state) => state.sidebar.sidebarHidden
  );
  const expanded = useAppSelector((state) => state.sidebar.sidebarExpanded);
  const handleBackButton = () => {
    if (isTemplatePage) {
      redirect("/templates-list");
    } else {
      redirect(`/chatbots/${botId}`);
    }
  };

  const imports = [
    { name: "Template", code: "template" },
    { name: "CSV", code: "csv" }
  ];

  const handleDownloadCSV = () => {
    const link = document.createElement("a");
    link.href = filecsvExample;
    link.download = "exampleCSV.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      <ModalConfirmImportTemplate
      // templateSelected={templateSelected as unknown as number}
      />
      <ModalCreateTemplate updateTemplateList={handleUpdateTemplateList} />
      <ContainerBegin sidebarHidden={sidebarHiddenRedux}>
        <ContainerLogo
          expanded={expanded}
          onClick={() => dispatch(toggleShowSidebar())}
        >
          <img src={bot} />
          {expanded ? (
            <span
              css={css`
                color: white;
                font-size: 40px;
                font-weight: bold;
              `}
            >
              {user.plataform.displayName}
            </span>
          ) : null}
          {sidebarHiddenRedux ? (
            <ImageArrowRight src={RArrowWhite} alt="Arrow-Right-Image" />
          ) : (
            <ImageArrowRight src={CloseX} alt="Arrow-Right-Image" />
          )}
        </ContainerLogo>

        <ButtonBack onClick={handleBackButton}>
          <img src={Back} alt="Button Back" />
        </ButtonBack>
        <img src={bot} alt="image-bot" />
        <img
          css={css`
            height: 44px;
          `}
          src={girl}
          alt="image-bot"
        />
        <input
          // autoFocus
          ref={inputRef}
          disabled={isTemplatePage}
          value={flowNameRedux}
          onChange={(e) => dispatch(setFlowName(e.target.value))}
          css={css`
            border: none;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            margin-left: 1rem;
            color: #0a043c;
            max-width: 17.5rem;
          `}
        />
        {isTemplatePage === false ? (
          <Img
            onClick={() => {
              // setEditName(!editName)
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 100);
            }}
            src={PencilGray}
            alt="Editar Flow Name"
          />
        ) : null}
      </ContainerBegin>

      <ContainerEnd>
        {/* <div
          css={css`
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #4c5862;
            background-color: #fff;
          `}
        >
          Configuração
        </div>
        <img src={ArrowRight} alt="Arrow-right" />
        <div
          css={css`
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #4c5862;
            background-color: #fff;
          `}
        >
          Canais
        </div>
        <img src={ArrowRight} alt="Arrow-right" />
        <div
          css={css`
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #4c5862;
            background-color: #fff;
          `}
        >
          Equipe
        </div>
        <img src={ArrowRight} alt="Arrow-right" />
        <div
          css={css`
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #4c5862;
            background-color: #fff;
          `}
        >
          Design
        </div>
        <img src={ArrowRight} alt="Arrow-right" />
        <button
          type="button"
          css={css`
            height: 38px;
            width: 96px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #2176f3;
            background-color: rgba(33, 150, 243, 0.16);
            border: none;
            border-radius: 6px;
            position: relative;
            z-index: 0;
          `}
        >
          Construção
        </button>
        <img src={ArrowRight} alt="Arrow-right" /> */}
        {isTemplatePage === false ? (
          <div className="buttons">
            {user.admin ||
            hasAnyPermission("template_import") ||
            hasAnyPermission("template_create") ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  padding: "0 0 0 1rem"
                }}
              >
                <ExportCSV />
              </div>
            ) : null}
            {user.admin ||
            hasAnyPermission("template_import") ||
            hasAnyPermission("template_create") ? (
              <Dropdown
                onChange={(e) => {
                  if (e.value.code === "template") {
                    dispatch(setModalConfirmImportTemplate(true));
                  } else if (e.value.code === "csv") {
                    setShowModalCSV(true);
                    dispatch(setShowModalCSV(true));
                  }
                }}
                style={{ background: "#d8e9f6", border: "none" }}
                options={imports}
                placeholder="Importar"
                optionLabel="name"
              />
            ) : null}
            {user.admin || hasAnyPermission("template_create") ? (
              <StyledButton
                style={{
                  marginRight: 0,
                  background: "#D8E9F6",
                  borderColor: "#D8E9F6",
                  color: "#046AF3"
                }}
                onClick={handleCriarTemplate}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                    paddingLeft: "0.2rem",
                    width: "200px"
                  }}
                >
                  <img
                    src={flowChartLine}
                    style={{
                      backgroundColor: "#f7f7f7",
                      padding: "0.17rem 0.2rem",
                      borderRadius: "6px"
                    }}
                  />
                  Criar template
                </div>
              </StyledButton>
            ) : null}
            <StyledButton
              style={{ marginRight: "2rem", width: "300px" }}
              onClick={handlePublicar}
            >
              Publicar
            </StyledButton>
          </div>
        ) : null}
        <Dialog
          closeOnEscape={false}
          closable={false}
          header="Sistema em atualização..."
          visible={displayModal}
          onHide={onHide}
        >
          <div className="flex">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
            <div className="">Por favor aguarde!</div>
          </div>
        </Dialog>
      </ContainerEnd>
      <Dialog
        className="addWspaceDialog relative"
        style={{ border: "none" }}
        headerStyle={{
          position: "relative",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          border: "none",
          height: "3rem",
          paddingTop: "1.5rem",
          backgroundColor: "#ff0025"
        }}
        visible={showModalCSV}
        contentStyle={{
          padding: 0,
          height: "40vh",
          width: "70vw",
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          border: "none",
          overflow: "hidden"
        }}
        onHide={() => dispatch(setShowModalCSV(false))}
        closeOnEscape={true}
        resizable={false}
        closable={true}
        keepInViewport={false}
        draggable={false}
      >
        <LeftHeaderWhiter />
        <div className="flex h-full flex-end">
          <ContainerLeftModal>
            <div className="content">
              <div
                style={{ alignItems: "center", display: "flex", gap: "10px" }}
              >
                <img src={botRed} className="mb-2" />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="font-bold"
                    style={{ color: "#0A043C", fontSize: "1.02rem !important" }}
                  >
                    Importar script
                  </div>
                  <div>
                    Importe scripts a partir de um modelo de arquivo CSV.
                  </div>
                </div>
              </div>
              <div className="list">
                No arquivo CSV as seguintes colunas devem estar presentes em
                ordem:
                <li>Nome do node;</li>
                <li>Interação do bot;</li>
                <li>Interação do usuário;</li>
                <li>Node de destino.</li>O primeiro node deve ter o nome
                "Ínicio".
              </div>
            </div>
            <div className="buttons">
              <Button
                label="CSV exemplo"
                style={{
                  background: "#D8E9F6",
                  borderColor: "#D8E9F6",
                  color: "#046AF3"
                }}
                icon="pi pi-download"
                onClick={handleDownloadCSV}
              />
              <ImportCSV />
            </div>
          </ContainerLeftModal>
          <ContainerRightModal>
            <div className="imageTitle">
              <img src={girl} />
              <div>
                Deseja importar um arquivo CSV para configurar um novo Fluxo de
                conversação?
              </div>
            </div>
            <div className="text">
              Para importar um script de robô no {user.plataform.displayName} a
              partir de um CSV, organize o arquivo com as colunas em ordem
              correta (Nome do node, Interação do bot, Interação do usuário,
              Node de destino) e utilize nomes de nodes claros. Isso agiliza a
              configuração e atualização dos fluxos de conversação, garantindo
              uma gestão mais eficiente.
            </div>
            <div className="link">
              <IoMdPlayCircle
                style={{ marginRight: "0.3rem" }}
                size={25}
                color="white"
                className="relative"
              />
              <span>Confira o vídeo</span>
            </div>
          </ContainerRightModal>
        </div>
      </Dialog>
    </Container>
  );
};

export default Header;
