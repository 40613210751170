import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckCard = css`
  padding: 0.7rem;
  border: 2px solid #acd0f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
  color: #999999;
  margin-top: 1.1rem;
  height: 50px;
  width: 116px;
  @media (min-width: 1921px) {
    width: 190px;
  }
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 22px;
  height: 22px;
  right: -11px;
  top: -11px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 800px;
`;
export const ContainerLeftModal = styled.div`
  width: 100%;
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-top: 0rem;
`;
export const ContainerRightModal = styled.div`
  width: 68.3%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: #ff0025;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

export const PlayButton = styled.button`
  background-color: #fff;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonSalvar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 42px;
  background-color: #046af3;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  transition: background-color ease-in-out 0.4s;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
  :disabled {
    background-color: #d2d2d2;
  }
`;
export const ButtonCancelar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 42px;
  background-color: transparent;
  color: #4c5862;
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  transition: background-color ease-in-out 0.4s;
  :hover {
    background-color: #d2d2d2;
    color: #fff;
    cursor: pointer;
  }
`;
interface IContainerInternalButton {
  color: string;
}
export const ContainerInternalButton = styled.button<IContainerInternalButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: none;
  background-color: ${(props) =>
    props.color === "blue" ? "#D4E2F3" : "#FFE5E9"};
  :hover {
    background-color: ${(props) =>
      props.color === "blue" ? "#A9C1DE" : "#FFA7B5"};
    cursor: pointer;
    transition: 0.4s linear ease-in-out;
  }
`;

interface IContainerButton {
  color: string;
}
export const ContainerButton = styled.div<IContainerButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  background-color: transparent;
  border: ${(props) =>
    props.color === "blue" ? "1px solid #0469F3" : "1px solid #FF0025"};
  :hover {
    border: ${(props) =>
      props.color === "blue" ? "1px solid #024AAE" : "1px solid #F60022"};
    background-color: ${(props) =>
      props.color === "blue" ? "#A9C1DE" : "#FFA7B5"};
    cursor: pointer;
    transition: 0.4s linear ease-in-out;
    ${ContainerInternalButton} {
      cursor: pointer;
      background-color: ${(props) =>
        props.color === "blue" ? "#A9C1DE" : "#FFA7B5"};
    }
  }
`;

interface ILabel {
  color: string;
}
export const Label = styled.label<ILabel>`
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${(props) => (props.color === "blue" ? "#0469F3" : "#FF0025")};
  :hover {
    cursor: pointer;
    color: ${(props) => (props.color === "blue" ? "#A9C1DE" : "#FFA7B5")};
    ${ContainerInternalButton} {
      cursor: pointer;
      background-color: ${(props) =>
        props.color === "blue" ? "#A9C1DE" : "#FFA7B5"};
    }
  }
`;

export const ErrorMessage = styled.div`
  margin-left: 0.5rem;
  font-size: 12px;
  color: #ff4d4f;
`;

export const LabelCard = styled.label`
  @media (max-width: 1921px) {
    font-size: 0.75rem;
  }
  @media (min-width: 1921px) {
    font-size: 1rem;
  }
`;
export const HiddenDiv = styled.div`
  @media (max-width: 1921px) {
    display: none;
  }
`;

export const InputUrl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;
