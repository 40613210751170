import { css, Global } from "@emotion/react";
import {
  PoppinsItalic,
  PoppinsRegular,
  PoppinsSemiBold,
  PoppinsSemiBoldItalic
} from "./fonts";
import { useAppSelector } from "./redux/hooks";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";

export default () => {
  const ToastConfig = useAppSelector((state) => state.user.toastApp);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (ToastConfig?.show) {
      toast?.current?.show({ ...ToastConfig });
    }
  }, [ToastConfig?.count]);

  return (
    <>
      <Toast ref={toast} />

      <Global
        styles={css`
          @font-face {
            font-family: "Poppins";
            src: url("${PoppinsRegular}") format("truetype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: "Poppins";
            src: url("${PoppinsItalic}") format("truetype");
            font-weight: normal;
            font-style: italic;
            font-display: swap;
          }

          @font-face {
            font-family: "Poppins";
            src: url("${PoppinsSemiBold}") format("truetype");
            font-weight: 600;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: "Poppins";
            src: url("${PoppinsSemiBoldItalic}") format("truetype");
            font-weight: 600;
            font-style: italic;
            font-display: swap;
          }

          html,
          body,
          div,
          span,
          applet,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          a,
          abbr,
          acronym,
          address,
          big,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          s,
          samp,
          small,
          strike,
          strong,
          sub,
          sup,
          tt,
          var,
          b,
          u,
          i,
          center,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          embed,
          figure,
          figcaption,
          footer,
          header,
          hgroup,
          menu,
          nav,
          output,
          ruby,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
          }

          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }

          ol,
          ul {
            list-style: none;
          }

          blockquote,
          q {
            quotes: none;
          }

          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: "";
            content: none;
          }

          table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          input:focus,
          textarea:focus,
          button:focus,
          div:focus,
          span:focus {
            outline: 0;
          }

          html {
            box-sizing: border-box;
          }

          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }

          html,
          body,
          div#root {
            width: 100%;
            height: 100%;
          }

          body {
            background-color: #ffffff;
            color: #212529;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
          }

          a {
            color: #046af3;
          }

          .p-component {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            line-height: 1.5;
          }

          .p-inputtext {
            color: #212529;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
          }

          .p-button.p-button-icon-only {
            padding: 4px 0;
          }

          .p-inputtext.p-component.p-invalid,
          .p-inputwrapper.p-component.p-invalid > .p-inputtext {
            border-color: #dc3545;
          }

          .p-inputtext.p-component.p-invalid:focus,
          .p-inputwrapper.p-component.p-invalid.p-inputwrapper-focus
            > .p-inputtext {
            border-color: #dc3545;
            box-shadow: 0 0 0 4px rgb(220 53 69 / 25%);
          }

          .p-dialog .p-dialog-header .p-dialog-title {
            font-size: 16px;
          }

          .p-dialog .p-dialog-header-icons {
            align-self: initial;
          }

          .p-dialog .p-dialog-footer {
            padding: 12px;
          }

          .p-dialog .p-dialog-header .p-dialog-header-icon {
            height: 16px;
            width: 16px;
          }

          .p-menu .p-menuitem-link {
            padding: 4px 16px;
          }

          .p-menu .p-menuitem-text {
            line-height: 1.5;
          }

          .field-checkbox,
          .field-radiobutton {
            margin-bottom: 4px;
          }

          .p-blockui-container {
            height: 100%;
            width: 100%;
          }

          .p-toast.p-toast-top-right {
            top: 60px;
          }
          .p-fluid .p-inputnumber .p-inputnumber-input {
            appearance: textfield;
          }
          .p-datatable-loading-overlay.p-component-overlay {
            background-color: rgba(0, 0, 0, 0.45);
          }

          .p-autocomplete-input.p-component {
            padding-right: 20px;
          }
          .p-avatar {
            border-radius: 50% !important;
          }
          .p-sidebar-sm {
            width: 23rem !important;
          }

          .triangle-node {
            background-color: "#9ca8b3";
            width: 0px;
            height: 0px;
            border-style: inset;
            border-width: 0 50px 80px 50px;
            border-color: transparent transparent #9ca8b3 transparent;
            float: left;
            transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
          }

          .triangle-node-text {
            text-align: center;
            top: 40px;
            left: -23px;
            position: relative;
            width: 46px;
            height: 46px;
            margin: 0px;
          }

          .dialog-enter {
            opacity: 0;
            transform: scale(0.7);
          }

          .dialog-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
          }

          .dialog-enter-done {
            transform: none;
          }

          .dialog-exit-active {
            opacity: 0;
            transform: scale(0.7);
            transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
          }

          .p-button.p-disabled {
            background: #efefef;
            border-color: #efefef;
            color: #979797;
          }
          .p-inputswitch .p-inputswitch-slider::before {
            border-radius: 10px;
          }
          .p-inputswitch .p-inputswitch-slider {
            border-radius: 13px;
          }
          .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            background: #046af3;
          }
          dialog::backdrop {
            position: absolute;
            z-index: 11;
            min-height: 600px;
            width: 100%;
            top: 0;
            left: 0;

            background-color: rgba(0, 0, 0, 0.5);
          }
          /* Configuração para posicionar corretamente o TabMenu quando scroll da página */
          /* no componente de edição do Node  - Componente central na pátina /drawflow   */
          .p-tabview-nav-container {
            position: sticky;
            top: 8px;
            background-color: #ffffff;
            z-index: 3;
            border-top: 1px solid #d2d2d2;
            /* -webkit-box-shadow: 0px 5px 15px 4px rgba(0,0,0,0.68); 
        box-shadow: 0px 5px 15px 4px rgba(0,0,0,0.68); */
          }
          /* Configuração para retirar o padding do tabview para posicionar corretamente */
          /* o botão salvar do flow no bottom do componente central na pátina /drawflow  */
          .p-tabview .p-tabview-panels {
            padding: 0;
          }
          .p-tabview-selected {
            border-bottom: 2px solid #2196f3;
          }
          .react-flow__handle {
            width: 12px;
            height: 12px;
          }

          .p-checkbox {
            margin-right: 5px;
          }
        `}
      />
    </>
  );
};
