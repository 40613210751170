import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../components";
import PoliticaPrivacidadeConfirmar from "../components/politica-privacidade-confirmar";
import useCompany from "../use-company";
import { botRed, background, dialogosInteligentes } from "./../assets";
import { v } from "../util";
import { useEffect, useState } from "react";
import Plataform from "../services/Plataform";

interface ConfirmProps {
  email: string;
}

const schema = v.object({
  email: v.string().superRefine(v.nonempty())
});

export default () => {
  const { resendEmail } = useCompany();
  const { userMail } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: userMail
    },
    resolver: zodResolver(schema)
  });

  const [disabledReenviar, setDisabledReenviar] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setDisabledReenviar(false), 60000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            flex: 1;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 500px;
            `}
          >
            <img src={botRed} />
            <div>
              Enviamos um e-mail para&nbsp;
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                {userMail} 😀
              </span>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 20px;
              gap: 20px;
              width: 500px;
            `}
          >
            <span>Verifique sua caixa de entrada para ativar a conta.</span>
            <span>
              Não recebeu nenhum e-mail? Verifique o spam ou clique no botão
              abaixo.
            </span>
          </div>

          <div
            css={css`
              width: 500px;
            `}
          >
            <form
              onSubmit={handleSubmit(async (data) => {
                setDisabledReenviar(true);

                const { email } = await resendEmail(data);
                navigate(`/confirm-subscription/${email}`, { replace: true });

                setTimeout(() => setDisabledReenviar(false), 60000);
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                `}
              >
                <Button
                  css={css`
                    width: 100%;
                    height: 45px;
                    margin-top: 20px;
                  `}
                  disabled={disabledReenviar}
                  label="Reenviar"
                />
                <div
                  css={css`
                    color: "#046AF3";
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <i
                    css={css`
                      color: #046af3;
                    `}
                    className="pi pi-arrow-left"
                  ></i>
                  <Link to="/login">Voltar para o login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
