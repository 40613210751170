import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { RoomsPage } from "../components/Pages/RoomsPage";

export const Rooms: React.FC = () => {
  return (
    <LayoutHeader2>
      <RoomsPage />
    </LayoutHeader2>
  );
};
