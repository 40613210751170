import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const AttributesStyle = styled.div`
  background: #ffffff;
  border: 1px solid #d8e9f6;
  border-radius: 6px;
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  /* z-index: 10; */
  /* overflow: hidden; */
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  /* :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  } */
`;
export const AttributeBar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: #00cdac;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;

export const Title = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0a043c;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544f77;
  text-align: left;
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;

export const IconDiv = styled.div`
  position: absolute;
  z-index: 30;
  right: -14px;
  top: -14px;
  width: 28px;
  height: 28px;
  background: #d4e2f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconImage = styled.div`
  margin-left: 1rem;
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: 0.75rem;
  background: #d4e2f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DeleteButtonIcon = styled.button`
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  position: absolute;
  top: -0.78125rem;
  right: -0.78125rem;
  z-index: 3;
`;

interface iAttributesContainer {
  bottom: string;
  height: string;
}
export const AttributesContainer = styled.div<iAttributesContainer>`
  position: absolute;
  z-index: 2;
  bottom: ${(props) => props.bottom};
  height: ${(props) => props.height};
  display: block;
  width: 80%;
  & + & {
    margin-top: 1rem;
  }
`;

export const FileName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;

  @media (min-width: 1020px) {
    width: 115px;
  }
  @media (min-width: 1200px) {
    width: 170px;
  }
  @media (min-width: 1400px) {
    width: 225px;
  }
  @media (min-width: 1600px) {
    width: 275px;
  }
  @media (min-width: 1850px) {
    width: 325px;
  }
`;

export const AudioContainer = styled.div`
  display: flex;
  border: 1px solid #d8e9f6;
  border-radius: 30px;
  padding: 0 0.5rem;
  background: transparent;
  gap: 5px;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: start;
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
`;

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
  border-radius: 50%;
  border: none;
  min-height: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
    background-color: #fbfaf6;
    transition: 0.2s ease-in-out;
  }
`;

export const WaveContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  #waveRecorder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    wave {
      width: 100%;
      display: unset !important;
      height: 100%;
      max-height: 23px;
      position: absolute !important;
    }
  }
`;

export const DeleteButtonIconAudio = styled.button`
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  z-index: 3;
`;
