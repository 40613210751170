import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const DivIconRounded = styled.div`
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #0a043c;
`;

export const Text = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #6e6b7b;
`;
export const Button = css`
  height: 28px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #dddddd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: #333c3f;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
interface IMyChip {
  active: boolean;
}
export const MyChip = styled.span<IMyChip>`
  margin: 0 auto;
  width: 2.9375rem;
  height: 1.375rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? "#AEE9D1" : "#FA4D64")};
  color: #333c3f;
  opacity: 50%;
  border-radius: 50px;
  height: 1.375rems;
`;

export const ModalImport = styled.div`
  display: flex;
  font-size: 16px;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  .divButton {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
`;
