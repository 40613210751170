// interface IOptionsRobotActions {

import {
  AttributeBar,
  AttributesStyle,
  DeleteButtonIcon,
  IconImage
} from "./styles";
import { ButtonDelete, PhotoVideo } from "../../../../../../assets";
import { Text, TextRejected } from "../../../../../../components/Text";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { eraseRejectedFiles } from "../../../../../../redux/slices/nodes";
import { Tooltip } from "primereact/tooltip";
interface iFilesAdded {
  id: string;
  name: string;
  reason: string;
  botId: string;
}
export const FilesRejected: React.FC<iFilesAdded> = ({
  id,
  name,
  botId,
  reason
}) => {
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const nodeId = nodeSelected?.id as string;
  const dispatch = useAppDispatch();
  return (
    <AttributesStyle>
      <AttributeBar />
      <IconImage>
        <img src={PhotoVideo} alt="Img Photo Video" />
      </IconImage>
      <div className="flex w-full align-items-center ml-2 justify-content-between">
        <div className="block align-items-center justify-content-start">
          <Text weight={600} style={{ textAlign: "left" }}>
            {name}
          </Text>
          <TextRejected
            className="tooltip-formatedAccepted"
            style={{ textAlign: "left" }}
          >
            Formato de arquivo inválido. Passe o mouse para ver os formatos
            válidos!
          </TextRejected>
          <Tooltip target=".tooltip-formatedAccepted">{reason}</Tooltip>
        </div>
        <DeleteButtonIcon
          onClick={() =>
            dispatch(eraseRejectedFiles({ nodeId, botId, rejectedFileId: id }))
          }
        >
          <img src={ButtonDelete} width="10px" alt="Delete-Node" />
        </DeleteButtonIcon>
      </div>
    </AttributesStyle>
  );
};
