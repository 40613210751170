import styled from '@emotion/styled';
import { css } from "@emotion/react";

export const ButtonSquared = css`
  border: 1px solid #d8e9f6;
  width: 2rem;
  height: 2rem;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

export const Container = styled.div`
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  height: 4.57rem;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  padding: 0.5rem;
  padding-left: 1.5rem;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  align-items: center;
`;
export const ImgBtn = styled.button`
background-color: transparent;
border: none;
width: 3rem;
height: 3rem;
display: flex;
align-items: center;
justify-content: center;
:hover {
cursor: pointer;
}
`;
