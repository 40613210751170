/* eslint-disable default-case */
/* eslint-disable no-negated-condition */
/* eslint-disable no-unused-expressions */
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { changedPhoneComponent } from "../../../../redux/slices/flowupdates";
import {
  Baloon,
  ButtonPlus,
  ButtonTrash,
  Flex,
  ImageSixPoints,
  Input,
  Span
} from "./styles";
import {
  A,
  Actions,
  Attachment,
  botRed,
  ButtonDelete,
  Link,
  MicBlue,
  SixPoints,
  Smile,
  ThreePointsBlue
} from "../../../../assets";
import { Text } from "../../../Text";
import {
  addNodeFallBack,
  deleteNodeFallback,
  updateNodeFallBackMessage
} from "../../../../redux/slices/nodes";

export const Exceptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const nodeSelectedFallBack = nodeSelected?.data?.fallbackMessages;
  const nodeId = nodeSelected?.id;
  const [firstFallbackMessage, setFirstFallbackMessage] = useState("");
  const handleUpdate = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    dispatch(
      updateNodeFallBackMessage({ newMessage: e.target.value, nodeId, idx })
    );
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.ctrlKey) {
      return null;
    } 
      switch (e.key) {
        case "Enter": {
          e.preventDefault();
          if (nodeSelectedFallBack?.length)
            {nodeSelected && dispatch(addNodeFallBack({ nodeId }));}
          else {
            nodeSelected && dispatch(addNodeFallBack({ nodeId }));
            firstFallbackMessage.length && dispatch(updateNodeFallBackMessage({
              newMessage: firstFallbackMessage,
              nodeId,
              idx: 0
            }));
            setFirstFallbackMessage("");
          }
          dispatch(changedPhoneComponent(true));
          break;
        }
        default:
          return null;
      } 
    
  };
  const handleAddFallbackMessage = () => {
    if (nodeSelectedFallBack?.length)
      {nodeSelected && dispatch(addNodeFallBack({ nodeId }));}
    else {
      nodeSelected && dispatch(addNodeFallBack({ nodeId }));
      firstFallbackMessage.length && dispatch(updateNodeFallBackMessage({
        newMessage: firstFallbackMessage,
        nodeId,
        idx: 0
      }));
      setFirstFallbackMessage("");
    }
    dispatch(changedPhoneComponent(true));
  };
  const handleDelete = (idx: number) => {
    nodeSelected && dispatch(deleteNodeFallback({ nodeId, idx }));
    dispatch(changedPhoneComponent(true));
  };
  useEffect(() => {
    if (!nodeSelectedFallBack?.length && firstFallbackMessage?.length) {
      nodeSelected && dispatch(addNodeFallBack({ nodeId }));
      firstFallbackMessage?.length && dispatch(updateNodeFallBackMessage({
        newMessage: firstFallbackMessage,
        nodeId,
        idx: 0
      }));
      setFirstFallbackMessage("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstFallbackMessage?.length])
  // useEffect(() => {
  //   if (!nodeSelected?.data?.fallbackMessages?.length) {
  //     dispatch(initFallbackNode({ nodeId }));
  //   }
  // }, [nodeSelected]);
  return (
      <div
        className="flex bg-white justify-content-start flex-column p-2 mt-4"
        // style={{
        //   borderTop: "1px solid #EEE"
        // }}
      >
        <div
          style={{ border: "3px solid rgba(33, 150, 243, 0.3)" }}
          className="relative mt-2 p-2 w-full border-round-lg"
        >
          <div className="absolute -mt-4 ml-6 bg-white border-round-right-3xl flex">
            <div className="flex align-items-center">
              <div className="w-2rem h-2rem -ml-4 shadow-7 text-center border-circle bg-white align-items-center justify-content-center">
                <img
                  className="my-1"
                  width="19px"
                  src={botRed}
                  alt="Bot-Image"
                />
              </div>

              <div
                className="ml-1"
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#046AF3"
                }}
              >
                Interação do robô
              </div>
              <Span>?</Span>
            </div>
          </div>

          <div className="content mt-4 ml-2">
            {/*Aqui colocar os elementos de interação*/}
            {/* <VariablesModal /> */}
            {nodeSelectedFallBack?.length ? (
              nodeSelectedFallBack?.map((el, index) => (
                <div key={index} css={Baloon}>
                  <img
                    css={ImageSixPoints}
                    src={SixPoints}
                    alt="icon-six-points"
                  />
                  <Input
                    autoFocus={true}
                    type="text"
                    value={el}
                    onChange={(e) => handleUpdate(e, index)}
                    onKeyDown={handleKeyPress}
                    placeholder="Insira sua mensagem de fallback aqui..."
                  />
                  <button onClick={handleAddFallbackMessage} type="button" css={ButtonPlus}>
                    +
                  </button>

                  {nodeSelectedFallBack?.length > 1 ? (
                    <button
                      type="button"
                      onClick={() => handleDelete(index)}
                      css={ButtonTrash}
                    >
                      <img src={ButtonDelete} alt="icon-button-delete" />
                    </button>
                  ) : null}
                </div>
              ))
            ) : (
              <div css={Baloon}>
                <img
                  css={ImageSixPoints}
                  src={SixPoints}
                  alt="icon-six-points"
                />
                <Input
                  autoFocus={true}
                  type="text"
                  value={firstFallbackMessage}
                  onChange={(e) => setFirstFallbackMessage(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Insira sua mensagem de fallback aqui..."
                />
                <button onClick={handleAddFallbackMessage} 
                  type="button"
                  css={ButtonPlus}>
                  +
                </button>
              </div>
            )}
            <div className="flex justify-content-between">
              <div className="flex gap-2">
                <img src={Actions} alt="Actions-Icon" />
                <Text lh="21px" color="#046AF3" s="0.875rem">
                  Ações
                </Text>
              </div>
              <div>
                <div css={Flex}>
                  <img src={A} alt="Icon-text-edit" />
                  <img src={Smile} alt="Icon-Smiles" />
                  <img src={Attachment} alt="Icon-Attachment" />
                  <img src={Link} alt="Icon-Link" />
                  <img src={MicBlue} alt="Icon-MicBlue" />
                  <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            border: "1px solid #EEE",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
        />
      </div>
  );
};
