import styled from "@emotion/styled";
interface IContainer {
    color: string;
}

export const Container = styled.div<IContainer>`
display: flex;
align-items: center;
justify-content: center;
width: 2.25rem;
height: 2.25rem;
border-radius: 50%;
background: ${props => props.color};
/* opacity: 0.5; */
`;

export const ContainerInterno = styled.div<IContainer>`
display: flex;
align-items: center;
justify-content: center;
width: 1.8rem;
height: 1.8rem;
border-radius: 50%;
background: ${props => props.color};
opacity: 1;
`;
