import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow-y: scrool;
  height: 600px;
  max-height: 73vh;
  width: 100%;
  z-index: 13;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 200px;
  box-shadow: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 11;
  max-height: 100%;
  min-width: 105%;
  bottom: -44vh;
  left: -11px;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Title = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0A043C;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544F77;
  text-align: left;
`;
