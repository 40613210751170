import styled from "@emotion/styled";

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .imageWebChat {
    height: 40px;
    width: 40px;
  }
`;

export const ButtonCreate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  height: 46px;
  width: 181px;
  gap: 1rem;
  box-shadow: 0px 4px 10px rgba(70, 64, 200, 0.24);
  border-radius: 6px;
  background: #046af3;
  color: #ffffff;
  cursor: pointer;

  :hover {
    transition: 0.2s ease-in-out;
    background-color: #023985;
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  .divTitle {
    margin-top: 10px;
  }

  .divFilter {
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    padding: 8px 12px;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .active {
    align-items: center;
    background: #aee9d1;
    border-radius: 50px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    padding: 5px;
    width: 50px;
  }

  .noActive {
    background: #fa4d64;
    border-radius: 50px;
    font-size: 12px;
    padding: 5px;
  }

  .custom-header .p-datatable-thead > tr > th {
    background-color: #eaeaea;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4rem;
  height: 80px;
  width: 100%;
  background-color: #fff;
  padding-right: 30px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    height: 9rem;
    gap: 15px;
    padding: 15px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 0;
    justify-content: center;
    height: 18.5rem;
    padding: 15px;
    gap: 15px;
  }

  @media screen and (max-width: 500px) {
    height: 16rem;
  }
`;
interface IContainerLeft {
  extends?: number;
}

export const ContainerLeft = styled.div<IContainerLeft>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) =>
    props.extends ? `${props.extends * 1.5}rem` : "1.5rem"};

  @media screen and (max-width: 820px) {
    flex-direction: row-reverse;
    gap: 1rem;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    margin-left: 0;
    width: 100%;
  }
`;
