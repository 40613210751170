/* eslint-disable func-style */
import { useFetch2 } from "../util";

function WorkspaceService() {
  const create = useFetch2();
  const useRequest = useFetch2();

  type invitesForNewUsersType = {
    email: string;
    profileId: number;
  };

  type inputArgType = {
    companyId: number;
    name: string;
    parentId: number;
    active: boolean;
    invitesForNewUsers: invitesForNewUsersType[];
    users: number[];
  };

  type findAllType = {
    offset: number;
    limit: number;
    name?: string;
    companyId?: number;
  };

  interface getEntityType {
    offset: number;
    limit: number;
    workspaces?: number[];
    name?: string;
    companyId: number;
  }

  const createWorkspace = async (input: inputArgType) => {
    try {
      return await create("/api/workspaces", { method: "POST" }, input);
    } catch (error) {
      return error;
    }
  };

  const findAllWorkspace = async (input?: getEntityType) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRequest(`/api/workspaces/list`, { method: "POST" }, { ...input });
  };

  const getWorkspaceByCompanyId = async (companyId: number | any) => {
    try {
      const paramRequest = new URLSearchParams({ companyId });
      const method = { method: "GET" };
      return await useRequest(`/api/workspaces/list?${paramRequest}`, method);
    } catch (error) {
      return error;
    }
  };

  const getWorkspaceById = async (workspaceId: number | any) => {
    try {
      const method = { method: "GET" };
      return await useRequest(`/api/workspaces/${workspaceId}`, method);
    } catch (error) {
      return error;
    }
  };

  const updateWorkspace = async (workspaceId: number, data: any) => {
    try {
      const method = { method: "PUT" };
      return await useRequest(`/api/workspaces/${workspaceId}`, method, data);
    } catch (error) {
      return error;
    }
  };

  return {
    createWorkspace,
    updateWorkspace,
    findAllWorkspace,
    getWorkspaceById,
    getWorkspaceByCompanyId
  };
}

export default WorkspaceService;
