import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import { UserList } from "../components/Pages/user";

export const Users: React.FC = () => {
  return (
    <LayoutHeader2>
      <UserList />
    </LayoutHeader2>
  );
};
