import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import { AuthContextType, useAuth } from "../../auth/auth-provider";

export interface WorkspaceBarProps {
  workspace?: number;
  onWorkspaceChange: (workspace: number) => void;
  onRefreshClick: () => void;
}

const WorkspaceBar = ({
  workspace,
  onWorkspaceChange,
  onRefreshClick
}: WorkspaceBarProps) => {
  const { user } = useAuth() as AuthContextType;

  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 12px;
        border-bottom: 1px solid #282d35;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          flex: 1;
        `}
      >
        <Dropdown
          css={css`
            border: 1px solid #2f343d;
            background-color: #2f343d;

            .p-dropdown-label {
              color: #ffffff;
              padding-left: 0;
              padding-bottom: 4px;
              padding-top: 4px;
            }

            .p-dropdown-label.p-placeholder {
              color: #ffffff;
            }

            &:not(.p-disabled):hover {
              border-color: #2f343d;
            }
          `}
          placeholder="Workspaces"
          options={user.workspaces.workspaces}
          optionLabel="name"
          optionValue="id"
          value={workspace}
          onChange={(e) => onWorkspaceChange(e.value)}
          filter
        />
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          padding: 4px;
          margin-left: 10px;
          color: #046af3;
          cursor: pointer;
        `}
        onClick={onRefreshClick}
      >
        <i
          className="pi pi-refresh"
          css={css`
            font-size: 22px;
          `}
        />
      </div>
    </div>
  );
};

export default WorkspaceBar;
