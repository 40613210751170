import { PhoneSimulator } from "../components/PhoneSimulator";
import React from "react";
import { Tooltip } from "primereact/tooltip";
import { ReactFlowProvider } from "react-flow-renderer";
import { CloseXRed, Expand, Flow } from "../assets";
import { DefaultInteraction } from "../components/Interactions/Default";
import { ReactFlowRenderer } from "../components/react-flow-renderer";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setFullScreenFlow } from "../redux/slices/flowInteractions";
import {
  ContainerDrawflowPage,
  InteractionArea,
  MyIconButton,
  Text
} from "../components/LayoutDrawflow/styles";

export const PageDrawflowChart: React.FC = () => {
  const { botId, flowId } = useParams();
  const fullScreen = useAppSelector(
    (state) => state.flowInteractions.fullScreenFlow
  );
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const dispatch = useAppDispatch();
  return (
    <ReactFlowProvider>
      <div
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          marginLeft: 0
        }}
      >
        <div
          style={{
            overflowY: "hidden",
            marginLeft: 0
          }}
          className={
            fullScreen
              ? "flex bg-blue-100 h-full"
              : "flex bg-blue-100 p-2 h-full"
          }
        >
          <ContainerDrawflowPage fullScreen={fullScreen}>
            <div
              className={
                fullScreen
                  ? "h-full overflow-hidden"
                  : "border-round-bottom-lg h-full mb-3 overflow-hidden"
              }
            >
              <div
                style={{
                  backgroundColor: fullScreen ? "#0A043C" : "#FFF"
                }}
                className={
                  fullScreen
                    ? "flex h-4rem align-items-center justify-content-end"
                    : "flex border-round-top-lg h-4rem align-items-center justify-content-end"
                }
              >
                {fullScreen ? (
                  <MyIconButton
                    onClick={() => dispatch(setFullScreenFlow(false))}
                  >
                    <img src={CloseXRed} alt="Close Button" />
                  </MyIconButton>
                ) : null}
                <div className="flex justify-content-center flex-grow-1">
                  <img width="12.75px" src={Flow} alt="img-flow" />
                  <Text fullScreen={fullScreen} className="ml-1">
                    Fluxo de conversação
                  </Text>
                </div>
                {fullScreen ? null : (
                  <div>
                    <Tooltip target=".expand-icon-tooltip" />
                    <img
                      data-pr-position="top"
                      data-pr-tooltip="Modo full screen"
                      data-pr-at="center top-5"
                      onClick={() => dispatch(setFullScreenFlow(true))}
                      className="expand-icon-tooltip mr-3 cursor-pointer"
                      src={Expand}
                      alt="Expand-icon"
                    />
                  </div>
                )}
              </div>
              <ReactFlowRenderer
                BotId={parseInt(botId as string)}
                FlowId={parseInt(flowId as string)}
              />
            </div>
          </ContainerDrawflowPage>
          <InteractionArea
            fullScreen={fullScreen}
            nodeSelected={Boolean(nodeSelected)}
          >
            <DefaultInteraction />
          </InteractionArea>
          {!fullScreen && !isTemplate ? (
            <PhoneSimulator node={undefined} />
          ) : null}
        </div>
      </div>
    </ReactFlowProvider>
  );
};
