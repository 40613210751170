import styled from "@emotion/styled";

export const ContainerLeftModal = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-top: 0rem;
`;
export const ContainerRightModal = styled.div`
  width: 68.3%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: #ff0025;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;
export const PlayButton = styled.button`
  background-color: #fff;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const Img = styled.img`
width: 5rem;
height: 5rem;
margin: 0 auto;
margin-bottom: 1rem;
max-height: 100%;
max-width: 100%;
`;
export const DivImg = styled.div`
width: 3.5rem;
min-width: 3.5rem;
height: 3.5rem;
min-height: 3.5rem;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
background-color: #D4E2F3;
`;
