import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Title = css`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  margin: 0 0 1rem 1rem;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0a043c;
  font-family: "Poppins";
`;

export const Icon = css`
  /* margin-left: 2rem; */
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
`;

export const Textarea = css`
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  height: 100px;
  padding: 16px;
  width: 100%;
`;

export const Info = css`
  background-color: #f4f4f4;
  border-radius: 6px;
  color: #4c5862;
  padding: 16px;
`;

export const Container = styled.div`
background: #FFFFFF;
border: 1px solid #D2D2D2;
border-radius: 25px;
`;