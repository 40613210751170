import { css } from "@emotion/react";
import { iDialogMessagesFile } from "../use-omnichannel";
import Avatar from "../avatar";

export interface TopBarChatInfosProps {
  dialog: iDialogMessagesFile;
}

const TopBarChatInfos = ({ dialog }: TopBarChatInfosProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 84px;
        padding: 13px 20px;
        border-bottom: 1px solid #e5e5e5;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Avatar name={dialog.name} />

        <div>
          <div>{dialog.name}</div>

          <div
            css={css`
              font-size: 12px;
              color: rgb(110, 107, 123);
            `}
          >
            Telefone do contato
            <br />
            workspace
          </div>
        </div>
      </div>

      <div
        css={css`
          text-align: center;
          font-size: 12px;
          padding: 5px;
          border-radius: 6px;
          border: 1px solid #e5e5e5;
          color: #046af3;
        `}
      >
        #{dialog.id}
      </div>
    </div>
  );
};

export default TopBarChatInfos;
