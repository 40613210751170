import { useAppSelector } from "../../redux/hooks";
import { Toast } from "primereact/toast";
import { ReactNode, useEffect, useRef } from "react";
import Header from "../header";
import { SidebarDrawflow } from "../sidebarDrawflow";
import { Container, Content, HeaderDiv } from "./styles";
interface iLayout {
  children: ReactNode;
}
export const LayoutDrawflow: React.FC<iLayout> = ({ children }) => {
  const toast = useRef<Toast>(null);
  const fullScreen = useAppSelector(
    (state) => state.flowInteractions.fullScreenFlow
  );
  const ToastConfig = useAppSelector(
    (state) => state.flowInteractions.responseError
  );

  useEffect(() => {
    if (ToastConfig.error) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro ao publicar flow.",
        detail: ToastConfig.description
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ToastConfig.count]);
  return (
    <Container>
      <Toast ref={toast} />
      {fullScreen === false ? (
        <>
          <HeaderDiv>
            <Header />
          </HeaderDiv>
          <SidebarDrawflow />
        </>
      ) : null}
      <Content fullScreen={fullScreen}>{children}</Content>
    </Container>
  );
};
