import styled from "@emotion/styled";

interface IOverlayInteractions{
    show: boolean;
}
  export const OverlayInteractionsContainer = styled.div<IOverlayInteractions>`
  display: ${props => props.show ? "block" : "none"};
  position: absolute;
  z-index: 11;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  `;