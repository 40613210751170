import { css } from "@emotion/react";
import { DataTable, DataTableProps } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";

export default (props: DataTableProps) => (
  <DataTable
    css={css`
      .p-datatable-tbody > tr > td {
        color: #6e6b7b;
        font-size: 12px;
        padding: 8px;
      }
      .p-datatable-wrapper {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .p-datatable-tbody > tr.p-highlight > td {
        color: #ffffff;
      }
      .p-datatable-header {
        padding: 0;
        border: none;
      }
      .p-datatable-thead > tr > th {
        padding: 12px;
        background: #eaeaea;
      }

      .p-paginator {
        background: #eaeaea;
        border-radius: 0px 0px 10px 10px;
      }

      .p-paginator .p-paginator-element {
        background-color: #f3f2f7;
        border-color: #f3f2f7;
        color: #6e6b7b;
        font-size: 12px;
      }

      .p-paginator .p-dropdown {
        margin-left: 10px;
        margin-right: 10px;
        height: auto;
      }

      .p-paginator .p-paginator-prev {
        border-radius: 29px;
        margin-right: 10px;
      }

      .p-paginator .p-paginator-next {
        border-radius: 29px;
        margin-left: 10px;
      }

      .p-paginator .p-paginator-pages {
        background: #f3f2f7;
        border-radius: 16px;
        padding: 4px;
      }

      .p-paginator .p-paginator-pages .p-paginator-page {
        height: auto;
        padding: 4px 12px;
        min-width: auto;
        margin: 0;
      }

      .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        border-radius: 29px;
        background-color: #eaeaea;
        border-color: #eaeaea;
        color: #0a043c;
      }
      .p-paginator
        .p-paginator-pages
        .p-paginator-page:not(.p-highlight):hover {
        border-radius: 29px;
      }
    `}
    paginatorTemplate={{
      layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
      RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 }
        ];

        return (
          <>
            <span
              css={css`
                user-select: none;
                color: #6e6b7b;
              `}
            >
              Por página:
            </span>

            <Dropdown
              value={options.value}
              options={dropdownOptions}
              onChange={options.onChange}
              css={css`
                .p-inputtext {
                  color: #6e6b7b;
                }
              `}
            />
          </>
        );
      }
    }}
    {...props}
  />
);
