import {
  BluePencil,
  PlayRed,
  Reload,
  WhatsIcon,
  createRoboImge,
  whatsapp,
  whatsappActive
} from "./../../../../../assets/index";
import { Checkbox } from "primereact/checkbox";
import { RefObject, useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
  ButtonReload,
  ButtonReloadDiv,
  CheckCard,
  ContainerLeftModal,
  ContainerQRCode,
  ContainerRightModal,
  ContainerTab,
  HiddenDiv,
  IconButton,
  Label,
  PhoneConected,
  PlayButton,
  RemoverButton,
  Span,
  Strong,
  Switch,
  ContainerButtons
} from "./styles";
import { Text } from "../../../../../components/Text";
import DialogPadrao from "../../../../../components/DialogPadrao";
import { ChatbotScript } from "../../ChatbotScript";
import { useAppSelector } from "../../../../../redux/hooks";
import { RightContentChatBotModal } from "../../chatBotModal/RightContent";
import { RightContentWhatsappModal } from "../../whatsappModal/RightContent";
import { LeftContentWhatsappModal } from "../../whatsappModal/LeftContent";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import BotsServices from "../../../../../services/bots";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../../../../components/toast-provider";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setModalFbLoginOpen } from "../../../../../redux/slices/flowInteractions";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
interface Qrcode {
  url: string;
}
interface Webhook {
  enabled: string;
  webhookUrl: string;
}
interface Browser {
  browser: string;
  platform: string;
  version: string;
}
interface IWhatsappInstance {
  browser: Browser;
  error: string;
  key: string;
  message: string;
  qrcode: Qrcode;
  webhook: Webhook;
}

interface IChannelName {
  name: string;
  img: string;
  checked: boolean;
  toastRef?: RefObject<Toast>;
  channelType?: string;
}
interface IWhatsappInstanceInfo {
  qrCodeConnected: boolean;
  name: string;
  phone: string;
  keepBotOnDialogInitiatedOnDevice: boolean;
}
interface IWhatsappImage {
  error: string;
  message: string;
  qrcode: string;
}
export const ChannelCheck: React.FC<IChannelName> = ({
  name,
  img,
  checked,
  channelType
}) => {
  // const [checked, setChecked] = useState<boolean>(false);
  const {
    GetWhatsappInstance,
    GetWhatsappInstanceInfo,
    UpdateWhatsappInfo,
    LogoutWhatsappInstance,
    GetWhatsappImage
  } = BotsServices();
  const bot = useAppSelector((state) => state.bot);
  const toast = useToast();

  const dispatch = useAppDispatch();
  const [typeModal, setTypeModal] = useState("FACEBOOK");
  const openModalFb = (type: string) => {
    setTypeModal(type);
    dispatch(setModalFbLoginOpen(true));
  };
  const openModalInstagram = (type: string) => {
    setTypeModal(type);
    dispatch(setModalFbLoginOpen(true));
  };

  const [whatsappInstance, setWhatsappInstance] = useState<IWhatsappInstance>(
    {} as IWhatsappInstance
  );
  const [whatsappInstanceInfo, setWhatsappInstanceInfo] =
    useState<IWhatsappInstanceInfo>({
      name: "",
      phone: "",
      keepBotOnDialogInitiatedOnDevice: false,
      qrCodeConnected: false
    });
  const [rotateIconComponent, setRotateIconComponent] = useState(false);

  const [displayModal, setDisplayModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [whatsappImage, setWhatsappImage] = useState<IWhatsappImage | null>({
    error: "",
    message: "",
    qrcode: ""
  });
  const botToken = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const sleep = async (ms: number) => {
    // eslint-disable-next-line no-new
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
  const getWhatsappImage = async () => {
    const imagem = await GetWhatsappImage(bot.id);
    setWhatsappImage(imagem);
    setRotateIconComponent(false);
  };
  const getWhatsappInstance = async () => {
    await GetWhatsappInstance(bot.id)
      .then(async (res) => {
        setWhatsappInstance(res);
        await sleep(2000);
        getWhatsappImage();
      })
      .catch(() => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao buscar dados. Por favor tente mais tarde!"
        });
      });
  };

  const getWhatsappInstanceInfo = async () => {
    if (bot.id > 0) {
      await GetWhatsappInstanceInfo(bot.id)
        .then((res) => {
          setWhatsappInstanceInfo({
            name: res.name,
            phone: res.phone,
            keepBotOnDialogInitiatedOnDevice:
              res.keepBotOnDialogInitiatedOnDevice,
            qrCodeConnected: res.qrCodeConnected
          });
          setWhatsappImage(null);
        })
        .catch(() => {
          toast?.current?.show({
            life: 5000,
            severity: "error",
            summary: "Erro",
            detail: "Erro ao buscar dados. Por favor tente mais tarde!"
          });
        });
    }
  };

  const handleRemover = async () => {
    await LogoutWhatsappInstance(bot.id)
      .then(() => {
        getWhatsappInstanceInfo();
        setWhatsappInstance({} as IWhatsappInstance);
        setWhatsappImage(null);
      })
      .catch(() => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao remover. Por favor tente mais tarde!"
        });
      });
  };

  const setValue = (e: string) => {
    setWhatsappInstanceInfo({
      ...whatsappInstanceInfo,
      name: e
    });
  };

  const setValueSwitch = (e: boolean) => {
    setWhatsappInstanceInfo({
      ...whatsappInstanceInfo,
      keepBotOnDialogInitiatedOnDevice: e
    });
  };

  useEffect(() => {
    if (displayModal) {
      getWhatsappInstanceInfo();
    }
  }, [bot.id, displayModal]);

  const handleRotateIcon = async () => {
    setRotateIconComponent(true);
    getWhatsappInstance();

    setTimeout(() => {
      setRotateIconComponent(false);
      setWhatsappImage({
        error: "",
        message: "",
        qrcode: ""
      });
    }, 60000);
  };

  const handleCheckedIcon = (channelType: string | undefined) => {
    if (channelType === "INSTAGRAM") {
      openModalInstagram("INSTAGRAM");
    } else if (channelType === "FACEBOOK") {
      openModalFb("FACEBOOK");
    } else {
      setDisplayModal(true);
    }
  };

  const handleUpdateChanges = async () => {
    const data = {
      name: whatsappInstanceInfo.name,
      keepBotOnDialogInitiatedOnDevice:
        whatsappInstanceInfo.keepBotOnDialogInitiatedOnDevice
    };
    try {
      await UpdateWhatsappInfo(bot.id, data);
      toast.current?.show({
        life: 5000,
        severity: "success",
        summary: "Sucesso",
        detail: "As informações foram atualizadas com sucesso."
      });
    } catch (error) {
      toast.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Erro ao atualizar. Por favor tente mais tarde!"
      });
    }
  };

  return (
    <div>
      <div css={CheckCard} className="relative field-checkbox">
        {checked && (
          <IconButton>
            <img
              width="10px"
              onClick={() => handleCheckedIcon(channelType)}
              src={BluePencil}
              alt="Edit Icon"
            />
          </IconButton>
        )}
        <img
          style={{ maxWidth: "1.875rem", maxHeight: "1.875rem" }}
          src={img}
          alt={`img-${name}`}
        />
        <Label htmlFor={name}>{name}</Label>
        <HiddenDiv>
          <Checkbox inputId={name} checked={checked} />
        </HiddenDiv>
      </div>
      <DialogPadrao
        displayDialog={displayModal}
        contentStyle={{ height: "420px" }}
        onHide={() => setDisplayModal(false)}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
      >
        <ContainerLeftModal>
          <TabView
            style={{ position: "sticky", top: 0 }}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel
              headerStyle={{ width: "100%" }}
              contentStyle={{
                border: 0,
                padding: 0,
                height: "100%"
              }}
              header="QRCode"
              // headerTemplate={dialogTabTemplate}
            >
              <ContainerTab>
                {whatsappInstanceInfo?.qrCodeConnected ? (
                  <div className="flex flex-column gap-4 mt-6 w-full h-18rem">
                    <span className="p-float-label w-full">
                      <InputText
                        style={{ width: "100%" }}
                        id="in"
                        value={whatsappInstanceInfo?.name}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <label htmlFor="in">Responsável</label>
                    </span>
                    <div className="relative w-full">
                      <span className="p-float-label">
                        <InputText
                          style={{ width: "100%" }}
                          id="in"
                          value={whatsappInstanceInfo?.phone}
                        />
                        <label htmlFor="in">Telefone</label>
                        <PhoneConected>
                          <img src={whatsapp} alt="Whatsapp icon" />
                          <Text
                            s="1rem"
                            lh="1.5rem"
                            weight={400}
                            color="#02B960"
                          >
                            Ativo
                          </Text>
                        </PhoneConected>
                      </span>
                    </div>
                    <Switch>
                      <InputSwitch
                        checked={
                          whatsappInstanceInfo?.keepBotOnDialogInitiatedOnDevice
                        }
                        onChange={(e) => setValueSwitch(e.target.value)}
                      />
                      Manter o bot ativo em conversas iniciadas pelo dispositivo
                      móvel.
                    </Switch>
                    <div className="flex align-items-center justify-content-center w-full gap-3">
                      <Span>?</Span>
                      <Text
                        style={{ textAlign: "left" }}
                        color="#4C5862"
                        lh="1.3125rem"
                        s="0.875rem"
                      >
                        Lembre-se de usar o celular uma vez a cada{" "}
                        <Strong>14 dias</Strong> para ficar sempre conectado.
                      </Text>
                    </div>
                    <ContainerButtons>
                      <RemoverButton onClick={handleRemover}>
                        Remover
                      </RemoverButton>
                      <Button
                        label="Salvar"
                        style={{ width: "160px", height: "42px" }}
                        onClick={handleUpdateChanges}
                      />
                    </ContainerButtons>
                  </div>
                ) : (
                  <>
                    <div className="flex gap-2">
                      <img src={WhatsIcon} alt="WhatsIcon" />
                      <div>
                        <Text
                          style={{ textAlign: "left" }}
                          weight={600}
                          lh="20px"
                          s="0.875rem"
                          color="#0A043C"
                        >
                          Todas as conversas em um único lugar!
                        </Text>
                        <Text
                          style={{ textAlign: "left" }}
                          weight={400}
                          lh="18px"
                          s="0.75rem"
                          color="#544F77"
                        >
                          Para integrar essa ferramenta incrível, basta conectar
                          o seu celular, vamos integrar todas as mensagens do
                          seu Bot em um único local.
                        </Text>
                      </div>
                    </div>
                    <div className="flex align-items-center justify-content-center">
                      <img src={createRoboImge} alt="Robot Image" />
                    </div>
                  </>
                )}
              </ContainerTab>
            </TabPanel>
            <TabPanel
              headerStyle={{ width: "100%" }}
              contentStyle={{ padding: 0, height: "100%" }}
              header="Meta"
              // headerTemplate={dialogTabTemplate}
            >
              <ContainerTab>
                {name === "Chatbot" && <ChatbotScript botToken={botToken} />}
                {name === "WhatsApp" && (
                  <LeftContentWhatsappModal show={setDisplayModal} />
                )}
              </ContainerTab>
            </TabPanel>
          </TabView>
        </ContainerLeftModal>
        {/* <div className="pl-2 pr-2 rigtContentFake">Hello dialog {name}</div> */}
        {/* <div className="flex justify-content-center h-full bg-red-500 w-full"> */}
        <ContainerRightModal>
          {activeIndex === 1 ? (
            <>
              <div></div>
              {name === "Chatbot" ? (
                <RightContentChatBotModal />
              ) : (
                <RightContentWhatsappModal />
              )}
            </>
          ) : (
            <div className="flex flex-column align-items-center">
              <Text weight={600} color="#FFF">
                {" "}
                Leia o QRCode para atualizar o número do celular.
              </Text>
              <ContainerQRCode>
                {whatsappInstanceInfo?.phone ? (
                  <div className="flex flex-column align-items-center justify-content-center">
                    <img
                      style={{ maxWidth: "95%", maxHeight: "95%" }}
                      src={whatsappActive}
                      alt="Qr code example"
                    />
                  </div>
                ) : whatsappImage?.qrcode ? (
                  <img
                    src={whatsappImage?.qrcode}
                    alt="Qr code example"
                    css={css`
                      height: 100%;
                      width: 100%;
                    `}
                  />
                ) : (
                  <div className="flex flex-column align-items-center justify-content-center">
                    <ButtonReload
                      rotateIcon={rotateIconComponent}
                      onClick={handleRotateIcon}
                      src={Reload}
                      alt="reload-button"
                    />
                    <div
                      css={ButtonReloadDiv}
                      onClick={handleRotateIcon}
                      className="mt-2"
                    >
                      {rotateIconComponent
                        ? "Gerando QRCode"
                        : "Gerar QRCode..."}
                    </div>
                  </div>
                )}
              </ContainerQRCode>
              <Text weight={400} color="#FFF" lh="1.25rem">
                {whatsappInstanceInfo?.phone
                  ? `Agora você já pode receber mensagnes diretamente do seu celular.`
                  : `O scanner do WhastApp está num lugar incomum! Para achá-lo, clique em Menu > Dispositivos conectados > Conectar um aparelho.`}
              </Text>
              <div className="flex gap-2 mt-2">
                <PlayButton>
                  <img
                    style={{ marginLeft: "0.14rem" }}
                    src={PlayRed}
                    alt="Icon-Play-Video"
                  />
                </PlayButton>
                <Text s="12px" weight={500} lh="16px" color="#FAFBFC">
                  Confira o vídeo
                </Text>
              </div>
            </div>
          )}
        </ContainerRightModal>

        {/* </div> */}
      </DialogPadrao>
    </div>
  );
};
