import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 1rem;
`;

export const DivIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  mix-blend-mode: normal;
`;
export const Img = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

export const ImgFilter = styled.img`
  width: 1rem;
  position: absolute;
  top: 30%;
  left: 12px;
`;
interface IChip {
  bgColor?: string;
}
export const Chip = styled.div<IChip>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  height: 2rem;
  width: 5.8125rem;
  background: ${(props) => (props.bgColor ? props.bgColor : " #EAEAEA")};
`;

export const HeaderTable = css`
  @media screen and (max-width: 599px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }
`;

export const Options = css`
  @media screen and (max-width: 599px) {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
`;

export const DivDataTable = css`
  // background: purple;
  // // background-color: #ffffff;
  // border-radius: 10px 10px 0px 0px;
  // padding: 8px 12px;
  // display: flex;
  // gap: 0.5rem;
  // flex-wrap: wrap;
`;
