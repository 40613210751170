import { ReactNode } from "react";
import { Card, Img } from "./styles";
import { NextLaunch as NextLaunchImage } from "../../../../../assets";
import { Text } from "../../../../../components/Text";
interface ICardHome {
  children: ReactNode;
  title: string;
  subtitle: string;
  proximoLancamento?: boolean;
  onClick?: () => void;
}
export const CardHome: React.FC<ICardHome> = ({
  children,
  title,
  subtitle,
  proximoLancamento,
  onClick
}) => {
  return (
    <Card cursor={!!onClick} onClick={onClick}>
      {proximoLancamento ? (
        <Img src={NextLaunchImage} alt="Next-Launch-Advisor" />
      ) : null}
      <Text s="0.875rem" weight={600} lh="1.5rem" color="#0A043C">
        {title}
      </Text>
      <Text s="0.75rem" weight={300} lh="1rem" color="#544F77">
        {subtitle}
      </Text>
      <div className="flex align-items-center justify-content-center max-h-13rem">
        {children}
      </div>
    </Card>
  );
};
