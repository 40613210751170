/* eslint-disable func-style */
import { useFetch2 } from "../util";


export interface Payload {
  position: number;
  label: string;
  type: string;
  link: string;
}
interface Response {
  message: string;
  payload: Payload[];
}
export interface returnDialogs {
  responses: Response[];
  dialogToken: string;
  botToken: string;
  description?: string
}
interface IPostDialogs {
  message: string[];
  dialogToken: string | null;
  botToken: string;
}
export interface IRequestBotDialogTest {
    message: string[],
    dialogToken: string | null,
    botToken: string
}
function BotDialogTestServices() {
  const useQuery = useFetch2();
  

  // this code just return a promise function to who is calling a function.
  
  const botDialogTest = async (data: IRequestBotDialogTest) => {
    try {
      const method = { method: "POST" }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return await useQuery(`/api/dialogs/bot-dialog-test`, method, data)
    } catch (error) {
      return error
    }
  }

  const postDialogsSimulator = async (data: IPostDialogs): Promise<returnDialogs> => {
    try {
      const method = { method: "POST" }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return await useQuery(`/api/dialogs/simulator`, method, data)
    } catch (error) {
      return error
    }
  }

  return {
    botDialogTest,
    postDialogsSimulator
  }
}

export default BotDialogTestServices
