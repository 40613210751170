import { useAppDispatch } from "../redux/hooks";
import { setUserName } from "../redux/slices/user";
import { differenceInMilliseconds, parseISO } from "date-fns";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useUsuario from "../use-usuario";
import {
  setRWorkspaces,
  setSelectedWorkspaces
} from "../redux/slices/workspaces";
import Cookies from "universal-cookie";
import { setRChatBots } from "../redux/slices/chatbots";
import WorkspaceUserService from "../services/workspaceUserService";
import { iWorkspaces } from "../types/iWorkspaces";

export interface IWorkspacesTree {
  key: number;
  label: string;
  parentId: number;
  parents: number;
  children: [string];
}

interface IWorkspaces {
  workspacesTree: IWorkspacesTree[];
  workspaces: { id: number; name: string }[];
}

export interface AuthContextUserPlataformType {
  name: string;
  displayName: string;
}

export interface AuthContextUserType {
  id: number;
  name: string;
  companyId: number;
  companyName: string;
  admin: boolean;
  email: string;
  image: string;
  expires: Date;
  workspaces?: IWorkspaces;
  profiles?: string[];
  permissions: string[];
  plataform: AuthContextUserPlataformType;
}

interface AuthContextCompanyType {
  id: number;
  name: string;
}

export interface AuthContextType {
  user: AuthContextUserType;
  login: (name: string, password: string) => Promise<void>;
  logout: () => void;
  company: AuthContextCompanyType;
  setCompany: (company: AuthContextCompanyType) => void;
  hasAnyPermission: (permission: string | string[]) => boolean;
  setWorkspaces: (workspaces: IWorkspaces) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthProvider = (props: any) => {
  const { getUserWorkspaces2 } = WorkspaceUserService();
  const [user, setUser] = useState<AuthContextUserType>();
  const location = useLocation();
  const { hash } = useLocation();
  const [userVerified, setUserVerified] = useState(false);
  const [companyAdmin, setCompanyAdmin] = useState<AuthContextCompanyType>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { auth, refreshToken } = useUsuario();
  const refreshSessionTimeoutRef = useRef<NodeJS.Timeout>();
  const cookies = new Cookies();

  const clearRefreshSessionTimeout = () => {
    if (refreshSessionTimeoutRef?.current) {
      clearTimeout(refreshSessionTimeoutRef.current);
    }
  };

  const setRefreshSessionTimeout = (user: AuthContextUserType) => {
    clearRefreshSessionTimeout();

    refreshSessionTimeoutRef.current = setTimeout(
      // eslint-disable-next-line no-use-before-define
      () => timeredFn(user),
      differenceInMilliseconds(user.expires, new Date())
    );
  };

  const timeredFn = (user: AuthContextUserType) => {
    refreshToken()
      .then((res) => {
        // eslint-disable-next-line no-negated-condition
        if (user?.id !== res?.user?.id) {
          // eslint-disable-next-line no-use-before-define
          updateUser(res.user);
        }
        // else update just timered function
        else {
          const updateUserRefresh = {
            ...user,
            expires: parseISO(res.user.expires)
          };
          setUserVerified(true);
          dispatch(
            setRWorkspaces(user.workspaces?.workspaces as iWorkspaces[])
          );

          setUser(updateUserRefresh as AuthContextUserType);
          setRefreshSessionTimeout(updateUserRefresh as AuthContextUserType);
          // if(location.pathname?.length) {
          //   navigate(location.pathname, {replace: true});
          // }
        }
      })
      .catch(() => {
        setUser(undefined);
        dispatch(setUserName(""));
        setUserVerified(true);
      });
  };

  const updateUser = async (user: AuthContextUserType) => {
    const userLocal = user;
    const companyAdmin = JSON.parse(localStorage.getItem("company") as string);
    setCompanyAdmin(companyAdmin);
    if (userLocal.admin && companyAdmin) {
      const workspaces = await getUserWorkspaces2(companyAdmin.id);
      setUser({ ...userLocal, expires: userLocal.expires, workspaces });
      dispatch(setRWorkspaces(workspaces.workspces));
      dispatch(setUserName(userLocal?.name));
      if (location.pathname?.length) {
        setUserVerified(true);
        setRefreshSessionTimeout({
          ...userLocal,
          expires: userLocal.expires,
          workspaces
        });
        navigate(`${location.pathname}${location.search}${hash}`, {
          replace: true
        });
      } else {
        setUserVerified(true);
      }
    } else {
      const workspacesWithTree = await getUserWorkspaces2();
      dispatch(setRWorkspaces(workspacesWithTree.workspces));
      setUser({
        ...userLocal,
        expires: userLocal.expires,
        workspaces: workspacesWithTree
      });
      dispatch(setUserName(userLocal?.name));
      if (location.pathname?.length) {
        setUserVerified(true);
        setRefreshSessionTimeout({
          ...userLocal,
          expires: userLocal.expires,
          workspaces: workspacesWithTree
        });
        navigate(`${location.pathname}${location.search}${hash}`, {
          replace: true
        });
      } else {
        setUserVerified(true);
      }
    }
  };

  useEffect(() => {
    const company =
      JSON.parse(localStorage.getItem("company") as string) || companyAdmin;
    if (user?.admin && company) {
      setCompanyAdmin(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyRefreshToken = async () => {
    const rToken = cookies.get("refresh-token");
    if (rToken !== undefined && rToken !== null && rToken?.length) {
      try {
        const { user: userLocal } = await refreshToken();
        userLocal.expires = parseISO(userLocal.expires);
        if (user?.id !== userLocal.id) {
          updateUser(userLocal);
        }
      } catch (e) {
        setUser(undefined);
        dispatch(setUserName(""));
        setUserVerified(true);
      }
    } else {
      setUserVerified(true);
    }
  };

  useEffect(() => {
    if (user) {
      setUserVerified(true);
    } else {
      verifyRefreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (name: string, password: string) => {
    try {
      const response = await auth({ name, password });

      if (response?.user) {
        response.user.expires = parseISO(response.user?.expires);
        const workspacesWithTree = await getUserWorkspaces2();

        setUser({
          ...response.user,
          workspaces: workspacesWithTree
        });
        dispatch(setUserName(response.user?.name));
        // localStorage.setItem("user", JSON.stringify({
        //   ...response.user,
        //   workspaces: workspacesWithTree}));

        setRefreshSessionTimeout({
          ...response.user,
          workspaces: workspacesWithTree
        });
      }
      return response;
    } catch (e) {
      setUser(undefined);
      dispatch(setUserName(""));
      dispatch(setRWorkspaces([]));
      setCompanyAdmin(undefined);
      localStorage.removeItem("company");
      // localStorage.removeItem("user");
      // setUserVerified(true);
      return e;
    }
  };

  if (!userVerified) {
    return null;
  }

  const logout = async () => {
    clearRefreshSessionTimeout();
    cookies.remove("jwt");
    cookies.remove("refresh-token");
    dispatch(setRWorkspaces([]));
    dispatch(setRChatBots([]));
    dispatch(setSelectedWorkspaces([]));
    dispatch(setUserName(""));
    setCompanyAdmin(undefined);
    setUser(undefined);
    localStorage.removeItem("company");
    // localStorage.removeItem("user");
    navigate("/login");
  };

  const hasAnyPermission = (permission: string | string[]) => {
    if (!permission) {
      return true;
    }
    if (typeof permission === "string") {
      return user?.permissions?.includes?.(permission);
    }

    return user?.permissions?.some?.((p) => permission.includes(p));
  };

  const setWorkspaces = (workspaces: IWorkspaces) => {
    if (user) {
      setUser({
        ...user,
        workspaces
      });
    }
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{
        user,
        login,
        logout,
        company: companyAdmin,
        setCompany: setCompanyAdmin,
        setWorkspaces,
        hasAnyPermission
      }}
    />
  );
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = ({
  children,
  permission
}: {
  children: JSX.Element;
  permission?: string | string[];
}) => {
  const { user, company, hasAnyPermission } = useAuth() as AuthContextType;
  const location = useLocation();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }

  if (!user.admin && permission && !hasAnyPermission(permission)) {
    return <Navigate to="/401" state={{ from: location }} replace={true} />;
  }

  if (
    user.admin &&
    !company &&
    !/(?:login|forgot|confirm-reset-password|confirm-subscription|subscribe|user-invite|subscribe-success|reset-password|avatar|password|change-company|company)/u.test(
      location.pathname
    )
  ) {
    return (
      <Navigate
        to="/change-company"
        state={{ from: location }}
        replace={true}
      />
    );
  }

  return children;
};
