import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = css`
  width: 100%;
  min-width: 411px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  align-items: center;
  height: 546px;
`;

export const Identification = css`
  position: relative;
  margin: 1rem;
  width: 90%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
`;

export const ContainerImg = css`
  margin: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 2px solid #046af3;
`;
export const ButtonPencil = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: absolute;
  right: -4px;
  top: 1px;
`;

export const ImgBluePencil = css`
  width: 11px;
`;
export const BotName = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #0a043c;
`;

export const BotNumberId = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 6px;

  color: #046af3;
`;

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin-bottom: 1rem;
`;
interface ISignal {
  color: string;
}
export const svgfill = styled.img`
  fill: ldfjlfkds;
`;
export const Signal = styled.div<ISignal>`
  margin: 1rem;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
export const SignalName = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #4c5862;
`;

export const Region = css`
  display: flex;
  align-items: center;
  height: 48px;
  width: 90%;
  padding: 0.5rem;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #4c5862;
  margin-bottom: 0.7rem;
`;

export const CreatedBycss = css`
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 1.25rem;
  width: 90%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  color: #4c5862;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  padding: 0.8rem;
`;

export const Delete = css`
  border: 1px solid #ff2c55;
  margin-bottom: 1rem;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  padding: 0.8rem;
  color: #ff2c55;
  width: 90%;
  align-items: center;
  background: #ffffff;
  justify-content: center;
  text-align: center;
  transition: all 0.4s;
  :hover {
    cursor: pointer;
    background-color: #d5d5d5;
    transition: 0.2s ease-in-out;
  }
`;

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  height: 694px;
  width: 50%;
  position: relative;
  height: 100%;
  padding: 0.5rem;
`;
export const ContainerRightModal = styled.div`
  width: 50%;
  position: relative;
  height: 100%;
  background-color: #4a6690;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const CardCenter = styled.div`
  position: relative;
  width: 352px;
  /* height: 177px; */
  margin: 2rem auto;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  background-color: #fff;
`;
export const CardRoundedAvatar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  left: calc(50% - 42px);
  top: -2rem;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #d4cef8;
`;
export const CardContentAvatar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #efefef;
`;
export const InputContainer = styled.div`
  width: calc(100% - 1rem);
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: end;
  justify-content: center;
  height: 12%;
`;

export const LeftHeaderWhiter = styled.div`
  width: 50%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3rem;
`;

export const Baloon = css`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 16px;
  padding: 1rem;
  padding-right: 3rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  position: relative;
`;
export const ImageSixPoints = css`
  margin-right: 0.5rem;
`;
export const Input = styled.input`
  width: 100%;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4c5862;
`;
export const ButtonPlus = css`
  position: absolute;
  width: 25px;
  height: 25px;
  right: 1.5rem;
  top: 40%;
  background-color: #d8e9f6;
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;

export const ButtonTrash = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: -0.6rem;
  top: 40%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;

export const Flex = css`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
`;

export const Span = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;

export const ContainerFields = styled.div`
  position: relative;
  padding: 1rem;
  margin-top: 0.5rem;
  width: calc(100% - 1rem);
  /* height: 104px; */
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
export const LabelContainerFields = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  top: -0.55rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: #fff;
  left: 1rem;
`;

interface IMyIconButton {
  color: string;
}
export const MyIconButton = styled.div<IMyIconButton>`
  color: ${(props) => props.color};
  height: 1.85rem;
  gap: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 50%;
  margin-left: 0.2rem;
  transition: all 0.2s;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  :hover {
    border: ${(props) => `2px solid ${props.color}`};
    background-color: #ff89a0;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
`;

export const Token = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 1rem);
  margin: 1.5rem 0.5rem 2rem 0.5rem;

  .divCopy {
    display: flex;
    cursor: pointer;
    padding: 0.4rem 0.6rem;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-left: none;
    border-radius: 4px;
  }

  .divCopy:active {
    background-color: #d9d9d9;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const ImgIcon = styled.button`
  border: none;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
