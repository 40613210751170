import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Button, InputText, PoliticaPrivacidadeConfirmar } from "../components";
import Plataform from "../services/Plataform";
import { background, botRed, dialogosInteligentes } from "./../assets";

export default () => {
  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            width: 400px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={botRed} />
            <div>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                Olá, Reges,
              </span>
              <div>agora vamos redefinir a sua senha 😀</div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              width: 100%;
              margin-top: 20px;
            `}
          >
            <span>Entre com a sua nova senha e confirmação para concluir.</span>
          </div>

          <div
            css={css`
              margin-top: 20px;
              width: 100%;
            `}
          >
            <form>
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div className="p-float-label">
                  <InputText id="passwd" />
                  <label htmlFor="passwd">Nova senha</label>
                </div>

                <div className="p-float-label">
                  <InputText id="confirm" />
                  <label htmlFor="confirm">Confirmar senha</label>
                </div>

                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="Atualizar senha"
                />
                <div
                  css={css`
                    color: "#046AF3";
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <i
                    css={css`
                      color: #046af3;
                    `}
                    className="pi pi-arrow-left"
                  ></i>
                  <Link to="/login">Voltar para o login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
