/* eslint-disable no-param-reassign */
import {
  ICustomButtons,
  ITransferNodeDTO,
  iFiles,
  iRejectedFiles
} from "./../../components/react-flow-renderer/NodeData.d";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  applyNodeChanges,
  Node,
  NodeChange,
  getIncomers,
  getOutgoers,
  Edge,
  EdgeProps,
  Connection
} from "react-flow-renderer";
import {
  IRobotInteraction,
  IUserInteraction,
  NodeData
} from "../../components/react-flow-renderer/NodeData";
import { getLevels } from "../../util/updateLevels";

interface IChangesRobotInteraction extends IRobotInteraction {
  nodeId: string;
}
interface ILevelsCount {
  nodeId: string;
  incomers: Node<NodeData>[];
  incomersLength: number;
  outgoers: Node<NodeData>[];
  outgoersLength: number;
  outgoersIds: string[];
  visited: boolean;
  incomersIds: string[];
  level: number;
}

interface IChangesUserInteraction extends IUserInteraction {
  nodeId: string;
  userInteractionId: string;
}
const initialState: Node<NodeData>[] = [];

const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    setRejectedFiles(
      state,
      action: PayloadAction<{
        nodeId: string;
        botId: string;
        rejectedFiles: iRejectedFiles[];
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botId
      );
      if (node && bi) {
        if (bi.rejectedFiles?.length) {
          bi.rejectedFiles = [
            ...bi.rejectedFiles,
            ...action.payload.rejectedFiles
          ];
        }
        bi.rejectedFiles = action.payload.rejectedFiles;
      }
    },
    eraseRejectedFiles(
      state,
      action: PayloadAction<{
        nodeId: string;
        botId: string;
        rejectedFileId: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botId
      );
      if (node && bi) {
        if (bi.rejectedFiles?.length) {
          bi.rejectedFiles = bi.rejectedFiles.filter(
            (rf) => rf.id !== action.payload.rejectedFileId
          );
        }
      }
    },
    setCustomButtons(
      state,
      action: PayloadAction<{
        nodeId: string;
        botInteractionId: string;
        customButtons: ICustomButtons[];
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botInteractionId
      );
      if (node && bi) {
        bi.buttons = action.payload.customButtons;
      }
    },
    addFiles(
      state,
      action: PayloadAction<{ nodeId: string; botId: string; files: iFiles }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      let BotInteraction;
      if (node?.data.botInteractions.length === 1) {
        BotInteraction = node?.data.botInteractions.find((el) => el);
      }
      // else if (node?.data.botInteractions.length >== 1 && no)
      else {
        BotInteraction = node?.data.botInteractions.find(
          (el) => el.id === action.payload.botId
        );
      }
      if (node && BotInteraction) {
        // eslint-disable-next-line no-negated-condition
        if (!BotInteraction.files) {
          BotInteraction.files = [action.payload.files];
        } else {
          BotInteraction.files = [
            ...(BotInteraction.files as iFiles[]),
            action.payload.files
          ];
        }
      }
    },
    excludeFiles(
      state,
      action: PayloadAction<{ nodeId: string; id: string; fileId: number }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const botInteraction = node?.data.botInteractions.find(
        (bi) => bi.id === action.payload.id
      ) as IRobotInteraction;
      if (node) {
        // eslint-disable-next-line no-negated-condition
        botInteraction.files = botInteraction?.files?.filter(
          (el) => el.fileId !== action.payload.fileId
        );
      }
    },
    setwebhookCall(
      state,
      action: PayloadAction<{ nodeId: string; webhookCall: boolean }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.webhookCall = action.payload.webhookCall;
      }
    },
    addCustomButton(
      state,
      action: PayloadAction<{
        nodeId: string;
        botInteractionId: string;
        label: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botInteractionId
      );
      const maxNumber = bi?.buttons?.reduce((acc, el) => {
        if (el.position > acc) {
          return el.position;
        }
        return acc;
      }, 0);
      if (node && bi && maxNumber) {
        bi.buttons = bi?.buttons?.concat({
          position: maxNumber + 1,
          label: action.payload.label,
          type: "QUICK_REPLY"
        });
      } else if (bi) {
        // eslint-disable-next-line no-return-assign
        bi.buttons = [
          {
            position: 1,
            label: action.payload.label,
            type: "QUICK_REPLY"
          }
        ];
      }
    },
    updateCustomButton(
      state,
      action: PayloadAction<{
        nodeId: string;
        botInteractionId: string;
        position: number;
        label: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botInteractionId
      );
      if (node && bi) {
        bi.buttons = bi?.buttons?.map((btn) => {
          if (btn.position === action.payload.position) {
            return {
              position: btn.position,
              label: action.payload.label,
              type: "QUICK_REPLY"
            };
          }
          return btn;
        });
      }
    },
    deleteAllCustomButtons(
      state,
      action: PayloadAction<{ nodeId: string; botInteractionId: string }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botInteractionId
      );
      if (node && bi) {
        bi.buttons = [];
      }
    },
    deleteCustomButton(
      state,
      action: PayloadAction<{
        nodeId: string;
        botInteractionId: string;
        position: number;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const bi = node?.data?.botInteractions?.find(
        (bi) => bi.id === action?.payload?.botInteractionId
      );
      if (node && bi) {
        bi.buttons = bi?.buttons?.filter(
          (btn) => btn.position !== action.payload.position
        );
      }
    },
    onNodesChange(state, action: PayloadAction<NodeChange[]>) {
      return applyNodeChanges(action.payload, state);
    },
    updateNodeColor(
      state,
      action: PayloadAction<{ nodeId: string; color: string }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.color = action.payload.color;
      }
    },
    changeLabel(
      state,
      action: PayloadAction<{ nodeId: string; label: string }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.label = action.payload.label;
      }
    },
    organizeNodes(state, action: PayloadAction<Edge<EdgeProps>[]>) {
      const NodesChildren = state
        .map((_node) => {
          return {
            nodeId: _node.id,
            incomers: getIncomers(_node, state, action.payload),
            incomersIds: getIncomers(_node, state, action.payload)
              .map((__node) => __node.id)
              .filter((notnulls) => notnulls !== null),
            outgoersIds: getOutgoers(_node, state, action.payload)
              .map((__node) => __node.id)
              .filter((notnulls) => notnulls !== null),
            incomersLength:
              getIncomers(_node, state, action.payload).length || 0,
            outgoers: getOutgoers(_node, state, action.payload),
            outgoersLength:
              getOutgoers(_node, state, action.payload).length || 0,
            visited: false,
            level: -1,
            hlevel: -1
          };
        })
        .sort((a, b) => a.incomersLength - b.incomersLength);
      const updateLevel2 = (node: ILevelsCount, level: number): number => {
        const nodeHasParent = node.incomersLength > 0;
        if (nodeHasParent) {
          // eslint-disable-next-line prefer-destructuring
          const FirstParentNode = NodesChildren.filter(
            (_node) => _node.nodeId === node.incomersIds[0]
          )[0];
          return updateLevel2(FirstParentNode, level + 1);
        }
        return level + 1;
      };
      // eslint-disable-next-line array-callback-return
      NodesChildren.find((_node) => {
        _node.level = updateLevel2(_node, _node.level);
      });
      const length = Math.max(...NodesChildren.map((_node) => _node.level));
      const ArrayCount = new Array(length + 1).fill(0);
      // eslint-disable-next-line array-callback-return
      NodesChildren.map((node) => {
        ArrayCount[node.level] += 1;
      });
      const x = new Array(length + 1).fill(0);
      state.find((node) => {
        NodesChildren.find((_node) => {
          if (_node.nodeId === node.id) {
            node.data.level = _node.level;
            node.position = {
              x:
                -(ArrayCount[_node.level] * 240) / 2 + 240 * x[_node.level] ||
                0,
              y: _node.level * 130
            };
            x[_node.level] += 1;
          }
          return null;
        });
        return null;
      });
    },
    setLevels(state, action: PayloadAction<Edge<EdgeProps>[]>) {
      const levels = getLevels({ nodes: state, edges: action.payload });
      return state.map((_node) => {
        return {
          ..._node,
          data: {
            ..._node.data,
            level: levels.filter((el) => el.nodeId === _node.id)[0].level
          }
        };
      });
      // let NodesChildren = state
      //   .map((_node) => {
      //     return {
      //       nodeId: _node.id,
      //       incomers: getIncomers(_node, state, action.payload),
      //       incomersIds: getIncomers(_node, state, action.payload)
      //         .map((__node) => __node.id)
      //         .filter((notnulls) => notnulls !== null),
      //       outgoersIds: getOutgoers(_node, state, action.payload)
      //         .map((__node) => __node.id)
      //         .filter((notnulls) => notnulls !== null),
      //       incomersLength:
      //         getIncomers(_node, state, action.payload).length || 0,
      //       outgoers: getOutgoers(_node, state, action.payload),
      //       outgoersLength:
      //         getOutgoers(_node, state, action.payload).length || 0,
      //       visited: false,
      //       level: -1,
      //       hlevel: -1
      //     };
      //   })
      //   .sort((a, b) => a.incomersLength - b.incomersLength);
      // const updateLevel2 = (node: ILevelsCount, level: number): number => {
      //   const nodeHasParent = node.incomersLength > 0;
      //   if (nodeHasParent) {
      //     const FirstParentNode = NodesChildren.filter(
      //       (_node) => _node.nodeId === node.incomersIds[0]
      //     )[0];
      //     return updateLevel2(FirstParentNode, level + 1);
      //   } else {
      //     return level + 1;
      //   }
      // };
      // NodesChildren.find((_node) => {
      //   _node.level = updateLevel2(_node, _node.level);
      // });
      // const length = Math.max(...NodesChildren.map((_node) => _node.level));
      // let ArrayCount = new Array(length + 1).fill(0);
      // NodesChildren.map((node) => {
      //   ArrayCount[node.level] += 1;
      // });
      // let x = new Array(length + 1).fill(0);
      // state.find((node) => {
      //   NodesChildren.find((_node) => {
      //     if (_node.nodeId === node.id) {
      //       node.data.level = _node.level;
      //     }
      //   });
      // });
    },
    changeNode(
      state,
      action: PayloadAction<{ nodeId: string; node: Node<NodeData> }>
    ) {
      return state.map((node) => {
        if (node.id === action.payload.nodeId) {
          return action.payload.node;
        }
        return node;
      });
    },
    addNode(state, action: PayloadAction<{ node: Node<NodeData> }>) {
      state.push(action.payload.node);
    },
    unSelectAll(state) {
      return state.map((_node) => {
        return {
          ..._node,
          selectable: true,
          selected: false
        };
      });
    },
    updatePos(state) {
      // eslint-disable-next-line array-callback-return
      state.find((node) => {
        node.position = {
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight
        };
      });
    },
    addRobotInteraction(
      state,
      action: PayloadAction<{ nodeId: string; interactions?: any[] }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const objectAdd = {
        id: (Math.random() * 10000000).toString(),
        text: "",
        entityTypeId: -1,
        entityName: "",
        sourceEntityNodeId: ""
      };
      if (node) {
        if (action.payload.interactions) {
          node.data.botInteractions = action.payload.interactions;
        } else {
          if (node.data?.botInteractions) {
            node.data.botInteractions =
              node.data.botInteractions.concat(objectAdd);
          } else {
            node.data.botInteractions = [objectAdd];
          }
        }
      }
    },
    deleteRobotInteraction(
      state,
      action: PayloadAction<IChangesRobotInteraction>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        const updatedBotInteractions = node.data?.botInteractions?.filter(
          (inter) => {
            return inter.id !== action.payload.id;
          }
        );
        node.data.botInteractions = updatedBotInteractions;
        node.selected = false;
      }
    },
    setAttribute(
      state,
      action: PayloadAction<{
        entityTypeId: number;
        entityTypeName: string;
        nodeId: string;
        sourceEntityNodeId: string;
        botInteractionID: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        const botInteraction = node?.data?.botInteractions?.find(
          (_botInteraction) =>
            _botInteraction.id === action.payload.botInteractionID
        );
        if (botInteraction) {
          botInteraction.sourceEntityNodeId = action.payload.sourceEntityNodeId;
        }
      }
    },
    updateRobotInteraction(
      state,
      action: PayloadAction<IChangesRobotInteraction>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.botInteractions = node?.data?.botInteractions?.map(
          (inter) => {
            if (inter.id === action.payload.id) {
              return {
                id: action.payload.id,
                text: action.payload.text,
                buttons: action.payload.buttons,
                entityName: action.payload.entityName,
                entityTypeId: action.payload.entityTypeId,
                sourceEntityNodeId: action.payload.sourceEntityNodeId,
                files: action.payload.files
              };
            }
            return inter;
          }
        );
      }
    },
    addManyUserInteractions(state, action: PayloadAction<IUserInteraction[]>) {
      const node = state.find((_node) => _node?.selected);
      if (node && Boolean(node?.data?.userInteractions)) {
        node.data.userInteractions.concat(action.payload);
      }
      if (node && !node?.data?.userInteractions) {
        node.data.userInteractions = action.payload;
      }
    },
    updateUserInteractions(state, action: PayloadAction<IUserInteraction[]>) {
      const node = state.find((_node) => _node?.selected);
      if (node) {
        node.data.userInteractions = action.payload;
      }
    },
    addUserInteractions(state, action: PayloadAction<IUserInteraction>) {
      const node = state.find((_node) => _node?.selected);
      if (node) {
        if (!node?.data?.userInteractions[0].source) {
          node.data.userInteractions = [action.payload];
        }
        node.data.userInteractions.push(action.payload);
      }
    },
    addUserInteractionByConnection(state, action: PayloadAction<Connection>) {
      const node = state.find((_node) => _node?.id === action.payload.source);
      if (node) {
        const NewUserInteraction = {
          id: (Math.random() * 10000000000).toString(),
          source: action.payload.source as string,
          target: action.payload.target as string,
          comparison: "Igual a",
          phrases: [],
          entityTypeId: -1,
          entityTypeName: ""
        };
        if (node?.data?.userInteractions[0]?.source) {
          node.data.userInteractions =
            node.data.userInteractions.concat(NewUserInteraction);
        } else {
          node.data.userInteractions = [NewUserInteraction];
        }
      }
    },
    updateUserInteraction(
      state,
      action: PayloadAction<IChangesUserInteraction>
    ) {
      return state.map((_node) => {
        if (_node.id === action.payload.nodeId) {
          return {
            ..._node,
            data: {
              ..._node.data,
              userInteractions: _node.data.userInteractions.map((ui) => {
                if (ui.id === action.payload.userInteractionId) {
                  return {
                    id: action.payload.id,
                    source: action.payload.source,
                    target: action.payload.target,
                    comparison: action.payload.comparison,
                    phrases: action.payload.phrases,
                    entityTypeId: action.payload.entityTypeId,
                    entityTypeName: action.payload.entityTypeName
                  };
                }
                return { ...ui };
              })
            }
          };
        }
        return { ..._node };
      });
    },
    deleteUserInteraction(
      state,
      action: PayloadAction<{ nodeId: string; userInteractionId: string }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.userInteractions = node?.data?.userInteractions?.filter(
          (inter) => inter.id !== action.payload.userInteractionId
        );
      }
    },
    addTag(
      state,
      action: PayloadAction<{
        tagToAdd: string;
        nodeId: string;
        userInteractionId: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const userInteraction = node?.data?.userInteractions?.find(
        (_userInteraction) =>
          _userInteraction.id === action.payload.userInteractionId
      );
      if (node && userInteraction) {
        if (userInteraction.phrases?.length) {
          userInteraction.phrases = [
            ...userInteraction.phrases,
            action.payload.tagToAdd
          ];
        } else {
          userInteraction.phrases = [action.payload.tagToAdd];
        }
      }
    },
    deleteTag(
      state,
      action: PayloadAction<{
        tagToDelete: string;
        nodeId: string;
        userInteractionId: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      const userInteraction = node?.data?.userInteractions?.find(
        (_userInteraction) =>
          _userInteraction.id === action.payload.userInteractionId
      );
      if (node && userInteraction) {
        userInteraction.phrases = userInteraction?.phrases?.filter(
          (_options) => _options !== action.payload.tagToDelete
        );
      }
    },
    zerarFlow(state) {
      // eslint-disable-next-line no-return-assign, @typescript-eslint/no-unused-vars
      return (state = []);
    },
    initFlow(state, action: PayloadAction<Node<NodeData>[]>) {
      state.push(...action.payload);
    },
    setEntity(
      state,
      action: PayloadAction<{
        entityTypeId: number;
        entityTypeName: string;
        nodeId: string | undefined;
        destinationId: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.entityTypeId = action.payload.entityTypeId;
        node.data.entityTypeName = action.payload.entityTypeName;
        node.data.userInteractions = [
          {
            id: (Math.random() * 10000000).toString(),
            entityTypeId: action.payload.entityTypeId,
            entityTypeName: action.payload.entityTypeName,
            source: node.id,
            target: action.payload.destinationId,
            comparison: "Igual a",
            phrases: []
          }
        ];
      }
    },
    setEntityOnUserInteraction(
      state,
      action: PayloadAction<{
        userInteractionId: string;
        entityTypeId: number;
        entityTypeName: string;
        nodeId: string | undefined;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.userInteractions = node.data.userInteractions.map((el) => {
          if (el.id === action.payload.userInteractionId) {
            return {
              id: el.id,
              entityTypeId: action.payload.entityTypeId,
              entityTypeName: action.payload.entityTypeName,
              source: node.id,
              target: el.target,
              comparison: "Igual a",
              phrases: []
            };
          }
          return el;
        });
      }
    },
    deleteEntity(
      state,
      action: PayloadAction<{
        nodeId: string | undefined;
        userInteractionId: string;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.userInteractions = node.data.userInteractions.map((el) => {
          if (el.id === action.payload.userInteractionId) {
            return {
              id: el.id,
              entityTypeId: -1,
              entityTypeName: "",
              source: el.id,
              target: el.target,
              comparison: "Igual a",
              phrases: el.phrases
            };
          }
          return el;
        });
        // node.data.userInteractions
        // node.data.entityTypeId = -1;
        // node.data.entityTypeName = "";
        // node.data.userInteractions = [
        //   {
        //     id: (Math.random() * 10000000).toString(),
        //     source: node.id,
        //     target: "",
        //     comparison: "",
        //     phrases: [],
        //     entityTypeId: -1,
        //     entityTypeName: ""
        //   }
        // ];
      }
    },
    setNodes(state, action: PayloadAction<Node<NodeData>[]>) {
      state = action.payload;
    },
    setStartNodeActive(state) {
      const node = state.find((_node) => _node.type === "startNode");
      if (node) {
        node.selected = true;
      }
    },
    selectNode(state, action: PayloadAction<string>) {
      const node = state.find((_node) => _node.id === action.payload);
      if (node) {
        node.selected = true;
      }
    },
    initFallbackNode(
      state,
      action: PayloadAction<{
        nodeId: string | undefined;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node && !node?.data?.fallbackMessages) {
        node.data.fallbackMessages = [""];
      }
    },
    addNodeFallBack(
      state,
      action: PayloadAction<{
        nodeId: string | undefined;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.fallbackMessages = node.data.fallbackMessages?.concat("");
      }
    },
    onDeleteEdge(state, action: PayloadAction<Edge[]>) {
      const node = state.find((_node) => _node.id === action.payload[0].source);
      if (node) {
        node.data.userInteractions = node?.data?.userInteractions?.filter(
          // eslint-disable-next-line array-callback-return, consistent-return
          (inter) => {
            if (
              inter.source === action.payload[0].source &&
              inter.target === action.payload[0].target
            ) {
              // handle delete edge
            } else {
              return inter;
            }
          }
        );
      }
    },
    deleteNodeFallback(
      state,
      action: PayloadAction<{ nodeId: string | undefined; idx: number }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.fallbackMessages = node?.data?.fallbackMessages?.filter(
          (msg, idx) => idx !== action.payload.idx
        );
      }
    },
    updateNodeFallBackMessage(
      state,
      action: PayloadAction<{
        newMessage: string;
        nodeId: string | undefined;
        idx: number;
      }>
    ) {
      const node = state.find((_node) => _node.id === action.payload.nodeId);
      if (node) {
        node.data.fallbackMessages = node?.data?.fallbackMessages?.map(
          (el, idx) => {
            if (action.payload.idx === idx) {
              return action.payload.newMessage;
            }
            return el;
          }
        );
      }
    },
    setTransferNode(state, action: PayloadAction<boolean>) {
      const node = state.find((_node) => _node.selected);
      if (node) {
        node.data.transfer = action.payload;
      }
    },
    nodesUpdateonEdgeUpdate(
      state,
      action: PayloadAction<{ oldEdge: Edge; newConnection: Connection }>
    ) {
      const node = state.find(
        (_node) => _node.id === action.payload.oldEdge.source
      );
      if (node) {
        node.data.userInteractions = node?.data?.userInteractions?.map(
          (inter) => {
            if (
              inter?.source === action?.payload?.oldEdge.source &&
              inter.target === action.payload.oldEdge.target
            ) {
              return {
                ...inter,
                target: action.payload.newConnection.target as string
              };
            }
            return inter;
          }
        );
      }
    },
    nodeTransfer(state, action: PayloadAction<boolean>) {
      const node = state.find((_node) => _node.selected === true);
      if (node) {
        node.data.transfer = action.payload;
      }
    },
    setNodeTransferData(state, action: PayloadAction<ITransferNodeDTO>) {
      const node = state.find((_node) => _node.selected === true);
      if (node) {
        node.data.transferData = action.payload;
      }
    },
    setLevelUntiedNodes(state, action: PayloadAction<Edge<EdgeProps>[]>) {
      const levels = state.map((_node) => {
        return {
          id: _node.id,
          incomers: getIncomers(_node, state, action.payload),
          outgoers: getOutgoers(_node, state, action.payload)
          // if (_node.u)
        };
      });
      // eslint-disable-next-line array-callback-return
      levels.find((_node) => {
        if (!_node.incomers?.length && !_node.outgoers?.length) {
          const node = state.find((el) => el.id === _node.id);
          if (node) {
            node.data.level = -1;
          }
        } else {
          // return null
        }
      });
    }
  }
});

export const {
  setwebhookCall,
  setCustomButtons,
  addCustomButton,
  updateCustomButton,
  deleteAllCustomButtons,
  deleteCustomButton,
  organizeNodes,
  setLevels,
  onNodesChange,
  updateNodeColor,
  changeLabel,
  addNode,
  changeNode,
  unSelectAll,
  updatePos,
  addRobotInteraction,
  updateRobotInteraction,
  deleteRobotInteraction,
  addUserInteractions,
  addUserInteractionByConnection,
  addManyUserInteractions,
  deleteUserInteraction,
  updateUserInteraction,
  addTag,
  deleteTag,
  initFlow,
  setEntity,
  setEntityOnUserInteraction,
  deleteEntity,
  setStartNodeActive,
  setAttribute,
  setNodes,
  zerarFlow,
  addNodeFallBack,
  setTransferNode,
  deleteNodeFallback,
  onDeleteEdge,
  updateNodeFallBackMessage,
  initFallbackNode,
  nodesUpdateonEdgeUpdate,
  nodeTransfer,
  setNodeTransferData,
  setLevelUntiedNodes,
  updateUserInteractions,
  selectNode,
  addFiles,
  excludeFiles,
  setRejectedFiles,
  eraseRejectedFiles
} = nodesSlice.actions;

export default nodesSlice.reducer;
