import { memo } from "react";
import { ButtonAdd } from "../../assets";
import {
  getOutgoers,
  Handle,
  MarkerType,
  NodeProps,
  Position
} from "react-flow-renderer";
import { useDispatch } from "react-redux";
import {
  addNode,
  addRobotInteraction,
  addUserInteractions,
  setLevels,
  updateUserInteraction
} from "../../redux/slices/nodes";
import { onEdgesChange } from "../../redux/slices/edges";
import { useAppSelector } from "../../redux/hooks";
import { IUserInteraction } from "./NodeData";
import { changedPhoneComponent } from "../../redux/slices/flowupdates";
import { getBestPosition } from "../../util/getBestPosition";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";

export default memo(({ data, isConnectable, selected, ...rest }: NodeProps) => {
  const dispatch = useDispatch();
  const edges = useAppSelector((state) => state.edges);
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.filter((node) => node.selected === true)[0];
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const startNodeHasTarget =
    nodeSelected &&
    nodeSelected?.data &&
    nodeSelected?.data.userInteractions &&
    nodeSelected?.data.userInteractions[0] &&
    nodeSelected?.data.userInteractions[0].target;
  const addNodeDefault = async () => {
    const oldNodeId = nodeSelected?.id;
    const newNodeId = uuidv4();
    const NodeIncrement = checkSameName(1);
    const oldNodeUserInteractionId =
      nodeSelected &&
      nodeSelected?.data &&
      nodeSelected?.data?.userInteractions &&
      (nodeSelected?.data?.userInteractions[0]?.id as string);
    const NewUserInteractionId = uuidv4();
    const newUserInteractionsOldNode: IUserInteraction = {
      id: NewUserInteractionId,
      source: oldNodeId,
      target: newNodeId,
      comparison: "Igual a",
      phrases: [],
      entityTypeId: -1,
      entityTypeName: ""
    };
    const NewNode = {
      id: newNodeId,
      draggable: true,
      position: {
        x: rest.xPos,
        y: rest.yPos + 100
      },
      selectable: true,
      selected: false,
      type: "defaultNode",
      data: {
        label: `${NodeIncrement}-Inserir-título`,
        botInteractions: [],
        userInteractions: [
          {
            id: NewUserInteractionId,
            source: newNodeId,
            target: "",
            comparison: "Igual a",
            phrases: [],
            entityTypeId: -1,
            entityTypeName: ""
          }
        ],
        level: data?.level + 1,
        entityTypeId: -1,
        entityTypeName: "",
        sourceEntityNodeId: ""
      }
    };
    const NewPosition = getBestPosition({ nodes, edges, node: nodeSelected });
    NewNode.position.x = NewPosition.x;
    NewNode.position.y = NewPosition.y;
    const countOutgoers = getOutgoers(nodeSelected, nodes, edges);
    if (countOutgoers?.length === 0 && !startNodeHasTarget) {
      const objectUpdate = {
        id: oldNodeUserInteractionId as string,
        source: oldNodeId,
        target: newNodeId,
        comparison: "Igual a",
        phrases: nodeSelected?.data.userInteractions[0].phrases,
        entityTypeName: nodeSelected?.data.userInteractions[0].entityTypeName,
        entityTypeId: nodeSelected?.data.userInteractions[0].entityTypeId,
        nodeId: oldNodeId,
        userInteractionId: oldNodeUserInteractionId as string
      };
      dispatch(updateUserInteraction(objectUpdate));
    } else {
      dispatch(addUserInteractions(newUserInteractionsOldNode));
    }
    dispatch(addNode({ node: NewNode }));
    const newEdge = {
      id: `reactflow__edge-${oldNodeId}a-${newNodeId}`,
      source: oldNodeId,
      target: newNodeId,
      animated: false,
      style: {
        stroke: "rgba(217, 217, 217, 1)",
        strokeWidth: "2px"
      },
      markerEnd: {
        type: "arrowclosed" as MarkerType
      }
    };
    dispatch(onEdgesChange([{ item: newEdge, type: "add" }]));
    dispatch(addRobotInteraction({ nodeId: newNodeId }));
    dispatch(setLevels(edges));
    dispatch(changedPhoneComponent(true));
  };

  const checkSameName = (count: number): number => {
    const hasSameLabel = nodes.reduce((countSameLabel, _node) => {
      if (_node.data.label === `${count}-Inserir-título`) {
        return (countSameLabel += 1);
      }
      return countSameLabel;
    }, 0);
    if (hasSameLabel >= 1) {
      return checkSameName(count + 1);
    }
    return count;
  };
  return (
    <div
      style={{
        fontFamily: "Poppins, sans-serif",
        boxSizing: "border-box",
        padding: "10px",
        position: "relative",
        backgroundColor: "rgba(219, 238, 253, 1)",
        borderRadius: "12px",
        WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
        width: "196px",
        height: "59px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: selected ? "1px solid rgba(4, 106, 243, 1)" : "none"
      }}
    >
      <div>{data.label}</div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          bottom: -6,
          background: "#555"
        }}
        isConnectable={isConnectable}
      ></Handle>
      {isTemplate ? null : (
        <button
          type="button"
          style={{
            WebkitBoxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
            boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.25)",
            display: selected ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            height: "25px",
            width: "25px",
            backgroundColor: "#FFF",
            color: "#FFF",
            position: "absolute",
            bottom: -10,
            left: -14
          }}
          onClick={addNodeDefault}
        >
          <img src={ButtonAdd} width="18px" alt="Add-Node" />
        </button>
      )}
    </div>
  );
});
