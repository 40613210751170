import { useFetch2 } from "../util";

export interface GetGCredentialsData {
  offset?: number;
  limit?: number;
  companyId?: number;
}
interface ICreateGoogleCredentials {
  googleCredential: string[];
  companyId: number;
}

interface IGetGoogleCredentialsWorkspaces {
  offset: number;
  limit: number;
  workspaces: number[];
  name: string;
  companyId: number;
}
function BotKeysServices() {
  const useQuery = useFetch2();
  // this code just return a promise function to who is calling a function.

  const ListBotKeys = async (data: GetGCredentialsData) => {
    const params = new URLSearchParams();
    if (data.limit) {
      params.append("limit", `${data.limit}`);
    }

    if (data.offset) {
      params.append("offset", `${data.offset}`);
    }

    if (data.companyId) {
      params.append("companyId", `${data.companyId}`);
    }
    try {
      const method = { method: "GET" };
      return await useQuery(`/api/credentials?${params}`, method);
    } catch (error) {
      return error;
    }
  };

  const ListBotKeysWorkspaces = async (
    data: IGetGoogleCredentialsWorkspaces
  ) => {
    
    try {
      const method = { method: "POST" };
      return await useQuery('/api/credentials/list', method, data);
    } catch (error) {
      return error;
    }
  };
  const GetBotKey = async (id: number): Promise<any> => {
    try {
      const method = { method: "GET" };
      return await useQuery(`/api/credentials/${id}`, method);
    } catch (error) {
      return error;
    }
  };

  const DeleteBotKey = async (id: number) => {
    try {
      const method = { method: "DELETE" };
      return await useQuery(`/api/credentials/${id}`, method);
    } catch (error) {
      return error;
    }
  };
  const InsertBotKey = async (data: ICreateGoogleCredentials) => {
    try {
      const method = { method: "POST" };
      return await useQuery(
        `/api/credentials/dialogFlowCredential/`,
        method,
        data
      );
    } catch (error) {
      return error;
    }
  };

  return {
    ListBotKeys,
    GetBotKey,
    DeleteBotKey,
    InsertBotKey,
    ListBotKeysWorkspaces
  };
}

export default BotKeysServices;
