import { iNodesSaveObject } from './../components/react-flow-renderer/NodeData.d';
import { useFetch2 } from "../util";

function BotPublishDialogTestServices() {
  const useQuery = useFetch2();
  

  // this code just return a promise function to who is calling a function.
  
  const botPublishDialogTest = async (botId: number, id: number, data: iNodesSaveObject) => {
    try {
      const method = { method: "POST" }
      return await useQuery(`/api/bots/${botId}/publish-test/${id}`, method, data)
    } catch (error) {
      return error
    }
  }



  return {
    botPublishDialogTest,
  }
}

export default BotPublishDialogTestServices
