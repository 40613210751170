
import { Skeleton } from 'primereact/skeleton';
import { Skelet1,Skelet2, Skelet3, Text } from './styles';

interface ITyping {
    active: boolean
}

export const Typing: React.FC<ITyping> = ({active}) => {
    return (
        <>
        {active ? (
            <div className='flex ml-8 mt-2'>
                <Skeleton css={Skelet1} shape="circle" borderRadius="5px" size="10px" />
                <Skeleton css={Skelet2} className='ml-2' borderRadius="5px" shape="circle" size="10px" />
                <Skeleton css={Skelet3} className='ml-2' shape="circle" borderRadius="5px" size="10px" />
                <div css={Text}>Digitando...</div>
            </div>

        ) : null}
        </>
    )
}