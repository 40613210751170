import { css } from "@emotion/react";
import styled from '@emotion/styled'
// import { rotateIcon } from "./index";

export const PhoneCss = css`
position: relative;
background: #FFFFFF;
border: 8px solid #DBDBDB;
border-radius: 3rem;
width: 100%;
min-width: 330px;
height: 100%;
margin-bottom: 2rem;
`;

export const TopPhoneCss = css`
position: absolute;
height: 24px;
width: 10rem;
left: -moz-calc(50% - 5rem);
left: -webkit-calc(50% - 5rem);
left: calc(50% - 5rem);
top: -1px;
border-radius: 0 0 5rem 5rem;
background-color: #DBDBDB;
z-index: 10;

`;
export const InputRounded = css`
    max-width: 90%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    margin: 1rem auto;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 2px solid #888888;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    width: 90%;
    color: #4c5862;
    &:focus {
    border: 2px solid rgba(33, 150, 243, 1);
    }
    z-index: 10;
`;

export const DivMicrofone = css`
width: 37px;
height: 5.4px;
position: absolute;
z-index: 10;
background: #FFF;
top: 0.6rem;
left: -moz-calc(50% - 18.5px);
left: -webkit-calc(50% - 18.5px);
left: calc(50% - 18.5px);
border: 0.5px solid #FFFFFF;
border-radius: 2.7px;

`;
export const DivCamera = css`
width: 6.8px;
height: 6.4px;
position: absolute;
z-index: 10;
background: transparent;
display: flex;
align-items: center;
justify-content: center;
top: 0.57rem;
left: -moz-calc(70% - 18.5px);
left: -webkit-calc(70% - 18.5px);
left: calc(70% - 18.5px);
border: 0.5px solid #FFFFFF;
border-radius: 50%
`;

export const DivCenterCamera = css`
width: 4.2px;
height: 4.5px;
background: #FFF;
border-radius: 50%;
`;

export const DivBottomPhone = css `
width: 6.75rem;
height: 5.4px;
position: absolute;
z-index: 10;
background: #DBDBDB;
bottom: 1rem;
left: -moz-calc(50% - 3.375rem);
left: -webkit-calc(50% - 3.375rem);
left: calc(50% - 3.375rem);
border-radius: 2.7px;
`;

export const DivIcons = css`
position: absolute;
top: 0.6rem;
z-index: 10;
left: -moz-calc(50% + 5.2rem);
left: -webkit-calc(50% + 5.2rem);
left: calc(50% + 5.2rem);
display: flex;
`;

export const ContentPhone = css`
position: absolute;
top: 36px;
display: flex;
flex-direction: column;
max-width: 100%;
width: inherit;
z-index: 3;
height: -moz-calc(100% - 60px);
height: -webkit-calc(100% - 60px);
height: calc(100% - 60px);
`;

export const Modal = css`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    background-color: rgba(255,255,255, 0.8);
    border: none;
    overflow-y: scrool;
    z-index: 12;
    position: absolute;
    top: -8px;
    left: -8px;
    width: 100%;
    min-width: 330px;
    height: -moz-calc(100% + 16px);
    height: -webkit-calc(100% + 16px);
    height: calc(100% + 16px);
    border-radius: 3rem;
    // margin-bottom: 5rem;
    @keyframes opacityOnLoad {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.8;
        }
    }
    
    animation: 400ms ease-out 0s 1 opacityOnLoad;
`;

  interface IButtonReload {
    rotateIcon: boolean
  }
export const ButtonReload = styled.img<IButtonReload>`
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
    -webkit-animation: ${props => (props.rotateIcon ? '2s linear infinite': '')};
    -moz-animation: ${props => (props.rotateIcon ? 'rotating 2s linear infinite': '')};
    -ms-animation: ${props => (props.rotateIcon ? 'rotating 2s linear infinite': '')};
    -o-animation: ${props => (props.rotateIcon ? 'rotating 2s linear infinite': '')};
    animation: ${props => (props.rotateIcon ? 'rotating 2s linear infinite': '')};
&:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
}
`;

export const ButtonReloadDiv = css`
&:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
}
`;
export const InputContainer = styled.div`
display: flex;
width: 100%;
`;