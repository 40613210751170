import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckCard = css`
  padding: 0.7rem;
  border: 2px solid #acd0f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
  color: #999999;
  margin-top: 1.1rem;
  height: 50px;
  width: 116px;

  @media (min-width: 1921px) {
    width: 190px;
  }
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 22px;
  height: 22px;
  right: -11px;
  top: -11px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 800px;
`;
export const ContainerLeftModal = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-top: 0rem;
`;
export const ContainerRightModal = styled.div`
  width: 68.3%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: #ff0025;
  padding: 1rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const ContainerTab = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;

export const ContainerQRCode = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;

  @media (max-width: 700px) {
    width: 170px;
    height: 170px;
  }
  @media (max-width: 595px) {
    width: 150px;
    height: 150px;
  }
`;

export const PlayButton = styled.button`
  background-color: #fff;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  display: flex;

  align-items: center;
  justify-content: center;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

interface IButtonReload {
  rotateIcon: boolean;
}
export const ButtonReload = styled.img<IButtonReload>`
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  -webkit-animation: ${(props) =>
    props.rotateIcon ? "2s linear infinite" : ""};
  -moz-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -ms-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -o-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

export const ButtonReloadDiv = css`
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

export const PhoneConected = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00e676;
  gap: 0.5rem;
  position: absolute;
  width: 97px;
  height: 36px;
  right: 1rem;
  top: 1.5px;
  background: #ffffff;
  border: 1px solid #00e676;
  border-radius: 6px;
`;
export const Span = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  min-width: 1.0625rem;
  height: 1.0625rem;
  min-height: 1.0625rem;
  border-radius: 50%;
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
`;

export const RemoverButton = styled.button`
  width: 160px;
  height: 42px;

  background: #ffffff;
  border: 1px solid #ff0025;
  border-radius: 8px;
  color: #ff0025;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  :hover {
    cursor: pointer;
    background-color: #ff6177;
  }
`;

export const Label = styled.label`
  @media (max-width: 1921px) {
    font-size: 0.75rem;
    /* display: none; */
  }
  @media (min-width: 1921px) {
    font-size: 1rem;
    /* display: none; */
  }
`;
export const HiddenDiv = styled.div`
  @media (max-width: 1921px) {
    display: none;
  }
`;

export const Switch = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: bold;
`;
