import { css } from "@emotion/react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes
} from "date-fns";
import { Tooltip } from "primereact/tooltip";
import { IconChannelBot, whatsapp } from "../../assets";

const getFormatedDate = (data: Date) => {
  const currentDate = new Date();
  const chatDate = new Date(data);

  const diffMinutes = differenceInMinutes(currentDate, chatDate);
  const diffHours = differenceInHours(currentDate, chatDate);
  const diffDays = differenceInDays(currentDate, chatDate);

  if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    return `${diffHours}h`;
  }

  return `${diffDays}d`;
};

const options: { [key: string]: string } = {
  LIVECHAT: IconChannelBot,
  WHATSAPP: whatsapp
};

export interface DialogProps {
  name: string;
  lastMessageDateTime?: Date;
  lastMessage?: string;
  unreadMessageTotal?: number;
  platform: string;
  onClick?: () => void;
  className?: string;
}

const Dialog = ({
  name,
  lastMessageDateTime,
  lastMessage,
  unreadMessageTotal,
  platform,
  onClick,
  className
}: DialogProps) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #282d35;
      cursor: pointer;
    `}
    className={className}
    onClick={onClick}
  >
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 35px;
        width: 35px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: #109cf1;
        color: #ffffff;
      `}
    >
      {name.charAt(0).toUpperCase()}

      <div
        className="bot-name-tooltip"
        data-pr-position="bottom"
        css={css`
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 20px;
          gap: 10px;
        `}
      >
        <img
          src={options[platform]}
          alt="platformImage"
          height={15}
          width={15}
        />

        <Tooltip target=".bot-name-tooltip"> {platform} </Tooltip>
      </div>
    </div>

    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;
      `}
    >
      <div>{name}</div>

      {lastMessage ? (
        <div
          css={css`
            flex: 1;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 5px;
            overflow: hidden;
          `}
        >
          {lastMessage}
        </div>
      ) : null}
    </div>

    <div
      css={css`
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      {lastMessageDateTime ? (
        <div
          css={css`
            font-size: 11px;
            margin-left: auto;
            margin-bottom: 5px;
          `}
          style={unreadMessageTotal ? { color: "#109CF1" } : {}}
        >
          {getFormatedDate(lastMessageDateTime)}
        </div>
      ) : null}

      {unreadMessageTotal ? (
        <div
          css={css`
            background-color: #109cf1;
            border-radius: 50%;
            height: 10px;
            width: 10px;
          `}
        ></div>
      ) : null}
    </div>
  </div>
);

export default Dialog;
