import { DataTable } from "primereact/datatable";
import { Text } from "../../../../components/Text"
import { Column } from 'primereact/column';
import { Card, Icon, Title } from "./styles"
import { BotBlue } from "./../../../../assets/index";
import "./tableStyle.css";
interface IUltimosAtendimentos {
    createdAt: Date;
    id: number;
    lastMessage: Date;
}
export const UltimosAtendimentos: React.FC<{ultimosAtendimentos: IUltimosAtendimentos[]}> = (ultimosAtendimentos) => {
    // const [products, setProducts] = useState(null);
    // const productService = new ProductService();
    // useEffect(() => {
    //     productService.getProductsSmall().then(data => setProducts(data));
    // }, []);
    const criadoBodyTemplate = (rowData: { createdAt: Date }) => {
        return new Date(rowData.createdAt).toLocaleDateString();
      };
    const atualizadoBodyTemplate = (rowData: { lastMessage: Date }) => {
        return new Date(rowData?.lastMessage).toLocaleDateString();
      };
      const closedAtTemplate = (rowData: { closedAt?: Date }) => {
        return rowData.closedAt ? "Fechado" : "Aberto";
      };
    return (
        <div css={Card}>
            <div css={Title}>
                <div css={Icon}> 
                <img height={15} src={BotBlue} alt="" />
                </div>
                <Text s="14px">Últimos Atendimentos</Text>
            </div>
            <DataTable value={ultimosAtendimentos.ultimosAtendimentos} showGridlines={true} responsiveLayout="scroll">
                <Column field="id" header="ID"></Column>
                <Column field="name" header="Criado em" body={criadoBodyTemplate}></Column>
                <Column field="lastMessage" header="Última mensagem" body={atualizadoBodyTemplate}></Column>
                <Column field="closedAt" header="Status" body={closedAtTemplate}></Column>
                <Column field="see" header="" ></Column>
            </DataTable>
        </div>
    )
}