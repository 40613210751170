import { css } from "@emotion/css";
import styled from "@emotion/styled";


export const AttributesStyle = styled.div`
  background: #ffffff;
  border: 1px solid #FF3333;
  border-radius: 6px;
  width: 100%;
  min-height: 6.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  /* z-index: 10; */
  /* overflow: hidden; */
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  /* :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  } */
`;
export const AttributeBar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: #FF3333;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;

export const Title = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0A043C;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544F77;
  text-align: left;
`;

export const Span = styled.span`
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
font-size: 0.875rem;
color: #046AF3;
background-color: #D4E2F3;
width: 1.0625rem;
height: 1.0625rem;
border-radius: 50%;
`;

// export const IconDiv = styled.div`
// position: absolute;
// z-index: 30;
// right: -14px;
// top: -14px;
// width: 28px;
// height: 28px;
// background: #D4E2F3;
// border-radius: 8px;
// display: flex;
// align-items: center;
// justify-content: center;
// `;

export const IconImage = styled.div`
margin-left: 1rem;
width: 2.6875rem;
height: 2.6875rem;
border-radius: 0.75rem;
background: #D4E2F3;
display: flex;
align-items: center;
justify-content: center;
`;
export const DeleteButtonIcon = styled.button`
width: 1.5625rem;
height: 1.5625rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
background-color: #FFF;
color: #FFF;
position: absolute;
top: -0.78125rem;
right: -0.78125rem;
z-index: 3;
`;

interface iAttributesContainer {
  bottom: string;
  height: string;
}
export const AttributesContainer = styled.div<iAttributesContainer>`
position: absolute;
z-index: 2;
bottom: ${props => props.bottom};
height: ${props => props.height};
display: block;
width: 80%;
& + & {
    margin-top: 1rem;
  }
`;
