import { useFetch2 } from "../../util";

export default () => {
  const fetch = useFetch2();

  return {
    findCompany: async (input: any) => {
      const paramRequest = new URLSearchParams(
        input.name
          ? {
              offset: `${input.offset}`,
              limit: `${input.limit}`,
              name: input.name
            }
          : {
              offset: `${input.offset}`,
              limit: `${input.limit}`
            }
      );

      const response = await fetch(`/api/companies?${paramRequest}`, {
        method: "GET"
      });

      return response;
    },
    findPlataforms: async (): Promise<{ id: number; name: string }[]> =>
      fetch("/api/plataforms", { method: "GET" }),
    updateCompany: async (id: number, data: any) =>
      fetch(`api/companies/${id}`, { method: "PUT" }, data),
    createNewCompany: async (data: any) =>
      fetch("api/companies", { method: "POST" }, data),
    searchCompany: async (id: number) =>
      fetch(`/api/companies/${id}`, { method: "GET" })
  };
};
