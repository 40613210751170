const pesoCPF = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
const pesoCNPJ = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

const calcularDigito = (str: string, peso: number[]) => {
  let soma = 0;

  for (let indice = str.length - 1, digito; indice >= 0; indice--) {
    digito = parseInt(str.substring(indice, indice + 1));
    soma += digito * peso[peso.length - str.length + indice];
  }

  soma = 11 - (soma % 11);

  return soma > 9 ? 0 : soma;
};

const validarCpf = (cpf: string) => {
  const digito1 = calcularDigito(cpf.substring(0, 9), pesoCPF);
  const digito2 = calcularDigito(`${cpf.substring(0, 9)}${digito1}`, pesoCPF);

  return cpf === `${cpf.substring(0, 9)}${digito1}${digito2}`;
};

const validarCnpj = (cnpj: string) => {
  const digito1 = calcularDigito(cnpj.substring(0, 12), pesoCNPJ);

  const digito2 = calcularDigito(
    `${cnpj.substring(0, 12)}${digito1}`,
    pesoCNPJ
  );

  return cnpj === `${cnpj.substring(0, 12)}${digito1}${digito2}`;
};

export default (value: string) => {
  if (!value) {
    return false;
  }

  if (!/^(?:\d{11}|\d{14})$/u.test(value)) {
    return false;
  }

  if (value.length === 11) {
    if (!validarCpf(value)) {
      return false;
    }
  }

  if (value.length === 14) {
    if (!validarCnpj(value)) {
      return false;
    }
  }

  return true;
};
