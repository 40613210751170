import { BotBallon, BotBallonPosition, divWatch, IconChat, MyLink, QuickReply } from "./styles";
import { BotInterctionChat } from "../../../../assets";
import { Payload } from "../../../../services/botDialogTest";
interface IRobotTextBallon {
  content: string;
  date: Date;
  quickReply?: Payload[];
  setQuickResponse: (response: string) => void;
}
export const RobotTextBallon: React.FC<IRobotTextBallon> = ({
  content,
  date,
  quickReply,
  setQuickResponse
}) => {
  return (
    <div className="flex flex-column">
    <div className="flex">
      <div css={IconChat}>
        <img
          width="28px"
          src={BotInterctionChat}
          alt="Img-Bot-Interaction-chat"
        />
      </div>
      <div css={BotBallon}>
        {content?.includes("\n") ? (
          content.split("\n").map((cont, i) => {
            return <div key={i} css={BotBallonPosition}>{cont}</div>;
          })
        ) : (
          <div css={BotBallonPosition}>{content}</div>
        )}
        <div css={divWatch}>{`${date
          .toLocaleTimeString()
          .substring(0, 5)} · Read`}</div>
      </div>

    </div>
    {quickReply && quickReply?.length > 0 ? (
      quickReply.map((el) => (
        // eslint-disable-next-line no-nested-ternary
        el.type === "QUICK_REPLY" ? (
          <QuickReply onClick={() => setQuickResponse(el.label)} key={el.position}>{el.label}</QuickReply>
        ):
        el.type === "LINK" ? (
          <MyLink key={el.position} href={el.link} rel="noopener noreferrer" target="blank">{el.label}</MyLink>
        ):
        null
      ))
    ) : null}
       </div>
  );
};
