/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFetch2 } from "../util";


interface IUser {
  active: boolean,
  companyId: number,
  email: string,
  id: number,
  name: string,
  phone: string,
  profile: string,
  workspaces: number,
}
interface IGetUser {
  result: IUser[],
  total: number
}
const UserServices = () => {
  const fetch = useFetch2();

  // const getUser = (input: {
  //   offset: number;
  //   limit: number;
  //   workspaceId?: number[];
  //   name?: string;
  // }): Promise<IGetUser> => {
  //   const params = new URLSearchParams();

  //   params.append("limit", `${input.limit || 10}`);

  //   params.append("offset", `${input.offset || 0}`);

  //   if (input.workspaceId?.length) {
  //     input.workspaceId.map((el) => params.append("workspaceId", String(el)));
  //   } else {
  //     params.append("workspaceId", "0");
  //   }

  //   if (input.name) {
  //     params.append("name", input.name);
  //   }

  //   return fetch(`/api/users?${params}`, { method: "GET" });
  // };
  const getUser = (input: {
    offset: number;
    limit: number;
    workspaceId?: number[];
    name?: string;
    companyId: number;
  }): Promise<IGetUser> => {
    // const params = new URLSearchParams();
    const body = {
      offset: input.offset || 0,
      limit: input.limit || 10,
      workspaces: input.workspaceId,
      name: input.name,
      companyId: input.companyId
    }
    return fetch(`/api/users/list`, { method: "POST" }, {...body});
  };
  const getUserById = async (UserId: number | any) => {
    try {
      const method = { method: "GET" };
      return await fetch(`/api/users/${UserId}`, method);
    } catch (error) {
      return error;
    }
  };

  const updateUser = async (UserId: number | any, data: any) => {
    const method = { method: "PUT" };
    return fetch(`/api/users/${UserId}`, method, data);
  };

  const patchUser = async (UserId: number | any, data: any) => {
    const method = { method: "PATCH" };
    return fetch(`/api/users/${UserId}`, method, data);
  };

  return {
    getUser,
    getUserById,
    updateUser,
    patchUser
  };
};

export default UserServices;
