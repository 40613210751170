import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { v } from "../../util";
import useCompany from "../Company/use-company";
import * as comunCss from "../comum_css/index";
import { Container } from "./styles";
import { useToast } from "../toast-provider";

interface FormData {
  name: string;
  email: string;
  sendInvite: boolean;
  plataform: number;
  active: boolean;
  workspaceQuota: number;
  userQuota: number;
  botQuota: number;
}

const EditCompany = ({
  visible,
  onHide,
  id
}: {
  visible: boolean;
  onHide: () => void;
  id: number;
}) => {
  const [company, setCompany] = useState<FormData>();
  const [plataforms, setPlataforms] = useState<{ id: number; name: string }[]>(
    []
  );
  const { findPlataforms, createNewCompany, searchCompany, updateCompany } =
    useCompany();

  const toastRef = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      email: "",
      sendInvite: false,
      plataform: undefined,
      active: false,
      workspaceQuota: 0,
      userQuota: 0,
      botQuota: 0
    },
    values: company,
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        email: v.string().superRefine(v.nonempty()),
        sendInvite: v.boolean(),
        plataform: v.number(),
        active: v.boolean(),
        workspaceQuota: v.number(),
        userQuota: v.number(),
        botQuota: v.number()
      })
    )
  });

  useEffect(() => {
    const fetchPlataforms = async () => {
      const result = await findPlataforms();
      setPlataforms(result);
    };

    fetchPlataforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (id) {
        const result = await searchCompany(id);

        if (result) {
          const platform = plataforms.find(
            (p) => p.name === result.plataformName
          );

          setCompany({
            ...result,
            plataform: platform ? platform.id : 0
          });
        }
      } else {
        reset({
          name: "",
          email: "",
          sendInvite: false,
          plataform: undefined,
          active: false,
          workspaceQuota: 0,
          userQuota: 0,
          botQuota: 0
        });
      }
    };

    fetchCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset, visible]);

  return (
    <Sidebar
      className="p-sidebar-sm"
      visible={visible}
      position="right"
      dismissable={true}
      onHide={onHide}
    >
      <Container>
        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="userName">
            Nome
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputText {...field} className="w-full" id="name" />
            )}
          />
          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
          ) : null}
        </div>

        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="email">
            E-mail
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputText {...field} className="w-full" id="email" />
            )}
          />
          {errors.email?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.email.message}</div>
          ) : null}
        </div>

        <div>
          <Controller
            name="sendInvite"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                id="sendInvite"
                checked={field.value}
                onChange={(e) => field.onChange(e.checked)}
              />
            )}
          />
          Enviar convite
        </div>

        <div>
          <Controller
            name="plataform"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                id="plataform"
                onChange={(e) => field.onChange(e.value)}
                options={plataforms}
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione a plataforma"
                className="w-full"
              />
            )}
          />
          {errors.plataform?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.plataform.message}</div>
          ) : null}
        </div>

        <div>
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                id="active"
                checked={field.value}
                onChange={(e) => field.onChange(e.checked)}
              />
            )}
          />
          Ativo
        </div>

        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="workspaceQuota">
            Limite de workspaces (0 para ilimitadas)
          </label>
          <Controller
            name="workspaceQuota"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                className="w-full"
                id="workspaceQuota"
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>
              {errors.workspaceQuota?.message}
            </div>
          ) : null}
        </div>

        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="userQuota">
            Limite de usuários (0 para ilimitados)
          </label>
          <Controller
            name="userQuota"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                className="w-full"
                id="userQuota"
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.userQuota?.message}</div>
          ) : null}
        </div>

        <div>
          <label css={comunCss.inputLabelDefault} htmlFor="userQuota">
            Limite de bots (0 para ilimitados)
          </label>
          <Controller
            name="botQuota"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                className="w-full"
                id="botQuota"
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          {errors.name?.message ? (
            <div style={{ color: "#AA3939" }}>{errors.botQuota?.message}</div>
          ) : null}
        </div>

        <Button
          title={id ? "Atualizar empresa" : "Criar empresa"}
          label={id ? "ATUALIZAR" : "CRIAR"}
          onClick={handleSubmit(async (data) => {
            try {
              if (id) {
                await updateCompany(id, data);

                toastRef.current?.show({
                  severity: "success",
                  detail: "Empresa atualizada com sucesso!"
                });
              } else {
                await createNewCompany(data);

                toastRef.current?.show({
                  severity: "success",
                  detail: "Empresa cadastrada com sucesso!"
                });
              }

              reset({
                name: "",
                email: "",
                sendInvite: false,
                plataform: undefined,
                active: false,
                workspaceQuota: 0,
                userQuota: 0,
                botQuota: 0
              });

              onHide();
            } catch (e) {
              toastRef.current?.show({
                severity: "error",
                detail: e.message
              });
            }
          })}
          icon="pi pi-check-circle"
          css={comunCss.saveBtn}
          className="saveBTN p-button-raised p-button-lg mt-3 p-button-text w-full text-blue-800"
        />
      </Container>
    </Sidebar>
  );
};

export default EditCompany;
