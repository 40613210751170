import styled from "@emotion/styled";

export const Container = styled.div`
  overflow-y: scroll;
  height: 21rem;
`;

export const MyIconButton = styled.div`
  height: 3rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d4e2f3;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  margin-left: 1rem;
  transition: all 0.2s;
  :hover {
    cursor: pointer;
  }
`;

export const Relative = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  margin: 0;
  padding: 0;
`;
export const ButtonCopiar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 106px;
  height: 32px;
  right: 6px;
  top: 5%;
  background: #d4e2f3;
  color: #046af3;
  border-radius: 6px;
  transition: all ease-in-out 0.2s;
  :hover {
    background-color: #024aae;
    color: white;
    cursor: pointer;
  }
`;

export const ButtonSalvar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 198px;
  height: 42px;
  background-color: #046af3;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  transition: background-color ease-in-out 0.4s;
  :hover {
    background-color: #024aae;
    cursor: pointer;
  }
`;
export const ErrorMessage = styled.div`
  margin-top: 5px;
  margin-left: 1rem;
  font-size: 12px;
  color: #ff4d4f;
`;
