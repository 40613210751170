import { css } from "@emotion/react";

export const Skelet1 = css`
background-color: rgba(4, 106, 243, 0.2);
`;
export const Skelet2 = css`
background-color: rgba(4, 106, 243, 0.6);
`;
export const Skelet3 = css`
background-color: #2196F3;
`;
export const Text = css`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #888888;
margin-left: 0.5rem;
margin-top: -0.45rem;
`;