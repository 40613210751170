import styled from "@emotion/styled";

export const PlayButton = styled.button`
  background-color: #fff;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  :hover {
    cursor: pointer;
  }
`;
