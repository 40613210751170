/* eslint-disable no-negated-condition */
import { changedPhoneComponent } from "../../../../redux/slices/flowupdates";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { EdgeChange } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onEdgesChange } from "../../../../redux/slices/edges";
import {
  setCustomEntitiesModalOpen,
  setEntitiesModalOpen,
  setUserInteractionId
} from "../../../../redux/slices/flowInteractions";
import {
  setEntity,
  setEntityOnUserInteraction,
  updateRobotInteraction
} from "../../../../redux/slices/nodes";
import entityTypesServices from "../../../../services/entityTypesService";
import { Entity } from "./Entity";
import { ContainerContent, Modal, Overlay, Textp, Title } from "./styles";
import { Dialog } from "primereact/dialog";
import { ISlateText } from "../../../../util/deserializeOnLoad";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};
interface Result {
  id: number;
  name: string;
}
interface IEntities {
  result: Result[];
  total: number;
}
interface IEntityToSet {
  entityTypeId: number;
  entityTypeName: string;
}
interface IEntitiesComponent {
  userInteractionId?: string;
}
export const Entities: React.FC<IEntitiesComponent> = ({
  userInteractionId
}) => {
  const dispatch = useAppDispatch();
  const [nodeIsUsedAsVariableModal, setNodeIsUsedAsVariableModal] =
    useState(false);
  const [entityToSet, SetEntityToSet] = useState<IEntityToSet>(
    {} as IEntityToSet
  );
  const edges = useAppSelector((state) => state.edges);
  const [entities, setEntities] = useState<IEntities>({} as IEntities);
  const BotIdActive = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const modalOpen = useAppSelector(
    (state) => state.flowInteractions.entityModalOpen
  );
  const customModalOpen = useAppSelector(
    (state) => state.flowInteractions.customEntityModalOpen
  );

  const toast = useRef<Toast>(null);

  const nodes = useAppSelector((state) => state.nodes);
  const userInteractionIdRedux = useAppSelector(
    (state) => state.flowInteractions.userInteractionId
  );
  const nodeSelected = nodes.find((node) => node.selected === true);
  const nodeSelectedHasEntitySetOnAnotherUserInteraction =
    nodeSelected?.data.userInteractions.filter(
      (el) => el.id !== userInteractionId && el.entityTypeId !== -1
    );
  const entitiesAlreadySetOnThisNode =
    nodeSelectedHasEntitySetOnAnotherUserInteraction?.map(
      (el) => el.entityTypeId
    );
  const entitesRemaining = entities?.result?.filter(
    (el) => !entitiesAlreadySetOnThisNode?.includes(el.id)
  );
  const [searchInput, setSearchInput] = useState("");
  const debouncedText = useDebounce(searchInput, 500);
  const tokenActive = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );

  const { getEntities, searchEntities } = entityTypesServices();

  const fetchFirstPage = async () => {
    try {
      const results = await getEntities({
        limit: 100,
        offset: 0,
        botId: BotIdActive
      });
      setEntities(results);
    } catch (error) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro ao buscar entidades.",
        detail:
          "Não é possível buscar entidades neste momento. Por favor tenta mais tarde."
      });
    }
  };
  const AcceptChange = (entityTypeId?: number, entityTypeName?: string) => {
    const entityIsSetAsVariableInAnotherBotsInteractions =
      botInteractionsIdsWithVariablesFromThisEntity(entityToSet.entityTypeId);
    nodes.map((node) => {
      const oldNode = node;
      const newBI = node.data.botInteractions.filter((bi) => {
        if (entityIsSetAsVariableInAnotherBotsInteractions.includes(bi.id)) {
          if (bi.text !== "") {
            const parseBi: ISlateText[] = JSON.parse(bi?.text);
            return {
              ...bi,
              text: JSON.stringify(
                parseBi?.map((biParsed) => {
                  return {
                    ...biParsed,
                    children: biParsed.children
                      .map((biChildren) => {
                        if (
                          biChildren.type === "mention" &&
                          biChildren.children &&
                          biChildren.children[0].text === bi?.id
                        ) {
                          return { text: "" };
                        }
                        return biChildren;
                      })
                      .map((el, idx) => {
                        if (idx > 0 && biParsed.children[idx - 1].text === "") {
                          return undefined;
                        }
                        return el;
                      })
                      .filter((notUndefineds) => notUndefineds !== undefined)
                  };
                })
              )
            };
          }
        }
      });
      dispatch(
        updateRobotInteraction({
          id: newBI[0]?.id,
          nodeId: oldNode?.id,
          text: newBI[0]?.text
        })
      );
    });
    // if (entityIsSetAsVariableInAnotherBotsInteractions.includes(node.id)) {
    //   const oldNode = node;
    //   const newBI = node.data.botInteractions.map((bi) => {
    //     if (bi.text !== "") {
    //       const parseBi: ISlateText[] = JSON.parse(bi?.text);
    //       return {
    //         ...bi,
    //         text: JSON.stringify(
    //           parseBi?.map((biParsed) => {
    //             return {
    //               ...biParsed,
    //               children: biParsed.children
    //                 .map((biChildren) => {
    //                   if (
    //                     biChildren.type === "mention" &&
    //                     biChildren.children &&
    //                     biChildren.children[0].text === nodeSelected?.id
    //                   ) {
    //                     return { text: "" };
    //                   } else return biChildren;
    //                 })
    //                 .map((el, idx) => {
    //                   if (idx > 0 && biParsed.children[idx - 1].text === "") {
    //                     return undefined;
    //                   } else return el;
    //                 })
    //                 .filter((notUndefineds) => notUndefineds !== undefined)
    //             };
    //           })
    //         )
    //       };
    //     } else return bi;
    //   });
    //   dispatch(
    //     updateRobotInteraction({
    //       id: newBI[0]?.id,
    //       nodeId: oldNode?.id,
    //       text: newBI[0]?.text
    //     })
    //   );
    // }
    // });
    // Setar a entidade e o node de destino
    const entityToSetId = entityTypeId || entityToSet.entityTypeId;
    const entityToSetName = entityTypeName || entityToSet.entityTypeName;
    dispatch(
      setEntityOnUserInteraction({
        userInteractionId: userInteractionIdRedux,
        entityTypeId: entityToSetId,
        entityTypeName: entityToSetName,
        nodeId: nodeSelected?.id
      })
    );
    dispatch(setUserInteractionId(""));
    SetEntityToSet({} as IEntityToSet);
    dispatch(changedPhoneComponent(true));
    dispatch(setEntitiesModalOpen(false));
    setNodeIsUsedAsVariableModal(false);
  };
  const RejectChange = () => {
    SetEntityToSet({} as IEntityToSet);
    setNodeIsUsedAsVariableModal(false);
    dispatch(setUserInteractionId(""));
  };
  useEffect(() => {
    if (!customModalOpen) {
      fetchFirstPage();
    }
  }, [customModalOpen]);
  useEffect(() => {
    const fetchSearch = async () => {
      try {
        const results = await searchEntities({
          limit: 100,
          offset: 0,
          name: debouncedText
        });
        setEntities(results);
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Erro ao buscar entidades.",
          detail:
            "Não é possível buscar entidades neste momento. Por favor tenta mais tarde."
        });
      }
    };
    if (debouncedText?.length) {
      fetchSearch();
    } else {
      fetchFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText, modalOpen]);

  const botInteractionsIdsWithVariablesFromThisEntity = (
    entityTypeId: number
  ) => {
    const entityIsSetAsVariableInTheseBotsInteractionsIds: string[] = [];
    nodes.map((_node) => {
      return _node?.data?.botInteractions.map((bi) => {
        if (bi.text !== "") {
          const biObject = JSON?.parse(bi?.text);
          return biObject
            ?.map((biObj: { children: any }) => {
              return biObj?.children
                ?.map(
                  (biChildren: { type: string; children: { text: any }[] }) => {
                    if (biChildren?.type === "mention") {
                      if (biChildren?.children[0]?.text === entityTypeId) {
                        return entityIsSetAsVariableInTheseBotsInteractionsIds.push(
                          bi?.id
                        );
                      }
                      return undefined;
                    }
                  }
                )
                .filter(
                  (notUndefined: undefined) => notUndefined !== undefined
                );
            })
            .filter((notUndefined: undefined) => notUndefined !== undefined);
        }
        return undefined;
      });
    });
    return entityIsSetAsVariableInTheseBotsInteractionsIds;
  };
  const setEntityNode = (entityTypeId: number, entityTypeName: string) => {
    SetEntityToSet({ entityTypeId, entityTypeName });
    // Primeiro verificar se a entidade selecionada é diferente da atual setada no node
    // const entityIsSame = nodeSelected?.data?.entityTypeId === entityTypeId;

    // Primeiro verificar se a entidade selecionada é diferente da atual setada no userInteraction Atual
    const entityIsSame = nodeSelected?.data?.userInteractions.find((ui) => {
      ui.id === userInteractionIdRedux;
    })?.id;
    const entityIsSetAsVariableInAnotherNodesLocal =
      botInteractionsIdsWithVariablesFromThisEntity(entityTypeId);
    if (!entityIsSame && entityIsSetAsVariableInAnotherNodesLocal?.length) {
      setNodeIsUsedAsVariableModal(true);
    } else {
      AcceptChange(entityTypeId, entityTypeName);
    }
  };

  return (
    <>
      <div style={{ display: modalOpen ? "block" : "none" }} css={Overlay} />
      <Dialog
        header="Atenção"
        visible={nodeIsUsedAsVariableModal}
        onHide={() => setNodeIsUsedAsVariableModal(false)}
      >
        <div className="flex ">
          <span className="pi pi-exclamation-triangle mr-2" />
          <p>
            Entidade anterior está sendo utilizada como variável em outros
            nodes.
          </p>
        </div>
        <p>Esta informação será perdida.</p>
        <p>Deseja prosseguir com a alteração?</p>
        <div className="flex mt-4 mb-4 flex-row-reverse">
          <Button
            label="Sim"
            className="p-button-info"
            onClick={() => AcceptChange()}
          />
          <Button
            label="Não"
            className="p-button-danger ml-2 mr-2"
            onClick={RejectChange}
          />
        </div>
      </Dialog>
      <dialog
        style={{ display: modalOpen ? "flex" : "none" }}
        open={modalOpen}
        css={Modal}
      >
        <Toast ref={toast} style={{ position: "sticky", top: 0, zIndex: 15 }} />
        <Button
          style={{ width: "2rem", height: "3rem" }}
          onClick={() => dispatch(setEntitiesModalOpen(!modalOpen))}
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
          aria-label="Cancel"
        />
        <div css={Title}>Entidades personalizadas</div>
        <div className="flex mt-2 w-full justify-content-start align-items-start">
          <p css={Textp}>
            Crie listas personalizadas, para coletar dados e preferências dos
            seus usuários.
          </p>
        </div>
        <ContainerContent>
          <span className="p-input-icon-left mt-2 w-full">
            <i className="pi pi-search" />
            <InputText
              className="border-round-3xl w-full"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
            />
          </span>
          {!searchInput.length ? (
            <Entity
              onClick={() => dispatch(setCustomEntitiesModalOpen(true))}
              name="Entidade personalizada"
            ></Entity>
          ) : null}
          {entitiesAlreadySetOnThisNode?.length
            ? entitesRemaining?.map((_entity) => (
                <Entity
                  key={_entity.id}
                  onClick={() => setEntityNode(_entity.id, _entity.name)}
                  name={_entity.name}
                ></Entity>
              ))
            : entities?.result?.map((_entity) => (
                <Entity
                  key={_entity.id}
                  onClick={() => setEntityNode(_entity.id, _entity.name)}
                  name={_entity.name}
                ></Entity>
              ))}
        </ContainerContent>
        {/* <button>Close Modal</button> */}
      </dialog>
    </>
  );
};
