import { useFetch, useFetch2 } from "./util";

type FindAllType = {
  offset: number;
  limit: number;
  name?: string;
};

export default () => {
  const setup = useFetch("/api/setup", { method: "POST" });
  const resendEmail = useFetch("/api/setup/resend-email", { method: "POST" });
  const findAll = useFetch2();

  return {
    setup,
    resendEmail,
    findAll: async (input: FindAllType) => {
      try {
        const paramRequest = new URLSearchParams(
          input.name
            ? {
                offset: `${input.offset}`,
                limit: `${input.limit}`,
                name: input.name
              }
            : {
                offset: `${input.offset}`,
                limit: `${input.limit}`
              }
        );

        return findAll(`/api/companies?${paramRequest}`, { method: "GET" });
      } catch (error) {
        return error;
      }
    }
  };
};
