import { css } from "@emotion/react";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { forwardRef } from "react";

export default forwardRef<Dropdown, DropdownProps & { css?: any }>(
  ({ css: cssProp, ...props }, ref) => (
    <Dropdown
      css={[
        css`
          width: 100%;
        `,
        cssProp
      ]}
      {...props}
      ref={ref}
    />
  )
);
