/* eslint-disable func-style */
import { useFetch2 } from "../util";

function DashboardServices() {
  const useQuery = useFetch2();
  type getDashboardType = {
    bots: number[];
    endAt: string; // format Date 2023-04-01
    startAt: string; // format Date 2023-04-01
  };
  type getDashboardByHour = {
    bots: number[];
    day: string; // format Date 2023-04-01
  };
  // this code just return a promise function to who is calling a function.
  // const getDashboardByDates = (input: getDashboardType) => {
  //   const params = new URLSearchParams();
  //   params.append(
  //     "startAt",
  //     `${input.startAt || new Date().toISOString().substring(0, 10)}`
  //   );
  //   params.append(
  //     "endAt",
  //     `${input.endAt || new Date().toISOString().substring(0, 10)}`
  //   );
  //   if (input.bots?.length) {
  //     input.bots.map((el) => params.append("bots", String(el)));
  //   } else {
  //     params.append("bots", "0");
  //   }
  //   return useQuery(`/api/dashboard/{info}?${params}`, { method: "GET" });
  // };
  const getDashboardByDates = (input: getDashboardType) => {
    // const params = new URLSearchParams();
    // params.append(
    //   "startAt",
    //   `${input.startAt || new Date().toISOString().substring(0, 10)}`
    // );
    // params.append(
    //   "endAt",
    //   `${input.endAt || new Date().toISOString().substring(0, 10)}`
    // );
    // if (input.bots?.length) {
    //   input.bots.map((el) => params.append("bots", String(el)));
    // } else {
    //   params.append("bots", "0");
    // }
    return useQuery(`/api/dashboard/{info}`, { method: "POST" }, { ...input });
  };
  const getDashboardByHours = async (input: getDashboardByHour) => {
    const params = new URLSearchParams();
    if (input.bots?.length) {
      input.bots.map((el) => params.append("bots", String(el)));
    } else {
      params.append("bots", "0");
    }
    params.append(
      "day",
      `${input.day || new Date().toISOString().substring(0, 10)}`
    );
    try {
      const method = { method: "GET" };
      return await useQuery(
        `/api/dashboard/services-by-hour?${params}`,
        method
      );
    } catch (error) {
      return error;
    }
  };

  return {
    getDashboardByDates,
    getDashboardByHours
  };
}

export default DashboardServices;
