import { css } from "@emotion/react";

const TitleContent = css({
  color: "#0a043c",
  fontSize: "18px",
  fontWeight: "bold"
});

const subTitleContent = css({
  color: "#0A043C",
  fontSize: "14px",
  fontWeight: "400"
});

const avatarCss = css({
  // position: 'relative',
  // left: '7.5rem',
  // border: '2px solid #046AF3',

  position: "relative",
  left: "7.5rem",
  border: "3px black solid",
  borderColor: "#046AF3",
  borderSpacing: "initial",
  color: "#046AF3",
  backgroundColor: "#D4E2F3 !important"
});

const idColor = css({
  color: "#046AF3"
});

const noBorder = css({
  borderRadius: "0px !important"
});

const boxProfileHead = css({
  border: "solid 1px #E5E5E5",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: "2rem",
  paddingBottom: "1rem",
  borderBottom: "none"
});

const dtDiv_bodt = css({
  alignTtems: "center",
  display: "flex",
  gap: "10px",
  justifyContent: "center"
});

const buttonCustom = css({
  fontSize: "12px",
  padding: "4px 12px"
});

const separatorBar = css({
  borderTop: "0px solid #E5E5E5",
  margin: "16px -20px"
});
const saveBtn = css({
  color: "#046AF3",
  fontSize: "14px",
  fontWeight: "600"
});
const inputLabelDefault = css({
  position: "relative",
  top: "0.7rem",
  left: "8px",
  backgroundColor: "white",
  width: "fit-content",
  paddingLeft: "4px",
  paddingRight: "4px",
  fontSize: "12px",
  color: "#999",
  zIndex: "1"
});

const cssP = {
  backgroundColor: "white",
  borderTopLeftRadius: "20px",
  borderBottomLeftRadius: "20px",
  borderTopRightRadius: "50px",
  width: "100%"
};

export {
  cssP,
  saveBtn,
  idColor,
  noBorder,
  avatarCss,
  dtDiv_bodt,
  separatorBar,
  buttonCustom,
  TitleContent,
  boxProfileHead,
  subTitleContent,
  inputLabelDefault
};

// Use arrays to compose styles
// export const largeErrorCss = css([errorCss, { fontSize: '1.5rem' }])
