import { Flex } from './../../../header/components/workspaceSelector/styles';
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;
export const MyTag = styled.span`
display:flex;
align-items: center;
margin-right: 0.5rem;
justify-content: center;
padding: 0 1rem;
width: min-content;
height: 29px;
background: #D7DDE7;
border-radius: 80px;
`;
export const Modal = css`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 0.625rem;
  border: none;
  width: 20.75rem;
  /* height: 10rem; */
  z-index: 7;
  position: absolute;
  top: 0;
  left: 30%;
  overflow-y: scroll;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
export const Overlay = css`
  position: absolute;
  z-index: 6;
  min-height: 100vh;
  max-height: max-content;
  width: calc(100% + 2rem);
  top: 0;
  left: -2rem;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Span = styled.span`
margin-left: 0.5rem;
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
font-size: 0.875rem;
color: #046AF3;
background-color: #D4E2F3;
width: 1.0625rem;
height: 1.0625rem;
border-radius: 50%;
`;
export const ContainerContent = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 0.5rem;
/* overflow: scroll; */
/* height: calc(100% - 4rem ); */
`;

export const ContainerButton = styled.div`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  padding-left: 2rem;
  border-radius: 6px;
  width: 100%;
  height: 3.4375rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  z-index: 7;
  overflow: hidden;
  margin-top: 1rem;
  & + & {
    margin-top: 0.2rem;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const Disabled = styled.div`
background-color: rgba(255,255,255, 0.8);
position: absolute;
top: 0;
left: 0;
width: 100%;
z-index: 10;
height: 3.4375rem;
:hover {
  cursor: normal;
}
`;
export const DisabledButton = styled.div`
width: 100%;
height: 64px;
position: absolute;
z-index: 10;
top: 0;
left: 0;
background-color: rgba(255,255,255, 0.8);
`;
export const BlueBar = styled.div`
  width: 0.3rem;
  height: 100%;
  background: #046AF3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;

export const IconButtonBlue = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 2.5rem;
height: 2.5rem;
background: rgba(4, 106, 243, 0.12);
border-radius: 50%;
`;
export const IconButtonRed = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 2.5rem;
height: 2.5rem;
background: rgba(4, 106, 243, 0.12);;
border-radius: 50%;
`;
export const BoxAgentNotice = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
gap: 1rem;
padding-left: 1rem;
margin-top: 1rem;
width: 100%;
height: 51px;
background: rgba(4, 106, 243, 0.12);
border: 1px solid rgba(4, 106, 243, 0.3);
border-radius: 6px;
`;
export const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  border-radius: 6px;
  width: 160px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #000000;
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const Divisor = styled.div`
height: 1px;
width: 100%;
border: 1px solid #E5E5E5;
margin: 1rem 0;
`;
export const ContainerButtonEnd = styled.div`
  position: absolute;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;
export const SaveButton = styled.button`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
/* line-height: 24px; */
letter-spacing: 0.01em;
color: #0075FF;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color: transparent;
width: 160px;
height: 64px;
border: 1px solid #D2D2D2;
border-radius: 6px;
&:disabled {
  color: #E6F1FF;
}
&:hover {
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: #CCE3FF;
}
`;
interface IMyImage {
  disabled?: boolean
}
export const MyImage = styled.img<IMyImage>`
filter: ${props => props.disabled ? "invert(92%) sepia(94%) saturate(1778%) hue-rotate(178deg) brightness(104%) contrast(111%)" : ""};
// color: ${props => props.disabled ? "#CCE3FF" : "#E6F1FF"};
margin-right: 0.5rem;
&:hover {
  cursor: pointer;
  transition: all ease-in-out 250ms;
}
`;
export const LabelBlue = styled.label`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* line-height: 3.9375rem; */
  color: #046AF3;

`;