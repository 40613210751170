import styled from "@emotion/styled";

export const RightContent = styled.div`
width: 58%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-left: 3rem;
  `;