import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media screen and (min-width: 600px) and (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
    gap: 3px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  margin-right: 0.25rem;
  border-radius: 50%;
  border: 1px solid #cdcfd5;
`;

export const Image = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0a043c;
`;

export const Profiles = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0a043c;
`;

interface IDropdownLogout {
  open: boolean;
}
export const DropdownLogout = styled.div<IDropdownLogout>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  padding: 10px 0;
  width: 113px;
  left: 50%;
  z-index: 1;
  bottom: -60px;
  border-radius: 5px;
  box-shadow: 0px 0px 17px -3px rgba(75, 75, 75, 0.78);
  background: #ffffff;

  -webkit-box-shadow: 0px 0px 17px -3px rgba(75, 75, 75, 0.78);

  /* transform: rotate(180deg); */
`;
export const Triangle = styled.div`
  position: absolute;
  height: 0;
  width: 0;
  top: -7px;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid white;
`;
interface IMyIconButton {
  color: string;
}
export const MyIconButton = styled.div<IMyIconButton>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  height: 1.85rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: max-content;
  gap: 0.3rem;
  border: none;
  border-radius: 0.5rem;
  margin-left: 0.2rem;
  background: #fff;
  color: ${(props) => props.color};

  :hover {
    border: 1px solid #ffca7f;
    background-color: #ffa629;
    cursor: pointer;
  }
`;

export const Img = styled.img`
  :hover {
    cursor: pointer;
  }
`;
