import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ArrowDown, Person, Sair } from "../../../../assets";
import {
  Container,
  ContainerImage,
  DropdownLogout,
  Image,
  Img,
  MyIconButton,
  Name,
  Triangle,
  UserBox,
  Profiles
} from "./styles";
import { LetterCircle } from "../../../../components/gestaoBot/components/LetterCircle";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { setOpenModalMyAccount } from "../../../../redux/slices/user";
import { Tooltip } from "primereact/tooltip";

export const Identification: React.FC = () => {
  const { user } = useAuth() as AuthContextType;
  const basicUserInfo = useAppSelector((state) => state.user);
  const [openDropdown, setopenDropdown] = useState(false);

  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [isTablet, setIsTablet] = useState(false);

  const { logout } = useAuth();

  const userRoles = (fromTooltip: boolean) => {
    let roles = "";

    if (fromTooltip) {
      user.profiles.forEach((element, index) => {
        if (index > 0) {
          roles += ", ";
        }

        roles += element;
      });
    } else if (user.profiles.length > 1) {
      roles = `${user.profiles[0]}...`;
    } else {
      roles = user.profiles[0];
    }

    return roles;
  };

  const firstCapitalLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSair = () => {
    logout();
  };

  const handleSetUser = () => {
    dispatch(setOpenModalMyAccount(true));
    redirect(`/users/`);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsTablet(window.innerWidth > 600 && window.innerWidth < 820);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Container>
      <ContainerImage onClick={() => setopenDropdown(!openDropdown)}>
        {basicUserInfo && basicUserInfo?.avatarURL?.length ? (
          <Image src={basicUserInfo?.avatarURL} alt="Image-User-Avatar" />
        ) : (
          <LetterCircle initial={basicUserInfo?.name} />
        )}
      </ContainerImage>

      <UserBox>
        {!isTablet && (
          <Name>
            Olá, {firstCapitalLetter(basicUserInfo?.name.split(" ")[0])}
          </Name>
        )}

        <Profiles className="name-tooltip" data-pr-position="bottom">
          {userRoles(false)}
        </Profiles>

        <Tooltip target=".name-tooltip"> {userRoles(true)} </Tooltip>
      </UserBox>

      {!isTablet && (
        <Img
          style={{ marginLeft: "0.5rem", marginRight: "2rem" }}
          onClick={() => setopenDropdown(!openDropdown)}
          src={ArrowDown}
          alt="Arrow down"
        />
      )}

      <DropdownLogout open={openDropdown}>
        <div className="relative w-full h-full">
          <Triangle />

          <MyIconButton onClick={handleSetUser} color="#6E6B7B">
            <img src={Person} alt="Person Icon" />
            Minha conta
          </MyIconButton>
        </div>
      </DropdownLogout>
    </Container>
  );
};
