/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable func-style */
import { useFetch2, useFetchMultipart } from "../util";
import { EntityTypesDB } from "../types/entityTypes";
// import { tryAxios } from "../util/useFetchMultipart";
interface getEntityType {
  offset: number;
  limit: number;
  botId?: number;
  workspaces?: number[];
}
interface getSearchEntityType {
  offset: number;
  limit: number;
  name: string;
  workspaces?: number[];
}
function entityTypesServices() {
  const useQuery = useFetch2();
  const useFetchMultpart = useFetchMultipart();

  // this code just return a promise function to who is calling a function.
  const getEntities = (input?: getEntityType) =>
    useQuery(
      `/api/entityTypes?${new URLSearchParams({
        offset: `${input?.offset}` || "0",
        limit: `${input?.limit}` || "0",
        botId: `${input?.botId}`
        // workspaces: `${input?.workspaces}`
      })}`,
      { method: "GET" }
    );

  //   const getAllEntities = async (input?: getEntityType) => {
  //     const params = new URLSearchParams();
  //     params.append("limit", `${input?.limit || 10}`);
  //     params.append("offset", `${input?.offset || 0}`);
  //     if (input?.workspaces?.length) {
  //       input.workspaces.map(el => params.append("workspaces", String(el)))
  //     } else {
  //       params.append("workspaces", "0");
  //     }
  //     return useQuery(`/api/entityTypes/list?${params}`, { method: "GET" } )

  // }
  const getAllEntities = async (input?: getEntityType) => {
    return useQuery(`/api/entityTypes/list`, { method: "POST" }, { ...input });
  };
  const getEntitiesById = async (EntityId: number | any) => {
    try {
      const method = { method: "GET" };
      return await useQuery(`/api/entityTypes/${EntityId}`, method);
    } catch (error) {
      return error;
    }
  };

  const updateEntities = async (
    EntityId: number | any,
    data: EntityTypesDB
  ) => {
    try {
      const method = { method: "PUT" };
      return await useQuery(`/api/entityTypes/${EntityId}`, method, data);
    } catch (error) {
      return error;
    }
  };

  const searchEntities = async (input: getSearchEntityType) => {
    const method = { method: "POST" };
    // const params = new URLSearchParams();
    //   params.append("limit", `${input?.limit || 10}`);
    //   params.append("offset", `${input?.offset || 0}`);
    //   if (input.name)
    //     {params.append("name", `${input?.name}`);}
    //   if (input?.workspaces?.length) {
    //     input.workspaces.map(el => params.append("workspaces", String(el)))
    //   } else {
    //     params.append("workspaces", "0");
    //   }
    try {
      return await useQuery(`/api/entityTypes/list`, method, { ...input });
    } catch (error) {
      return error;
    }
  };

  const insertEntities = async (data: EntityTypesDB) => {
    try {
      const method = { method: "POST" };
      return await useQuery("/api/entityTypes", method, data);
    } catch (error) {
      return error;
    }
  };
  const uploadEntities = async (data: FormData) => {
    try {
      const method = { method: "POST" };
      // return await tryAxios.post("/api/entityTypes/upload", data);
      return await useFetchMultpart("/api/entityTypes/upload", method, data);
    } catch (error) {
      return error;
    }
  };

  return {
    getEntities,
    getEntitiesById,
    updateEntities,
    searchEntities,
    insertEntities,
    getAllEntities,
    uploadEntities
  };
}

export default entityTypesServices;

// const getEntities = (input?: getEntityType) => {
//   const params = new URLSearchParams();
//   params.append("limit", `${input?.limit || 10}`);
//   params.append("offset", `${input?.offset || 0}`);
//   params.append("botId", `${input?.botId || 0}`);
//     useQuery("/api/entityTypes?" + new URLSearchParams({
//       offset: `${input?.offset}` || '0',
//       limit: `${input?.limit}` || '0',
//       botId: `${input?.botId}`,
//       // workspaces: `${input?.workspaces}`
//     }), { method: "GET" } )
//   }
//   const getAllEntities = (input?: getEntityType) => {
//     const params = new URLSearchParams();
//     params.append("limit", `${input?.limit || 10}`);
//     params.append("offset", `${input?.offset || 0}`);
//     if (input?.workspaces?.length) {
//       input.workspaces.map(el => params.append("workspaceId", String(el)))
//     }
//     useQuery(`/api/entityTypes/list?${params}`, { method: "GET" } )
//   }
