import { css } from "@emotion/react";
import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { Sidebar } from "../sidebar";
import { useAppSelector } from "../../redux/hooks";
interface iLayout {
  children: ReactNode;
}
export const Layout: React.FC<iLayout> = ({ children }) => {
  const [screenMobile, setScreenMobile] = useState(false);
  const screenOmnichannel = location.pathname === "/omnichannel";
  const sidebarChats = useAppSelector((state) => state.sidebar.sidebarChats);

  useLayoutEffect(() => {
    let script: HTMLScriptElement;

    if (process.env.NODE_ENV === "production") {
      script = document.createElement("script");

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.icwToken = process.env.BOT_TOKEN;

      script.src = `https://${process.env.CHAT_URL}/main.js`;

      document.body.appendChild(script);
    }

    return () => {
      if (process.env.NODE_ENV === "production") {
        document.body.removeChild(script);

        const elements = document.getElementsByClassName("imbot-chat");

        for (const element of elements) {
          element.remove();
        }
      }
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!screenMobile ? (
        <div
          style={{ backgroundColor: "#f5f5f5", overflow: "hidden" }}
          className="h-screen flex relative"
        >
          <Sidebar />
          <div
            css={css`
              background-color: #f5f5f5;
              flex: 1 1 auto;
              height: 100%;
              overflow: auto;
            `}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            background: #f5f5f5;
            overflow: hidden;
            width: 100vw;
            height: 100vh;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              background-color: #f5f5f5;
              flex: 1 1 auto;
              overflow: auto;
            `}
          >
            {children}
          </div>
          {!screenOmnichannel ? (
            <Sidebar />
          ) : (
            <>{sidebarChats ? <Sidebar /> : null}</>
          )}
        </div>
      )}
    </>
  );
};
