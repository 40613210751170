import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Password, PoliticaPrivacidadeConfirmar } from "../components";
import Plataform from "../services/Plataform";
import { useFetch, v } from "../util";
import { background, botRed, dialogosInteligentes } from "./../assets";

const schema = v.object({
  newPassword: v.string().superRefine(v.nonempty())
});

export default () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      newPassword: ""
    },
    resolver: zodResolver(schema)
  });

  const { confirmationCode } = useParams();
  const resetPassword = useFetch(
    `/api/users/reset-password/${confirmationCode}`,
    { method: "POST" }
  );
  const navigate = useNavigate();
  const location = useLocation();

  const from =
    (
      location.state as {
        from: {
          pathname: string;
        };
      }
    )?.from?.pathname || "/confirm/";

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          <img src={dialogosInteligentes} />
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            font-size: 12px;
            margin-top: 10px;
          `}
        >
          Já possui uma conta? &nbsp;
          <Link to="/login">Login</Link>
        </div>

        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            gap: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img src={botRed} />
            <div>
              <span>Olá </span>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                ,
              </span>
              <div
                css={css`
                  font-weight: 600;
                `}
              >
                agora vamos redefinir a sua senha 😀
              </div>
            </div>
          </div>
          <span>Entre com a sua nova senha e confirmação para concluir.</span>
          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async (data) => {
                const { email } = await resetPassword(data);
                navigate(`/login/${email}`, { replace: true });
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div className="p-float-label">
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => <Password {...field} id="passwd" />}
                  />
                  <label htmlFor="passwd">Criar senha</label>
                </div>

                <div className="p-float-label">
                  <Password id="confirm" />
                  <label htmlFor="confirm">Confirmar senha</label>
                </div>

                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="Atualizar senha"
                />

                <div
                  css={css`
                    color: "#046AF3";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <i
                    css={css`
                      color: #046af3;
                    `}
                    className="pi pi-arrow-left"
                  ></i>
                  <Link to="/login">Voltar para o login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
