import { Toast } from "primereact/toast";
import { createContext, RefObject, useContext, useRef } from "react";

const ToastContext = createContext<RefObject<Toast>>(null!);

type ToastProviderProps = {
  children?: React.ReactNode;
};

export const ToastProvider = ({ children, ...props }: ToastProviderProps) => {
  const ref = useRef<Toast>(null);

  return (
    <ToastContext.Provider {...props} value={ref}>
      <Toast ref={ref} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
