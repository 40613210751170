import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import WorkspaceInviteTabNew from "./WorkspaceInviteTabNew";
import WorkspaceInviteTeam from "./WorkspaceInviteTeam";
import { Button } from "../..";
import { useAppSelector } from "../../../redux/hooks";
import WorkspaceEditInviteTeam from "./WorkspaceEditInviteTeam";

type conviteValueType = {
  email: string;
  cargo: number;
};

type AppProps = {
  conviteValue: conviteValueType[];
  onConviteChange: (value: conviteValueType[]) => void;
  equipeValue: { id: number }[];
  onEquipeChange: (value: { id: number }[]) => void;
  sendForm: () => void;
};

const WorkspaceInviteTab: React.FC<AppProps> = ({
  conviteValue,
  onConviteChange,
  equipeValue,
  onEquipeChange,
  sendForm
}: AppProps) => {
  const worksPaceMode = useAppSelector((state) => state.workspaces.mode);
  return (
    <div className="mt-2 panelWorkspace">
      <TabView className="mr-4" style={{ fontWeight: "unset !important" }}>
        <TabPanel header="Convidar novo">
          <WorkspaceInviteTabNew
            value={conviteValue}
            onChange={onConviteChange}
          />
        </TabPanel>

        <TabPanel header="Equipe">
          {worksPaceMode === "add" ? (
            <WorkspaceInviteTeam
              value={equipeValue}
              onChange={onEquipeChange}
            />
          ) : null}
          {worksPaceMode === "edit" ? (
            <WorkspaceEditInviteTeam onChange={onEquipeChange} />
          ) : null}
        </TabPanel>
      </TabView>
    </div>
  );
};

export default WorkspaceInviteTab;
