import React from "react";
import { Copy, Download, Expand, ThreePointsBlue } from "../../../../assets";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ButtonSquared, Container, ImgBtn } from "./styles";
import { toggleTagModal } from "../../../../redux/slices/flowInteractions";

const InteractionsHeader = () => {
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes?.filter((node) => node.selected === true)[0];
  const dispatch = useAppDispatch();
  return (
        <Container>
          {/* <button css={ButtonSquared}>
            <img src={Download} alt="Download-button" />
          </button> */}
          <div />
          <div>{nodeSelected?.data?.label}</div>
          <div className="flex justify-content-start">
            <ImgBtn onClick={() => dispatch(toggleTagModal())}>
              <img className="mr-3" src={ThreePointsBlue} alt="Three-points-icon" />
            </ImgBtn>
            
          </div>
        </Container>
  );
};

export default InteractionsHeader;
