import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

type propsType = { element: React.ReactNode; visible: boolean; }

export default ({ element, visible = false }: propsType) => { 
  
  const [mounted, setMounted] = useState(visible);

  const hasDOM = () => {
    return Boolean (typeof window !== "undefined" && window.document && window.document.createElement );
  };

  useEffect(() => {
    if (hasDOM() && !mounted) {
      setMounted(true);
    }
  }, [mounted]);

  return element && mounted ? createPortal(element, document.body) : null;
};
