import { css } from "@emotion/react";

export const HeaderDiv = css`
display: flex;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.01em;

color: #000000;

`;
export const IconChat = css`
margin-left: 1rem;
width: 28px;
height: 28px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #1F95EE;
`;