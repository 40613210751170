import { Fb3d, Instagram3d, MetaIcon } from "../../../../../../assets";
import { Icon1, Icon2, IconsContainer } from "./styles";

interface ILoggedIcons {
    type?: "FACEBOOK" | "INSTAGRAM";
  }
  
  export const LoggedIcons: React.FC<ILoggedIcons> = ({type}) => {
    return (
        <div className="flex relative -mt-2">
            <IconsContainer>
            {type === "FACEBOOK" && (

            <Icon1 src={Fb3d} alt="Facebook-Icon"/>
            )}
            {type === "INSTAGRAM" && (

            <Icon1 src={Instagram3d} alt="Facebook-Icon"/>
            )}
                <Icon2>
                    <img src={MetaIcon} alt="Meta-Icon" />
                </Icon2>
            </IconsContainer>
        </div>
    )
}