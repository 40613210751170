import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border: 1px solid #000;
  min-height: 100vh;
  max-height: 100vh;
  width: 25.5625rem;
  min-width: 25.5625rem;
  z-index: 11;
  position: absolute;
  top: calc(-25.5625rem/2 + 6.5rem);
  left: calc(100vw - 25.5625rem);
  margin-top: 100px;
  box-shadow: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
`;
export const Overlay = styled.div`
  position: fixed;
  z-index: 12;
  height: 100vh;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;
export const OverlayRelative = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const Span = styled.span`
margin-left: 0.5rem;
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
font-size: 0.875rem;
color: #046AF3;
background-color: #D4E2F3;
width: 1.0625rem;
height: 1.0625rem;
border-radius: 50%;
`;

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;


export const LabelBlue = styled.label`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* line-height: 3.9375rem; */
  color: #046AF3;

`;


export const BoxAgentNotice = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
gap: 1rem;
padding-left: 1rem;
margin-top: 1rem;
width: 100%;
height: 51px;
background: rgba(4, 106, 243, 0.12);
border: 1px solid rgba(4, 106, 243, 0.3);
border-radius: 6px;
`;
export const ContainerButtonEnd = styled.div`
  bottom: 1rem;
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;
export const SaveButton = styled.button`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
/* line-height: 24px; */
letter-spacing: 0.01em;
color: #0075FF;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color: transparent;
width: 160px;
height: 64px;
border: 1px solid #D2D2D2;
border-radius: 6px;
&:disabled {
  color: #E6F1FF;
}
&:hover {
  cursor: pointer;
  transition: all ease-in-out 250ms;
  background-color: #CCE3FF;
}
`;
interface IMyImage {
  disabled?: boolean
}
export const MyImage = styled.img<IMyImage>`
filter: ${props => props.disabled ? "invert(92%) sepia(94%) saturate(1778%) hue-rotate(178deg) brightness(104%) contrast(111%)" : ""};
// color: ${props => props.disabled ? "#CCE3FF" : "#E6F1FF"};
margin-right: 0.5rem;
&:hover {
  cursor: pointer;
  transition: all ease-in-out 250ms;
}
`;

export const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  border-radius: 6px;
  width: 160px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #000000;
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;