import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow-y: scrool;
  min-height: 400px;
  width: 100%;
  z-index: 13;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: -200px;
  box-shadow: rgba(0, 0, 0, 0.3);
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 11;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const AttributesStyle = styled.div`
  background: #ffffff;
  border: 1px solid #c4ccd8;
  border-radius: 6px;
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #544f77;
  z-index: 10;
  overflow: hidden;
  margin-top: 1rem;
  & + & {
    margin-top: 1rem;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const AttributeBlueBar = styled.div`
  width: 0.25rem;
  height: 100%;
  background: #046af3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
`;

export const Title = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0A043C;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544F77;
  text-align: left;
`;

export const Span = styled.span`
display: flex;
align-items: center;
justify-content: center;
font-weight: 400;
font-size: 0.875rem;
color: #046AF3;
background-color: #D4E2F3;
width: 1.0625rem;
height: 1.0625rem;
border-radius: 50%;
`;

export const IconDiv = styled.div`
margin-left: 1rem;
margin-right: 1rem;
width: 28px;
height: 28px;
background: #D4E2F3;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
`;
