import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = css`
  background-color: rgb(245, 245, 245);
  width: 100%;
  box-sizing: border-box;
`;
export const ContainerFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 0.5rem;
  @media (max-width: 1439px) {
    flex-wrap: wrap;
    justify-content: start;
    min-height: 825px;
  }
`;
export const ContainerBotIdentification = styled.div`
  width: 34%;
  min-width: 411px;
  @media (max-width: 1439px) {
    width: 50%;
  }
`;
export const ThirdCollumn = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;

  @media (max-width: 1439px) {
    flex-direction: row;
    height: unset;
  }
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ContainerAutomation = styled.div`
  flex-grow: 1;
  width: 33%;
  min-width: 438px;
  height: 546px;
`;
export const ContainerBotResume = styled.div`
  min-width: 461px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1439px) {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  gap: 0.5rem;
`;

export const LastContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;
