// import { Phone, TopPhone } from "../../assets";
import {
  Battery,
  MobileSignal,
  Wifi,
  whatsapp,
  WebChat,
  Reload
} from "../../assets";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  ButtonReload,
  ContentPhone,
  DivBottomPhone,
  DivCamera,
  DivCenterCamera,
  DivIcons,
  DivMicrofone,
  InputRounded,
  Modal,
  PhoneCss,
  TopPhoneCss,
  ButtonReloadDiv,
  InputContainer
} from "./styles";
import { Watch } from "./components/watch";
import { HeaderChat } from "./components/headerChat";
import { TabView, TabPanel } from "primereact/tabview";
import { TabChatTemplate } from "./components/TabChatTemplate";
import { Divider } from "primereact/divider";
import { Typing } from "./components/Typing";
import { Button } from "primereact/button";
import { UserTextBallon } from "./components/UserTextBallon";
import { RobotTextBallon } from "./components/RobotTextBallon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changedPhoneComponent } from "../../redux/slices/flowupdates";
import BotDialogTestServices, {
  Payload,
  returnDialogs
} from "../../services/botDialogTest";
import BotPublishDialogTestServices from "../../services/botPublishDialogTest";
import { onSave } from "../../util/onSave";
import { useParams } from "react-router-dom";
import { organizeNodes, setLevels } from "../../redux/slices/nodes";
import { Toast } from "primereact/toast";
import {
  setBotIdActive,
  setFlowIdActive
} from "../../redux/slices/flowInteractions";

interface IPhoneSimulator {
  node?: ReactNode;
}
interface ITexts {
  text: string;
  botTalk: boolean;
  time: Date;
  payload?: Payload[];
}
// export let rotateIcon: boolean = false;

export const PhoneSimulator: React.FC<IPhoneSimulator> = ({ node }) => {
  const dispatch = useAppDispatch();
  const [typing, setTyping] = useState(false);
  const [textEditing, setTextEditing] = useState("");
  const [dialogToken, setDialogToken] = useState("");
  const flowNameRedux = useAppSelector(
    (state) => state.flowAttributes.flowName
  );
  const { botId, flowId } = useParams();
  const [conversations, setConversations] = useState<ITexts[]>([]);
  const refPhoneContent = useRef<HTMLDivElement>(null);
  const { botDialogTest, postDialogsSimulator } = BotDialogTestServices();
  const { botPublishDialogTest } = BotPublishDialogTestServices();
  const [rotateIconComponent, setRotateIconComponent] = useState(false);
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  const tokenActive = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const toast = useRef<Toast>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextEditing(e.target.value);
    if (e.target.value?.length >= 1) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  };
  const sleep = (ms: number) => {
    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const handleRotateIcon = async () => {
    setRotateIconComponent(true);
    // Simulando a demora no retorno da api
    // await sleep(2000);
    // Api atualização bot
    dispatch(setLevels(edges));
    const publishBot = await botPublishDialogTest(
      parseInt(botId as string),
      parseInt(flowId as string),
      onSave(flowNameRedux, nodes)
    );
    setRotateIconComponent(false);
    setDialogToken("");
    setConversations([]);
    setTextEditing("");
    setRotateIconComponent(false);
    setTyping(false);
    dispatch(changedPhoneComponent(false));
  };

  const modalOpen = useAppSelector(
    (state) => state.flowAttributes
  ).updatePhoneComponent;
  useEffect(() => {
    dispatch(setBotIdActive(parseInt(botId as string)));
    dispatch(setFlowIdActive(parseInt(flowId as string)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId, flowId]);
  const handleBotConversation = (str: string, quickReply: Payload[] = []) => {
    setConversations((oldstate) =>
      oldstate.concat({
        text: str,
        botTalk: true,
        time: new Date(),
        payload: quickReply
      })
    );
  };
  const handleInsertConversation = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setConversations((oldstate) => [
        ...oldstate,
        {
          text: textEditing,
          botTalk: false,
          time: new Date()
        }
      ]);
      // Consultando a api
      try {
        const response = await postDialogsSimulator({
          message: [textEditing],
          dialogToken: dialogToken?.length > 0 ? dialogToken : null,
          botToken: tokenActive
        });
        if (response?.description === "Token do Bot não encontrado.") {
          toast?.current?.show({
            severity: "error",
            summary: "Bot não encontrado.",
            detail: `${response.description}`
          });
        }
        // simulando resposta com mais de uma interação.
        // const response = {
        //   message: ["Olá", "Qual o seu estado?"],
        //   dialogToken: "123"
        // }
        if (response?.responses.length > 1) {
          setTextEditing("");
          for (const item of response?.responses) {
            setTyping(true);
            // eslint-disable-next-line no-await-in-loop
            await sleep(1500);
            handleBotConversation(item?.message, item.payload);
            setTyping(false);
          }
        } else {
          setDialogToken(response?.dialogToken);

          // await HandleBotResponse(textEditing);
          setTextEditing("");
          setConversations([
            ...conversations,
            {
              text: textEditing,
              botTalk: false,
              time: new Date()
            },
            {
              text: response?.responses[0].message,
              botTalk: true,
              time: new Date(),
              payload: response?.responses[0].payload
            }
          ]);
        }
        setTyping(false);
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Bot não encontrado.",
          detail: `Token do Bot não encontrado`
        });
      }
    }
  };

  const setQuickResponse = async (response: string) => {
    const oldConversations = conversations;
    setConversations((oldstate) => [
      ...oldstate,
      {
        text: response,
        botTalk: false,
        time: new Date()
      }
    ]);
    setTyping(true);

    // Consultando a api
    try {
      const responseApi = await postDialogsSimulator({
        message: [response],
        dialogToken: dialogToken?.length > 0 ? dialogToken : null,
        botToken: tokenActive
      });
      if (responseApi?.description === "Token do Bot não encontrado.") {
        toast?.current?.show({
          severity: "error",
          summary: "Bot não encontrado.",
          detail: `${responseApi.description}`
        });
      }

      if (responseApi?.responses.length > 1) {
        setConversations((oldConversations) => [
          ...oldConversations,
          {
            text: response,
            botTalk: false,
            time: new Date()
          }
        ]);
        setTextEditing("");
        for (const item of responseApi?.responses) {
          setTyping(true);
          // eslint-disable-next-line no-await-in-loop
          await sleep(1500);
          handleBotConversation(item?.message, item.payload);
          setTyping(false);
        }
      } else {
        setDialogToken(responseApi?.dialogToken);

        // await HandleBotResponse(textEditing);
        setTextEditing("");
        setConversations([
          ...oldConversations,
          {
            text: response,
            botTalk: false,
            time: new Date()
          },
          {
            text: responseApi?.responses[0].message,
            botTalk: true,
            time: new Date(),
            payload: responseApi?.responses[0].payload
          }
        ]);
      }
      setTyping(false);
    } catch (error) {
      toast?.current?.show({
        severity: "error",
        summary: "Bot não encontrado.",
        detail: `Token do Bot não encontrado`
      });
      setTyping(false);
    }
  };

  useEffect(() => {
    if (refPhoneContent.current) {
      refPhoneContent.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  }, [conversations, textEditing]);
  return (
    <>
      <Toast ref={toast} />
      <div className="max-h-screen mt-2 m-2 mb-2">
        <div css={PhoneCss}>
          <div css={TopPhoneCss} />
          <Watch />
          <div css={DivIcons}>
            <img src={MobileSignal} alt="image-mobile-signal" />
            <img
              style={{ marginLeft: "0.3rem" }}
              src={Wifi}
              alt="image-mobile-signal"
            />
            <img
              style={{ marginLeft: "0.3rem" }}
              src={Battery}
              alt="image-mobile-signal"
            />
          </div>
          <div css={ContentPhone}>
            <HeaderChat />
            {/* <Divider /> */}
            {/* <TabView className="">
            <TabPanel
              headerStyle={{ width: "100%" }}
              contentStyle={{ padding: 0 }}
              header="Whatsapp"
              headerTemplate={
                <TabChatTemplate text="Whatsapp" icon={whatsapp} />
              }
            > */}
            <div className="flex flex-column overflow-y-scroll flex-grow-1 h-auto">
              {conversations?.map((conversation, i) =>
                conversation.botTalk ? (
                  <RobotTextBallon
                    setQuickResponse={setQuickResponse}
                    content={conversation.text}
                    quickReply={conversation?.payload}
                    date={conversation.time}
                    key={i}
                  />
                ) : (
                  <UserTextBallon
                    content={conversation.text}
                    date={conversation.time}
                    key={i}
                  />
                )
              )}
              <Typing active={typing} />
              <div ref={refPhoneContent} />
            </div>
            {/* </TabPanel>

            <TabPanel
              disabled
              headerStyle={{ width: "100%" }}
              header="Web chat"
              headerTemplate={
                <TabChatTemplate text="Web chat" icon={WebChat} />
              }
            ></TabPanel>
          </TabView> */}
            <InputContainer>
              <input
                onChange={handleOnChange}
                value={textEditing}
                css={InputRounded}
                onKeyUp={(e: React.KeyboardEvent<Element>) =>
                  handleInsertConversation(e)
                }
                placeholder="Escrever mensagem..."
              />
            </InputContainer>
          </div>
          <div css={DivMicrofone} />
          <div css={DivCamera}>
            <div css={DivCenterCamera} />
          </div>
          <div css={DivBottomPhone} />
          <div style={{ display: modalOpen ? "flex" : "none" }} css={Modal}>
            <div className="flex flex-column align-items-center justify-content-center">
              <ButtonReload
                rotateIcon={rotateIconComponent}
                onClick={handleRotateIcon}
                src={Reload}
                alt="reload-button"
              />
              <div
                css={ButtonReloadDiv}
                onClick={handleRotateIcon}
                className="mt-2"
              >
                {rotateIconComponent
                  ? "Configurando chatbot..."
                  : "Atualizar Bot"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
