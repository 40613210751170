import { useEffect, useState } from "react";
import { divWatch } from "./styles"

export const Watch: React.FC = () => {
    const [date, setDate] = useState(new Date());
    function refreshClock() {
        setDate(new Date());
      }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
          clearInterval(timerId);
        };
      }, []);
    return (
        <div css={divWatch}>{date.toLocaleTimeString().substring(0,5)}</div>
    )
}