import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IBaloon {
  expanded: string;
}
export const Baloon = styled.div<IBaloon>`
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 16px;
  padding: 1rem;
  padding-right: 3rem;
  /* padding-bottom: ${(props) => (props.expanded ? "6.5rem" : "1rem")}; */
  padding-bottom: ${(props) => props.expanded};
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  position: relative;
`;
export const ImageSixPoints = css`
  margin-right: 0.5rem;
`;

export const ButtonPlus = css`
  position: absolute;
  width: 25px;
  height: 25px;
  right: 1rem;
  top: 35%;
  background-color: #d8e9f6;
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;

export const ButtonTrash = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: -0.7rem;
  top: -0.7rem;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;
interface iMyCustomButton {
  bottom: string;
}
export const MyCustomButton = styled.button<iMyCustomButton>`
  width: 17.875rem;
  height: 2.625rem;
  max-width: 80%;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 15%;
  /* accent button shadow */
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 800px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;
