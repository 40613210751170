/* eslint-disable no-use-before-define */
import { useEffect, useState } from "react";
import { Container } from "./styles";

interface IAudioTimer {
  timeCurrent: number;
  timeTotal: number;
  isPlaying: boolean;
}
export const ClockAudio: React.FC<IAudioTimer> = ({timeCurrent, timeTotal, isPlaying}) => {
    
    // eslint-disable-next-line consistent-return
    const timeCurrentReturn = () => {
        if(timeCurrent >= 3600 && timeCurrent < 3600*24) { 
            const currentHour = Math.floor(Math.floor(timeCurrent)/60/60);
            const currentMinute = Math.floor(timeCurrent)/60;
            const currentSecond = Math.floor(timeCurrent%60);
            setcurrentTimeLocal(`${currentHour?.toString().padStart(2,'0')}:${currentMinute?.toString().padStart(2,'0')}:${currentSecond?.toString().padStart(2,'0')}`);
        }
        else if(timeCurrent >= 60 && timeCurrent > 1) { 
            const currentMinute = Math.floor(timeCurrent/60)
            const currentSecond = Math.floor(timeCurrent%60)
            setcurrentTimeLocal(`${currentMinute?.toString().padStart(2,'0')}:${currentSecond?.toString().padStart(2,'0')}`);
        }
        const currentMinute = Math.floor(timeCurrent/60)
        const currentSecond = Math.floor(timeCurrent%60)
        setcurrentTimeLocal(`${currentMinute?.toString().padStart(2,'0')}:${currentSecond?.toString().padStart(2,'0')}`);
    }
    // eslint-disable-next-line consistent-return
    const timeTotalReturn = () => {
        if(timeTotal >= 3600 && timeTotal < 3600*24) { 
            const totalHours = Math.floor(Math.floor(timeTotal)/60/60);
            const totalMinutes = Math.floor(timeTotal)/60;
            const totalSeconds = Math.floor(timeTotal%60);
            setTotalTimeLocal(`${totalHours?.toString().padStart(2,'0')}:${totalMinutes?.toString().padStart(2,'0')}:${totalSeconds?.toString().padStart(2,'0')}`);
        }
        // else if(timeTotal >= 60) { 
            const totalMinutes = Math.floor(timeTotal/60)
            const totalSeconds = Math.floor(timeTotal%60)
            setTotalTimeLocal(`${totalMinutes?.toString().padStart(2,'0')}:${totalSeconds?.toString().padStart(2,'0')}`);
        // }
    }
    const [currentTimeLocal, setcurrentTimeLocal] = useState('00:00')
    const [totalTimeLocal, setTotalTimeLocal] = useState('00:00')
    useEffect(() => {
        timeCurrentReturn()
        timeTotalReturn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCurrent, timeTotal, isPlaying]);
  return (
    <div>
      {isPlaying || timeCurrent > 0 ? (
        <Container>{currentTimeLocal}</Container>
      ) : (
        <Container>{totalTimeLocal}</Container>
      )}
    </div>
  );
};
