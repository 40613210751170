import {
  NodeData
} from "./../components/react-flow-renderer/NodeData.d";
import { Node, Edge, EdgeProps, getIncomers, getOutgoers } from "react-flow-renderer";

interface iLevels {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeProps>[];
}
interface IRetornoLevels {
  nodeId: string;
  level: number;
}
interface iNodeInternals {
id: string,
type?: 'endNode' | 'startNode' | 'defaultNode',
incomers: Node<NodeData>[],
incomersIds: string[],
incomersLength: number,
outgoers: Node<NodeData>[],
outgoersIds: string[],
outgoersLength: number,
visited: boolean,
level: number,
}

export const getLevels = (data: iLevels): IRetornoLevels[] => {
  const nodes: iNodeInternals[] = data.nodes.map((_node) => {
    return {
      id: _node.id.toString(),
      type: _node.type as "endNode" | "startNode" | "defaultNode" || undefined,
      incomers: getIncomers(_node, data.nodes, data.edges),
      incomersIds: getIncomers(_node, data.nodes, data.edges)
        .map((__node) => __node.id)
        .filter((notnulls) => notnulls !== null),
      incomersLength: getIncomers(_node, data.nodes, data.edges).length || 0,
      outgoers: getOutgoers(_node, data.nodes, data.edges),
      outgoersIds: getOutgoers(_node, data.nodes, data.edges)
        .map((__node) => __node.id)
        .filter((notnulls) => notnulls !== null),
      outgoersLength: getOutgoers(_node, data.nodes, data.edges).length || 0,
      visited: false,
      level: -1,
  }
  });
  // Set First Level
  const FunctionSetFistLevel = (nodes: iNodeInternals[]): iNodeInternals[] => {
    return nodes.map((_node) => {
      if (_node.type === "startNode" && !_node.visited) {
        return {..._node, level: 0, visited: true}
      }
      return _node
    })

  }

  const FunctionSetNextLevels = (StartLevel: number, nodes: iNodeInternals[]): iNodeInternals[] => {
    const nodeIdsToSetLevel = nodes?.reduce((acc, el) => {
      if (el.level === StartLevel)
        {return acc.concat(el.outgoersIds);}
      return acc
    }, [] as string[]);

    return nodes.map((_node) => {
      if (nodeIdsToSetLevel !== undefined){
        if (nodeIdsToSetLevel?.includes(_node.id) && !_node.visited) {
          return {..._node, level: StartLevel+1, visited: true}
        }}
        
        if (_node.incomersLength === 0 && _node.outgoersLength === 0) {
          return {..._node, level: -1, visited: true}
        }
        return _node
    }
    )
  }
  const setFirstLevelUntiedNodes = (nodes: iNodeInternals[]): iNodeInternals[] => {
    const setFirstLevelUntieds = nodes.map(node => {
      if (node.type !== "startNode" && !node.visited && !node.incomersLength) {
        return {...node, level: -1, visited: true}
      }
      return node
    })
    return setFirstLevelUntieds;
  }

  const FunctionIncludesVisitedFalse = (nodes: iNodeInternals[]): boolean => {
    const containsFalse = nodes.filter((_node) => _node.visited === false);
    return containsFalse.length > 0;
  }

  // Set First Level
  const nodesFirstLevel = FunctionSetFistLevel(nodes);
  // Set Untied Nodes
  const setUntieds = setFirstLevelUntiedNodes(nodesFirstLevel);
  // Set Next Levels
  let firstAndUntiedLevelNodes = setUntieds;
  let startLevel = 0;

  while (FunctionIncludesVisitedFalse(firstAndUntiedLevelNodes) && startLevel < 10000) {
    firstAndUntiedLevelNodes = FunctionSetNextLevels(startLevel, firstAndUntiedLevelNodes);
    startLevel ++;
  }
  const nodesLevels: IRetornoLevels[] = firstAndUntiedLevelNodes.map(el => {
    return {
      nodeId: el.id,
      level: el.level,
    }
  })
  return nodesLevels;
};

export const getLevel = (node: Node<NodeData>, data: iLevels): number => {
  const nodesLevels = getLevels(data);
  return nodesLevels.filter(el => el.nodeId === node.id)[0].level;
}
