import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const Modal = css`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: none;
  width: 98%;
  height: 95%;
  z-index: 7;
  position: absolute;
  top: 10px;
  left: 0;
  overflow-y: scroll;
`;
export const Overlay = css`
  position: absolute;
  z-index: 6;
  min-height: 100vh;
  max-height: max-content;
  width: calc(100% + 2rem);
  top: 0;
  left: -2rem;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Title = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0a043c;
  margin: 0 auto;
`;
export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #544f77;
  text-align: left;
  /* display: -webkit-inline-box; */
  &:after {
    display: inline-flex;
    flex-direction: row;
    margin-left: 0.5rem;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    align-items: center;
    justify-content: center;
    background-color: #c5dcff;
    content: "?";
  }
`;
export const ContainerContent = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
/* overflow: scroll; */
/* height: calc(100% - 4rem ); */
`;
