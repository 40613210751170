/* eslint-disable no-negated-condition */
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogPadrao from "../../../../components/DialogPadrao";
import {
  ContainerLeftModal,
  ContainerRightModal,
  DivImg,
  Img,
  PlayButton
} from "./styles";
import { setModalClonarBotOpen } from "../../../../redux/slices/flowInteractions";
import { Clone as CloneImg, PlayRed, girl } from "../../../../assets";
import { Text } from "../../../../components/Text/text";
import { Button, Dropdown, InputText, useToast } from "../../..";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../../../util";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import BotsServices from "../../../../services/bots";
import { useNavigate } from "react-router-dom";


export const ModalClonarBot: React.FC = () => {
  const { user } = useAuth() as AuthContextType;
  const { Clone } = BotsServices();
  const navigate = useNavigate();

  const show = useAppSelector(
    (state) => state.flowInteractions.modalClonarBotOpen
  );
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<{ name: string; workspaceId: number }>({
    defaultValues: {
      name: "",
      workspaceId: undefined
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        workspaceId: v.number().optional()
      })
    )
  });
  const toast = useToast();
  const errorNameMessage = () => errors.name?.message;
  // const errorBotSourceIdMessage = () => errors.botSourceId?.message;
  const errorWorkspaceIdMessage = () => errors.workspaceId?.message;
  const botId = useAppSelector((state) => state.flowInteractions.botIdActive);
  const sendForm = handleSubmit(async (data) => {
    try {
      await Clone({
        name: data.name,
        botSourceId: botId,
        workspaceId: data.workspaceId,
      }).
      then(() => {
        reset();
        dispatch(setModalClonarBotOpen(false));
        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Bot clonado com sucesso!!",
          life: 3000
        });
        navigate({ pathname: "/chatbots" });
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro",
        detail: e?.message,
        life: 3000
      });
    }
  });
  return (
    <DialogPadrao
      displayDialog={show}
      contentStyle={{ height: "420px" }}
      onHide={() => dispatch(setModalClonarBotOpen(false))}
    >
      <ContainerLeftModal>
        <div className="flex align-items-start justify-content-left gap-2">
          <DivImg
          style={{ marginLeft: "0.14rem" }}
           >
            <img src={CloneImg} alt="Clone-Image" />
          </DivImg>
          <div>
            <Text
              fontWeight={600}
              fontSize="0.9375rem"
              lineHeight="3.25rem"
              color="#0A043C"
              
            >
              Duplicar Bot             
            </Text>
          </div>
        </div>
        <form 
          onSubmit={sendForm} 
          style={{ marginTop: "25px", display: "flex", flexDirection: "column", justifyContent: "flex-start", flexGrow:1 }}

        >
          <div className="flex flex-column flex-grow-1">
          <div className="flex flex-column" style={{ gap: "25px" }}>
            <div>
              <div className="">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      id="name"
                      placeholder="Insira o nome do seu robô"
                    />
                  )}
                />
              </div>
              <div className="mt-1">
                {" "}
                {errors.name?.message !== undefined ? (
                  <> {errorNameMessage()} </>
                ) : (
                  ""
                )}{" "}
              </div>
            </div>
            </div>

            <div className="mt-2">
              <div className="">
                <Controller
                  name="workspaceId"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      id="workspaceId"
                      options={user?.workspaces?.workspaces}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Área de trabalho"
                      onChange={(e) => field.onChange(e.value)}
                      filter={true}
                    />
                  )}
                />
              </div>
              <div className="mt-1">
                {" "}
                {errors.workspaceId?.message !== undefined ? (
                  <> {errorWorkspaceIdMessage()} </>
                ) : (
                  ""
                )}{" "}
              </div>
            </div>

            </div>
          <div className="flex justify-content-center">
            <Button 
            label="Salvar" 
            disabled={isSubmitting} 
            style={{width: "12.375rem"}} 
            loading={isSubmitting}
            />
          </div>
        </form>
      </ContainerLeftModal>
      <ContainerRightModal>
        <Img src={girl} alt="Bot Girl" />
        <div className="mb-6">
          <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="#FFF">
            Dica para duplicar seu bot
          </Text>
        </div>
        <Text fontSize="12px" fontWeight={400} lineHeight="20px" color="#FFF">
          Duplicar um robô e aproveitar o script atual no Im bot oferece vantagens significativas aos usuários. 
          Isso permite uma rápida expansão e otimização das estratégias de criação de bots já estabelecidas. 
          Os benefícios incluem economia de tempo. Dessa forma, os usuários podem criar novos bots sem começar do zero.
        </Text>
        <div className="flex gap-2 align-items-center justify-content-center m-auto">
          <PlayButton>
            <img
              style={{ marginLeft: "0.14rem" }}
              src={PlayRed}
              alt="Icon-Play-Video"
            />
          </PlayButton>
          <Text
            fontSize="12px"
            fontWeight={500}
            lineHeight="16px"
            color="#FAFBFC"
            style={{ textAlign: "left" }}
          >
            Confira o vídeo
          </Text>
        </div>
      </ContainerRightModal>
    </DialogPadrao>
  );
};
