import styled from "@emotion/styled";

export const Container = styled.div`
padding: 1rem;
height: calc(100% - 79px);
width: calc(100vw - 111px);
display: flex;
flex-wrap: wrap;
flex-direction: column;
/* overflow-y: scroll; */
position: relative;
background-color: "#f5f5f5";
`;
export const BgGradient = styled.div`
position: absolute;
width: 100%;
height: 50%;
top: 0;
left: 0;
z-index: 0;
background: linear-gradient(154.77deg, #046AF3 9.18%, #95C2FF 90.33%);
`;
export const ContainerTransparent = styled.div`
position: relative;
width: auto;
height: max-content;
background-color: transparent;
`;
export const ContainerImage = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 4.5rem;
height: 4.5rem;
border: 2px solid #4290F9;
padding: 2px;
border-radius: 50%;
`;
export const DivImage = styled.div`
width: 100%;
height: 100%;
background-color: #F5F5F5;
border-radius: 50%;
overflow: hidden;
`;
export const Img = styled.img`
width: 3.75rem;
max-height: 100%;
max-width: 100%;
`;