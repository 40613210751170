import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Button, DataTable, InputText } from "../../..";
import { userList } from "../../../../assets";
import { useAppSelector } from "../../../../redux/hooks";
import * as comunCss from "../../../comum_css";
import "../../../comum_css/rawCss.css";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import TemplatesService from "../../../../services/TemplatesService";
import { useNavigate } from "react-router";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const FlowTemplatesListComponent: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menu = useRef<any>();
  const toast = useRef<Toast>(null);
  const redirect = useNavigate();

  const effectRan = useRef(false);
  const openModalMyAccount = useAppSelector(
    (state) => state.user.openMyAccount
  );
  const shouldUpdateFromRedux = useAppSelector(
    (state) => state.flowUpdates.shouldUpdate
  );
  const { deleteTemplate, templatesList } = TemplatesService();
  const { user, company, hasAnyPermission } = useAuth() as AuthContextType;
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [tablePropsData, setTablePropsData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const debouncedText = useDebounce(globalFilterValue, 600);
  const [rowDataId, setRowDataId] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });
  // Fetching Data from endpoint to list Usuario=================>>
  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  const changeStateList = () => {
    setShouldUpdate((prevSt) => !prevSt);
  };

  const showSuccess = (message: string) => {
    changeStateList();
    if (message) {
      toast?.current?.show({
        severity: "success",
        summary: "Atenção",
        detail: message,
        life: 3000
      });
    }
  };
  const showError = (message: string) => {
    toast?.current?.show({
      severity: "error",
      summary: "Atenção",
      detail: message,
      life: 3000
    });
  };
  const onPage = (event: never) => {
    setLazyParams(event);
  };
  const fetch = async () => {
    try {
      if (selectedWorkspacesIds.length) {
        const data = await templatesList({
          offset: lazyParams.first,
          limit: lazyParams.rows,
          companyId: user.admin ? company.id : user.companyId,
          name: debouncedText || "",
          workspaces: selectedWorkspacesIds
        });
        const tableProps = {
          first: lazyParams.first,
          lazy: true,
          onPage,
          paginator: true,
          rows: lazyParams.rows,
          totalRecords: data.total,
          value: data.result
        };
        setTablePropsData(tableProps);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      showError(e.message);
    }
  };
  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate(rowDataId);
      showSuccess("Template excluído com sucesso!");
      fetch();
    } catch (error) {
      showError(`Erro ao excluir template! Por favor. Tente mais tarde!`);
    }
  };
  useEffect(() => {
    if (effectRan.current) {
      fetch();
    }
    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyParams,
    shouldUpdate,
    selectedWorkspacesIds,
    shouldUpdateFromRedux,
    openModalMyAccount,
    debouncedText
  ]);

  const MenuModelCheck = () => {
    if (
      user.admin ||
      (hasAnyPermission("template_view") && hasAnyPermission("template_remove"))
    ) {
      return [
        {
          label: "Visualizar",
          command: () => redirect(`/drawflow/templates-view/${rowDataId}`)
        },
        {
          label: "Remover",
          command: () => handleDeleteTemplate()
        }
      ];
    }
    if (!user.admin && hasAnyPermission("template_view")) {
      return [
        {
          label: "Visualizar",
          command: () => redirect(`/drawflow/templates-view/${rowDataId}`)
        }
      ];
    }
    if (!user.admin && hasAnyPermission("template_remove")) {
      return [
        {
          label: "Remover",
          command: () => handleDeleteTemplate()
        }
      ];
    }
    return [];
  };

  const inputStyles = css`
    width: 342px;

    @media screen and (max-width: 505px) {
      width: 100%;
    }
  `;

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-5 gap-2">
        <div
          css={css`
            align-items: center;
            display: flex;
            gap: 10px;
          `}
        >
          <img src={userList} />
          <div css={comunCss.TitleContent}>Flow Templates</div>
        </div>

        <div
          css={css`
            margin-top: 10px;
          `}
        >
          Gerencie seus templates.
        </div>

        <div
          css={css`
            margin-top: 10px;
          `}
        >
          <div
            css={css`
              background-color: #ffffff;
              border-radius: 10px 10px 0px 0px;
              padding: 8px 12px;
            `}
          >
            <span className="p-input-icon-left" css={inputStyles}>
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={(e) => setGlobalFilterValue(e.target.value)}
                id="name"
                placeholder="Buscar"
              />
            </span>
          </div>

          <DataTable
            {...tablePropsData}
            filterDelay={600}
            emptyMessage="Nenhum resultado encontrado"
          >
            <Column field="id" header="Id" />
            <Column field="name" header="Nome" />
            <Column field="createdBy" header="Responsável" />

            <Column
              align="center"
              alignHeader="center"
              headerStyle={{ width: "12%" }}
              body={(rowData) => (
                <div css={comunCss.dtDiv_bodt}>
                  {user.admin || hasAnyPermission("template_view") ? (
                    <Button
                      label="Visualizar"
                      className="p-button-outlined"
                      onClick={() =>
                        redirect(`/drawflow/templates-view/${rowData.id}`)
                      }
                      css={comunCss.buttonCustom}
                    />
                  ) : null}
                  <Menu
                    model={MenuModelCheck()}
                    popup={true}
                    ref={menu}
                    id="popup_menu"
                    style={{ fontSize: "12px" }}
                  />
                  <Button
                    icon="pi pi-angle-down"
                    className="p-button-outlined"
                    onClick={(event) => {
                      menu.current?.toggle(event);
                      setRowDataId(rowData.id);
                    }}
                    aria-controls="popup_menu"
                    aria-haspopup={true}
                    css={comunCss.buttonCustom}
                  />
                </div>
              )}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
