import { useFetch2 } from "../util";
function BotsServices() {
  const fetch = useFetch2();

  const GetWhatsappInstance = async (userId: number) => {
    return fetch(`/api/users/whatsapp/instance/${userId}`, { method: "GET" });
  };

  const GetWhatsappInstanceInfo = async (userId: number) => {
    return fetch(`/api/users/whatsapp/instance-info/${userId}`, {
      method: "GET"
    });
  };

  const GetWhatsappImage = async (userId: number) => {
    return fetch(`/api/users/whatsapp/qrCode/${userId}`, { method: "GET" });
  };

  const DisconectWhatsapp = async (userId: number) => {
    return fetch(`/api/users/whatsapp/logout-instance/${userId}`, {
      method: "DELETE"
    });
  };

  return {
    GetWhatsappInstance,
    GetWhatsappInstanceInfo,
    GetWhatsappImage,
    DisconectWhatsapp
  };
}

export default BotsServices;
