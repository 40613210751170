import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const BotBallonPosition = css`
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  text-align: left;
  white-space: break-word;
`;
export const BotBallon = css`
  display: flex;
  width: max-content;
  margin-left: 0.25rem;
  max-width: 250px;
  flex-direction: column;
  padding: 10px 14px 10px 10px;
  gap: 4px;
  background: #f0eff5;
  border-radius: 16px 16px 16px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 1rem;
`;

export const divWatch = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.02em;
  color: #adb5bd;
`;

export const IconChat = css`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1f95ee;
  margin-left: 0.2rem;
`;

export const QuickReply = styled.div`
  display: flex;
  width: max-content;
  margin-left: 2.5rem;
  max-width: 85%;
  width: 16rem;
  white-space: break-word;
  min-width: 6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px 10px 10px;
  gap: 4px;
  background: #fff;
  border-radius: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #046af3;
  border: 1px solid #d2d2d2;
  margin-bottom: 0.3rem;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 800px;
  :hover {
    cursor: pointer;
    color: #fff;
    background-color: #7887ab;
    transition: 0.2s ease-in-out;
  }
`;

export const MyLink = styled.a`
  display: flex;
  width: max-content;
  margin-left: 2.5rem;
  max-width: 85%;
  width: 16rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px 10px 10px;
  gap: 4px;
  overflow-x: scroll;
  text-decoration: none;
  background: #fff;
  border-radius: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #046af3;
  margin-bottom: 0.3rem;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 800px;
  :hover {
    cursor: pointer;
    color: #fff;
    background-color: #7887ab;
    transition: 0.2s ease-in-out;
  }
`;

export const MyCustomButton = styled.button`
  width: 17.875rem;
  height: 2.625rem;
  max-width: 80%;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  position: absolute;
  bottom: 1rem;
  right: 15%;
  /* accent button shadow */
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 800px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
  }
`;
