import { InputSwitch } from "primereact/inputswitch";
import { Text } from "../../../../../../components/Text";
import { Container, Icon, IconDiv } from "./styles";

interface ISocialMediaPage {
    img: string;
    pageTitle: string;
    pageId: string;
    activateDate: string;
    active: boolean;
    connect: (pageId: string) => void;
    disconnect:  (pageId: string) => void;

}
export const SocialMediaPage: React.FC<ISocialMediaPage> = ({
    img,
    pageTitle,
    pageId,
    activateDate,
    active,
    connect,
    disconnect
}) => {
    // const [activeState, setActiveState] = useState(active)
    const onChangeActiveInternalState = () => {
        if (active) {
            disconnect(pageId);
        } else {
            connect(pageId);
        }
        // setActiveState(!active);
    }

    return (
        <Container>
            <div className="flex flex-none">

            <IconDiv>
                <Icon src={img} alt={pageTitle} />
            </IconDiv>
            </div>
            <div className="flex flex-column align-items-start flex-grow-1">
                <Text s="0.875rem" lh="1.3125rem" weight={500} color="#4C5862">{pageTitle}</Text>
                <Text s="0.75rem" lh="1.125rem" weight={400} color="#999999">{`Id da Página: ${pageId}`}</Text>
                <Text s="0.75rem" lh="1.125rem" weight={400} color="#999999">{`Data da Ativação: ${new Date(activateDate).toLocaleDateString()} - ${new Date(activateDate).toLocaleTimeString()}`}</Text>
            </div>
            <div className="flex flex-none">
            <InputSwitch checked={active} onChange={onChangeActiveInternalState} />
            </div>
        </Container>
    )
}