import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { girl } from "../../../assets";
require("../../comum_css/rawCss.css");
import WorkspaceForm from "./WorkspaceForm";
import { IoMdPlayCircle } from "react-icons/io";
import { Message } from "primereact/message";

type IAddWorkspaceProps = {
  displayWorkspace: boolean;
  onHide: (status?: string) => void;
};

type MessageSeverityType = "success" | "info" | "warn" | "error";

type messageType = {
  type?: MessageSeverityType;
  title: string;
};
const defaultMessage = {
  // type: '',
  title: ""
};

const AddWorkspace: React.FC<IAddWorkspaceProps> = ({
  displayWorkspace,
  onHide
}: IAddWorkspaceProps) => {
  const [showMessage, setShowMessage] = useState(false);
  const [messageData, setMessageData] = useState<messageType>(defaultMessage);

  // hide message after 5 secunds
  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 8000);
  }, [showMessage]);

  const hideDialog = (status?: string) => {
    onHide(status);
  };

  const apresentaMensagem = (messageObj: messageType): void => {
    setMessageData(messageObj);
    setShowMessage(true);
  };

  // const defDialogCss = { width: "50vw", height: "29vw" };
  const dialogPreference = { width: "60vw", height: "85vh" };

  return (
    <div>
      <Dialog
        className="addWspaceDialog relative"
        visible={displayWorkspace}
        closeOnEscape={true}
        resizable={false}
        closable={true}
        draggable={false}
        style={dialogPreference}
        onHide={() => hideDialog()}
        headerStyle={{
          backgroundColor: "#ff0025"
        }}
      >
        <div className="rigtContent"></div>

        <div className="rigtContentFake">
          <WorkspaceForm
            showMessageBox={apresentaMensagem}
            onHideDialog={hideDialog}
          />
          {showMessage && (
            <Message
              className="absolute bottom-0"
              style={{ width: "81%", height: "13%", marginLeft: "10px" }}
              severity={messageData?.type}
              text={messageData?.title}
            />
          )}
        </div>

        <div className="leftContent">
          <div>
            <div
              css={css`
                color: white;
                padding: 0px 30px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <img
                  src={girl}
                  css={css`
                    margin-top: 20px;
                  `}
                />
              </div>

              <div>Dica para workspace e colaboradores</div>
              <br />
              <div style={{ fontSize: "12px" }}>
                Escolha um nome descritivo, saiba que a workspace pai é
                opcional, se necessário, convide colaboradores para a workspace
                para facilitar a colaboração.
              </div>
              <div className="mt-2">
                <IoMdPlayCircle
                  style={{ top: "8px", marginRight: "0.3rem" }}
                  size={25}
                  color="white"
                  className="relative"
                />
                <span>Vídeo - Workspaces</span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddWorkspace;
