import styled from "@emotion/styled";

interface IBorder {
    color: string;
}
export const Border = styled.div<IBorder>`
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
width: 1.5rem;
height: 1.5rem;
padding: 0.1rem;
border: ${props => `1px solid ${props.color}`};
`;
interface ISpan {
    color: string;
}
export const Label = styled.label<ISpan>`
width: 100%;
height: 100%;
border-radius: 50%;
background-color: ${props => props.color};
`;

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
gap: 0.5rem;
`;