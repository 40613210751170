/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
import React, { useEffect, useState } from "react";
import InteracaoRobo from "../components/interacaoRobo";
import { InteracaoUsuario } from "../components/interacaoUsuario";
import InteractionsHeader from "../components/interactionsHeader";
import { TabView, TabPanel } from "primereact/tabview";
import {
  Span,
  TextNoMargin,
  CheckButton,
  ContainerButton,
  ContainerContent,
  ContainerTab,
  Container,
  ContainerInside,
  Transfer,
  CardrLeft,
  CardRight,
  ButtonRoundedBigBlue,
  ButtonRoundedBigRed,
  TransferAdd,
  ContainerIcon,
  BackgrounIcon
} from "./styles";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import { EdgeChange, XYPosition } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  changeNode,
  setLevels,
  setwebhookCall,
  nodeTransfer,
  setNodeTransferData
} from "../../../redux/slices/nodes";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { onEdgesChange } from "../../../redux/slices/edges";
import { BluePencil, Check, TransferIcon, TrashRed } from "../../../assets";
import DialogTab from "../components/dialogTab";
import ExceptionsTab from "../components/exceptionsTab";
import { changedPhoneComponent } from "../../../redux/slices/flowupdates";
import InteracaoRoboStartNode from "../components/interacaoRobo/startNode";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BotPublishDialogServices from "../../../services/botPublishDialog";
import { onSave } from "../../../util/onSave";
import { MyImage } from "../components/Entities/CustomEntities/styles";
import { Text } from "../../../components/Text";
import { Entities } from "../components/Entities";
import { CustomEntities } from "../components/Entities/CustomEntities";
import { Exceptions } from "../components/Exceptions";
import {
  setAddMoreTransferOptions,
  setAdvisor,
  setCustomButtonModalOpen,
  setCustomEntitiesModalOpen,
  setCustomEntityUpdating,
  setEntitiesModalOpen,
  setEntityIdUpdate,
  setInsertEntity,
  setModalOpenRobotActions,
  setOverlayInteractionsShow,
  setSendFileModalOpen,
  setTransferModal,
  toggleTransferModal
} from "../../../redux/slices/flowInteractions";
import { IRobotInteraction } from "../../../components/react-flow-renderer/NodeData";
import { LetterCircle } from "../../../components/gestaoBot/components/LetterCircle";
import { DivRoundedBigRed } from "../components/interacaoUsuario/styles";
import { TransferModal } from "../components/Transfer";
import { ProgressSpinner } from "primereact/progressspinner";
import { useToast } from "../../../components/toast-provider";
import { TagModal } from "../components/TagModal";

export const DefaultInteraction: React.FC = () => {
  const { botUpdateDialog } = BotPublishDialogServices();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const nodes = useAppSelector((state) => state.nodes);
  const edges = useAppSelector((state) => state.edges);
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const flowNameRedux = useAppSelector(
    (state) => state.flowAttributes.flowName
  );
  const nodeSelected = nodes.find((node) => node.selected === true);
  const qtdeSelected = nodes?.filter((node) => node?.selected);
  // Transferencias Webhook e Omnichannel
  //
  const transferedOmnichannel =
    nodeSelected?.data?.transferData?.thirdPartySystem;
  const transferedWebhook =
    nodeSelected?.data?.transferData?.workspaceOnWebhook ||
    Number(nodeSelected?.data?.transferData?.workspaceId) > 0;
  const exclusiveXor =
    (transferedOmnichannel && !transferedWebhook) ||
    (!transferedOmnichannel && transferedWebhook);
  const { botId, flowId } = useParams();
  const [loading, setLoading] = useState(false);
  const [nodeIsUsedAsVariableModal, setNodeIsUsedAsVariableModal] =
    useState(false);
  const location = useNavigate();

  const dialogTabTemplate = () => {
    return (
      <div onClick={() => setActiveIndex(0)}>
        <DialogTab />
      </div>
    );
  };
  const exceptionsTabTemplate = () => {
    return (
      <div onClick={() => setActiveIndex(1)}>
        <ExceptionsTab />
      </div>
    );
  };
  const addMoreTransferOptions = () => {
    dispatch(setAddMoreTransferOptions(true));
    dispatch(toggleTransferModal());
  };
  useEffect(() => {
    setActiveIndex(0);
    dispatch(setEntityIdUpdate(-1));
    dispatch(setCustomEntityUpdating(false));
    dispatch(setInsertEntity(false));
    dispatch(setTransferModal(false));
    dispatch(setAdvisor(false));
    dispatch(setModalOpenRobotActions(false));
    dispatch(setOverlayInteractionsShow(false));
    dispatch(setAdvisor(false));
    dispatch(setEntitiesModalOpen(false));
    dispatch(setCustomEntitiesModalOpen(false));
    dispatch(setCustomButtonModalOpen(false));
    dispatch(setSendFileModalOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSelected?.id]);

  const AcceptDelete = () => {
    setNodeIsUsedAsVariableModal(false);
  };

  const RejectDelete = () => {
    setNodeIsUsedAsVariableModal(false);
  };
  const [visible, setVisible] = useState(false);
  const handleCheckTransfer = (e: InputSwitchChangeParams) => {
    if (e.value) {
      if (!transferedOmnichannel && !transferedWebhook) {
        dispatch(toggleTransferModal());
      } else {
        dispatch(nodeTransfer(true));
      }
    }
    if (!e.value) {
      dispatch(nodeTransfer(false));
    }
  };
  const headerStyle = {
    width: "100%"
  };
  const handleCheckWebhook = (e: InputSwitchChangeParams) => {
    dispatch(
      setwebhookCall({
        nodeId: nodeSelected?.id as string,
        webhookCall: e.value
      })
    );
  };
  const handleToggleTypeNode = () => {
    setVisible(true);
  };

  const Accept = () => {
    if (nodeSelected?.type === "defaultNode") {
      const newNode = {
        ...nodeSelected,
        type: "endNode",
        data: {
          label: nodeSelected?.data.label,
          userInteractions: [],
          botInteractions: nodeSelected?.data.botInteractions,
          entityTypeId: -1,
          entityTypeName: "",
          sourceEntityNodeId: "",
          webhookCall: nodeSelected?.data?.webhookCall
        }
      };
      const edgesToDelete = edges
        .map((edge) => {
          if (edge.source.includes(nodeSelected?.id)) {
            return {
              id: edge.id,
              type: "remove"
            };
          }
          return undefined;
        })
        .filter((notUndefineds) => notUndefineds !== undefined);
      dispatch(onEdgesChange(edgesToDelete as EdgeChange[]));
      dispatch(changedPhoneComponent(true));
      dispatch(changeNode({ nodeId: nodeSelected?.id, node: newNode }));
      setVisible(false);
    } else {
      const userInteractionsOldNode = {
        id: (Math.random() * 10000000).toString(),
        source: "",
        target: "",
        comparison: "Igual a",
        phrases: [],
        text: "",
        entityTypeName: "",
        entityTypeId: -1
      };
      const newNode = {
        ...nodeSelected,
        id: nodeSelected?.id as string,
        position: nodeSelected?.position as XYPosition,
        type: "defaultNode",
        data: {
          ...nodeSelected?.data,
          label: nodeSelected?.data?.label as string,
          userInteractions: [userInteractionsOldNode],
          botInteractions: nodeSelected?.data
            .botInteractions as IRobotInteraction[],
          entityTypeId: -1,
          entityTypeName: "",
          sourceEntityNodeId: ""
        }
      };
      dispatch(
        changeNode({ nodeId: nodeSelected?.id as string, node: newNode })
      );
      dispatch(changedPhoneComponent(true));
      setVisible(false);
    }
  };
  const handleUpdateFlow = async () => {
    dispatch(setLevels(edges));
    setLoading(true);
    try {
      const res = await botUpdateDialog({
        botId: parseInt(botId as string),
        flowId: parseInt(flowId as string),
        data: onSave(flowNameRedux, nodes)
      });
      if (res.id) {
        location(`/drawflow/${botId}/${res.id}`, { replace: true });
        toast?.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Script salvo com sucesso!"
        });
      }
    } catch (error) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Erro ao salvar script. Por favor tente mais tarde!"
      });
    } finally {
      setLoading(false);
    }
  };
  const Reject = () => {
    setVisible(false);
  };

  const handleUnsetTransferWebhook = () => {
    //
    dispatch(
      setNodeTransferData({
        onlyOnlineUser: nodeSelected?.data?.transferData
          ?.onlyOnlineUser as boolean,
        queue: nodeSelected?.data?.transferData?.queue as number,
        thirdPartySystem: nodeSelected?.data?.transferData
          ?.thirdPartySystem as boolean,
        userId: null,
        userName: "",
        workspaceId: null,
        workspaceOnWebhook: false
      })
    );

    // dispatch(toggleTransferModal());
    dispatch(setAddMoreTransferOptions(false));
    if (exclusiveXor) {
      dispatch(nodeTransfer(false));
    }
    // setVisible(false);
  };
  return (
    <Container>
      <TagModal />
      <Entities />
      <CustomEntities />
      <Dialog
        header="Atenção"
        visible={nodeIsUsedAsVariableModal}
        onHide={() => setNodeIsUsedAsVariableModal(false)}
      >
        <div className="flex ">
          <span className="pi pi-exclamation-triangle mr-2" />
          <p>
            Este node contém entidade que está sendo utilizada como variável em
            outro(s) node(s).
          </p>
        </div>
        <p>Esta informação será perdida.</p>
        <p>Deseja prosseguir com a exclusão?</p>
        <div className="flex mt-4 mb-4 flex-row-reverse">
          <Button
            label="Sim"
            className="p-button-info"
            onClick={() => AcceptDelete()}
          />
          <Button
            label="Não"
            className="p-button-danger ml-2 mr-2"
            onClick={RejectDelete}
          />
        </div>
      </Dialog>
      <InteractionsHeader />
      {qtdeSelected.length === 1 ? (
        <ContainerInside>
          {/* <ButtonLoadScripts content="Carregar scripts" /> */}
          <TabView
            style={{ position: "sticky", top: 0 }}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel
              headerStyle={{ width: "100%" }}
              // contentStyle={{ padding: 0 }}
              header="Diálogo"
              headerTemplate={dialogTabTemplate}
            >
              <ContainerTab>
                <ContainerContent>
                  {nodeSelected?.type === "startNode" ? (
                    <InteracaoRoboStartNode />
                  ) : (
                    <InteracaoRobo />
                  )}
                  {nodeSelected?.data?.userInteractions?.length &&
                  nodeSelected?.type !== "endNode"
                    ? nodeSelected?.data?.userInteractions?.map(
                        (inter, idx) => (
                          <InteracaoUsuario
                            userInteraction={inter}
                            key={idx}
                            index={idx}
                            total={nodeSelected?.data?.userInteractions?.length}
                          />
                        )
                      )
                    : null}
                  <div className="flex align-items-center justify-content-around w-full">
                    <div className="flex flex-column align-items-center justify-content-center">
                      <InputSwitch
                        disabled={isTemplate}
                        checked={nodeSelected?.type === "endNode" || false}
                        onChange={handleToggleTypeNode}
                      />
                      <div className="flex">
                        <div className="ml-1" css={TextNoMargin}>
                          Finalizar
                        </div>
                        <Span>?</Span>
                      </div>
                    </div>
                    <div className="flex flex-column align-items-center justify-content-center">
                      <InputSwitch
                        disabled={isTemplate}
                        checked={nodeSelected?.data?.transfer}
                        onChange={(e) => handleCheckTransfer(e)}
                        // disabled={nodeSelected?.type !== "endNode"}
                      />
                      <div className="flex">
                        <div className="ml-1" css={TextNoMargin}>
                          Transferir
                        </div>
                        <Span>?</Span>
                      </div>
                    </div>
                    <div className="flex flex-column align-items-center justify-content-center">
                      <InputSwitch
                        checked={nodeSelected?.data?.webhookCall}
                        onChange={(e) => handleCheckWebhook(e)}
                        disabled={isTemplate}
                        // disabled={nodeSelected?.type !== "endNode"}
                      />
                      <div className="flex">
                        <div className="ml-1" css={TextNoMargin}>
                          Webhook
                        </div>
                        <Span>?</Span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 mb-2 h-1rem" />
                  {/* Transferencias Webhook e Omnichannel */}
                  {nodeSelected?.data?.transfer && transferedWebhook ? (
                    <Transfer>
                      <CardrLeft>
                        <Text s="12px" weight={400} color="#707981">
                          Transferido para
                        </Text>
                        <div className="flex align-items-center">
                          <div style={{ marginLeft: "-0.5rem" }}>
                            <LetterCircle
                              initial={
                                Number(
                                  nodeSelected?.data?.transferData?.userId
                                ) > 0
                                  ? `${nodeSelected?.data?.transferData?.userName}`
                                  : nodeSelected?.data?.transferData
                                      ?.workspaceOnWebhook
                                  ? "Workspace set by Webhook"
                                  : "Sequencial"
                              }
                            />
                          </div>
                          <Text s="14px" weight={400} color="#999999">
                            {Number(nodeSelected?.data?.transferData?.userId) >
                            0
                              ? `${nodeSelected?.data?.transferData?.userName}`
                              : nodeSelected?.data?.transferData
                                  ?.workspaceOnWebhook
                              ? "Workspace set by Webhook"
                              : "Sequencial"}
                          </Text>
                        </div>
                      </CardrLeft>
                      <CardRight>
                        <ButtonRoundedBigBlue
                          onClick={() => dispatch(toggleTransferModal())}
                        >
                          <img src={BluePencil} alt="Edit Button" />
                        </ButtonRoundedBigBlue>
                        <ButtonRoundedBigRed
                          onClick={handleUnsetTransferWebhook}
                          css={DivRoundedBigRed}
                        >
                          <img src={TrashRed} alt="Trash-Red" />
                        </ButtonRoundedBigRed>
                      </CardRight>
                    </Transfer>
                  ) : null}
                  {nodeSelected?.data?.transfer &&
                  nodeSelected?.data?.transferData?.thirdPartySystem ? (
                    <Transfer>
                      <CardrLeft>
                        <Text s="12px" weight={400} color="#707981">
                          Transferido para
                        </Text>
                        <div className="flex align-items-center">
                          <div style={{ marginLeft: "-0.5rem" }}>
                            <LetterCircle initial="Plataforma externa (Webhook)" />
                          </div>
                          <Text s="14px" weight={400} color="#999999">
                            Plataforma externa (Webhook)
                          </Text>
                        </div>
                      </CardrLeft>
                      <CardRight>
                        <ButtonRoundedBigRed
                          onClick={() => {
                            dispatch(
                              setNodeTransferData({
                                ...nodeSelected?.data?.transferData,
                                queue: 0,
                                workspaceId: nodeSelected?.data?.transferData
                                  ?.workspaceId as number,
                                userId: nodeSelected?.data?.transferData
                                  ?.userId as number,
                                onlyOnlineUser: true,
                                workspaceOnWebhook: nodeSelected?.data
                                  ?.transferData?.workspaceOnWebhook as boolean,
                                thirdPartySystem: false
                              })
                            );
                            if (exclusiveXor) {
                              dispatch(nodeTransfer(false));
                            }
                          }}
                          css={DivRoundedBigRed}
                        >
                          <img src={TrashRed} alt="Trash-Red" />
                        </ButtonRoundedBigRed>
                      </CardRight>
                    </Transfer>
                  ) : null}
                  {exclusiveXor && nodeSelected?.data?.transfer ? (
                    <TransferAdd onClick={addMoreTransferOptions}>
                      <ContainerIcon>
                        <BackgrounIcon>
                          <img
                            width="65%"
                            src={TransferIcon}
                            alt="Transfer Icon"
                          />
                        </BackgrounIcon>
                      </ContainerIcon>
                      <Text s="1rem" lh="1.5rem" weight={400} color="#046AF3">
                        Transferir para mais locais e pessoas
                      </Text>
                    </TransferAdd>
                  ) : null}
                </ContainerContent>
                <ContainerButton>
                  {!isTemplate && (
                    <button
                      type="button"
                      onClick={handleUpdateFlow}
                      css={CheckButton}
                      disabled={
                        isNaN(parseInt(botId as string)) ||
                        isNaN(parseInt(flowId as string)) ||
                        loading
                      }
                    >
                      {loading ? (
                        <ProgressSpinner
                          style={{
                            width: "1.8rem",
                            height: "1.8rem",
                            margin: "0 0.5rem"
                          }}
                        />
                      ) : (
                        <MyImage
                          disabled={
                            isNaN(parseInt(botId as string)) ||
                            isNaN(parseInt(flowId as string))
                          }
                          src={Check}
                          alt="Check-Button"
                        />
                      )}
                      <div>Salvar Fluxo</div>
                    </button>
                  )}
                </ContainerButton>
              </ContainerTab>
              {/* <ButtonDotted onClick={addNodeDefault} content="Criar novo card" /> */}
              <Dialog
                header="Confirmação"
                visible={visible}
                onHide={() => setVisible(false)}
              >
                <div className="flex ">
                  <span className="pi pi-exclamation-triangle mr-2" />
                  <p>{`Todas as informações contidas no node ${
                    nodeSelected?.type === "endNode"
                      ? "tipo end "
                      : "tipo default "
                  } serão perdidas.`}</p>
                </div>
                <p>Deseja prosseguir?</p>
                <div className="flex mt-4 mb-4 flex-row-reverse">
                  <Button
                    label="Sim"
                    className="p-button-info"
                    onClick={Accept}
                  />
                  <Button
                    label="Não"
                    className="p-button-danger ml-2 mr-2"
                    onClick={Reject}
                  />
                </div>
              </Dialog>
            </TabPanel>
            <TabPanel
              headerStyle={headerStyle}
              header="Exceções"
              headerTemplate={exceptionsTabTemplate}
            >
              <Exceptions />
              <ContainerButton>
                <button
                  onClick={handleUpdateFlow}
                  type="button"
                  css={CheckButton}
                  disabled={
                    isNaN(parseInt(botId as string)) ||
                    isNaN(parseInt(flowId as string)) ||
                    loading
                  }
                >
                  {loading ? (
                    <ProgressSpinner
                      style={{
                        width: "1.8rem",
                        height: "1.8rem",
                        margin: "0 0.5rem"
                      }}
                    />
                  ) : (
                    <MyImage
                      disabled={
                        isNaN(parseInt(botId as string)) ||
                        isNaN(parseInt(flowId as string))
                      }
                      src={Check}
                      alt="Check-Button"
                    />
                  )}
                  Salvar Fluxo
                </button>
              </ContainerButton>
            </TabPanel>
          </TabView>
        </ContainerInside>
      ) : (
        <div className="flex flex-column align-itens-center justify-content-evenly h-full">
          <ContainerContent>
            <Text s="1rem" weight={600}>
              Clique em um node para editá-lo
            </Text>
          </ContainerContent>
          <ContainerButton>
            <button
              type="button"
              onClick={handleUpdateFlow}
              css={CheckButton}
              disabled={
                isNaN(parseInt(botId as string)) ||
                isNaN(parseInt(flowId as string)) ||
                loading
              }
              // disabled={!NewEntityName?.length || !newEntiteValues?.length}
            >
              {loading ? (
                <ProgressSpinner
                  style={{
                    width: "1.8rem",
                    height: "1.8rem",
                    margin: "0 0.5rem"
                  }}
                />
              ) : (
                <MyImage
                  disabled={
                    isNaN(parseInt(botId as string)) ||
                    isNaN(parseInt(flowId as string))
                  }
                  src={Check}
                  alt="Check-Button"
                />
              )}
              Salvar Fluxo
            </button>
          </ContainerButton>
        </div>
      )}
      <TransferModal />
    </Container>
  );
};
