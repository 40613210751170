import { Container, ContainerInterno } from "./styles";
import { bot } from "../../assets";
interface IBotPreview {
    color: string;
}
export const BotPreview: React.FC<IBotPreview> = ({color}) => {
    return (
        <Container color={color}>
            <ContainerInterno color={color}>
                <img width="20px" src={bot} alt="Bot Icon" />
            </ContainerInterno>
        </Container>
    )
}