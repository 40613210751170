import { css } from "@emotion/react";

export const divWatch = css`
position: absolute;
top: 0.2rem;
left: 2.1rem;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
display: flex;
align-items: flex-end;
text-align: center;
letter-spacing: -0.02em;
color: #000000;
`;
