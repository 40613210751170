import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { useEffect } from "react";

const options = [
  {
    name: "+55",
    code: "55",
    country: "br"
  },
  {
    name: "+93",
    code: "93",
    country: "af"
  },
  {
    name: "+358",
    code: "358",
    country: "ax"
  },
  {
    name: "+355",
    code: "355",
    country: "al"
  },
  {
    name: "+213",
    code: "213",
    country: "dz"
  },
  {
    name: "+1684",
    code: "1684",
    country: "as"
  },
  {
    name: "+376",
    code: "376",
    country: "ad"
  },
  {
    name: "+244",
    code: "244",
    country: "ao"
  },
  {
    name: "+1264",
    code: "1264",
    country: "ai"
  },
  {
    name: "+672",
    code: "672",
    country: "aq"
  },
  {
    name: "+1268",
    code: "1268",
    country: "ag"
  },
  {
    name: "+54",
    code: "54",
    country: "ar"
  },
  {
    name: "+374",
    code: "374",
    country: "am"
  },
  {
    name: "+297",
    code: "297",
    country: "aw"
  },
  {
    name: "+61",
    code: "61",
    country: "au"
  },
  {
    name: "+43",
    code: "43",
    country: "at"
  },
  {
    name: "+994",
    code: "994",
    country: "az"
  },
  {
    name: "+1242",
    code: "1242",
    country: "bs"
  },
  {
    name: "+973",
    code: "973",
    country: "bh"
  },
  {
    name: "+880",
    code: "880",
    country: "bd"
  },
  {
    name: "+1246",
    code: "1246",
    country: "bb"
  },
  {
    name: "+375",
    code: "375",
    country: "by"
  },
  {
    name: "+32",
    code: "32",
    country: "be"
  },
  {
    name: "+501",
    code: "501",
    country: "bz"
  },
  {
    name: "+229",
    code: "229",
    country: "bj"
  },
  {
    name: "+1441",
    code: "1441",
    country: "bm"
  },
  {
    name: "+975",
    code: "975",
    country: "bt"
  },
  {
    name: "+591",
    code: "591",
    country: "bo"
  },
  {
    name: "+387",
    code: "387",
    country: "ba"
  },
  {
    name: "+267",
    code: "267",
    country: "bw"
  },
  {
    name: "+246",
    code: "246",
    country: "io"
  },
  {
    name: "+673",
    code: "673",
    country: "bn"
  },
  {
    name: "+359",
    code: "359",
    country: "bg"
  },
  {
    name: "+226",
    code: "226",
    country: "bf"
  },
  {
    name: "+257",
    code: "257",
    country: "bi"
  },
  {
    name: "+855",
    code: "855",
    country: "kh"
  },
  {
    name: "+237",
    code: "237",
    country: "cm"
  },
  {
    name: "+1",
    code: "1",
    country: "ca"
  },
  {
    name: "+238",
    code: "238",
    country: "cv"
  },
  {
    name: "+ 345",
    code: " 345",
    country: "ky"
  },
  {
    name: "+236",
    code: "236",
    country: "cf"
  },
  {
    name: "+235",
    code: "235",
    country: "td"
  },
  {
    name: "+56",
    code: "56",
    country: "cl"
  },
  {
    name: "+86",
    code: "86",
    country: "cn"
  },
  {
    name: "+61",
    code: "61",
    country: "cx"
  },
  {
    name: "+61",
    code: "61",
    country: "cc"
  },
  {
    name: "+57",
    code: "57",
    country: "co"
  },
  {
    name: "+269",
    code: "269",
    country: "km"
  },
  {
    name: "+242",
    code: "242",
    country: "cg"
  },
  {
    name: "+243",
    code: "243",
    country: "cd"
  },
  {
    name: "+682",
    code: "682",
    country: "ck"
  },
  {
    name: "+506",
    code: "506",
    country: "cr"
  },
  {
    name: "+225",
    code: "225",
    country: "ci"
  },
  {
    name: "+385",
    code: "385",
    country: "hr"
  },
  {
    name: "+53",
    code: "53",
    country: "cu"
  },
  {
    name: "+357",
    code: "357",
    country: "cy"
  },
  {
    name: "+420",
    code: "420",
    country: "cz"
  },
  {
    name: "+45",
    code: "45",
    country: "dk"
  },
  {
    name: "+253",
    code: "253",
    country: "dj"
  },
  {
    name: "+1767",
    code: "1767",
    country: "dm"
  },
  {
    name: "+1849",
    code: "1849",
    country: "do"
  },
  {
    name: "+593",
    code: "593",
    country: "ec"
  },
  {
    name: "+20",
    code: "20",
    country: "eg"
  },
  {
    name: "+503",
    code: "503",
    country: "sv"
  },
  {
    name: "+240",
    code: "240",
    country: "gq"
  },
  {
    name: "+291",
    code: "291",
    country: "er"
  },
  {
    name: "+372",
    code: "372",
    country: "ee"
  },
  {
    name: "+251",
    code: "251",
    country: "et"
  },
  {
    name: "+500",
    code: "500",
    country: "fk"
  },
  {
    name: "+298",
    code: "298",
    country: "fo"
  },
  {
    name: "+679",
    code: "679",
    country: "fj"
  },
  {
    name: "+358",
    code: "358",
    country: "fi"
  },
  {
    name: "+33",
    code: "33",
    country: "fr"
  },
  {
    name: "+594",
    code: "594",
    country: "gf"
  },
  {
    name: "+689",
    code: "689",
    country: "pf"
  },
  {
    name: "+241",
    code: "241",
    country: "ga"
  },
  {
    name: "+220",
    code: "220",
    country: "gm"
  },
  {
    name: "+995",
    code: "995",
    country: "ge"
  },
  {
    name: "+49",
    code: "49",
    country: "de"
  },
  {
    name: "+233",
    code: "233",
    country: "gh"
  },
  {
    name: "+350",
    code: "350",
    country: "gi"
  },
  {
    name: "+30",
    code: "30",
    country: "gr"
  },
  {
    name: "+299",
    code: "299",
    country: "gl"
  },
  {
    name: "+1473",
    code: "1473",
    country: "gd"
  },
  {
    name: "+590",
    code: "590",
    country: "gp"
  },
  {
    name: "+1671",
    code: "1671",
    country: "gu"
  },
  {
    name: "+502",
    code: "502",
    country: "gt"
  },
  {
    name: "+44",
    code: "44",
    country: "gg"
  },
  {
    name: "+224",
    code: "224",
    country: "gn"
  },
  {
    name: "+245",
    code: "245",
    country: "gw"
  },
  {
    name: "+595",
    code: "595",
    country: "gy"
  },
  {
    name: "+509",
    code: "509",
    country: "ht"
  },
  {
    name: "+379",
    code: "379",
    country: "va"
  },
  {
    name: "+504",
    code: "504",
    country: "hn"
  },
  {
    name: "+852",
    code: "852",
    country: "hk"
  },
  {
    name: "+36",
    code: "36",
    country: "hu"
  },
  {
    name: "+354",
    code: "354",
    country: "is"
  },
  {
    name: "+91",
    code: "91",
    country: "in"
  },
  {
    name: "+62",
    code: "62",
    country: "id"
  },
  {
    name: "+98",
    code: "98",
    country: "ir"
  },
  {
    name: "+964",
    code: "964",
    country: "iq"
  },
  {
    name: "+353",
    code: "353",
    country: "ie"
  },
  {
    name: "+44",
    code: "44",
    country: "im"
  },
  {
    name: "+972",
    code: "972",
    country: "il"
  },
  {
    name: "+39",
    code: "39",
    country: "it"
  },
  {
    name: "+1876",
    code: "1876",
    country: "jm"
  },
  {
    name: "+81",
    code: "81",
    country: "jp"
  },
  {
    name: "+44",
    code: "44",
    country: "je"
  },
  {
    name: "+962",
    code: "962",
    country: "jo"
  },
  {
    name: "+77",
    code: "77",
    country: "kz"
  },
  {
    name: "+254",
    code: "254",
    country: "ke"
  },
  {
    name: "+686",
    code: "686",
    country: "ki"
  },
  {
    name: "+850",
    code: "850",
    country: "kp"
  },
  {
    name: "+82",
    code: "82",
    country: "kr"
  },
  {
    name: "+965",
    code: "965",
    country: "kw"
  },
  {
    name: "+996",
    code: "996",
    country: "kg"
  },
  {
    name: "+856",
    code: "856",
    country: "la"
  },
  {
    name: "+371",
    code: "371",
    country: "lv"
  },
  {
    name: "+961",
    code: "961",
    country: "lb"
  },
  {
    name: "+266",
    code: "266",
    country: "ls"
  },
  {
    name: "+231",
    code: "231",
    country: "lr"
  },
  {
    name: "+218",
    code: "218",
    country: "ly"
  },
  {
    name: "+423",
    code: "423",
    country: "li"
  },
  {
    name: "+370",
    code: "370",
    country: "lt"
  },
  {
    name: "+352",
    code: "352",
    country: "lu"
  },
  {
    name: "+853",
    code: "853",
    country: "mo"
  },
  {
    name: "+389",
    code: "389",
    country: "mk"
  },
  {
    name: "+261",
    code: "261",
    country: "mg"
  },
  {
    name: "+265",
    code: "265",
    country: "mw"
  },
  {
    name: "+60",
    code: "60",
    country: "my"
  },
  {
    name: "+960",
    code: "960",
    country: "mv"
  },
  {
    name: "+223",
    code: "223",
    country: "ml"
  },
  {
    name: "+356",
    code: "356",
    country: "mt"
  },
  {
    name: "+692",
    code: "692",
    country: "mh"
  },
  {
    name: "+596",
    code: "596",
    country: "mq"
  },
  {
    name: "+222",
    code: "222",
    country: "mr"
  },
  {
    name: "+230",
    code: "230",
    country: "mu"
  },
  {
    name: "+262",
    code: "262",
    country: "yt"
  },
  {
    name: "+52",
    code: "52",
    country: "mx"
  },
  {
    name: "+691",
    code: "691",
    country: "fm"
  },
  {
    name: "+373",
    code: "373",
    country: "md"
  },
  {
    name: "+377",
    code: "377",
    country: "mc"
  },
  {
    name: "+976",
    code: "976",
    country: "mn"
  },
  {
    name: "+382",
    code: "382",
    country: "me"
  },
  {
    name: "+1664",
    code: "1664",
    country: "ms"
  },
  {
    name: "+212",
    code: "212",
    country: "ma"
  },
  {
    name: "+258",
    code: "258",
    country: "mz"
  },
  {
    name: "+95",
    code: "95",
    country: "mm"
  },
  {
    name: "+264",
    code: "264",
    country: "na"
  },
  {
    name: "+674",
    code: "674",
    country: "nr"
  },
  {
    name: "+977",
    code: "977",
    country: "np"
  },
  {
    name: "+31",
    code: "31",
    country: "nl"
  },
  {
    name: "+599",
    code: "599",
    country: "an"
  },
  {
    name: "+687",
    code: "687",
    country: "nc"
  },
  {
    name: "+64",
    code: "64",
    country: "nz"
  },
  {
    name: "+505",
    code: "505",
    country: "ni"
  },
  {
    name: "+227",
    code: "227",
    country: "ne"
  },
  {
    name: "+234",
    code: "234",
    country: "ng"
  },
  {
    name: "+683",
    code: "683",
    country: "nu"
  },
  {
    name: "+672",
    code: "672",
    country: "nf"
  },
  {
    name: "+1670",
    code: "1670",
    country: "mp"
  },
  {
    name: "+47",
    code: "47",
    country: "no"
  },
  {
    name: "+968",
    code: "968",
    country: "om"
  },
  {
    name: "+92",
    code: "92",
    country: "pk"
  },
  {
    name: "+680",
    code: "680",
    country: "pw"
  },
  {
    name: "+970",
    code: "970",
    country: "ps"
  },
  {
    name: "+507",
    code: "507",
    country: "pa"
  },
  {
    name: "+675",
    code: "675",
    country: "pg"
  },
  {
    name: "+595",
    code: "595",
    country: "py"
  },
  {
    name: "+51",
    code: "51",
    country: "pe"
  },
  {
    name: "+63",
    code: "63",
    country: "ph"
  },
  {
    name: "+872",
    code: "872",
    country: "pn"
  },
  {
    name: "+48",
    code: "48",
    country: "pl"
  },
  {
    name: "+351",
    code: "351",
    country: "pt"
  },
  {
    name: "+1939",
    code: "1939",
    country: "pr"
  },
  {
    name: "+974",
    code: "974",
    country: "qa"
  },
  {
    name: "+40",
    code: "40",
    country: "ro"
  },
  {
    name: "+7",
    code: "7",
    country: "ru"
  },
  {
    name: "+250",
    code: "250",
    country: "rw"
  },
  {
    name: "+262",
    code: "262",
    country: "re"
  },
  {
    name: "+590",
    code: "590",
    country: "bl"
  },
  {
    name: "+290",
    code: "290",
    country: "sh"
  },
  {
    name: "+1869",
    code: "1869",
    country: "kn"
  },
  {
    name: "+1758",
    code: "1758",
    country: "lc"
  },
  {
    name: "+590",
    code: "590",
    country: "mf"
  },
  {
    name: "+508",
    code: "508",
    country: "pm"
  },
  {
    name: "+1784",
    code: "1784",
    country: "vc"
  },
  {
    name: "+685",
    code: "685",
    country: "ws"
  },
  {
    name: "+378",
    code: "378",
    country: "sm"
  },
  {
    name: "+239",
    code: "239",
    country: "st"
  },
  {
    name: "+966",
    code: "966",
    country: "sa"
  },
  {
    name: "+221",
    code: "221",
    country: "sn"
  },
  {
    name: "+381",
    code: "381",
    country: "rs"
  },
  {
    name: "+248",
    code: "248",
    country: "sc"
  },
  {
    name: "+232",
    code: "232",
    country: "sl"
  },
  {
    name: "+65",
    code: "65",
    country: "sg"
  },
  {
    name: "+421",
    code: "421",
    country: "sk"
  },
  {
    name: "+386",
    code: "386",
    country: "si"
  },
  {
    name: "+677",
    code: "677",
    country: "sb"
  },
  {
    name: "+252",
    code: "252",
    country: "so"
  },
  {
    name: "+27",
    code: "27",
    country: "za"
  },
  {
    name: "+211",
    code: "211",
    country: "ss"
  },
  {
    name: "+500",
    code: "500",
    country: "gs"
  },
  {
    name: "+34",
    code: "34",
    country: "es"
  },
  {
    name: "+94",
    code: "94",
    country: "lk"
  },
  {
    name: "+249",
    code: "249",
    country: "sd"
  },
  {
    name: "+597",
    code: "597",
    country: "sr"
  },
  {
    name: "+47",
    code: "47",
    country: "sj"
  },
  {
    name: "+268",
    code: "268",
    country: "sz"
  },
  {
    name: "+46",
    code: "46",
    country: "se"
  },
  {
    name: "+41",
    code: "41",
    country: "ch"
  },
  {
    name: "+963",
    code: "963",
    country: "sy"
  },
  {
    name: "+886",
    code: "886",
    country: "tw"
  },
  {
    name: "+992",
    code: "992",
    country: "tj"
  },
  {
    name: "+255",
    code: "255",
    country: "tz"
  },
  {
    name: "+66",
    code: "66",
    country: "th"
  },
  {
    name: "+670",
    code: "670",
    country: "tl"
  },
  {
    name: "+228",
    code: "228",
    country: "tg"
  },
  {
    name: "+690",
    code: "690",
    country: "tk"
  },
  {
    name: "+676",
    code: "676",
    country: "to"
  },
  {
    name: "+1868",
    code: "1868",
    country: "tt"
  },
  {
    name: "+216",
    code: "216",
    country: "tn"
  },
  {
    name: "+90",
    code: "90",
    country: "tr"
  },
  {
    name: "+993",
    code: "993",
    country: "tm"
  },
  {
    name: "+1649",
    code: "1649",
    country: "tc"
  },
  {
    name: "+688",
    code: "688",
    country: "tv"
  },
  {
    name: "+256",
    code: "256",
    country: "ug"
  },
  {
    name: "+380",
    code: "380",
    country: "ua"
  },
  {
    name: "+971",
    code: "971",
    country: "ae"
  },
  {
    name: "+44",
    code: "44",
    country: "gb"
  },
  {
    name: "+1",
    code: "1",
    country: "us"
  },
  {
    name: "+598",
    code: "598",
    country: "uy"
  },
  {
    name: "+998",
    code: "998",
    country: "uz"
  },
  {
    name: "+678",
    code: "678",
    country: "vu"
  },
  {
    name: "+58",
    code: "58",
    country: "ve"
  },
  {
    name: "+84",
    code: "84",
    country: "vn"
  },
  {
    name: "+1284",
    code: "1284",
    country: "vg"
  },
  {
    name: "+1340",
    code: "1340",
    country: "vi"
  },
  {
    name: "+681",
    code: "681",
    country: "wf"
  },
  {
    name: "+967",
    code: "967",
    country: "ye"
  },
  {
    name: "+260",
    code: "260",
    country: "zm"
  },
  {
    name: "+263",
    code: "263",
    country: "zw"
  },
  {
    name: "",
    code: "",
    country: "",
    disabled: true
  }
];

export default ({
  id,
  value,
  disabled,
  onChange
}: {
  id?: string;
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}) => {
  function checkForCountryOnValue(value: string[]) {
    if (value[0] === "") {
      value = ["55", value[1]];

      onChange(value);
    }
  }

  useEffect(() => {
    checkForCountryOnValue(value);
  }, []);

  return (
    <div
      id={id}
      className="relative"
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
      `}
    >
      <label
        css={css`
          position: absolute;
          font-size: 12px;
          top: -9px;
          left: 8px;
          z-index: 9;
          padding-left: 4px;
          padding-right: 4px;
          background-color: white;
          color: #999;
        `}
        className="block"
      >
        Telefone
      </label>

      <Dropdown
        options={options}
        optionLabel="name"
        optionValue="code"
        css={css`
          width: 100%;
          max-width: 120px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        `}
        itemTemplate={(option) => (
          <div
            css={css`
              align-items: center;
              display: flex;
              gap: 10px;
            `}
          >
            <span className={`fi fi-${option.country}`}></span>
            <span>{option.name}</span>
          </div>
        )}
        valueTemplate={(option) => (
          <div
            css={css`
              align-items: center;
              display: flex;
              gap: 10px;
            `}
          >
            <span className={`fi fi-${option.country}`}></span>
            <span>{option.name}</span>
          </div>
        )}
        value={value[0]}
        onChange={(e) => onChange?.([e.value, value[1]])}
        disabled={disabled}
      />

      <div
        css={css`
          width: 100%;
        `}
      >
        {value[0] === "55" ? (
          <InputMask
            mask={value[1].length == 10 ? "(99) 9999-9999" : "(99) 99999-9999"}
            css={css`
              width: 100%;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              :enabled:focus {
                box-shadow: none !important;
              }
            `}
            value={value[1]}
            unmask={true}
            onChange={(e) => onChange?.([value[0], e.value])}
            disabled={disabled}
          />
        ) : (
          <InputText
            keyfilter="pint"
            css={css`
              width: 100%;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              :enabled:focus {
                box-shadow: none !important;
              }
            `}
            value={value[1]}
            onChange={(e) => onChange?.([value[0], e.target.value])}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
