// interface IOptionsRobotActions {

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  AttributeBlueBar,
  AttributesStyle,
  IconDiv,
  Modal,
  Overlay,
  Span,
  Title
} from "./styles";
import {
  setAdvisor,
  setCustomButtonModalOpen,
  setModalOpenRobotActions,
  setOverlayInteractionsShow,
  setSendFileModalOpen,
  setSendsendFileSelectorSOOpenOpen,
  setbotInteractionIDActive,
  toggleModalVariablesBotInteractions
} from "../../../../../redux/slices/flowInteractions";
import { Button } from "primereact/button";
import {
  ArrowRightGray,
  CustomButton,
  PhotoVideo,
  PlusIcon,
  Variables
} from "../../../../../assets";
import { addRobotInteraction } from "../../../../../redux/slices/nodes";
import { changedPhoneComponent } from "../../../../../redux/slices/flowupdates";

export const OptionsRobotActions: React.FC = () => {
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const nodeId = nodeSelected?.id as string;
  const modalOpenRobotActions = useAppSelector(
    (state) => state.flowInteractions.modalOpenRobotActions
  );

  const dispatch = useAppDispatch();
  return (
    <Overlay style={{ display: modalOpenRobotActions ? "block" : "none" }}>
      <Modal
        style={{ display: modalOpenRobotActions ? "block" : "none" }}
        open={modalOpenRobotActions}
      >
        <Button
          onClick={() => dispatch(setModalOpenRobotActions(false))}
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
          aria-label="Cancel"
        />

        <div className="flex mt-2 w-full justify-content-center align-items-center">
          <Title>Interações rápidas</Title>
          <Span>?</Span>
        </div>

        <AttributesStyle
          onClick={() => {
            dispatch(addRobotInteraction({ nodeId }));
            dispatch(changedPhoneComponent(true));
            dispatch(setModalOpenRobotActions(false));
          }}
        >
          <AttributeBlueBar />
          <div className="flex w-full align-items-center justify-content-between">
            <div className="flex align-items-center justify-content-start">
              <IconDiv>
                <img src={PlusIcon} alt="More-Bot-Interaction-Icon" />
              </IconDiv>
              <div>Caixa de diálogo do Bot</div>
            </div>
            <div className="mr-4">
              <img src={ArrowRightGray} alt="Arrow-Right-Gray-Icon" />
            </div>
          </div>
        </AttributesStyle>
        <AttributesStyle
          onClick={() => {
            dispatch(setAdvisor(true));
            dispatch(setModalOpenRobotActions(false));
            dispatch(setOverlayInteractionsShow(true));
          }}
        >
          <AttributeBlueBar />
          <div className="flex w-full align-center justify-content-between">
            <div className="flex align-center justify-content-start">
              <IconDiv>
                <img src={Variables} alt="Add-Variables-Icon" />
              </IconDiv>
              <div>Adicionar variável</div>
            </div>
            <div className="mr-4">
              <img src={ArrowRightGray} alt="Arrow-Right-Gray-Icon" />
            </div>
          </div>
        </AttributesStyle>
        <AttributesStyle
          onClick={() => {
            dispatch(setModalOpenRobotActions(false));
            dispatch(setOverlayInteractionsShow(true));
            dispatch(setCustomButtonModalOpen(true));
          }}
        >
          <AttributeBlueBar />
          <div className="flex w-full align-center justify-content-between">
            <div className="flex align-center justify-content-start">
              <IconDiv>
                <img src={CustomButton} alt="Custom-Button-Icon" />
              </IconDiv>
              <div>Botão customizado</div>
            </div>
            <div className="mr-4">
              <img src={ArrowRightGray} alt="Arrow-Right-Gray-Icon" />
            </div>
          </div>
        </AttributesStyle>
        <AttributesStyle
          onClick={() => {
            dispatch(setSendFileModalOpen(true));
            dispatch(setSendsendFileSelectorSOOpenOpen(true));
            dispatch(setModalOpenRobotActions(false));
          }}
        >
          <AttributeBlueBar />
          <div className="flex w-full align-center justify-content-between">
            <div className="flex align-center justify-content-start">
              <IconDiv>
                <img src={PhotoVideo} alt="Custom-Button-Icon" />
              </IconDiv>
              <div>Adicionar Arquivo</div>
            </div>
            <div className="mr-4">
              <img src={ArrowRightGray} alt="Arrow-Right-Gray-Icon" />
            </div>
          </div>
        </AttributesStyle>
      </Modal>
    </Overlay>
  );
};
