/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFetch2 } from "../util";

interface Header {
  key: string;
  value: string;
}

export interface IWebHookConfig {
  url: string;
  headers: Header[];
  transferUrl: string;
  sendMessageUrl: string;
  closeRoomUrl: string;
  callbackUrl: string;
  digitalEmployeeUrl: string;
  digitalEmployeeCallbackUrl: string;
}

const WebhookServices = () => {
  const fetch = useFetch2();
  const getWebhookConfig = (id: number): Promise<IWebHookConfig> => {
    return fetch(`/api/bots/${id}/webhook-config`, { method: "GET" });
  };

  const postWebhookConfig = async (id: number, data: IWebHookConfig) => {
    try {
      const method = { method: "POST" };
      return await fetch(`/api/bots/${id}/webhook-config`, method, data);
    } catch (error) {
      return error;
    }
  };

  return {
    getWebhookConfig,
    postWebhookConfig
  };
};

export default WebhookServices;
