import React from "react";
import Company from "../components/Company";

export default () => {
  return (
    <div>
      <Company />
    </div>
  );
};
