import React from "react";
import { botRed } from "../../../../../assets";
import { useAuth } from "../../../../../auth/auth-provider";
import { useAppSelector } from "../../../../../redux/hooks";
import { BotInteractionStart } from "./styles";
const InteracaoRoboStartNode: React.FC = () => {
  const nodes = useAppSelector((state) => state.nodes);
  const { user } = useAuth();

  return (
    <div className="w-full flex flex-column mb-2">
      <div
        className="flex bg-white justify-content-start mb-2 flex-column"
        style={{
          borderTop: "1px solid #EEE"
        }}
      >
        <div
          style={{
            border: "3px solid rgba(33, 150, 243, 0.3)",
            marginTop: "1.5rem"
          }}
          className="relative w-full border-round-xl"
        >
          <div
            className="absolute ml-6 bg-white border-round-right-3xl flex"
            style={{ marginTop: "-1.1rem" }}
          >
            <div className="mr-2 flex align-items-center">
              <div className="w-2rem h-2rem -ml-4 shadow-7 text-center border-circle bg-white align-items-center justify-content-center">
                <img
                  className="my-1"
                  width="17px"
                  src={botRed}
                  alt="Bot-Image"
                />
              </div>

              <div
                className="ml-1"
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#046AF3"
                }}
              >
                Interação do robô
              </div>
              <div
                className="flex ml-1 border-circle bg-blue-100 align-items-center justify-content-center"
                style={{
                  width: "1.5rem",
                  height: "1.5rem"
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#046AF3"
                  }}
                >
                  ?
                </div>
              </div>
            </div>
          </div>
          <div css={BotInteractionStart}>
            <p className="mt-4">
              Olá, bem-vindo ao seu primeiro chatBot desenvolvido pela{" "}
              {user.plataform.displayName} 🚀{" "}
            </p>
            <p className="mt-3">
              A conversa do seu chatbot sempre inicia através da interação do
              usuário.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteracaoRoboStartNode;
