import { css } from "@emotion/react";
import Button from "./button";
import { useState } from "react";

export default () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true);

  return (
    <>
      {showPrivacyPolicy && (
        <div
          css={css`
            border: 1px solid #d2d2d2;
            border-radius: 20px 20px 0px 0px;
            padding: 20px;
            width: 100%;
          `}
        >
          <div
            className="grid"
            css={css`
              @media (max-width: 540px) {
                display: flex;
                flex-direction: column;
              }
            `}
          >
            <div
              className="col-8"
              css={css`
                font-size: 12px;

                @media (max-width: 540px) {
                  width: fit-content;
                }
              `}
            >
              Ao prosseguir com a utilização da plataforma você concorda com a
              nossa Política de Privacidade e Termos de uso.
            </div>
            <div
              className="col-4"
              css={css`
                text-align: right;

                @media (max-width: 540px) {
                  display: flex;
                  width: 100%;
                  justify-content: center;
                }
              `}
            >
              <Button
                onClick={() => setShowPrivacyPolicy(false)}
                className="p-button-outlined"
                label="ESTOU DE ACORDO"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
