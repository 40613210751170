import { AtendimentoOmnichannel } from "../../assets";
import { css } from "@emotion/react";

const NoDialog = () => (
  <div
    css={css`
      background-color: #ffffff;
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    `}
  >
    <div
      css={css`
        font-size: 22px;
        font-weight: bold;
      `}
    >
      Tudo calmo por aqui
    </div>

    <div
      css={css`
        color: #4c5862;
        font-size: 16px;
        margin-top: 10px;
        padding: 0 15px;
      `}
    >
      Você ainda não tem nenhum atendimento aberto.
    </div>

    <img
      src={AtendimentoOmnichannel}
      css={css`
        margin-top: 10px;
        @media (max-width: 600px) {
          max-width: 95%;
        }
      `}
    />
  </div>
);

export default NoDialog;
