import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  toggleTagModal,
  toggleTransferModal
} from "../../../../redux/slices/flowInteractions";
import {
  nodeTransfer,
  setNodeTransferData,
} from "../../../../redux/slices/nodes";
import {
  BlueBar,
  BoxAgentNotice,
  CancelButton,
  ContainerButton,
  ContainerButtonEnd,
  ContainerContent,
  Disabled,
  DisabledButton,
  Divisor,
  IconButtonBlue,
  LabelBlue,
  Modal,
  MyImage,
  MyTag,
  Overlay,
  SaveButton,
  Span,
} from "./styles";
import { Addlist, Tag, TrashRed } from "../../../../assets";
import { ITransferNodeDTO } from "../../../../components/react-flow-renderer/NodeData";
import { Text } from "../../../../components/Text";
import Dropdown from "../../../../components/dropdown";
import { Checkbox } from "primereact/checkbox";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import { DropdownChangeParams } from "primereact/dropdown";
import { useAuth } from "../../../../auth/auth-provider";

export const TagModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const tagModalOpen = useAppSelector(
    (state) => state.flowInteractions.tagModal
  );
  const toast = useRef<Toast>(null);
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  
  return (
    <>
      <div
        style={{ display: tagModalOpen ? "block" : "none" }}
        css={Overlay}
      />

      <dialog
        style={{ display: tagModalOpen ? "flex" : "none" }}
        open={tagModalOpen}
        css={Modal}
      >
        <div className="relative h-full w-full">
          <div className="flex align-items-center justify-content-end">
            <Button
              style={{ width: "2rem", height: "2rem", marginLeft: "auto", backgroundColor: "#F5F5F5" }}
              onClick={() => dispatch(toggleTagModal())}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
              aria-label="Cancel"
            />
          </div>
          <ContainerContent>
          <ContainerButton>
                  <BlueBar />
                  <IconButtonBlue>
                    <img src={Tag} alt="Tag Icon" />
                  </IconButtonBlue>
                  <div>Pipeline</div>
                  <Span>?</Span>
                  <MyTag>Etapa{nodeSelected?.data.level}</MyTag>

                </ContainerButton>
          <ContainerButton>
            <Disabled />
                  <BlueBar />
                  <IconButtonBlue>
                    <img src={Addlist} alt="AddList Icon" />
                  </IconButtonBlue>
                  <div>Adicionar na Lista</div>
                </ContainerButton>
          <ContainerButton>
          <Disabled />
                  <BlueBar />
                  <IconButtonBlue>
                    <img src={TrashRed} alt="Trash-Red-Icon" />
                  </IconButtonBlue>
                  <div>Remover Node</div>
                </ContainerButton>
          </ContainerContent>
          
        </div>
      </dialog>
    </>
  );
};
