import { css } from "@emotion/react";

export interface AvatarProps {
  name: string;
}

const Avatar = ({ name }: AvatarProps) => {
  return (
    <div
      css={css`
        align-items: center;
        border: 1px solid #046af3;
        border-radius: 50%;
        display: flex;
        height: 43px;
        justify-content: center;
        margin-right: 5px;
        width: 43px;
      `}
    >
      <div
        css={css`
          align-items: center;
          background-color: #d4e2f3;
          border-radius: 50%;
          color: #046af3;
          display: flex;
          font-size: 16px;
          height: 37px;
          justify-content: center;
          width: 37px;
        `}
      >
        {name.toUpperCase().charAt(0)}
      </div>
    </div>
  );
};

export default Avatar;
