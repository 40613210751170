import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const UserInteractionCSS = css`
  border: 3px solid rgba(33, 150, 243, 0.3);
  border-radius: 8px;
  background-color: #fff;
  margin-top: 0.1rem;
  position: relative;
  padding: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
`;
export const DeleteButton = css`
width: 1.375rem;
display: flex;
align-items: center;
justify-content: center;
height: 1.375rem;
right: -0.6875rem;
top: -0.6875rem;
position: absolute;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
border-radius: 50%;
`;
export const UserInteractionHeader = css`
  position: absolute;
  top: -1rem;
  left: 2rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  /* z-index: 10; */
`;
export const IconCss = css`
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextUserInteractionHeader = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #046af3;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
export const QuestionStyle = css`
  width: 17px;
  height: 17px;
  border: none;
  background-color: #d4e2f3;
  color: rgba(4, 106, 243, 1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DivSelects = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DivSelect = css`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 5rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 1rem;
`;
export const divSpacing = css`
  margin-right: 0.5rem;
`;
export const Label = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  
`;
export const ContainerRelative = styled.div`
position: relative;
padding: 0;
margin: 0;
`;
export const Button = styled.button`
border: none;
position: absolute;
width: 111px;
height: 28px;
right: 10px;
top: 34%;
background-color: #D8E9F6;
border-radius: 80px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #046AF3;
`;
export const InputRounded = css`
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #888888;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4c5862;
  &:focus {
    border: 2px solid rgba(33, 150, 243, 1);
  }
`;
export const DivTags = css`
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  line-height: 28px;
  gap: 1rem;

`;
export const RoundedButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.725rem;
  border: 1px solid rgba(33, 150, 243, 1);
  height: 1.25rem;
  width: 8rem;
  color: #4c5862;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  left: calc(50% - 4rem);
  bottom: -0.725rem;
  position: absolute;
  z-index: 10;
  &:hover {
    cursor: pointer;
    background-color: #fbfaf6;
    transition: 0.2s ease-in-out;
  }
`;
export const Message = css`
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  background: #fff3d6;
  opacity: 0.5;
  border: 1px solid #e8b539;
  border-radius: 6px;
`;
export const Strong = css`
  font-weight: 600;
`;
export const Hide = css`
  display: none;
`;
export const DropdownMenu = css`
  position: absolute;
  height: max-content;
  max-height: 300px;
  overflow-y: scroll;
  padding: 1rem;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 11;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  
  color: #888888;
  background-color: #fff;
  border: 1px solid #d2d2d2;

  color: #4c5862;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  transition: height 4s;
  transition-timing-function: ease-in-out;
  /* transform: perspective(17px);
  transform-origin: left top; */
  /* transform: 0.3s ease-in-out;
  transition: all 0.2s; */
  &:hover {
    background-color: #ccc;
  }
`;
export const Divisor = styled.div`

width: calc(100% + 2rem);
margin-top: 0.5rem;
margin-bottom: 0.5rem;
margin-left: -1rem;
height: 1px;
background: #D9D9D9;
`;

export const ButtonStyleFull = css`
  margin-top: 1rem;
  width: 100%;
  padding: 2rem;
  height: 49px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  & + & {
    margin-left: 1rem;
  }
  
`;

export const DivFlex = css`
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const DivRoundedBigBlue = css`
  width: 33px;
  height: 33px;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 1rem;
  border: none;
  background-color: #D4E2F3;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    transition: all ease-in-out 400ms;
  }
`;

export const DivRoundedBigRed = css`
  width: 33px;
  height: 33px;
  display: flex;
  padding-bottom: 2px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #FFE8E2;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    transition: all ease-in-out 400ms;
  }
`;