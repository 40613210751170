import { css } from "@emotion/react";

export const Card = css`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  display: block;
`;

export const Title = css`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  margin: 1.5rem 0 1rem 1.5rem;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0a043c;
  font-family: "Poppins";
`;

export const Icon = css`
  background: #d4e2f3;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
