import { zodResolver } from "@hookform/resolvers/zod";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams
} from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { v } from "../../../../util";
import { WorkspaceContext } from "../workspace-list";

type inviteTeamType = {
  onChange: (value: { id: number }[]) => void;
};
interface IUser {
  id: number;
  name: string;
  phone: string;
  email: string;
  profile: string;
  workspaces: number;
  active: boolean;
  companyId: number;
  thirdPartyId?: number;
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

const WorkspaceEditInviteTeam: React.FC<inviteTeamType> = ({
  onChange
}: inviteTeamType) => {
  const [inviteData, setInviteData] = useState(false);
  const editWKData = useContext(WorkspaceContext);
  const totalRecords = editWKData?.users?.length;
  const [selectedUsers, setSelectedUsers] = useState(
    editWKData.users.filter((item) => item?.active)
  );
  const [filterValue, setFilterValue] = useState("");
  const debouncedText = useDebounce(filterValue, 600);

  // const { findList } = useUsuario();

  // useEffect(() => {
  //   const e = {first: 0, last: 10};
  //   loadDataLazy(e);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setInviteData(false);
    }, 6000);
  }, [inviteData]);

  const onSelectionChange = (e: DataTableSelectionChangeParams) => {
    // eslint-disable-next-line no-negated-condition
    setSelectedUsers(e.value);
    onChange(e.value);
  };

  const change = () => {
    setInviteData(true);
  };
  const unselect = () => {
    setInviteData(false);
  };

  const setFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const filteredUsers = editWKData.users.filter((user) =>
    user.workspace.includes(debouncedText)
  );

  return (
    <>
      <form className="">
        <div
          style={{
            borderRadius: "6px",
            border: "solid 1px #ced4da",
            borderTop: "0px"
          }}
        >
          <div>
            <div className="">
              <InputText
                style={{ width: "100%" }}
                type="text"
                id="nome"
                value={filterValue}
                className="p-inputtext p-component p-filled noBorderRL"
                placeholder="Buscar"
                onChange={setFilter}
              />
            </div>
          </div>
          <DataTable
            value={filteredUsers}
            dataKey="id"
            // value={users}
            selectionMode="checkbox"
            filterDelay={500}
            // eslint-disable-next-line no-negated-condition
            selection={selectedUsers}
            onSelectionChange={(e) => onSelectionChange(e)}
            // dataKey="id"
            totalRecords={editWKData.users.length}
            // responsiveLayout="scroll"
            // style={{ marginTop: "10px" }}
            scrollHeight="12rem"
            scrollable={true}
            onRowSelect={change}
            showGridlines={false}
            showSelectAll={true}
            onRowUnselect={unselect}
          >
            <Column
              style={{ maxWidth: "3rem" }}
              selectionMode="multiple"
              headerStyle={{ maxWidth: "3rem", maxHeight: "2rem" }}
              bodyStyle={{ height: "2rem" }}
            ></Column>
            <Column
              field="workspace"
              style={{ width: "6rem" }}
              headerStyle={{ width: "6rem", height: "2rem" }}
              header="Nome"
              bodyStyle={{ height: "2rem" }}
            ></Column>
          </DataTable>
        </div>
      </form>
      <div className="flex col-4, align-items-center justify-content-end">
        {inviteData && (
          <Message
            severity="success"
            text="convite adicionado com sucesso"
            style={{ width: "100%", height: "13%" }}
          />
        )}
      </div>
      {/* <pre>{JSON.stringify(editWKData.users, null, 2)}</pre> */}
    </>
  );
};

export default WorkspaceEditInviteTeam;
