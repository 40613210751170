/* eslint-disable func-style */
import { useAuth } from "../auth/auth-provider";
import { useFetch2 } from "../util";

export type FindAllProfilesInput = {
  offset?: number;
  limit?: number;
  name?: string;
};

function ProfileService() {
  const { company } = useAuth();
  const request = useFetch2();

  const createProfile = async (data: any) => {
    return request(`/api/profiles`, { method: "POST" }, data);
  };

  const updateProfile = async (profileId: number, data: any) => {
    return request(`/api/profiles/${profileId}`, { method: "PUT" }, data);
  };

  const findProfileById = async (profileId: number) => {
    return request(`/api/profiles/${profileId}`, { method: "GET" });
  };

  const findPermissions = async () => {
    return request(`/api/permissions/`, { method: "GET" });
  };

  const getProfile = async (companyId: number) => {
    try {
      const dataRet = await request(
        `/api/profiles?${  new URLSearchParams({ companyId: `${companyId}` })}`,
        { method: "GET" }
      );

      return dataRet;
    } catch (error) {
      console.error("error ===>>", error);
    }
  };

  const findAllProfiles = async (data: FindAllProfilesInput) => {
    const params = new URLSearchParams();

    if (data.limit) {
      params.append("limit", `${data.limit}`);
    }

    if (data.offset) {
      params.append("offset", `${data.offset}`);
    }

    if (company) {
      params.append("companyId", `${company.id}`);
    }

    if (data.name) {
      params.append("name", `${data.name}`);
    }

    return request(`/api/profiles/paged?${params}`, { method: "GET" });
  };

  return {
    createProfile,
    updateProfile,
    findPermissions,
    findProfileById,
    getProfile,
    findAllProfiles
  };
}

export default ProfileService;
