import workspaces, {
  setFilterValue,
  setLoadOmnichannel,
  setSelectedWorkspaces,
  setValueLoad
} from "../../../../redux/slices/workspaces";
import { TreeSelect, TreeSelectProps } from "primereact/treeselect";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { botRed } from "../../../../assets";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Container, Divisor, Flex, Text, treeSelect } from "./styles";
import "./treeStyles.css";
import { useToast } from "../../../../components/toast-provider";
import { v } from "../../../../util";
import { zodResolver } from "@hookform/resolvers/zod";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import {
  setRWorkspaces,
  setUpdateKeys
} from "../../../../redux/slices/workspaces";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import useCompany from "../../../../use-company";
import { AutoComplete } from "primereact/autocomplete";
import { css } from "@emotion/react";

type FormValues = {
  company: {
    id: number;
    name: string;
  };
};

const schema = v.object({
  company: v.object({
    id: v.number(),
    name: v.string()
  })
});

export const WorkspaceSelector: React.FC = () => {
  const { company, setCompany, user } = useAuth() as AuthContextType;
  const dispatch = useAppDispatch();
  const [selectedNodeKeys, setSelectedNodeKeys] = useState<{
    [k: string]: any;
  } | null>(null);
  const toast = useToast();
  const [nodes, setNodes] = useState<TreeSelectProps | undefined>(undefined);
  const authContext = useAuth() as AuthContextType;
  const [currentPathname] = useState(window.location.pathname);
  const [items, setItems] = useState([]);
  const { getUserWorkspaces2 } = WorkspaceUserService();
  const { findAll } = useCompany();
  const [nameNewCompany, setNameNewCompany] = useState(null);
  const updateWorkspaces = useAppSelector(
    (state) => state.workspaces.updateWorkspaces
  );
  const loadOmnichannel = useAppSelector(
    (state) => state.workspaces.loadOmnichannel
  );
  const valueLoad = useAppSelector((state) => state.workspaces.valueLoad);
  const filterValue = useAppSelector((state) => state.workspaces.filterValue);
  const updateKeys = useAppSelector((state) => state.workspaces.updateKeys);
  const [previousSelectedNodeKeys, setPreviousSelectedNodeKeys] = useState<{
    [k: string]: any;
  } | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      company: undefined
    },
    values: {
      company
    },
    resolver: zodResolver(schema)
  });

  const handleChangeCompany = async (data) => {
    if (data.company.id !== company.id) {
      setCompany(data.company);
      localStorage.setItem("company", JSON.stringify(data.company));
      if (authContext.user.admin) {
        try {
          const workspaces = await getUserWorkspaces2(data.company.id);
          if (workspaces) {
            authContext.setWorkspaces(workspaces);
            dispatch(setRWorkspaces(workspaces.workspaces));
            setTimeout(() => {
              updateTreeSelect(workspaces);
            }, 200);
          } else {
            updateTreeSelect({ workspacesTree: [], workspaces: [] });
          }
        } catch (error) {
          console.error("Erro ao atualizar workspaces:", error);
          toast.current?.show({
            life: 3000,
            severity: "error",
            detail: "Erro ao atualizar workspaces. Tente novamente."
          });
        }
      }
    }
    dispatch(setUpdateKeys(!updateKeys));
  };

  const updateTreeSelect = async (workspaces) => {
    if (workspaces.workspacesTree?.length > 0) {
      const selected = workspaces.workspaces.map((_ws: { id: number }) => [
        [_ws.id],
        { checked: true, partialChecked: false }
      ]);
      const objectSelected = Object.fromEntries(selected);
      setNodes(workspaces.workspacesTree as unknown as TreeSelectProps);
      const selectedNodeKeys = Array.from(Object.keys(objectSelected)).map(
        (el) => Number(el)
      );
      if (currentPathname != "/rooms/" && currentPathname != "/omnichannel") {
        setSelectedNodeKeys(objectSelected);
      } else if (loadOmnichannel === false) {
        setSelectedNodeKeys(objectSelected);
        dispatch(setSelectedWorkspaces(selectedNodeKeys));
      }
    } else {
      setNodes(undefined);
      setSelectedNodeKeys(null);
      dispatch(setSelectedWorkspaces([]));
    }
  };

  useEffect(() => {
    if (loadOmnichannel) {
      return;
    }
    const getWorkspaces = async () => {
      try {
        if (user?.workspaces?.workspaces?.length) {
          const selected = user?.workspaces?.workspaces.map(
            (_ws: { id: number }) => {
              // setSelectedNodeKeys(_ws.id)
              return [[_ws.id], { checked: true, partialChecked: false }];
            }
          );
          const objectSelected = Object.fromEntries(selected);
          if (user?.workspaces?.workspacesTree?.length) {
            setNodes(
              user.workspaces.workspacesTree as unknown as TreeSelectProps
            );
            const selectedNodeKeys = Array?.from(
              Object?.keys(objectSelected as { [k: string]: any })
            ).map((el) => Number(el));
            setSelectedNodeKeys(objectSelected);
            dispatch(setSelectedWorkspaces(selectedNodeKeys));
            setPreviousSelectedNodeKeys(objectSelected);
          }
        }
      } catch (error: any) {
        // console.error(error);
        toast.current?.show({
          life: 3000,
          severity: "error",
          detail: error.message
        });
      }
    };
    getWorkspaces();
  }, [user.id]);

  const handleChange = (e: any) => {
    setSelectedNodeKeys(e.value);
    dispatch(
      setSelectedWorkspaces(Object.keys(e.value).map((el) => Number(el)))
    );
    dispatch(setFilterValue(e.value));
    dispatch(setLoadOmnichannel(true));
    dispatch(setValueLoad("datatableLoad"));
  };

  useEffect(() => {
    if (
      currentPathname != "/rooms/" &&
      currentPathname != "/omnichannel" &&
      currentPathname != "/omnichannel/"
    ) {
      dispatch(setLoadOmnichannel(false));
      dispatch(setValueLoad("firstLoad"));
    }
  }, [currentPathname]);

  useEffect(() => {
    if (loadOmnichannel) {
      setSelectedNodeKeys(filterValue);
    } else {
      setSelectedNodeKeys(previousSelectedNodeKeys);
    }
  }, [valueLoad, previousSelectedNodeKeys]);

  useEffect(() => {
    if (loadOmnichannel) {
      setSelectedNodeKeys(filterValue);
      dispatch(
        setSelectedWorkspaces(Object.keys(filterValue).map((el) => Number(el)))
      );
    }
  }, [loadOmnichannel, filterValue]);

  const expandNode = (node: any, _expandedKeys: { [x: string]: boolean }) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (const child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const { result } = await findAll({
          limit: 20,
          offset: 0,
          name: ""
        });
        setItems(result);
      } catch (error) {
        console.error("Erro ao buscar empresas:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        if (user?.companyId) {
          const workspaces = await getUserWorkspaces2(
            user.admin ? company.id : undefined
          );
          authContext.setWorkspaces(workspaces);
          dispatch(setRWorkspaces(workspaces.workspaces));
          updateTreeSelect(workspaces);
        }
      } catch (error) {
        console.error("Erro ao buscar workspaces:", error);
        toast.current?.show({
          life: 3000,
          severity: "error",
          detail: "Erro ao buscar workspaces. Tente novamente."
        });
      }
    };

    fetchWorkspaces();
  }, [updateWorkspaces]);

  return (
    <div css={Container}>
      <div css={Flex}>
        <img
          width="17px"
          style={{ marginRight: "0.5rem" }}
          src={botRed}
          alt="Imbot-Image"
        />
        <div css={Text}>{user.plataform.displayName}</div>
      </div>

      <div css={Divisor} />

      <div className="autoComplete">
        {authContext.user.admin ? (
          <AutoComplete
            css={css`
              width: 13rem;
              @media (max-width: 600px) {
                width: 100%;
              }
            `}
            id="company"
            value={nameNewCompany !== null ? nameNewCompany : company}
            onChange={(e) => {
              setNameNewCompany(e.value);
            }}
            onSelect={(e) => {
              handleChangeCompany({ company: e.value });
            }}
            suggestions={items}
            forceSelection={true}
            dropdown={true}
            field="name"
            completeMethod={async (event) => {
              const { result } = await findAll({
                limit: 20,
                offset: 0,
                name: event.query
              });

              setItems(result);
            }}
          />
        ) : (
          <div css={Text}>{user.admin ? company.name : user.companyName}</div>
        )}
      </div>

      <div css={Divisor} />

      <div className="treeselect" css={treeSelect}>
        <TreeSelect
          value={selectedNodeKeys}
          options={nodes}
          style={{ width: "100%" }}
          selectionMode="checkbox"
          placeholder="Selecione a workspace"
          onChange={(e) => handleChange(e)}
          filter={true}
          emptyMessage="Nenhum resultado encontrado"
        />
      </div>
    </div>
  );
};
