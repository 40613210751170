import { BotInterctionChat } from "../../../../assets"
import { HeaderDiv, IconChat } from "./styles"

export const HeaderChat: React.FC = () => {
    return (

        <div css={HeaderDiv}>
            <div css={IconChat}>
                <img src={BotInterctionChat} />
            </div>
            <div className="ml-2">Teste o seu chatbot ;)</div>
        </div>
    )
}