import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./global-style";
import Avatar from "./pages/avatar";
import ConfirmResetPassword from "./pages/confirm-reset-password";
import ConfirmSubscription from "./pages/confirm-subscription";
import Forgot from "./pages/forgot";
import Login from "./pages/login";
import Password from "./pages/password";
import ResetPassowrd from "./pages/reset-password";
import Subscribe from "./pages/subscribe";
import UserInvite from "./pages/user-invite";
import SubscribeSuccess from "./pages/subscribe-success";
import { store } from "./redux/store";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { AuthProvider } from "./auth/auth-provider";
import { Router } from "./routes";
import { Provider } from "react-redux";
import { ToastProvider } from "./components";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FbLogin } from "./pages/fblogin";
import { useEffect } from "react";

export default () => {
  useEffect(() => {
    let link: HTMLLinkElement;

    if (process.env.NODE_ENV === "production") {
      link = document.createElement("link");
      link.href = `https://${process.env.CHAT_URL}/main.css`;
      link.rel = "preload";
      link.as = "style";

      document.head.appendChild(link);
    }

    return () => {
      if (link) {
        document.head.removeChild(link);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ToastProvider>
          <AuthProvider>
            <GlobalStyle />
            <ConfirmDialog />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login/:email" element={<Login />} />
              <Route path="/login/:email/:invite" element={<Login />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route
                path="/confirm-reset-password/:userMail"
                element={<ConfirmResetPassword />}
              />
              <Route
                path="/confirm-subscription/:userMail"
                element={<ConfirmSubscription />}
              />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route
                path="/user-invite/:userMail/:confirmationCode"
                element={<UserInvite />}
              />
              <Route
                path="/subscribe-success/:confirmationCode"
                element={<SubscribeSuccess />}
              />
              <Route
                path="/reset-password/:confirmationCode"
                element={<ResetPassowrd />}
              />
              <Route path="/avatar/:idUser/:nameUser" element={<Avatar />} />
              <Route path="/password" element={<Password />} />
              <Route path="/facebook/Oauth" element={<FbLogin />} />
              <Route path="/*" element={<Router />} />
            </Routes>
          </AuthProvider>
        </ToastProvider>
      </Provider>
    </BrowserRouter>
  );
};
