/* eslint-disable no-nested-ternary */
import { css } from "@emotion/react";
import { WebChat } from "../../../assets";

export const TopInfo: React.FC<{ totalCount: number }> = ({ totalCount }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;
        `}
      >
        <img
          css={css`
            height: 40px;
            width: 40px;
          `}
          src={WebChat}
          alt="imbot-chat"
        />

        <div
          css={css`
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #0a043c;
          `}
        >
          Chatbots
        </div>

        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            padding: 7px 10px;
            border-radius: 6px;
            background: #eaeaea;
            color: #0a043c;
          `}
        >
          {totalCount + " Bots"}
        </div>
      </div>

      <div
        css={css`
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          color: #6e6b7b;
        `}
      >
        Gerencie os seus bots cadastrados
      </div>
    </div>
  );
};
