import React from "react";
import { EntitiesStyle, EntityBlueBar } from "./styles";

interface IEntity {
  name: string;
  onClick: () => void;
}
export const Entity: React.FC<IEntity> = ({ name, onClick }) => {
  return (
    <>
      <div 
      onClick={onClick}
      css={EntitiesStyle}>
        <div css={EntityBlueBar} />
        {name}
      </div>
    </>
  );
};
