import { Text } from "../../../../../components/Text/text";
import { MetaIcon } from "../../../../../assets";
import {
  Container,
  ButtonCopiar,
  ButtonSalvar,
  ErrorMessage,
  MyIconButton,
  Relative
} from "./styles";
import { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setBotActiveMeta,
  setBotPhone,
  setBotTokenMeta,
  setBotURLMeta,
  setBotBusinessPhoneId,
  setBotBusinessAccountId
} from "../../../../../redux/slices/bot";
import { css } from "@emotion/react";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "../../../../../components";
import { v } from "../../../../../util";
import { zodResolver } from "@hookform/resolvers/zod";
import BotsServices from "../../../../../services/bots";
import { ProgressSpinner } from "primereact/progressspinner";
import { setToast } from "../../../../../redux/slices/user";

interface ILeftContentWhatsappModal {
  show: (open: boolean) => void;
}
export const LeftContentWhatsappModal: React.FC<ILeftContentWhatsappModal> = ({
  show
}) => {
  const counterToast = useAppSelector(
    (state) => state.user.toastApp?.count || 0
  );
  const schema = v.object({
    phone: v.fone().superRefine(v.nonempty()),
    token: v.string().superRefine(v.nonempty()),
    metaUrl: v.string().superRefine(v.nonempty()).superRefine(v.url()),
    businessPhoneId: v.string().superRefine(v.nonempty()),
    businessAccountId: v.string().superRefine(v.nonempty()),
    on: v.boolean()
  });
  const botResumeWhats = useAppSelector((state) => state.bot.whats);
  const [loading, setLoading] = useState(false);
  const botId = useAppSelector((state) => state.bot.id);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      phone: botResumeWhats?.phone,
      token: botResumeWhats?.tokenMeta,
      metaUrl: botResumeWhats?.urlMeta,
      businessPhoneId: botResumeWhats?.businessPhoneId,
      businessAccountId: botResumeWhats?.businessAccountId,
      on: botResumeWhats.on
    },
    reValidateMode: "onSubmit",
    resolver: zodResolver(schema)
  });
  const { UpdateWhatsapp } = BotsServices();
  const dispatch = useAppDispatch();
  const toastRef = useRef<Toast>(null);

  return (
    <Container>
      <Toast ref={toastRef} />
      <div className="flex">
        <MyIconButton>
          <img src={MetaIcon} alt="Meta-Icon" />
        </MyIconButton>
        <div className="ml-3">
          <Text
            fontSize="0.9375rem"
            fontWeight={600}
            lineHeight="1.25rem"
            color="#0A043C"
          >
            Integração com o WhatsApp
          </Text>
          <Text
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="1.125rem"
            color="#544F77"
          >
            Integre o seu número de Whatsapp com a Meta Business Suite.
          </Text>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(async (d) => {
          setLoading(true);
          await UpdateWhatsapp(botId, {
            phone: d.phone,
            token: d.token,
            metaUrl: d.metaUrl,
            businessPhoneId: d.businessPhoneId,
            businessAccountId: d.businessAccountId,
            active: d.on
          })
            .then(() => {
              dispatch(setBotPhone(d.phone));
              dispatch(setBotTokenMeta(d.token));
              dispatch(setBotURLMeta(d.metaUrl));
              dispatch(setBotBusinessPhoneId(d.businessPhoneId));
              dispatch(setBotBusinessAccountId(d.businessAccountId));
              dispatch(setBotActiveMeta(d.on));
              dispatch(
                setToast({
                  count: counterToast + 1,
                  show: true,
                  life: 3000,
                  severity: "success",
                  summary: "Sucesso.",
                  detail: "Dados Whatsapp atualizados.",
                  sticky: false
                })
              );
              setLoading(false);
              show(false);
            })
            .catch(() => {
              setLoading(false);
              dispatch(
                setToast({
                  count: counterToast + 1,
                  show: true,
                  severity: "error",
                  summary: "Erro ao gravar dados Whatsapp.",
                  detail: "Favor tentar novamente mais tarde.",
                  life: 3000
                })
              );
            });
        })}
      >
        <div className="mt-4 w-full gap-5 flex flex-column align-items-center justify-content-start w-full">
          <div className="w-full">
            <div
              style={{ width: "inherit", marginTop: "1rem" }}
              className="p-float-label"
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputText
                    {...field}
                    id="phone"
                    type="text"
                    // autoClear={false}
                    // mask={/^\(\d{2}\) 9/ugmi.test(field.value) ? "(99) 99999-9999" : "(99) 9999 999 9999"}
                    // placeholder={/^\(\d{2}\) 9/ugmi.test(field.value) ? "(99) 99999-9999" : "(99) 9999 999 9999"}
                    // slotChar="_"
                  />
                )}
              />
              <label htmlFor="phone">Número de telefone</label>
            </div>
            {errors?.phone && (
              <ErrorMessage>{errors.phone?.message}</ErrorMessage>
            )}
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="token"
                control={control}
                render={({ field }) => <InputText {...field} id="token" />}
              />
              <label htmlFor="token">Token</label>
            </div>
            {errors?.token && (
              <ErrorMessage>{errors.token?.message}</ErrorMessage>
            )}
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="metaUrl"
                control={control}
                render={({ field }) => <InputText {...field} id="host" />}
              />
              <label htmlFor="host">URL Meta</label>
            </div>
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="businessPhoneId"
                control={control}
                render={({ field }) => (
                  <InputText {...field} id="businessPhoneId" />
                )}
              />
              <label htmlFor="businessPhoneId">
                Identificação do número de telefone
              </label>
            </div>
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="businessAccountId"
                control={control}
                render={({ field }) => (
                  <InputText {...field} id="businessAccountId" />
                )}
              />
              <label htmlFor="businessAccountId">
                Identificação da conta do WhatsApp Business
              </label>
            </div>
          </div>
          <Relative>
            <div className="relative w-full">
              <div style={{ width: "inherit" }} className="p-float-label">
                <InputText
                  disabled={true}
                  value={botResumeWhats?.urlWebhook}
                  style={{ paddingRight: "3rem" }}
                />

                <label htmlFor="host1">URL WebHook</label>
              </div>
            </div>
            <ButtonCopiar
              onClick={() => {
                navigator.clipboard.writeText(botResumeWhats?.urlWebhook);
                toastRef.current?.show({
                  severity: "success",
                  detail: "URL Webhook copiado com sucesso!"
                });
              }}
            >
              Copiar
            </ButtonCopiar>
          </Relative>

          <div className="flex align-items-center">
            <Text
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.3125rem"
              color="#6D6F6F"
            >
              Ambiente:
            </Text>
            <Text
              style={{ marginLeft: "0.5rem" }}
              fontSize="0.875rem"
              fontWeight={600}
              lineHeight="1.3125rem"
              color="#046AF3"
            >
              Produção
            </Text>
            <div className="ml-4 flex align-items-center">
              <Controller
                name="on"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputSwitch
                    checked={botResumeWhats.on}
                    onChange={(e) => {
                      onChange(e.value);
                      dispatch(setBotActiveMeta(e.value));
                    }}
                  />
                )}
              />
              <div className="ml-2">
                <Text
                  fontSize="0.875rem"
                  fontWeight={400}
                  lineHeight="1.3125rem"
                  color="#6D6F6F"
                >
                  {`${botResumeWhats?.on ? "Ativo" : "Inativo"}`}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-content-center align-items-center"
          style={{ height: "5rem" }}
        >
          <ButtonSalvar disabled={loading}>
            {loading && (
              <ProgressSpinner
                style={{ width: "16px", height: "16px", margin: "0.2rem" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            )}
            Salvar
          </ButtonSalvar>
        </div>
      </form>
    </Container>
  );
};
