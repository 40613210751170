import { css } from "@emotion/react";
import { TridimentionalWhatsIcon, createRoboImge } from "../../assets";

export const LeftDisabledBox: React.FC = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: flex-end;
          width: 100%;
        `}
      >
        <div className="mr-5">
          <img src={TridimentionalWhatsIcon} alt="3dWhats" />
        </div>

        <div>
          <div
            css={css`
              font-size: 14px;
              font-weight: 600;
              color: #0a043c;
            `}
          >
            Todas as conversas em um único lugar!
          </div>

          <div
            css={css`
              font-size: 12px;
              color: #544f77;
            `}
          >
            Para integrar essa ferramenta incrível, basta conectar o seu
            celular, vamos integrar todas as mensagens do seu Bot em um único
            local.
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: center;
          width: 100%;
        `}
      >
        <img
          src={createRoboImge}
          alt="createBotImage"
          css={css`
            height: 100%;
          `}
        />
      </div>
    </div>
  );
};
