import styled from "@emotion/styled";
interface IContainer {
    color: string;
}
export const Container = styled.div<IContainer>`
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0.5rem;
gap: 0.5rem;
width: 187px;
height: 49px;
background: ${props => props.color};
border-radius: 20px 20px 20px 0px;
`;