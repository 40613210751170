import React from "react";
import {
  DialogException,
  Divisor,
  Text,
  VDivisor
} from "./styles";

const ExceptionsTab = () => {
  return (
      <div css={DialogException}>
        <h2 css={Text}>Exceções</h2>
      </div>
  );
};

export default ExceptionsTab;
