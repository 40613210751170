import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import FacebookServices from "../services/facebook";
import { useToast } from "../components";
import { AuthContextType, useAuth } from "../auth/auth-provider";
import { Text } from "../components/Text";
import Lottie from "react-lottie-player";

import lottieJson from "../assets/animations/loading.json";

export const FbLogin: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { fbOauth } = FacebookServices();
  const toast = useToast();
  const { user } = useAuth() as AuthContextType;
  const closeTab = () => {
    window.opener = null;
    const tab = window.open("", "_self");
    setTimeout(() => tab?.close(), 200);
  };
  const connectFb = async (code: string, botToken: string) => {
    await fbOauth(code, botToken)
      .then(() => {
        localStorage.setItem("FbLogin", "true");
        closeTab();
      })
      .catch((err) => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao salvar token. Por favor tente mais tarde!"
        });
      });
  };
  useEffect(() => {
    const param = new URLSearchParams(search);
    const code = param.get("code");
    const state = param.get("state");
    if (user.name){
      if (!code || !state) {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro nos parâmetros recebidos. Por favor tente mais tarde!"
        });
        navigate("/");
        return;
      }
      connectFb(code, state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name]);
  return (
    <div
      style={{ backgroundColor: "#F8FAFC" }}
      className="flex w-full h-full align-items-center justify-content-center"
    >
      <div>
        {/* <img src={FbConnecting} alt="Facebook conecting" /> */}
        <Lottie
          loop={true}
          animationData={lottieJson}
          play={true}
          style={{ width: 340, height: 441 }}
        />
        <Text
          s="22px"
          weight={400}
          lh="33px"
          color="#999999"
          style={{ maxWidth: "50%" }}
        >
          Aguarde a página será fechada automaticamente após a configuração...
        </Text>
      </div>
    </div>
  );
};
