import React from "react";
import {
  DialogException,
  DivDialogExceptions,
  Divisor,
  Text,
  VDivisor
} from "./styles";

const DialogTab = () => {
  return (
      <div css={DialogException}>
        <h2 css={Text}>Diálogo</h2>
    </div>
  );
};

export default DialogTab;
