import React, { memo } from "react";

import { Handle, NodeProps, Position } from "react-flow-renderer";

export default memo(({ data, isConnectable, ...rest }: NodeProps) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555", top: -4 }}
        isConnectable={isConnectable}
      />
      <div>
        Custom Color Picker Background: <strong>{data.color}</strong>
      </div>
      <input
        className="nodrag"
        type="color"
        onChange={data.onChange}
        defaultValue={data.color}
        value={data.color}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ bottom: -4, left: "33%", background: "#555" }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={{ bottom: -4, left: "66%", background: "#555" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
