import DialogCreateFromZero from "./template/addBlocks/DialogCreateFromZero";

import { useState } from "react";
import AddProfile from "./Pages/profile/add-profile";
import AddWorkspace from "./Pages/workspace/addWorkspace";
import WorkspaceSidebar from "./Pages/workspace/WorkspaceSidebar";
import Button from "./button";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setOnSetShouldUpdate } from "../redux/slices/flowupdates";
require("./comum_css/rawCss.css");

type paramType = {
  onSetShouldUpdate?: (status: string) => any;
};
interface IAdicionarBar {
  visible: boolean;
  hide: () => void;
}
export const AdicionarBar: React.FC<IAdicionarBar> = ({ visible, hide }) => {
  const onSetShouldUpdate = useAppSelector(
    (state) => state.flowUpdates.onSetShouldUpdate
  );
  const dispatch = useAppDispatch();
  const [visibleWorkspace, setVisibleWorkspace] = useState(false);
  const [visibleRobo, setVisibleRobo] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  // const [visibleSidebar, setVisibleSidebar] = useState(visible);

  return (
    // <div
    //   className="flex justify-content-end bg-white align-items-center"
    //   style={{ padding: "10px 30px" }}
    // >
    //   <form>
    //     <Button
    //       icon="pi pi-plus-circle"
    //       label="Adicionar"
    //       type="button"
    //       onClick={() => setVisibleSidebar(true)}
    //       className="btnAddDefault"
    //     />
    //   </form>
    <>
      <WorkspaceSidebar
        visible={visible}
        hideSidebar={hide}
        showDialog={(dialog) => {
          if (dialog === "workspace" || dialog === "user") {
            setVisibleWorkspace(true);
          } else if (dialog === "robo") {
            setVisibleRobo(true);
          } else if (dialog === "profile") {
            setVisibleProfile(true);
          }
        }}
      />

      <AddWorkspace
        displayWorkspace={visibleWorkspace}
        onHide={(status) => {
          setVisibleWorkspace(false);

          if (status && !!onSetShouldUpdate) {
            // onSetShouldUpdate(status);
            dispatch(setOnSetShouldUpdate(status));
          }
        }}
      />

      <DialogCreateFromZero
        displayDialog={visibleRobo}
        onHide={() => setVisibleRobo(false)}
      />

      <AddProfile
        visible={visibleProfile}
        onSave={() => {
          setVisibleProfile(false);

          if (onSetShouldUpdate) {
            // onSetShouldUpdate("success");
            dispatch(setOnSetShouldUpdate("success"));
          }
        }}
        onHide={() => {
          setVisibleProfile(false);
        }}
      />
      {/* </div> */}
    </>
  );
};
