import React, { ReactSVG } from 'react'

type typeProps = {
  img: string;
  description: string;
  title: string;
  onclick: (param:boolean) => any;
}

function CreateCards({img, description, title, onclick}:typeProps) {

  const clickCard = () => {
    onclick(true)
  }

  return (
    <div onClick={clickCard} className="hover:shadow-2 cursor-pointer flex-1 h-auto bg-white text-indigo-900 font-bold text-center p-4 border-round-xl mr-4">
      <div className="flex flex-column mt-4">
        <div className="text-base">{title}</div>
        <span className="font-light text-xs w-16rem m-auto mt-1">{description}</span>
        <img className="flex align-self-center" style={{width:'70%'}} src={img} alt="" />
      </div>
    </div>
  )
}

export default CreateCards
