import { css } from "@emotion/react";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { FindDialogOutput, FindDialogsOutput } from "../use-omnichannel";
import Dialog from "./dialog";
import InfiniteScroll from "../../components/inifinite-scroll";

export interface DialogsProps {
  hasRequest: boolean;
  dialog?: FindDialogOutput;
  dialogs: FindDialogsOutput[];
  fetchMoreDialogs: () => void;
  onDialogClick: (id: number) => void;
}

const Dialogs = ({
  hasRequest,
  dialog,
  dialogs,
  fetchMoreDialogs,
  onDialogClick
}: DialogsProps) => {
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    if (dialog) {
      setSelected(dialog.id);
    }
  }, [dialog]);

  return (
    <InfiniteScroll hasRequest={hasRequest} emitFetch={fetchMoreDialogs}>
      {dialogs.map((d) => (
        <Dialog
          css={css`
            background-color: ${d.id === selected ? "#282d35" : "#2f343d"};
          `}
          key={d.id}
          name={d.name}
          lastMessage={d.lastMessage}
          lastMessageDateTime={parseISO(d.lastMessageDateTime)}
          unreadMessageTotal={d.unreadMessageTotal}
          platform={d.plataform}
          onClick={() => {
            setSelected(d.id);
            onDialogClick(d.id);
          }}
        />
      ))}
    </InfiniteScroll>
  );
};

export default Dialogs;
