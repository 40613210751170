import styled from "@emotion/styled";

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow-y: scrool;
  /* height: 200px; */
  max-height: 73vh;
  width: 40%;
  z-index: 12;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 200px;
  box-shadow: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;

  @media (max-width: 600px) {
    width: 70%;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  z-index: 11;
  height: 100vh;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;
