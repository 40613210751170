import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default (url: string, init?: RequestInit) => {
  const navigate = useNavigate();

  return useCallback(
    async (input?: Record<string, unknown>) => {
      const response = await fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        ...init,
        body: input ? JSON.stringify(input) : undefined
      });

      if (response.status <= 399) {
        return response.json();
      } else if (response.status >= 400 && response.status <= 499) {
        const errors = await response.json();
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(errors);
        }
        if (errors.message === "Senha inválida.") {
          return errors
          // navigate("/login");
        }
        if (response.status === 401) {
          navigate("/login");
        }

        return errors;
      } 
        const errors = await response.text();

        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(errors);
        }

        return errors;
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document, navigate]
  );
};
