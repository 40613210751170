import { css } from "@emotion/react";

export const InsideCircleLetter = css`
width: 23px;
height: 23px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
background: #D4E2F3;
color: #046AF3;
`;

export const CircleLetter = css`
margin: 0 0.5rem;
border: 1px solid #046AF3;
width: 26px;
height: 26px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;

`;
