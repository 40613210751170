import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { DataTablePFSEvent } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { createContext, lazy, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, DataTable, InputText } from "../..";
import { userList } from "../../../assets";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import UserServices from "../../../services/UserServices";
import * as comunCss from "../../comum_css";
import UserEdit from "./UserEdit";
import "../../comum_css/rawCss.css";
import { setOpenModalMyAccount } from "../../../redux/slices/user";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditUserContext = createContext<any>({});
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};
export default () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menu = useRef<any>();
  const toast = useRef<Toast>(null);
  const effectRan = useRef(false);
  const dispatch = useAppDispatch();
  const { getUser } = UserServices();
  const openModalMyAccount = useAppSelector(
    (state) => state.user.openMyAccount
  );
  const shouldUpdateFromRedux = useAppSelector(
    (state) => state.flowUpdates.shouldUpdate
  );
  const { user, company } = useAuth() as AuthContextType;
  const [toggleUserData, setToggleUserData] = useState<object>({});
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [hideText, setHideText] = useState(true);
  const [tablePropsData, setTablePropsData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const debouncedText = useDebounce(globalFilterValue, 600);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });
  // Fetching Data from endpoint to list Usuario=================>>
  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  const changeStateList = () => {
    setShouldUpdate((prevSt) => !prevSt);
  };

  const showSuccess = (message: string) => {
    changeStateList();
    if (message) {
      toast?.current?.show({
        severity: "success",
        summary: "Atenção",
        detail: message,
        life: 3000
      });
    }
  };
  const showError = (message: string) => {
    toast?.current?.show({
      severity: "error",
      summary: "Atenção",
      detail: message,
      life: 3000
    });
  };
  const onPage = (event: never) => {
    setLazyParams(event);
  };
  const fetch = async () => {
    try {
      if (selectedWorkspacesIds.length) {
        const data = await getUser({
          offset: lazyParams.first,
          limit: lazyParams.rows,
          workspaceId: selectedWorkspacesIds,
          name: debouncedText || "",
          companyId: user.admin ? company.id : user.companyId
        });
        const tableProps = {
          first: lazyParams.first,
          lazy: true,
          onPage,
          paginator: true,
          rows: lazyParams.rows,
          totalRecords: data.total,
          value: data.result
        };
        setTablePropsData(tableProps);
        if (openModalMyAccount) {
          setToggleUserData(data.result.filter((el) => el.id === user.id)[0]);
          dispatch(setOpenModalMyAccount(false));
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      showError(e.message);
    }
  };

  useEffect(() => {
    if (effectRan.current) {
      fetch();
    }
    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyParams,
    shouldUpdate,
    selectedWorkspacesIds,
    shouldUpdateFromRedux,
    openModalMyAccount,
    debouncedText
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleEdit = (rowData: any) => {
    setToggleUserData(rowData);
  };

  const inputStyles = css`
    display: flex;
    width: 342px;

    @media screen and (max-width: 849px) {
      width: 100%;
    }
  `;

  const styleButtons = css`
    @media screen and (max-width: 849px) {
      width: 342px;
      justify-content: space-between;
    }

    @media screen and (max-width: 494px) {
      justify-content: flex-start;
    }
  `;

  useEffect(() => {
    const screenResolution = () => {
      const widthScreen = window.innerWidth;
      setHideText(widthScreen > 494);
    };
    screenResolution();
    window.addEventListener("resize", screenResolution);

    return () => {
      window.removeEventListener("resize", screenResolution);
    };
  }, []);

  return (
    <div>
      <Toast ref={toast} />
      {/* <AdicionarBar onSetShouldUpdate={(sts) => setStatusFromChild(sts)} /> */}
      <div className="p-5 gap-2">
        <div
          css={css`
            align-items: center;
            display: flex;
            gap: 10px;
          `}
        >
          <img src={userList} />
          <div css={comunCss.TitleContent}>Colaboradores</div>
        </div>

        <div
          css={css`
            margin-top: 10px;
          `}
        >
          Gerencie seus colaboradores, realocando equipe, bots ou editando como
          desejar.
        </div>

        <div
          css={css`
            margin-top: 10px;
          `}
        >
          <div
            css={css`
              background-color: #ffffff;
              border-radius: 10px 10px 0px 0px;
              padding: 8px 12px;
              display: flex;
              gap: 0.5rem;
              flex-wrap: wrap;
            `}
          >
            <span className="p-input-icon-left" css={inputStyles}>
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={(e) => setGlobalFilterValue(e.target.value)}
                id="name"
                placeholder="Buscar"
              />
            </span>

            <div className="flex gap-2" css={styleButtons}>
              <Dropdown
                disabled={true}
                placeholder="Filtrar"
                optionLabel="name"
                optionValue="filter"
                options={[
                  { name: "Ativo", value: "ativo" },
                  { name: "Inativo", value: "inativo" },
                  { name: "Sem bot", value: "sem-bot" },
                  { name: "Com bot", value: "com-bot" }
                ]}
              />

              <Button
                label={hideText ? "Exportar" : ""}
                className="p-button-outlined"
                icon="pi pi-upload"
                disabled={true}
              />
              <Button
                label={hideText ? "Importar" : ""}
                className="p-button-outlined"
                icon="pi pi-download"
                disabled={true}
              />
            </div>
          </div>

          <DataTable
            {...tablePropsData}
            filterDelay={600}
            emptyMessage="Nenhum resultado encontrado"
          >
            <Column field="name" header="Nome" />
            <Column field="phone" header="Telefone" />
            <Column field="email" header="E-mail" />
            <Column field="profile" header="Perfil" />

            <Column
              field="workspaces"
              header="Workspaces"
              align="center"
              alignHeader="center"
              headerStyle={{ width: "10%" }}
              body={(rowData) => (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <div
                    css={css`
                      align-items: center;
                      background: #eaeaea;
                      border-radius: 50px;
                      display: flex;
                      font-size: 12px;
                      justify-content: center;
                      padding: 5px;
                      width: 50px;
                    `}
                  >
                    {rowData.workspaces}
                  </div>
                </div>
              )}
            />

            <Column
              field="active"
              header="Ativo"
              align="center"
              alignHeader="center"
              headerStyle={{ width: "10%" }}
              body={(rowData) => (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {rowData.active ? (
                    <div
                      css={css`
                        align-items: center;
                        background: #aee9d1;
                        border-radius: 50px;
                        display: flex;
                        font-size: 12px;
                        justify-content: center;
                        padding: 5px;
                        width: 50px;
                      `}
                    >
                      Ativo
                    </div>
                  ) : (
                    <div
                      css={css`
                        background: #fa4d64;
                        border-radius: 50px;
                        font-size: 12px;
                        padding: 5px;
                      `}
                    >
                      Inativo
                    </div>
                  )}
                </div>
              )}
            />

            <Column
              align="center"
              alignHeader="center"
              headerStyle={{ width: "12%" }}
              body={(rowData) => (
                <div css={comunCss.dtDiv_bodt}>
                  <Button
                    label="Editar"
                    className="p-button-outlined"
                    onClick={() => toggleEdit(rowData)}
                    css={comunCss.buttonCustom}
                  />
                </div>
              )}
            />
          </DataTable>

          <EditUserContext.Provider value={toggleUserData}>
            <UserEdit
              setToggleUserData={setToggleUserData}
              toggleUserData={toggleUserData}
              showSuccess={showSuccess}
              onShowError={showError}
            />
          </EditUserContext.Provider>
        </div>
      </div>
    </div>
  );
};
