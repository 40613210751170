import styled from "@emotion/styled";

export const Container = styled.div`
border: 1px solid #E5E5E5;
width: 100%;
height: 5.1875rem;
border-radius: 0.5rem;
padding: 0.5rem;
gap: 1rem;
display: flex;
align-items: center;
justify-content: start;
margin-bottom: 0.5rem;
`;
export const IconDiv = styled.div`
width: 2.875rem;
height: 2.875rem;
border-radius: 50%;
background-color: #FFF;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #E5E5E5;
`;
export const Icon = styled.img`
width: 90%;
height: 90%;
border-radius: 50%;
background-color: #FFF;
display: flex;
align-items: center;
justify-content: center;
`;