import { useState } from "react";
import { divWatch, UserBallon, UserBallonPosition } from "./styles"

interface IUserTextBallon {
    content: string
    date: Date
}
export const UserTextBallon: React.FC<IUserTextBallon> = ({content, date}) => {

    return (
        <div css={UserBallon}>
            <div css={UserBallonPosition}>{content}</div>
            <div css={divWatch}>{`${date.toLocaleTimeString().substring(0,5)} · Read`}</div>
        </div>
    )
}