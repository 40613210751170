import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RoundedDiv = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  background-color: rgba(212, 226, 243, 1);
  border-radius: 10px;
  height: 20px;
  /* margin-top: 1rem; */
  width: max-content;
  color: #4c5862;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  & + & {
    margin-left: 0rem;
  }
`;
export const Spacing = styled.div`
  width: 0.5rem;
`;
export const RoundedButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  color: #4c5862;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-right: 2px;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    transition: 0.2s ease-in-out;
  }
`;
