import { css } from "@emotion/react";

export const BotInteractionStart = css`
    background: #DBEEFD;
    border: 3px solid #D8E9F6;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4C5862;
    padding: 1rem;
`;