import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setCustomEntitiesModalOpen,
  setCustomEntityUpdating,
  setEntitiesModalOpen,
  setEntityIdUpdate,
  setInsertEntity,
  setTransferModal,
  toggleOpenEntityDialog
} from "../../../../../redux/slices/flowInteractions";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { Entity } from "../Entity";
import {
  CheckButton,
  ContainerContent,
  EntitiesStyle,
  EntityBlueBar,
  Modal,
  MyImage,
  Overlay,
  Textp,
  Title,
  VDivisor
} from "./styles";
import { Check } from "../../../../../assets";
import { Toast } from "primereact/toast";
import { EntityTypesDB } from "../../../../../types/entityTypes";
import entityTypesServices from "../../../../../services/entityTypesService";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

interface ICustomEntities {
  onClick?: () => void;
}
interface INewEntitiesValues {
  id: number;
  value: string;
}
const envio = {
  name: "@Estados",
  defineSynonyms: true,
  regexpEntity: true,
  allowAutomatedExpansion: true,
  fuzzyMatching: true,
  entities: [
    { id: 1, value: "Pernambuco", synonyms: ["PE", "Pernambuco"] },
    { id: 2, value: "Sergipe", synonyms: ["Sergipe", "SE"] },
    { id: 3, value: "Tocantins", synonyms: ["TO", "Tocantins"] },
    { id: 4, value: "Piauí", synonyms: ["PI", "Piauí"] },
    { id: 5, value: "Santa Catarina", synonyms: ["SC", "Santa Catarina"] },
    { id: 6, value: "Distrito Federal", synonyms: ["DF", "Distrito Federal"] },
    { id: 7, value: "Acre", synonyms: ["AC", "Acre"] },
    { id: 8, value: "Rio de Janeiro", synonyms: ["Rio de Janeiro", "RJ"] },
    { id: 9, value: "Roraima", synonyms: ["RR", "Roraima"] },
    { id: 10, value: "Amazonas", synonyms: ["AM", "Amazonas"] },
    { id: 11, value: "Mato Grosso", synonyms: ["Mato Grosso", "MT"] },
    { id: 12, value: "São Paulo", synonyms: ["SP", "São Paulo", "Sampa"] },
    { id: 13, value: "Paraná", synonyms: ["PR", "Paraná"] },
    { id: 14, value: "Rondônia", synonyms: ["RO", "Rondônia"] },
    { id: 15, value: "Maranhão", synonyms: ["MA", "Maranhão"] },
    { id: 16, value: "Minas Gerais", synonyms: ["Minas Gerais", "MG"] },
    { id: 17, value: "Amapá", synonyms: ["Amapá", "AP"] },
    { id: 18, value: "Paraíba", synonyms: ["PB", "Paraíba"] },
    { id: 19, value: "Espírito Santo", synonyms: ["ES", "Espírito Santo"] },
    { id: 20, value: "Alagoas", synonyms: ["Alagoas", "AL"] },
    {
      id: 21,
      value: "Rio Grande do Norte",
      synonyms: ["RN", "Rio Grande do Norte"]
    },
    { id: 22, value: "Goiás", synonyms: ["Goiás", "GO"] },
    { id: 23, value: "Bahia", synonyms: ["BA", "Bahia"] },
    { id: 24, value: "Ceará", synonyms: ["CE", "Ceará"] },
    { id: 25, value: "Pará", synonyms: ["Pará", "PA"] },
    {
      id: 26,
      value: "Mato Grosso do Sul",
      synonyms: ["MS", "Mato Grosso do Sul"]
    },
    {
      id: 27,
      value: "Rio Grande do Sul",
      synonyms: ["RS", "Rio Grande do Sul"]
    }
  ],
  botId: 1
};
export const CustomEntities: React.FC<ICustomEntities> = ({ onClick }) => {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [NewEntityName, setNewEntityName] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputEdit, setInputEdit] = useState("");
  const [newEntiteValues, setNewEntiteValues] = useState<INewEntitiesValues[]>(
    []
  );
  const [filteredEntiteValues, setFilteredNewEntiteValues] = useState<
    INewEntitiesValues[]
  >([]);
  const { botId } = useParams();
  const [notFound, setNotFound] = useState(false);
  const toast = useRef<Toast>(null);
  const { insertEntities, updateEntities, getEntitiesById } =
    entityTypesServices();
  const entityUpdating = useAppSelector(
    (state) => state.flowInteractions.customEntityUpdating
  );
  const entityIdUpdate = useAppSelector(
    (state) => state.flowInteractions.entityIDUpdate
  );
  const customModalOpen = useAppSelector(
    (state) => state.flowInteractions.customEntityModalOpen
  );
  const handleNewEntitiesValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputEdit(e.target.value);
  };
  const handleNewEntitiesKeypress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setNewEntiteValues((oldstate) =>
        oldstate.concat({
          id: newEntiteValues?.length + 1 || 0,
          value: inputEdit
        })
      );
      setInputEdit("");
    }
  };
  useEffect(() => {
    const updatedEntity = async () => {
      if (entityIdUpdate !== -1 && entityUpdating) {
        const values = await getEntitiesById(entityIdUpdate);
        setNewEntityName(values.displayName);
        const entityValues = values.entities.map(
          (el: { id: number; value: string; synonyms: string[] }) => {
            return {
              id: el.id,
              value: [el.value, ...el.synonyms].join().replaceAll(",", ", ")
            };
          }
        );
        setNewEntiteValues(entityValues);
      } else {
        setSearchInput("");
        setNewEntityName("");
        setNewEntiteValues([]);
        setFilteredNewEntiteValues([]);
      }
    };
    updatedEntity();
  }, [entityIdUpdate, entityUpdating]);

  const handleSalvar = async () => {
    setLoading(true);
    if (!NewEntityName?.length) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Nome da entidade invalido.",
        detail: "Favor inserir um nome para sua entidade.",
        sticky: true
      });
      setLoading(false);
    }
    if (!newEntiteValues?.length) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Entidade inválida.",
        detail:
          "Favor inserir pelo menos um valor/sinônimo para a nova entidade.",
        sticky: true
      });
      setLoading(false);
    }
    if (NewEntityName?.length && newEntiteValues?.length) {
      const saveObject: EntityTypesDB = {
        name: NewEntityName.replaceAll(" ", "_"),
        defineSynonyms: true,
        regexpEntity: false,
        allowAutomatedExpansion: false,
        fuzzyMatching: false,
        entries: newEntiteValues.map((newEntityValue, idx) => {
          const synonymsSplit: any = newEntityValue.value.split(",");
          const synonymsSplitWithoutSpaces = synonymsSplit.map((el: any) =>
            el.trim()
          );
          return {
            id: idx + 1,
            value: synonymsSplitWithoutSpaces[0],
            synonyms: synonymsSplitWithoutSpaces.slice(1)
          };
        }),
        botId: parseInt(botId as string)
      };
      try {
        if (entityUpdating) {
          await updateEntities(entityIdUpdate, saveObject);
          setLoading(false);

          dispatch(setCustomEntitiesModalOpen(false));
          dispatch(setTransferModal(false));
          dispatch(setCustomEntityUpdating(false));
        } else {
          const response = await insertEntities(saveObject);
          if (response?.description === "Entidade já cadastrada!") {
            toast?.current?.show({
              severity: "error",
              summary: "Erro ao salvar nova entidade.",
              detail: `${response.description}. Favor definir outro nome para a entidade `
            });
            setLoading(false);
          } else {
            setSearchInput("");
            setNewEntityName("");
            setNewEntiteValues([]);
            setFilteredNewEntiteValues([]);
            dispatch(setEntityIdUpdate(-1));
            dispatch(setCustomEntityUpdating(false));
            dispatch(setInsertEntity(false));
            dispatch(setCustomEntitiesModalOpen(false));
            dispatch(setTransferModal(false));
            setLoading(false);
          }
        }
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Erro ao salvar nova entidade.",
          detail: `Não foi possível salvar a entidade neste momento. Por favor tente mais tarde.
             Favor definir outro nome para `
        });
        setLoading(false);
      }
    }
  };
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (e.target.value?.length > 0) {
      setFilteredNewEntiteValues(
        newEntiteValues.filter((newEntitie) => {
          return newEntitie.value
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
      if (
        newEntiteValues.filter((newEntitie) =>
          newEntitie.value.toLowerCase().includes(searchInput.toLowerCase())
        )?.length === 0
      ) {
        setFilteredNewEntiteValues([]);
        return setNotFound(true);
      }
      setNotFound(false);
    } else {
      setFilteredNewEntiteValues([]);
      setNotFound(false);
    }
  };
  const handleonClick = () => {
    setNewEntiteValues((oldstate) =>
      oldstate.concat({
        id: newEntiteValues?.length + 1 || 0,
        value: inputEdit
      })
    );
    setInputEdit("");
  };
  const handleEditNewEntitiesValues = (id: number, value: string) => {
    setNewEntiteValues((oldstate) =>
      oldstate.map((oldstate) => {
        if (oldstate.id === id) {
          return {
            id,
            value
          };
        }
        return oldstate;
      })
    );
    setFilteredNewEntiteValues((oldstate) =>
      oldstate.map((oldstate) => {
        if (oldstate.id === id) {
          return {
            id,
            value
          };
        }
        return oldstate;
      })
    );
  };
  const deleteNewEntitiesValues = (id: number) => {
    const oldstate = newEntiteValues;
    const newState = oldstate.filter((newentity) => newentity.id !== id);
    setNewEntiteValues(newState);
    setFilteredNewEntiteValues((oldstate) =>
      oldstate.filter((el) => el.id !== id)
    );
  };
  return (
    <>
      <div
        css={Overlay}
        style={{ display: customModalOpen ? "flex" : "none" }}
      />
      <dialog
        style={{ display: customModalOpen ? "flex" : "none" }}
        open={customModalOpen}
        css={Modal}
      >
        <Toast style={{ position: "sticky", top: 0, zIndex: 15 }} ref={toast} />
        <Button
          style={{ width: "2rem", height: "3rem" }}
          onClick={() => {
            dispatch(setCustomEntitiesModalOpen(!customModalOpen));
            dispatch(setCustomEntityUpdating(false));
            dispatch(setTransferModal(false));
            dispatch(setEntityIdUpdate(-1));
          }}
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
          aria-label="Cancel"
        ></Button>
        <div css={Title}>Entidades personalizadas</div>
        <div className="flex mt-2 w-full justify-content-start align-items-start">
          <p css={Textp}>
            Crie listas personalizadas, para coletar dados e preferências dos
            seus usuários.
          </p>
        </div>
        {/* // Input para o nome da entidade */}
        <span className="mt-2 w-full">
          <InputText
            className="w-full"
            value={NewEntityName}
            onChange={(e) => setNewEntityName(e.target.value)}
            placeholder="Inserir nome da entidade"
          />
        </span>
        <div className="flex mt-3 align-items-center justify-content-center">
          <span css={Textp} />
          <div className="ml-2">
            Você pode inserir sinônimos após a vírgula Ex. São Paulo, Sampa, SP
          </div>
        </div>
        {/* // Input para INSERIR os valores das entidades */}
        <span className="p-input-icon-right mt-2 w-full">
          <div css={VDivisor} />
          <i
            className="pi pi-plus-circle text-blue-600"
            onClick={handleonClick}
          />
          <InputText
            className="w-full"
            value={inputEdit}
            onChange={(e) => setInputEdit(e.target.value)}
            placeholder="Adicionar entidade"
            onKeyUp={(e) => handleNewEntitiesKeypress(e)}
          />
        </span>
        <ContainerContent>
          {/* // Input para BUSCAR os valores das entidades */}
          <span className="p-input-icon-left mt-2 mb-2 w-full">
            <i className="pi pi-search" />
            <InputText
              className="border-round-3xl w-full"
              value={searchInput}
              onChange={(e) => handleFilter(e)}
              placeholder="Buscar"
            />
          </span>
          {/* // Input para DEMONSTRAR os valores das entidades */}
          <div className="flex flex-column w-full m-0">
            {filteredEntiteValues?.length && !notFound ? (
              filteredEntiteValues.map((newEntityValue, i) => (
                <span key={i} className="p-input-icon-right mt-2 w-full">
                  <div css={VDivisor} />
                  <i
                    className="pi pi-times-circle text-red-500"
                    onClick={() => deleteNewEntitiesValues(newEntityValue.id)}
                  />
                  <InputText
                    className="w-full"
                    value={newEntityValue.value}
                    onChange={(e) =>
                      handleEditNewEntitiesValues(
                        newEntityValue.id,
                        e.target.value
                      )
                    }
                    placeholder="Adicionar entidade"
                  />
                </span>
              ))
            ) : notFound ? (
              <span className="mt-2 w-full">
                Não foram encontrados valores em sua busca.
              </span>
            ) : (
              newEntiteValues.map((newEntityValue, i) => (
                <span key={i} className="p-input-icon-right mt-2 w-full">
                  <div css={VDivisor} />
                  <i
                    className="pi pi-times-circle text-red-500"
                    onClick={() => deleteNewEntitiesValues(newEntityValue.id)}
                  />
                  <InputText
                    className="w-full"
                    value={newEntityValue.value}
                    onChange={(e) =>
                      handleEditNewEntitiesValues(
                        newEntityValue.id,
                        e.target.value
                      )
                    }
                    placeholder="Adicionar entidade"
                  />
                </span>
              ))
            )}
          </div>
        </ContainerContent>
        <button
          onClick={handleSalvar}
          css={CheckButton}
          disabled={!NewEntityName?.length || !newEntiteValues?.length}
        >
          {loading ? (
            <div className="mr-2">
              <ProgressSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            <MyImage
              disabled={!NewEntityName?.length || !newEntiteValues?.length}
              src={Check}
              alt="Check-Button"
            />
          )}
          Salvar
        </button>
      </dialog>
    </>
  );
};
